import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React from "react";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";

const ActivityLogGrid = (props) => {
   const useStyles = makeStyles((theme) => ({
      styledGrid: {
         "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important",
         },
         "& .MuiDataGrid-cell": {
            padding: "0.5rem 1rem",
            display: "flex",
            alignItems: "center",
            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal",
         },
         "& .MuiDataGrid-row": {
            maxHeight: "none !important",
         },
      },
   }));

   const classes = useStyles();

   return (
      <Box>
         <Typography
            sx={{
               m: 0,
               p: 0,
               color: "#002D72",
               fontSize: "0.875rem",
               fontWeight: 700,
            }}
            variant="h4"
            component="h4"
         >
            ACTIVITY LOG
         </Typography>
         <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
               <StyledDataGrid
                  className={classes.styledGrid}
                  columns={props.columns}
                  rows={props.tableData ? props.tableData : null}
               ></StyledDataGrid>
            </Grid>
         </Grid>
      </Box>
   );
};

ActivityLogGrid.propTypes = {
   columns: PropTypes.array.isRequired,
   tableData: PropTypes.array.isRequired,
};

ActivityLogGrid.defaultProps = {
   columns: [],
   tableData: [],
};

export default ActivityLogGrid;
