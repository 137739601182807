import { HandleError, HandleResponse } from "./apiUtils";
import { HEADERS } from './headers';
const baseUrl = "/api/adminMessage/";

/**
 * Get Messages
 * @param {*} query
 * @returns
 */
export function getAdminMessage(query) {

   const url = `${baseUrl}getAdminMessage${query ? "?" + query : ""}`;

   return fetch(url, {
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

/**
 * Get messages for the user.
 * @returns
 */
export function getUserMessages() {

   const url = `${baseUrl}getUserMessages`;

   return fetch(url, {
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

/**
 * Get all users that read a message by messageID
 * @param {*} id
 * @returns
 */
export function getUsersByMessageID(id) {

   const url = `${baseUrl}getUsersByMessageID/${id}`;

   return fetch(url, {
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

/**
 * Create or update a message
 * @param {*} adminMessage
 * @returns
 */
export function setAdminMessage(adminMessage) {

   const url = `${baseUrl}setAdminMessage`;

   return fetch(url, {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify(adminMessage),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

/**
 * Update the date read for the message.
 * @param {Array} messageIDs
 */
export function setUserMessages(messageIDs) {

   const url = `${baseUrl}setUserMessages`;

   return fetch(url, {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify(messageIDs),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteAdminMessage(adminMessageID) {

   const url = `${baseUrl}deleteAdminMessage/${adminMessageID}`;

   return fetch(url, {
      method: "DELETE",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}
