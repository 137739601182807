import React from "react";
import { Route, Switch } from "react-router-dom";
import OrdersNew from "./OrdersNew";
import OrdersView from "./OrdersView";

const Orders = (props) => {
   return (
      <>
         <Switch>
            <Route path="/orders" exact component={OrdersView} />
            <Route path="/orders/new/" exact component={OrdersNew} />
            <Route path="/orders/details/:orderid?/" exact component={OrdersNew} />
         </Switch>
      </>
   );
};

export default Orders;
