import { HandleError, HandleResponse } from "./apiUtils";
import { HEADERS } from "./headers";
const baseUrl = "/api/rate/";

export class SearchQuery {
   constructor(custID, terminalID, destination, validRatesOnly) {
      this.custID = custID;
      this.terminalID = terminalID;
      this.destination = destination;
      this.validRatesOnly = validRatesOnly;
   }
}

export async function searchRates(searchQuery = []) {

   return await fetch(baseUrl + "searchRates", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ searchQuery }),
   })
      .then(await HandleResponse)
      .catch(await HandleError);
}

export function saveRate(rate) {

   var arg = {
      rate: rate,
   };

   return fetch(baseUrl + "saveRates", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify(arg),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function searchAllRates(terminalID, destination) {

   return fetch(baseUrl + "searchRates", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({
         searchQuery: [
            {
               terminalID: terminalID,
               destination: destination,
            },
         ],
      }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteRate(rateID, termID, custID) {

   let url = baseUrl + "deleteRate?";
   if (rateID) {
      url += "rateID=" + rateID;
   }
   if (termID) {
      url += "&termID=" + termID;
   }
   if (custID) {
      url += "&custID=" + custID;
   }
   return fetch(url, {
      method: "delete",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export async function exportRates(termID = "", custID = "", userID = "", destination = null, exportType = '', searchQuery = []) {

   return await fetch(baseUrl + "exportRates", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ termID, custID, userID, destination, exportType, searchQuery }),
   })
      .then(await HandleResponse)
      .catch(await HandleError);
}

export async function extendRates(termID = "", custID = "", userID = "", extendDate = "") {

   return await fetch(baseUrl + "extendRates", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ termID, custID, userID, extendDate }),
   })
      .then(await HandleResponse)
      .catch(await HandleError);
}

export async function expireRates(termID = "", custID = "", userID = "", expireDate = "") {

   return await fetch(baseUrl + "expireRates", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ termID, custID, userID, expireDate }),
   })
      .then(await HandleResponse)
      .catch(await HandleError);
}