import actionTypes from "../actions/actionTypes";

const initialState = [];

export default function terminalReducer(state = initialState, action) {
   switch (action.type) {
      case actionTypes.GET_TERMINALS:
         return action.terminals;
      default:
         return state;
   }
}
