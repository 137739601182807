import { HandleError, HandleResponse } from "../../api/apiUtils";
import * as customerApi from "../../api/customerApi";
import actionTypes from "./actionTypes";

export function getCustomers() {
   return function (dispatch) {
      customerApi
         .getCustomer()
         .then((resp) => {
            if (resp) {
               dispatch({ type: actionTypes.GET_CUSTOMERS, customer: resp.customer, customerUser: resp.user });
            }
         })
         .catch((err) => {
            console.error("Getting customers error: " + err);
            return;
         });
   };
}

export function saveCustomer(customer) {
   //no need to dispatch anything because the global store should remain the same.
   return function (dispatch) {
      customerApi.saveCustomer(customer).then(HandleResponse).catch(HandleError);
   };
}
