export function activityCompareObjects(moduleName, newObject, oldObject, userID) {
   var activity = [];

   for (const value in newObject) {
      if (newObject[value] != null) {
         if (typeof newObject[value] === "object" || typeof newObject[value] === "array") {
            for (const object in newObject[value]) {
               if (typeof newObject[value][object] === "object" || typeof newObject[value] === "array") {
                  for (const childObject in newObject[value][object]) {
                     if (
                        typeof newObject[value][object][childObject] === "object" ||
                        typeof newObject[value][object] === "array"
                     ) {
                        for (const grandChildObject in newObject[value][object][childObject]) {
                           if (
                              JSON.stringify(newObject[value][object][childObject][grandChildObject]) !==
                              JSON.stringify(oldObject[value][object][childObject][grandChildObject])
                           ) {
                              var act = {
                                 module: moduleName,
                                 record_id: newObject._id
                                    ? newObject._id
                                    : newObject.code
                                    ? newObject.code
                                    : newObject.name
                                    ? newObject.name
                                    : newObject.email,
                                 event:
                                    "Updated " +
                                    object +
                                    ": " +
                                    (newObject.code
                                       ? newObject.code
                                       : newObject.name
                                       ? newObject.name
                                       : newObject.email
                                       ? newObject.email
                                       : newObject._id) +
                                    ". Changed : " +
                                    grandChildObject +
                                    " Original value: " +
                                    JSON.stringify(oldObject[value][object][childObject][grandChildObject]) +
                                    " New value: " +
                                    JSON.stringify(newObject[value][object][childObject][grandChildObject]),
                                 user_id: userID,
                              };

                              activity.push(act);
                           }
                        }
                     } else {
                        if (
                           JSON.stringify(newObject[value][object][childObject]) !==
                           JSON.stringify(oldObject[value][object][childObject])
                        ) {
                           var act = {
                              module: moduleName,
                              record_id: newObject._id
                                 ? newObject._id
                                 : newObject.code
                                 ? newObject.code
                                 : newObject.name
                                 ? newObject.name
                                 : newObject.email,
                              event:
                                 "Updated " +
                                 value +
                                 ": " +
                                 (newObject.code
                                    ? newObject.code
                                    : newObject.name
                                    ? newObject.name
                                    : newObject.email
                                    ? newObject.email
                                    : newObject._id) +
                                 ". Changed : " +
                                 childObject +
                                 " Original value: " +
                                 JSON.stringify(oldObject[value][object][childObject]) +
                                 " New value: " +
                                 JSON.stringify(newObject[value][object][childObject]),
                              user_id: userID,
                           };

                           activity.push(act);
                        }
                     }
                  }
               } else if (JSON.stringify(newObject[value][object]) !== JSON.stringify(oldObject[value][object])) {
                  var act = {
                     module: moduleName,
                     record_id: newObject._id
                        ? newObject._id
                        : newObject.code
                        ? newObject.code
                        : newObject.name
                        ? newObject.name
                        : newObject.email,
                     event:
                        "Updated " +
                        value +
                        ": " +
                        (newObject.code
                           ? newObject.code
                           : newObject.name
                           ? newObject.name
                           : newObject.email
                           ? newObject.email
                           : newObject._id) +
                        ". Changed : " +
                        object +
                        " Original value: " +
                        JSON.stringify(oldObject[value][object]) +
                        " New value: " +
                        JSON.stringify(newObject[value][object]),
                     user_id: userID,
                  };

                  activity.push(act);
               }
            }
         } else if (oldObject[value] !== newObject[value]) {
            var act = {
               module: moduleName,
               record_id: newObject._id
                  ? newObject._id
                  : newObject.code
                  ? newObject.code
                  : newObject.name
                  ? newObject.name
                  : newObject.email,
               event:
                  "Updated: " +
                  (newObject.code
                     ? newObject.code
                     : newObject.name
                     ? newObject.name
                     : newObject.email
                     ? newObject.email
                     : newObject._id) +
                  ". Changed : " +
                  value +
                  " Original value: " +
                  oldObject[value] +
                  " New value: " +
                  newObject[value],
               user_id: userID,
            };

            activity.push(act);
         }
      }
   }

   return activity;
}
