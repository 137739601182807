import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
   tableGridContainer: {
      autoPageSize: true,
      height: "715px",
      width: "100%",
      "& .MuiDataGrid-root": {
         border: "none",
      },
      "& .MuiDataGrid-root .MuiDataGrid-virtualScroller": {
         borderTop: "2px solid #002D72",
      },
      "& .MuiDataGrid-root .MuiDataGrid-row .MuiDataGrid-cell": {
         border: "none",
         borderBottom: "2px solid #A9A9A9",
         fontSize: 16,
      },
      "& .MuiDataGrid-cell": {
         paddingLeft: "1rem",
         textAlign: "left !important",
      },
      "& .MuiDataGrid-columnHeaderTitle": {
         color: "#002D72",
         fontSize: 14,
         fontWeight: 600,
         marginLeft: ".25rem",
      },
      "& .MuiDataGrid-iconButtonContainer .MuiButtonBase-root": {
         fontSize: "2rem",
         padding: 0,
         "& .MuiIconButton-label": {
            color: "#002D72",
         },
      },
      "& .MuiDataGrid-cell.MuiDataGrid-cell--editable": {
         fontWeight: "bold",
      },
      "& .MuiInputBase-input.Mui-disabled": {
         fontWeight: "bold",
         color: theme.palette.text.primary,
      },
      "& .MuiInput-underline.Mui-disabled:before": {
         borderBottomStyle: "groove",
      },
      "& .MuiDataGrid-sortIcon": {
         color: "#002D72",
         marginLeft: ".25rem",
      },
   },
}));

export default useStyles;
