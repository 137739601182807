import actionTypes from "../actions/actionTypes";

const initialState = {
   currentUser: {},
};

export default function userReducer(state = initialState, action) {
   switch (action.type) {
      case actionTypes.ADD_SOCKET:
         return { ...state, socket: action.socket };
      default:
         return state;
   }
}
