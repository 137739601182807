import { HandleError, HandleResponse } from "./apiUtils";
import { HEADERS, HEADERS_WITH_AUTHORIZATION } from "./headers";
const baseUrl = "/api/customer/";

export function getCustomer(custId = null) {

   let url = baseUrl + "getCustomer?";
   if (custId) {
      url += "custID=" + custId;
   }

   return fetch(url, {
      method: "GET",
      headers: HEADERS_WITH_AUTHORIZATION(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function saveCustomer(customer, isNew = false) {


   return fetch(baseUrl + "saveCustomer", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ customer, isNew }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteCustomer(customerId) {

   let url = baseUrl + "deleteCustomer?";
   if (customerId) {
      url += "custID=" + customerId;
   }
   return fetch(url, {
      method: "DELETE",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function sendInvite(emailAddresses = [], custID = "", customerName = "") {


   return fetch(baseUrl + "register", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ emailAddresses, custID, customerName }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteToken(customerId, inviteToken) {

   let url = baseUrl + "deleteCustomerInvite?";

   if (customerId) {
      url += "custID=" + customerId;
   }

   if (inviteToken) {
      url += "&token=" + inviteToken;
   }

   return fetch(url, {
      method: "DELETE",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}
