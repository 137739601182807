import { Paper } from "@mui/material";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import TransferList from "../TransferList/TransferList";

const TerminalGroup = (props) => {
   var handleError = useErrorHandler();

   //transferlist variables
   let [transferListLeft, setTransferListLeft] = useState([]);
   let [transferListRight, setTransferListRight] = useState([]);

   useEffect(() => {
      //load left column of transfer list
      var leftList = [];
      props.termGroupList.map((term) => {
         leftList.push({ _id: term._id, name: term.name });
      });

      //load right column of transfer list
      var rightList = [];
      props.selectedTerminal.group.terminal_ids.map((termID) => {
         var term = props.terminals.find((terminal) => {
            return terminal._id === termID;
         });
         if (term) {
            rightList.push({ _id: term._id, name: term.name });
         }
      });

      setTransferListLeft(leftList);
      setTransferListRight(rightList);
   }, []);

   const getLists = (leftList, rightList) => {
      try {
         setTransferListLeft(leftList);
         setTransferListRight(rightList);

         //set assigned terminals to group terminal based on "right" list
         var newSelectedTerminal = _.cloneDeep(props.selectedTerminal);

         newSelectedTerminal.group.terminal_ids = _.map(rightList, "_id");
         props.setSelectedTerminal(newSelectedTerminal);
      } catch (err) {
         handleError(err);
      }
   };

   return (
      <>
         <Paper elevation={3}>
            <TransferList
               leftItems={transferListLeft}
               rightItems={transferListRight}
               height={800}
               getLists={getLists}
               leftLabel="Available Terminals"
               rightLabel="Assigned Terminals"
            />
         </Paper>
      </>
   );
};

TerminalGroup.propTypes = {
   termGroupList: PropTypes.array.isRequired,
   terminals: PropTypes.array.isRequired,
   selectedTerminal: PropTypes.object.isRequired,
   setSelectedTerminal: PropTypes.func.isRequired,
};

TerminalGroup.defaultProps = {
   termGroupList: [],
   terminals: [],
   selectedTerminal: {},
   setSelectedTerminal: () => {
      return;
   },
};

export default TerminalGroup;
