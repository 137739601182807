import { combineReducers } from "redux";
import accessorialGuide from "./accessorialGuideReducer";
import accessorial from "./accessorialReducer";
import customer from "./customerReducer";
import fuel from "./fuelReducer";
import notification from "./notificationsReducer";
import snackbar from "./snackBarReducer";
import socket from "./socketRuducer";
import terminal from "./terminalReducer";
import user from "./userReducer";

const rootReducer = combineReducers({
   user,
   customer,
   terminal,
   notification,
   fuel,
   snackbar,
   accessorial,
   accessorialGuide,
   socket,
});

export default rootReducer;
