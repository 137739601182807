import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, InputAdornment, Paper, TextField, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as userApi from "../../api/userApi";
import { escapeRegExp } from "../../common/escapeRegExp";
import * as userActions from "../../redux/actions/userActions";
import DeleteDialog from "../Delete/DeleteDialog";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";
import "./User.css";
import UserInvite from "./UserInvite";

const User = (props) => {
   //////////////////////////init //////////////////////////////////////////////////////////
   const history = useHistory();
   const handleError = useErrorHandler();

   const [newUserModal, setNewUserModal] = useState(false);
   const [deleteModal, setDeleteModal] = useState(false);
   const [selectedUser, setSelectedUser] = useState(null);
   const [openDrawer, setOpenDrawer] = useState(false);
   const [tableData, setTableData] = useState([]);
   const [gridLoading, setGridLoading] = useState(true);
   const [showAlert, setShowAlert] = useState(false);
   const [alertMessage, setAlertMessage] = useState(null);
   const [alertType, setAlertType] = useState("");
   const [readOnly, setReadOnly] = useState(false);

   useEffect(() => {
      var newUserObject = _.cloneDeep(props.users);

      if (newUserObject) {
         if (newUserObject.length) {
            Object.keys(newUserObject).forEach(function (key) {
               newUserObject[key].id = newUserObject[key]._id;
            });

            setTableData(newUserObject);
            setGridLoading(false);
         }
      }
   }, [props.users]);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "intusermanagement");
      var userReadOnly = true;
      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         borderBottom: "1px solid #2F3136",
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         fontSize: "2rem",
      },
      save: {
         float: "right",
         fontSize: "2rem",
         marginRight: "1rem",
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      inputRoot: {},
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
      listDrawer: {
         width: "300px",
         maxWidth: 360,
         backgroundColor: theme.palette.background.paper,
      },
   }));

   const classes = useStyles();

   function newUserClick() {
      try {
         setNewUserModal(true);
         setOpenDrawer(false);
      } catch (err) {
         handleError(err);
      }
   }

   var headerData = [
      { id: "firstname", allign: "left", disablePadding: false, label: "First Name", type: "string" },
      { id: "lastname", allign: "center", disablePadding: false, label: "Last Name", type: "string" },
      { id: "email", allign: "center", disablePadding: false, label: "Email", type: "string" },
      { id: "active", allign: "center", disablePadding: false, label: "Active", type: "boolean" },
   ];

   function deleteUser() {
      setDeleteModal(!deleteModal);

      userApi
         .deleteUser(selectedUser._id)
         .then((resp) => {
            //success
            openAlertMessage("User Successfully Deleted.", "success");
            //update original record in termlist
            props.getInternalUsers();
         })
         .catch(console.error());
   }

   const handleDeleteClick = (user) => {
      try {
         setSelectedUser(user.row);
         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   };

   function handleEditClick(user) {
      history.push({ pathname: `/user/details/${user.row._id}` });
   }

   function onChangeFilter(event, searchValue = null) {
      try {
         //create blank array for return
         var filteredObjects = [];
         //this turns values into lowercase strings
         var regValue = new RegExp(
            searchValue ? searchValue.toLowerCase() : escapeRegExp(event.target.value).toLowerCase(),
            "g",
         );
         //loop through header data since it contains the field names we need to check
         headerData.map((header) => {
            var filter = [];
            //we can only do this on strings, so make sure the type is filled in via the parent component
            if (header.type === "string") {
               //fill the filter on the original table data that was passed in from parent
               filter = props.users.filter((filt) => {
                  //return a match where the filter index is the name of the header id
                  return filt[header.id].toLowerCase().match(regValue);
               });
            } else {
               //fill the filter on the original table data that was passed in from parent
               filter = props.users.filter(
                  (filt) => filt[header.id === (searchValue ? searchValue : event.target.value)],
               );
            }
            //check to see if it returned objects
            if (filter.length > 0) {
               //concat merges arrays since we are adding data from one array to another.  Push only adds a single item/object to an existing array
               filteredObjects = filteredObjects.concat(filter);
            }
         });
         //remove duplicates
         filteredObjects = _.uniqBy(filteredObjects, "_id");

         if (filteredObjects) {
            if (filteredObjects.length) {
               Object.keys(filteredObjects).forEach(function (key) {
                  filteredObjects[key].id = filteredObjects[key]._id;
               });
            }
         }

         //set the hook with the newly filtered data and it will render
         setTableData(filteredObjects);
         setGridLoading(false);
      } catch (err) {
         setGridLoading(false);
         handleError(err);
      }
   }

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   return (
      <>
         <div id={"userView"} className={classes.layoutMain}>
            <div id={"userViewHeader"} className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                  <Grid item xs={7}>
                     <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                        <Link color="inherit" style={{ cursor: "pointer" }} onClick={() => breadcrumbOnClick("/user")}>
                           <h3 style={{ fontWeight: "500" }}>{"Users"}</h3>
                        </Link>
                     </Breadcrumbs>
                  </Grid>
                  <Grid item xs={3} display="flex" justifyContent="flex-end">
                     <TextField
                        style={{
                           width: "100%",
                           borderRadius: "3px",
                        }}
                        id="filled-basic"
                        placeholder="Search"
                        size="small"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <SearchIcon style={{ color: "#000000DE" }} />
                              </InputAdornment>
                           ),
                        }}
                        onChange={onChangeFilter}
                     />
                  </Grid>
                  <Grid item xs={2}>
                     <Tooltip title="New User" arrow>
                        <IconButton
                           style={{ float: "right", marginRight: "1rem" }}
                           variant="contained"
                           color="primary"
                           onClick={newUserClick}
                           disabled={readOnly}
                        >
                           <AddIcon style={{ fontSize: "2rem" }} />
                        </IconButton>
                     </Tooltip>
                  </Grid>
               </Grid>
               <Collapse in={showAlert}>
                  <Alert
                     style={{ color: "#FFFFFF" }}
                     variant="filled"
                     severity={alertType ? alertType : "success"}
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           sx={{ marginBottom: "0.25rem" }}
                           onClick={() => {
                              {
                                 closeAlertMessage();
                              }
                           }}
                        >
                           <CloseIcon fontSize="inherit" />
                        </IconButton>
                     }
                  >
                     {alertMessage}
                  </Alert>
               </Collapse>
            </div>
            <div className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}>
               <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
                  <Grid item xs={12}>
                     <div>
                        <Paper elevation={3}>
                           <StyledDataGrid
                              gridHeight={"785px"}
                              loading={gridLoading}
                              columns={[
                                 {
                                    field: "firstname",
                                    headerName: "First Name",
                                    description: "First name of the user.",
                                    type: "string",
                                    flex: 0.3,
                                 },
                                 {
                                    field: "lastname",
                                    headerName: "Last Name",
                                    description: "Last name of the user.",
                                    type: "string",
                                    flex: 0.3,
                                 },
                                 {
                                    field: "email",
                                    headerName: "Email",
                                    description: "Email address of the user.",
                                    type: "string",
                                    flex: 0.4,
                                 },
                                 {
                                    field: "active",
                                    headerName: "Active",
                                    description: "Active True|False.",
                                    type: "string",
                                    flex: 0.2,
                                 },
                                 {
                                    field: "_id",
                                    headerName: " ", //This is a space because material-UI default to field unless there is a value.
                                    type: "string",
                                    sortable: false,
                                    filterable: false,
                                    renderCell: (params) => (
                                       <div>
                                          <Tooltip title="Edit User" arrow>
                                             <IconButton
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => handleEditClick(params)}
                                             >
                                                <EditIcon />
                                             </IconButton>
                                          </Tooltip>
                                          |
                                          <Tooltip title="Delete User" arrow>
                                             <IconButton
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                onClick={() => handleDeleteClick(params)}
                                                disabled={readOnly}
                                             >
                                                <DeleteForeverIcon />
                                             </IconButton>
                                          </Tooltip>
                                       </div>
                                    ),
                                    flex: 0.1,
                                 },
                              ]}
                              rows={tableData ? tableData : null}
                           ></StyledDataGrid>
                        </Paper>
                     </div>
                  </Grid>
               </Grid>
               {/* </Modal> */}
               <DeleteDialog
                  open={deleteModal}
                  handleCancel={() => setDeleteModal(!deleteModal)}
                  deleteFunc={deleteUser}
                  title={selectedUser && `Delete ${selectedUser.email}`}
                  text={selectedUser && `Are you sure you want to delete ${selectedUser.email} from internal users?`}
               />
               <UserInvite
                  open={newUserModal}
                  handleClose={() => setNewUserModal(!newUserModal)}
                  user={props.user}
                  openAlertMessage={openAlertMessage}
               />
            </div>
         </div>
      </>
   );
};

function mapStateToProps(state) {
   return {
      terminals: state.terminal.terminals || [],
      users: state.user.internalUsers || [],
      customer: state.customer !== null ? state.customer.customer : [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getInternalUsers: () => dispatch(userActions.getInternalUsers()),
   };
}

User.propTypes = {
   customer: PropTypes.array.isRequired,
   terminals: PropTypes.array.isRequired,
   users: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   getInternalUsers: PropTypes.func.isRequired,
};

User.defaultProps = {
   customer: [],
   terminals: [],
   users: [],
   user: {},
   getInternalUsers: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
