import * as accessorialApi from "../../api/accessorialApi";
import actionTypes from "./actionTypes";

export function getTariffs() {
   return function (dispatch) {
      accessorialApi
         .getTariffs()
         .then((resp) => {
            dispatch({ type: actionTypes.GET_ACCESSORIAL_TARIFFS, tariffs: resp || [] });
         })
         .catch((err) => {
            console.error("Getting accessorial tariffs error: " + err);
            return [];
         });
   };
}

export function updateTariff(tariff) {
   return { type: actionTypes.UPDATE_ACCESSORIAL_TARIFF, tariff };
}
