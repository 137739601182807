import { GetApp } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, Paper, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Collapse from "@mui/material/Collapse";
import { green } from "@mui/material/colors";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as applicationApi from "../../api/applicationApi";
import * as rateApi from "../../api/rateApi";
import { dateFormatter } from "../../common/dateFormatter";
import { escapeRegExp } from "../../common/escapeRegExp";
import * as terminalActions from "../../redux/actions/terminalActions";
import DeleteDialog from "../Delete/DeleteDialog";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";
import TerminalRatesDialog from "../TerminalRatesDialog/TerminalRatesDialog";
import { headerCSV } from "./ExportHeader";
import "./Terminal.css";
import TerminalNew from "./TerminalNew";

const useStyles = makeStyles((theme) => ({
   secondaryHeader: {
      borderBottom: "1px solid #2F3136",
      zIndex: "3",
      width: "100%",
   },
   layoutMain: {
      marginTop: "2rem",
      position: "fixed",
      width: "inherit",
      height: "100%",
   },
   layoutBody: {
      position: "absolute",
      height: "calc(100% - 9.5rem)",
      width: "100%",
      overflowY: "scroll",
   },
   layoutBody2: {
      position: "absolute",
      height: "calc(100% - 12.5rem)",
      width: "100%",
      overflowY: "scroll",
   },
   search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      border: "1px solid black",
      backgroundColor: "#ffff",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "30%",
      float: "right",
      [theme.breakpoints.up("sm")]: {
         marginLeft: theme.spacing(3),
         width: "auto",
      },
   },
   searchIcon: {
      color: "black",
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "2rem",
   },
   save: {
      float: "right",
      fontSize: "2rem",
      marginRight: "1rem",
   },
   back: {
      color: "white",
      backgroundColor: "#2F3136",
      float: "right",
      marginRight: "1rem",
      "&:hover": {
         backgroundColor: "black",
      },
   },
   inputRoot: {},
   inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
         width: "20ch",
      },
   },
   listGroup: {
      width: "100%",
      maxHeight: "59%",
   },
   tabInfo: {
      maxHeight: "59%",
   },
   modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   modalInfo: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
   },
   tabForm: {
      paddingTop: "2%",
   },
   listDrawer: {
      width: "300px",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
   },
   buttonProgress: {
      color: green[500],
   },
}));

const TerminalView = (props) => {
   //////////////////////////init //////////////////////////////////////////////////////////
   var history = useHistory();
   var handleError = useErrorHandler();

   let [tableData, setTableData] = useState([]);
   let [gridLoading, setGridLoading] = useState(true);
   let [newTerminalModal, setNewTerminalModal] = useState(false);
   let [deleteModal, setDeleteModal] = useState(false);
   let [selectedTerminal, setSelectedTerminal] = useState(null);
   let [openDrawer, setOpenDrawer] = useState(false);
   let [showAlert, setShowAlert] = useState(false);
   let [alertMessage, setAlertMessage] = useState(null);
   let [alertType, setAlertType] = useState("");
   let [readOnly, setReadOnly] = useState(false);
   let [exportingRates, setExportingRates] = useState(false);
   const [extendDate, setExtendDate] = useState(null);
   const [expireDate, setExpireDate] = useState(null);
   let [terminalRatesModal, setTerminalRatesModal] = useState(false);
   let [confirmExtendRatesModal, setConfirmExtendRatesModal] = useState(false);
   let [confirmExpireRatesModal, setConfirmExpireRatesModal] = useState(false);

   const classes = useStyles();

   var headerData = [
      { id: "name", allign: "left", disablePadding: true, label: "Name", type: "string" },
      { id: "code", allign: "center", disablePadding: false, label: "Code", type: "string" },
      { id: "active", allign: "center", disablePadding: false, label: "Active", type: "boolean" },
      { id: "add_date", allign: "center", disablePadding: false, label: "Date Added", type: "date" },
   ];

   useEffect(() => {
      var newTerminalObject = _.cloneDeep(props.terminals);

      if (newTerminalObject) {
         if (newTerminalObject.length) {
            Object.keys(newTerminalObject).forEach(function (key) {
               newTerminalObject[key].id = newTerminalObject[key]._id;
            });

            setTableData(newTerminalObject);
            setGridLoading(false);
         }
      }
   }, [props.terminals]);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "termmanagement");
      var userReadOnly = true;

      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   function newTermClick() {
      try {
         setOpenDrawer(false);
         setNewTerminalModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   function deleteTerm() {
      setDeleteModal(!deleteModal);

      applicationApi
         .deleteTerminal(selectedTerminal._id)
         .then((resp) => {
            //success
            openAlertMessage("Terminal Successfully Deleted.", "success");

            //update original record in termlist
            props.getTerminals();
         })
         .catch((err) => {
            openAlertMessage(`Error deleting terminal: ${err}`, "error");
         });
   }

   const handleDeleteClick = (term) => {
      try {
         setSelectedTerminal(term.row);
         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   };

   function handleEditClick(term) {
      try {
         history.push({ pathname: `/terminal/details/${term.row._id}` });
      } catch (err) {
         handleError(err);
      }
   }

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   function onChangeFilter(event, searchValue = null) {
      try {
         setGridLoading(true);

         //create blank array for return
         var filteredObjects = [];
         //this turns values into lowercase strings
         var regValue = new RegExp(
            searchValue ? searchValue.toLowerCase() : escapeRegExp(event.target.value).toLowerCase(),
            "g",
         );
         //loop through header data since it contains the field names we need to check
         headerData.map((header) => {
            var filter = [];
            //we can only do this on strings, so make sure the type is filled in via the parent component
            if (header.type === "string") {
               //fill the filter on the original table data that was passed in from parent
               filter = props.terminals.filter((filt) => {
                  //return a match where the filter index is the name of the header id
                  return filt[header.id].toLowerCase().match(regValue);
               });
            } else {
               //fill the filter on the original table data that was passed in from parent
               filter = props.terminals.filter(
                  (filt) => filt[header.id === (searchValue ? searchValue : event.target.value)],
               );
            }
            //check to see if it returned objects
            if (filter.length > 0) {
               //concat merges arrays since we are adding data from one array to another.  Push only adds a single item/object to an existing array
               filteredObjects = filteredObjects.concat(filter);
            }
         });
         //remove duplicates
         filteredObjects = _.uniqBy(filteredObjects, "_id");

         if (filteredObjects) {
            if (filteredObjects.length) {
               Object.keys(filteredObjects).forEach(function (key) {
                  filteredObjects[key].id = filteredObjects[key]._id;
               });
            }
         }

         //set the hook with the newly filtered data and it will render
         setTableData(filteredObjects);
         setGridLoading(false);
      } catch (err) {
         setGridLoading(false);
         handleError(err);
      }
   }

   // function exportRates() {
   //    setExportingRates(true);

   //    rateApi
   //       .exportRates()
   //       .then((resp) => {
   //          console.log(resp);

   //          //success
   //          openAlertMessage("Terminal Rates Successfully Exported.", "success");

   //          //update original record in termlist
   //          props.getTerminals();
   //          setExportingRates(false);
   //       })
   //       .catch((err) => {
   //          openAlertMessage(`${err}`, "error");
   //          setExportingRates(false);
   //       });
   // }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   function closeTerminalRates() {
      setTerminalRatesModal(false);
      setConfirmExtendRatesModal(false);
      setConfirmExpireRatesModal(false);
      setExtendDate(null);
      setExpireDate(null);
   }

   function confirmExtendRates() {
      setTerminalRatesModal(false);
      setConfirmExtendRatesModal(true);
   }

   function closeConfirmExtendRates() {
      setTerminalRatesModal(false);
      setConfirmExtendRatesModal(false);
      setExtendDate(null);
   }

   function extendRates() {
      setTerminalRatesModal(false);
      setConfirmExtendRatesModal(false);

      rateApi
         .extendRates("", "", props.user._id, extendDate)
         .then((resp) => {
            openAlertMessage(
               "Rate Extension has started. You will receive a notification and email once it has completed",
               "success",
            );
         })
         .catch((err) => openAlertMessage(`Error extending rates: ${err}`, "error"));

      setExtendDate(null);
   }

   function confirmExpireRates() {
      setTerminalRatesModal(false);
      setConfirmExpireRatesModal(true);
   }

   function closeConfirmExpireRates() {
      setTerminalRatesModal(false);
      setConfirmExpireRatesModal(false);
      setExpireDate(null);
   }

   function expireRates() {
      setTerminalRatesModal(false);
      setConfirmExpireRatesModal(false);

      rateApi
         .expireRates("", "", props.user._id, expireDate)
         .then((resp) => {
            openAlertMessage(
               "Rate Expiration has started. You will receive a notification and email once it has completed",
               "success",
            );
         })
         .catch((err) => openAlertMessage(`Error expiring rates: ${err}`, "error"));

      setExpireDate(null);
   }

   //data scrubbing function to return string values for display in table

   //////////////////render////////////////////////////////////
   return (
      <>
         <div id={"terminalView"} className={classes.layoutMain}>
            <div id={"terminalViewHeader"} className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                  <Grid item xs={7}>
                     <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                        <Link
                           color="inherit"
                           style={{ cursor: "pointer" }}
                           onClick={() => breadcrumbOnClick("/terminal")}
                        >
                           <h3 style={{ fontWeight: "500" }}>{"Terminals"}</h3>
                        </Link>
                     </Breadcrumbs>
                  </Grid>
                  <Grid item xs={3}>
                     <TextField
                        style={{
                           width: "100%",
                           borderRadius: "3px",
                        }}
                        id="filled-basic"
                        placeholder="Search"
                        variant="outlined"
                        size="small"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <SearchIcon style={{ color: "#000000DE" }} />
                              </InputAdornment>
                           ),
                        }}
                        onChange={onChangeFilter}
                     />
                  </Grid>
                  <Grid item xs={2}>
                     {/* <Tooltip title="Export Terminal Rates" arrow>
                        <IconButton
                           style={{ marginRight: "1rem", float: "right" }}
                           variant="contained"
                           color="primary"
                           onClick={exportRates}
                           disabled={props.user.security.admin ? false : true}
                        >
                           {exportingRates ? (
                              <CircularProgress size={32} className={classes.buttonProgress} />
                           ) : (
                              <ImportExportIcon style={{ fontSize: "2rem" }} />
                           )}
                        </IconButton>
                     </Tooltip> */}
                     <Tooltip title="Extend CPG Default Rates" arrow>
                        <IconButton
                           style={{ float: "right", marginRight: "1rem" }}
                           variant="contained"
                           color="primary"
                           onClick={() => setTerminalRatesModal(true)}
                           disabled={props.user.security.admin ? false : true}
                        >
                           <DateRangeIcon style={{ fontSize: "2rem" }} />
                        </IconButton>
                     </Tooltip>
                     <CSVLink
                        style={{ WebkitTextFillColor: "white", width: "-webkit-fill-available" }}
                        data={props.terminals ? props.terminals : []}
                        headers={headerCSV}
                        filename={Date.now() + "_terminals.csv"}
                     >
                        <Tooltip title="Export Terminal Info" arrow>
                           <IconButton style={{ float: "right" }} variant="contained" color="primary">
                              <GetApp style={{ fontSize: "2rem" }} />
                           </IconButton>
                        </Tooltip>
                     </CSVLink>
                     <Tooltip title="New Terminal" arrow>
                        <IconButton
                           style={{ float: "right" }}
                           variant="contained"
                           color="primary"
                           onClick={newTermClick}
                           disabled={readOnly}
                        >
                           <AddIcon style={{ fontSize: "2rem" }} />
                        </IconButton>
                     </Tooltip>
                  </Grid>
               </Grid>
               <Collapse in={showAlert}>
                  <Alert
                     style={{ color: "#FFFFFF" }}
                     variant="filled"
                     severity={alertType ? alertType : "success"}
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                              {
                                 closeAlertMessage();
                              }
                           }}
                        >
                           <CloseIcon fontSize="inherit" />
                        </IconButton>
                     }
                  >
                     {alertMessage}
                  </Alert>
               </Collapse>
            </div>
            <div className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}>
               <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
                  <Grid item xs={12}>
                     <Paper elevation={3}>
                        <StyledDataGrid
                           gridHeight={"785px"}
                           loading={gridLoading}
                           className={classes.colCellTitle}
                           columns={[
                              {
                                 field: "name",
                                 headerName: "Name",
                                 description: "Name description of the terminal.",
                                 type: "string",
                                 flex: 0.6,
                              },
                              {
                                 field: "code",
                                 headerName: "Code",
                                 description: "Code ID of the terminal.",
                                 type: "string",
                                 flex: 0.2,
                              },
                              {
                                 field: "active",
                                 headerName: "Active",
                                 description: "Active True|False.",
                                 type: "string",
                                 flex: 0.2,
                              },
                              {
                                 field: "add_date",
                                 headerName: "Date Added",
                                 description: "Date when customer was first added.",
                                 type: "date",
                                 valueFormatter: ({ value }) => dateFormatter(value),
                                 flex: 0.2,
                              },
                              {
                                 field: "_id",
                                 headerName: " ", //This is a space because material-UI default to field unless there is a value.
                                 type: "string",
                                 sortable: false,
                                 filterable: false,
                                 renderCell: (params) => (
                                    <div>
                                       <Tooltip title="Edit Terminal" arrow>
                                          <IconButton
                                             variant="contained"
                                             color="primary"
                                             size="small"
                                             onClick={() => handleEditClick(params)}
                                          >
                                             <EditIcon />
                                          </IconButton>
                                       </Tooltip>
                                       |
                                       <Tooltip title="Delete Terminal" arrow>
                                          <IconButton
                                             variant="contained"
                                             color="secondary"
                                             size="small"
                                             onClick={() => handleDeleteClick(params)}
                                             disabled={readOnly}
                                          >
                                             <DeleteForeverIcon />
                                          </IconButton>
                                       </Tooltip>
                                    </div>
                                 ),
                                 flex: 0.1,
                              },
                           ]}
                           rows={tableData ? tableData : null}
                        ></StyledDataGrid>
                     </Paper>
                  </Grid>
               </Grid>
            </div>
         </div>
         {/* outside the view */}
         <DeleteDialog
            open={deleteModal}
            handleCancel={() => setDeleteModal(!deleteModal)}
            deleteFunc={deleteTerm}
            title={selectedTerminal && `Delete ${selectedTerminal.name}`}
            text={selectedTerminal && `Are you sure you want to delete ${selectedTerminal.name} from terminals?`}
         />
         <TerminalNew
            open={newTerminalModal}
            handleClose={() => setNewTerminalModal(!newTerminalModal)}
            getTerminals={props.getTerminals}
            openAlertMessage={openAlertMessage}
         />
         <TerminalRatesDialog
            open={terminalRatesModal}
            handleCancel={() => closeTerminalRates()}
            extendDate={extendDate}
            setExtendDate={setExtendDate}
            extendRatesFunc={extendRates}
            confirmExtendRates={confirmExtendRates}
            confirmExtendRatesModal={confirmExtendRatesModal}
            closeConfirmExtendRates={() => closeConfirmExtendRates()}
            expireDate={expireDate}
            setExpireDate={setExpireDate}
            expireRatesFunc={expireRates}
            confirmExpireRates={confirmExpireRates}
            confirmExpireRatesModal={confirmExpireRatesModal}
            closeConfirmExpireRates={() => closeConfirmExpireRates()}
         />
      </>
   );
};

function mapStateToProps(state, ownProps) {
   return {
      terminals: state.terminal.terminals || [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getTerminals: () => dispatch(terminalActions.getTerminals()),
   };
}

TerminalView.propTypes = {
   terminals: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   getTerminals: PropTypes.func.isRequired,
};

TerminalView.defaultProps = {
   terminals: [],
   user: {},
   getTerminals: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminalView);
