import PropTypes from "prop-types";
import { useCallback, useRef, useState } from "react";
// Material
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { keyframes } from "@mui/system";
// services
import { setUserMessages } from "../../api/adminMessageApi";

const StyledDialogTitle = styled(DialogTitle)(() => ({
   backgroundColor: "#002D72",
   color: "white",
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
}));

const StyledDialogContent = styled(DialogContent)(() => ({
   maxHeight: "250px",
}));

const StyledDialog = styled(Dialog)(() => ({
   "& .MuiDialog-paper": {
      minWidth: "400px",
   },
   backdropFilter: "blur(5px)",
}));

const StyledDialogActions = styled(DialogActions)(() => ({
   padding: "8px 18px 16px 18px",
}));

const StyledPaper = styled("div")(({ theme }) => ({
   textAlign: "justify",
   padding: "8px",
   margin: "10px 0px",
   borderLeft: "4px solid #002d72",
   borderRadius: "6px",
   backgroundColor: theme.palette.background.default,
   boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
   "&:hover": {
      backgroundColor: "#FFFFFF",
   },
}));

const StyledButton = styled(Button)(() => ({
   backgroundColor: "#002D72",
   color: "#FFFFFF",
   padding: "8px 12px",
   "&:hover": {
      backgroundColor: "#063987",
      color: "#FFFFFF",
   },
   "&.Mui-disabled": {
      backgroundColor: "#e0e0e0",
   },
}));

const intermittent = keyframes`
   0%{
      opacity:0;
   }

   10%{
      opacity:0.2;
   }

   20%{
      opacity:0.4;
   }
   
   30%{
      opacity:0.6;
   }

   40%{
      opacity:0.8;
   }
   
   50%{
      opacity:1.0;
   }

   60%{
      opacity:0.8;
   }
   
   70%{
      opacity:0.6;
   }

   80%{
      opacity:0.4;
   }
   
   90%{
      opacity:0.2;
   }
   
   100%{
      opacity:0;
   }
`;

const StyledNewReleasesIcon = styled(NewReleasesIcon)(() => ({
   animation: `${intermittent} 2s linear infinite`,
}));

const getDateFormat = () => {
   const date = new Date();
   const month = date.getMonth() + 1;
   const day = date.getDate();
   const year = date.getFullYear();
   return `${month}/${day}/${year}`;
};

const SIZE_OF_BLOCK_PIXELS = 270;

function AdminMessageDialog({ messages, acknowledgeFcn }) {
   const [today, _] = useState(() => getDateFormat());
   const [isItRead, setIsItRead] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const theme = useTheme();
   const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
   const contentRef = useRef(null);

   const divRefCallback = useCallback((node) => {
      if (node !== null) {
         if (!isItRead && node.scrollHeight < 240) {
            setIsItRead(true);
         }
      }
   }, []);

   const readMessage = async () => {
      setIsLoading(true);
      const IDs = messages.map((message) => message._id);
      try {
         const payload = {
            messageIDs: IDs,
         };
         await setUserMessages(payload);
         acknowledgeFcn();
      } catch (error) {
         console.log("error: ", error.message);
      }
      setIsLoading(false);
   };

   const handleAcknowledge = () => {
      readMessage();
   };

   const handleScroll = () => {
      if (!isItRead && contentRef.current.scrollTop > contentRef.current.scrollHeight - SIZE_OF_BLOCK_PIXELS) {
         setIsItRead(true);
      }
   };

   return (
      <StyledDialog open={true} fullWidth keepMounted fullScreen={fullScreen}>
         <StyledDialogTitle>
            <StyledNewReleasesIcon sx={{ marginRight: 2 }} />
            What&apos;s New {today}
         </StyledDialogTitle>
         <StyledDialogContent ref={contentRef} onScroll={handleScroll}>
            <div ref={divRefCallback}>
               {messages?.map((message) => (
                  <StyledPaper key={message._id} elevation={3}>
                     {message.message}
                  </StyledPaper>
               ))}
            </div>
         </StyledDialogContent>
         <StyledDialogActions>
            <StyledButton
               onClick={handleAcknowledge}
               disabled={!isItRead || isLoading}
               startIcon={
                  isLoading ? <CircularProgress size={20} disableShrink /> : isItRead ? <DoneAllIcon /> : <DoneIcon />
               }
            >
               Acknowledge
            </StyledButton>
         </StyledDialogActions>
      </StyledDialog>
   );
}

AdminMessageDialog.propTypes = {
   messages: PropTypes.array.isRequired,
   acknowledgeFcn: PropTypes.func.isRequired,
};

export default AdminMessageDialog;
