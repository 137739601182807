import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import * as customerApi from "../../api/customerApi";
import { stateList } from "../../common/stateList";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const CustomerNew = (props) => {
   var handleError = useErrorHandler();

   let [saveErrors, setSaveErrors] = useState(null);
   let [saving, setSaving] = useState(false);
   const [fieldsWereModified, setFieldsWereModified] = useState(false);
   let [newCust, setNewCust] = useState({
      _id: null,
      code: "",
      name: "",
      address: {
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         country: "",
      },
      security: {
         terminal_ids: [],
      },
      active: true,
      fuelUOM: "UOM",
      add_date: "",
      userList: [],
   });

   useEffect(() => {
      setNewCust({
         _id: null,
         code: "",
         name: "",
         address: {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
         },
         security: {
            terminal_ids: [],
         },
         active: true,
         fuelUOM: "UOM",
         add_date: "",
         userList: [],
      });
   }, [props]);

   useEffect(() => {
      if (!props.open) {
         setFieldsWereModified(false);
      }
   }, [props.open]);

   function saveNewCustomer() {
      //validate name and code fields
      if (newCust.name.length === 0 || newCust.code.length === 0) {
         setFieldsWereModified(true);
         return;
      }

      if (newCust.code.indexOf(",") > -1) {
         setFieldsWereModified(true);
         return;
      }

      setSaving(true);

      customerApi
         .saveCustomer(newCust, true)
         .then((resp) => {
            if (!resp.success) {
               setSaveErrors(resp.message);
            } else {
               //success
               props.openAlertMessage("Customer Successfully Added.", "success");
               props.getCustomers();
               props.handleClose();
            }

            setSaving(false);
         })
         .catch((err) => {
            setSaving(false);
            setSaveErrors(`Could Not Save New Customer: ${err}`);
         });
   }

   function onChange(event) {
      try {
         var selectedCustomer = _.cloneDeep(newCust);
         selectedCustomer[event.target.name] =
            event.target.type === "checkbox" ? event.target.checked : event.target.value;
         setNewCust(selectedCustomer);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeAddress(event) {
      try {
         var selectedCustomer = _.cloneDeep(newCust);
         selectedCustomer.address[event.target.name] = event.target.value;
         setNewCust(selectedCustomer);
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.handleClose}>
         <Collapse in={saveErrors ? true : false}>
            <Alert
               style={{ color: "#FFFFFF" }}
               variant="filled"
               severity="error"
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setSaveErrors(null);
                     }}
                  >
                     <CloseIcon fontSize="inherit" />
                  </IconButton>
               }
            >
               {saveErrors}
            </Alert>
         </Collapse>
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            New Customer
         </DialogTitle>
         <DialogContent>
            <form>
               <TextField
                  error={newCust.name.length > 0 || !fieldsWereModified ? false : true}
                  style={{ paddingRight: "1rem" }}
                  id={newCust.name.length > 0 || fieldsWereModified ? "standard-basic" : "outlined-error-helper-text"}
                  label="Name"
                  helperText={newCust.name.length === 0 && fieldsWereModified && "Required Field."}
                  variant="standard"
                  name="name"
                  value={newCust.name}
                  onChange={onChange}
                  required
               />
               <TextField
                  error={
                     (newCust.code.length === 0 && fieldsWereModified && true) ||
                     (newCust.code.indexOf(",") > -1 && fieldsWereModified && true)
                  }
                  style={{ paddingRight: "1rem" }}
                  id="tf-code"
                  label="Master Account"
                  helperText={
                     (newCust.code.length === 0 && fieldsWereModified && "Required Field.") ||
                     (newCust.code.indexOf(",") > -1 && fieldsWereModified && "Commas not allowed.")
                  }
                  variant="standard"
                  name="code"
                  value={newCust.code}
                  onChange={onChange}
                  required
               />
               <TextField
                  style={{ paddingRight: "1rem" }}
                  id="standard-basic"
                  label="Address 1"
                  variant="standard"
                  name="address1"
                  value={newCust.address.address1}
                  onChange={onChangeAddress}
               />
               <TextField
                  style={{ paddingRight: "1rem" }}
                  id="standard-basic"
                  label="Address 2"
                  variant="standard"
                  name="address2"
                  value={newCust.address.address2}
                  onChange={onChangeAddress}
               />
               <TextField
                  style={{ paddingRight: "1rem" }}
                  id="standard-basic"
                  label="City"
                  variant="standard"
                  name="city"
                  value={newCust.address.city}
                  onChange={onChangeAddress}
               />
               <FormControl style={{ width: "11.25rem", paddingRight: "1rem" }} variant="standard">
                  <InputLabel>State</InputLabel>
                  <Select
                     style={{ paddingRight: "1rem" }}
                     labelId="state-simple-select-label"
                     id="state-simple-select"
                     value={newCust.address.state}
                     name="state"
                     onChange={(e) => onChangeAddress(e)}
                  >
                     <MenuItem value={null}>Choose...</MenuItem>
                     {stateList.map((state, index) => {
                        return (
                           <MenuItem key={`mi-${index}`} value={state}>
                              {state}
                           </MenuItem>
                        );
                     })}
                  </Select>
               </FormControl>
               <TextField
                  style={{ paddingRight: "3%" }}
                  id="standard-basic"
                  label="Zip"
                  variant="standard"
                  name="zip"
                  value={newCust.address.zip}
                  onChange={(e) => onChangeAddress(e)}
               />
            </form>
         </DialogContent>
         <DialogActions>
            <Tooltip title="Exit" arrow>
               <IconButton onClick={props.handleClose} variant="contained" color="default">
                  <CloseIcon />
               </IconButton>
            </Tooltip>
            <Tooltip title="Save New Customer" arrow>
               <IconButton disabled={saving} onClick={saveNewCustomer} variant="contained" color="primary">
                  <SaveIcon />
               </IconButton>
            </Tooltip>
         </DialogActions>
      </Dialog>
   );
};

CustomerNew.propTypes = {
   open: PropTypes.bool.isRequired,
   selectedCustomer: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired,
   getCustomers: PropTypes.func.isRequired,
   handleClose: PropTypes.func.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
};

CustomerNew.defaultProps = {
   open: false,
   selectedCustomer: {},
   user: {},
   getCustomers: () => {
      return;
   },
   handleClose: () => {
      return;
   },
   openAlertMessage: () => {
      return;
   },
};

export default CustomerNew;
