import React from "react";
import { Route, Switch } from "react-router-dom";
import CustomerEdit from "./CustomerDetails";
import CustomerSpecificAccessorials from "./CustomerSpecificAccessorials";
import CustomerView from "./CustomerView";
var _ = require("lodash");

const CustomerManagement = (props) => {
   return (
      <>
         <Switch>
            <Route path="/customer" exact component={CustomerView} />
            <Route path="/customer/details/:id?" exact component={CustomerEdit} />
            <Route
               path="/customer/details/:id?/customer-specific-accessorials"
               exact
               component={CustomerSpecificAccessorials}
            />
         </Switch>
      </>
   );
};

export default CustomerManagement;
