import * as applicationApi from "../api/applicationApi";
import * as userActions from "../redux/actions/userActions";
import store from "../redux/configureStore";
var azure = require("azure-storage");
var stream = require("stream");

export function uploadAvatarToStorage(avatarSrc, userID) {
   //initiate service
   applicationApi
      .getConnectionString()
      .then((resp) => {
         var blobService = azure.createBlobService(resp);

         blobService.createContainerIfNotExists("user-avatars", function (error, result, response) {
            if (!error) {
               // if result = true, share was created.
               // if result = false, share already existed.
            }
         });

         var fileBuffer = Buffer.from(avatarSrc);

         var fileStream = new stream.Readable();
         fileStream.push(fileBuffer);
         fileStream.push(null);

         var blobName = userID + ".png";

         blobService.createBlockBlobFromStream(
            "user-avatars",
            blobName,
            fileStream,
            fileBuffer.length,
            function (error, result, response) {
               if (!error) {
                  // file uploaded
                  store.dispatch(userActions.setAvatar(avatarSrc));
               }
            },
         );
      })
      .catch((err) => {
         console.error(err);
      });
}

export async function getAvatarFromStorage(userID) {
   //initiate service
   applicationApi
      .getConnectionString()
      .then((resp) => {
         var blobService = azure.createBlobService(resp);
         var echoStream = new stream.Writable();

         echoStream._write = function (chunk, encoding, done) {
            store.dispatch(userActions.setAvatar(chunk.toString()));
            done();
         };

         try {
            blobService.getBlobToStream(
               "user-avatars",
               userID + ".png",
               echoStream,
               function (error, result, response) {
                  if (!error) {
                     // blob retrieved
                  }
               },
            );
         } catch (err) {
            console.error(err);
         }
      })
      .catch((err) => {
         console.error(err);
      });
}
