import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SaveIcon from "@mui/icons-material/Save";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import Alert from "@mui/lab/Alert";
import { Autocomplete } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import defaultDayjs from "dayjs";
import customParseFormatPlugin from "dayjs/plugin/customParseFormat";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import localizedFormatPlugin from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as activityApi from "../../api/activityApi";
import * as rateApi from "../../api/rateApi";
import { _handleObjectProperty } from "../../common/convert";
import { customerCodeFormatter } from "../../common/customerCodeFormatter";
import { fromUTC } from "../../common/fromUTC";
import { isOverlapped } from "../../common/isOverlapped";
import { localToUTC } from "../../common/localToUTC";
import { sortAlphabetically } from "../../common/sortAlphabetically";
import { timeFormatter } from "../../common/timeFormatter";
import ActivityLogGrid from "../Activity/ActivityLogGrid";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";

defaultDayjs.extend(customParseFormatPlugin);
defaultDayjs.extend(localizedFormatPlugin);
defaultDayjs.extend(isBetweenPlugin);
defaultDayjs.extend(utc);

const adapter = new AdapterDayjs({ instance: defaultDayjs });

const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
   secondaryHeader: {
      borderBottom: "1px solid #2F3136",
      marginTop: "2rem",
      zIndex: "3",
      width: "100%",
      overflowY: "hidden",
   },
   layoutMain: {
      width: "100%",
      height: "100vh",
   },
   layoutBody: {
      height: "calc(100% - 9rem)",
      width: "100%",
      overflowY: "scroll",
   },
   back: {
      color: "white",
      backgroundColor: "#2F3136",
      float: "right",
      marginRight: "1rem",
      "&:hover": {
         backgroundColor: "black",
      },
   },
   appBar: {
      "& .MuiButtonBase-root": {
         padding: "1.25rem 2.25rem",
      },
      "& .MuiTabs-indicator": {
         height: "4px",
      },
      backgroundColor: "#FFFFFF",
   },
   resultsTitle: {
      color: "#002D72",
      fontWeight: 600,
      marginTop: "2rem",
      marginBottom: ".5rem",
      verticalAlign: "middle",
      display: "inline-flex",
   },
   resultsText: {
      fontWeight: 600,
      marginTop: ".5rem",
      marginLeft: ".5rem",
   },
   rateDetailsTitle: {
      color: "#002D72",
      fontWeight: 600,
      marginTop: "1rem",
      marginLeft: "1rem",
      marginBottom: ".5rem",
      verticalAlign: "middle",
      display: "inline-flex",
   },
   divider: {
      backgroundColor: "#D1D1D1",
      height: "25px",
      width: "2px",
      marginTop: "1rem",
      marginRight: ".5rem",
   },
   circleIcon: {
      color: "#F50057",
      fontSize: ".5rem",
      position: "absolute",
      right: "1rem",
   },
   buttonProgress: {
      color: green[500],
   },
}));

const RateManagementDetails = (props) => {
   const handleError = useErrorHandler();
   const history = useHistory();
   const classes = useStyles();
   const [selectedRate, setSelectedRate] = useState(props.rate);
   const [showAlert, setShowAlert] = useState(false);
   const [alertType, setAlertType] = useState("");
   const [fuelTariffId, setFuelTariffId] = useState("");
   const [fuelTariffNameFixed, setFuelTariffNameFixed] = useState(false);
   const [alertMessage, setAlertMessage] = useState(null);
   const [tabsValue, setTabsValue] = useState(0);
   const [rateInfoList, setRateInfoList] = useState([]);
   const [originalRateInfoList, setOriginalRateInfoList] = useState([]);
   const [selectedRateInfo, setSelectedRateInfo] = useState("");
   const [rateInfoIndex, setRateInfoIndex] = useState(0);
   const [gridLoadingDateRange, setGridLoadingDateRange] = useState(true);
   const [tableHistoricalRates, setTableHistoricalRates] = useState([]);
   const [gridLoadingHistoricalRates, setGridLoadingHistoricalRates] = useState(true);
   const [saving, setSaving] = useState(false);
   const [savingCustomer, setSavingCustomer] = useState(false);
   const [activityTableData, setActivityTableData] = useState(null);
   const [loadActivity, setLoadActivity] = useState(true);
   const [selectedCustomerObj, setSelectedCustomerObj] = useState(props.selectedCustomer);
   const [error, setError] = useState([]);
   // regex for $ fields
   const regex = /^(?:\d*\.\d{1,2}|\d+)$/;
   const regexField = ['cost', 'base_rate', 'bobtail', 'tolls', 'pprValue', 'dpmValue', 'fixedFuelAmount'];
   //
   const headerTariff = props.rate.customer_id
      ? props.customers.find((customer) => {
         return customer._id === props.rate.customer_id;
      }).name
      : "DEFAULT";

   let tableHeader = [
      {
         field: "add_date",
         headerName: "Date Added",
         description: "When the rate was added.",
         type: "date",
         valueFormatter: ({ value }) => dateFormatter(value),
         flex: 0.1,
      },
      {
         field: "userName",
         headerName: "User Name",
         description: "Name of user that entered the rate.",
         type: "string",
         flex: 0.2,
      },
      {
         field: "startDate",
         headerName: "Start Date",
         description: "When the rate will start.",
         type: "date",
         valueFormatter: ({ value }) => dateFormatter(value),
         flex: 0.1,
      },
      {
         field: "endDate",
         headerName: "End Date",
         description: "When the rate will end.",
         type: "date",
         valueFormatter: ({ value }) =>
            value !== null ? dateFormatter(value) : null,
         flex: 0.1,
      },
      {
         field: "miles",
         headerName: "Miles",
         description: "Total miles of the rate.",
         type: "number",
         headerAlign: "left",
         align: "left",
         valueFormatter: ({ value }) => (value !== null ? value : 0) + " mi",
         flex: 0.1,
      },
      {
         field: "tolls",
         headerName: "Tolls",
         description: "Total tolls of the rate.",
         type: "number",
         headerAlign: "left",
         align: "left",
         valueFormatter: ({ value }) => "$" + (value !== null ? value : 0),
         flex: 0.1,
      },
      {
         field: "cost",
         headerName: "Cost",
         description: "Total cost of the rate.",
         type: "number",
         headerAlign: "left",
         align: "left",
         valueFormatter: ({ value }) => "$" + (value !== null ? value : 0),
         flex: 0.1,
      },
      {
         field: "base_rate",
         headerName: "Base Rate",
         description: "Total base rate of the rate.",
         type: "number",
         headerAlign: "left",
         align: "left",
         valueFormatter: ({ value }) => "$" + (value !== null ? value : 0),
         flex: 0.1,
      },
      {
         field: "bobtail",
         headerName: "Bobtail",
         description: "Total bobtail of the rate.",
         type: "number",
         headerAlign: "left",
         align: "left",
         valueFormatter: ({ value }) => "$" + (value !== null ? value : 0),
         flex: 0.1,
      },
      {
         field: "lane_type",
         headerName: "Lane Type",
         description: "Lane type of the rate.",
         type: "string",
         flex: 0.1,
      }
   ];
   let tableHeaderAdmin = [...tableHeader, {
      field: "notes",
      headerName: "Notes",
      description: "Notes of the Quote",
      type: "string",
      flex: 0.1,
   }];

   useEffect(() => {
      //List of Rate Information.
      getRateInfo();
   }, []);

   useEffect(() => {
      if (props.users.length > 0) {
         //List historical rates. Must wait for users to be filled.
         getHistoricalRates();
      }
   }, [props.users]);

   useEffect(() => {
      if (fuelTariffId) {
         let fuelTariff = _.find(props.fuelTariffs, function (f) { return f._id === fuelTariffId; });
         let fName = fuelTariff?.name.toLowerCase();
         if (fName === 'fixed rate') {
            setFuelTariffNameFixed(true);
         } else { setFuelTariffNameFixed(false) }
      }
   }, [fuelTariffId])

   useEffect(() => {
      activityApi
         .getActivity(null, null, null, [
            {
               module: "RATE",
               record_id: props.rate._id,
               rows: null,
            },
         ])
         .then((resp) => {
            //Build the tableData
            const newTableData = [];

            resp.activities.forEach((activityObjectResp) => {
               activityObjectResp.activity.forEach((activity, i) => {
                  if (activityObjectResp._id) {
                     const newActivityObj = {};

                     newActivityObj["_id"] = activityObjectResp._id;
                     newActivityObj["id"] = `${activityObjectResp._id}${i}`;
                     newActivityObj["date"] = dateFormatter(activityObjectResp.add_date);
                     newActivityObj["hour"] = timeFormatter(activityObjectResp.add_date);
                     newActivityObj["event"] = activity.event_code || "N/A";
                     newActivityObj["description"] = activity.event || "N/A";

                     newTableData.push(newActivityObj);
                  }
               });
            });

            setActivityTableData(newTableData);
            setLoadActivity(false);
         })
         .catch((err) => console.error(err));
   }, [loadActivity, props.rate, selectedRate]);

   //Create list of Rate Information.
   function getRateInfo() {
      let rateInfo = [];

      if (props.rate.rateInfo) {
         rateInfo.push({
            ...props.rate.rateInfo,
            id: props.rate.rateInfo._id ? props.rate.rateInfo._id : props.rate._id,
            startDate: fromUTC(props.rate.rateInfo.startDate),
            endDate: fromUTC(props.rate.rateInfo.endDate),
            notes: props?.rate?.rateInfo?.notes ? props?.rate?.rateInfo?.notes : ''
         });
      }

      if (props.rate.futureRateInfo) {
         _.forEach(props.rate.futureRateInfo, async (futureRate) => {
            rateInfo.push({
               ...futureRate,
               id: futureRate._id,
               startDate: fromUTC(futureRate.startDate),
               endDate: fromUTC(futureRate.endDate),
               notes: futureRate?.notes ? futureRate?.notes : ''
            });
         });
      }

      rateInfo = _.orderBy(rateInfo, ["startDate"], ["asc"]);

      setRateInfoList(rateInfo);
      setRateInfoIndex(0);
      setOriginalRateInfoList(rateInfo);
      setFuelTariffId(props.rate.fuelTariff_id);
      setSelectedRateInfo(rateInfo.length === 0 ? [] : rateInfo[0].id);
      setGridLoadingDateRange(false);
   }

   async function saveCustRate() {
      let newSelectedRate = { ...selectedRate };

      var newRateInfoList = _.cloneDeep(rateInfoList);

      //Check if more than one rate Infor has null for an End Date.
      let nullStartDateRateInfo = _.filter(rateInfoList, (x) => x.startDate === null);

      if (nullStartDateRateInfo.length > 0) {
         openAlertMessage(`Start Dates must be entered for all date ranges.`, "warning");
         return;
      }

      for (const [i, rateInfo_i] of newRateInfoList.entries()) {
         if (!rateInfo_i.lane_type) {
            openAlertMessage(`You must select a Lane Type for Row ${i + 1}!`, "warning");
            return;
         }

         if (!["REGIONAL", "LOCAL", "OTR"].includes(rateInfo_i.lane_type)) {
            openAlertMessage(`You must select a valid Lane Type for Row ${i + 1}!`, "warning");
            return;
         }

         if (!rateInfo_i.endDate) {
            openAlertMessage(`You must enter an End Date on Row ${i + 1}!`, "warning");
            return;
         }

         const startDate_i = new Date(new Date(rateInfo_i.startDate).setHours(0, 0, 0, 0)).getTime();

         const endDate_i = rateInfo_i.endDate
            ? new Date(new Date(rateInfo_i.endDate).setHours(23, 59, 59, 59)).getTime()
            : new Date(8640000000000000).getTime();

         if (startDate_i >= endDate_i) {
            openAlertMessage(`Start Date must be before the End Date on Row ${i + 1}!`, "warning");
            return;
         }

         for (const [j, rateInfo_j] of newRateInfoList.entries()) {
            if (i !== j) {
               const startDate_j = new Date(new Date(rateInfo_j.startDate).setHours(0, 0, 0, 0)).getTime();
               const endDate_j = rateInfo_j.endDate
                  ? new Date(new Date(rateInfo_j.endDate).setHours(23, 59, 59, 59)).getTime()
                  : new Date(8640000000000000).getTime();

               const dateOverlapped = isOverlapped(startDate_i, endDate_i, startDate_j, endDate_j);

               if (dateOverlapped) {
                  openAlertMessage(`Row ${i + 1} overlaps the rate date range with Row ${j + 1}`, "warning");
                  return;
               }
            }
         }
      }

      const currentRateInfo = rateInfoList.filter(
         (rate) =>
            new Date(new Date(rate.startDate).setHours(0, 0, 0, 0)).getTime() < new Date().getTime() &&
            new Date(new Date(rate.endDate).setHours(23, 59, 59, 59)).getTime() > new Date().getTime(),
      );

      if (currentRateInfo.length === 0) {
         newSelectedRate.rateInfo = null;
      } else {
         delete currentRateInfo[0].id;

         newSelectedRate.rateInfo = _.cloneDeep(currentRateInfo[0]);
      }

      if (!selectedRate.historicalRateInfo) {
         selectedRate.historicalRateInfo = [];
      }

      //Add changes to historical rateInfo as well.
      const changedRateInfo = rateInfoList.filter((rate) => rate.modified === true);

      if (changedRateInfo.length > 0) {
         changedRateInfo.forEach((changedRate) => {
            const originalRate = originalRateInfoList.filter((rate) => rate._id === changedRate._id);

            if (originalRate.length > 0) {
               originalRate[0].add_date = Date.now();
               originalRate[0].user_id = props.user._id;
               originalRate[0]._id = null;
               newSelectedRate.historicalRateInfo = [...newSelectedRate.historicalRateInfo, ...originalRate];
            } else {
               const newHistoricalRate = changedRateInfo.filter((rate) => rate.id === changedRate.id);
               newHistoricalRate[0].add_date = Date.now();
               newHistoricalRate[0].user_id = props.user._id;
               newHistoricalRate[0]._id = null;

               newSelectedRate.historicalRateInfo = [...newSelectedRate.historicalRateInfo, ...newHistoricalRate];
            }
         });
      }

      let futureRateInfo = _.filter(
         rateInfoList,
         (rate) => new Date(new Date(rate.startDate).setHours(0, 0, 0, 0)).getTime() > new Date().getTime(),
      );

      futureRateInfo = _.orderBy(futureRateInfo, ["startDate"], ["asc"]);

      if (newSelectedRate.rateInfo && futureRateInfo.length > 0) {
         newSelectedRate.rateInfo.endDate = new Date(
            new Date(futureRateInfo[0].startDate).setDate(
               new Date(new Date(futureRateInfo[0].startDate).getDate() - 1),
            ),
         ).setHours(23, 59, 59, 59);
      }

      newSelectedRate.futureRateInfo = _.cloneDeep(futureRateInfo);
      newSelectedRate.customer_id = selectedCustomerObj._id;
      newSelectedRate._id = null;
      newSelectedRate.fuelTariff_id = fuelTariffId || null;

      if (newSelectedRate.rateInfo) {
         newSelectedRate.rateInfo.startDate = localToUTC(
            new Date(newSelectedRate.rateInfo.startDate).setHours(0, 0, 0, 0),
         );
         newSelectedRate.rateInfo.endDate = localToUTC(
            new Date(newSelectedRate.rateInfo.endDate).setHours(23, 59, 59, 59),
         );
         newSelectedRate.rateInfo.pprValue = (fuelTariffNameFixed && newSelectedRate?.rateInfo?.fixedFuelType === 'ppr') ?
            newSelectedRate?.rateInfo?.pprValue : null;
         newSelectedRate.rateInfo.dpmValue = (fuelTariffNameFixed && newSelectedRate?.rateInfo?.fixedFuelType === 'dpm') ?
            newSelectedRate?.rateInfo?.dpmValue : null;
         newSelectedRate.rateInfo.fixedFuelAmount = fuelTariffNameFixed ? newSelectedRate?.rateInfo?.fixedFuelAmount : null;
         newSelectedRate.rateInfo.fixedFuelType = fuelTariffNameFixed ? newSelectedRate?.rateInfo?.fixedFuelType : null;
      }

      if (newSelectedRate.futureRateInfo.length > 0) {
         _.forEach(newSelectedRate.futureRateInfo, (x) => {
            x.startDate = localToUTC(new Date(x.startDate).setHours(0, 0, 0, 0));
            x.endDate = localToUTC(new Date(x.endDate).setHours(23, 59, 59, 59));
            x.pprValue = (fuelTariffNameFixed && x?.fixedFuelType === 'ppr') ? x?.pprValue : null;
            x.dpmValue = (fuelTariffNameFixed && x?.fixedFuelType === 'dpm') ? x?.dpmValue : null;
            x.fixedFuelAmount = fuelTariffNameFixed ? x?.fixedFuelAmount : null;
            x.fixedFuelType = fuelTariffNameFixed ? x?.fixedFuelType : null;
         });
      }

      setSavingCustomer(true);

      await rateApi
         .saveRate(newSelectedRate)
         .then((resp) => {
            openAlertMessage("Rate succesfully saved.", "success");

            setTimeout(() => {
               setSavingCustomer(false);
               props.close();
            }, 1000);
         })
         .catch((err) => {
            openAlertMessage(`Error saving rate: ${err}`, "error");
            setSavingCustomer(false);
         });
   }

   const getRateValidate = async () => {
      //loop through search queries to create properly formatted searchquery object for the api
      const newMultiSearchResults = [
         new rateApi.SearchQuery(
            selectedCustomerObj ? selectedCustomerObj._id : null,
            selectedRate.terminal_id,
            selectedRate.destination,
            false,
         ),
      ];

      setSavingCustomer(true);

      await rateApi
         .searchRates(newMultiSearchResults)
         .then((resp) => {
            if (resp.rates.length > 0) {
               if (
                  (resp.rates[0].customer_id !== "" && resp.rates[0].customer_id !== null) ||
                  resp.rates[0].customer_id
               ) {
                  openAlertMessage("Customer rate already exists.", "error");
                  setSavingCustomer(false);
               } else {
                  setSavingCustomer(false);
                  return saveCustRate();
               }
            } else {
               setSavingCustomer(false);
               return saveCustRate();
            }
         })
         .catch((err) => {
            setSavingCustomer(false);
            openAlertMessage(`Failed to validate search rate: ${err}`, "error");
         });
   };

   //Create Historical Rates table.
   function getHistoricalRates() {
      let historicalRates = [];

      if (props.rate.historicalRateInfo) {
         _.forEach(props.rate.historicalRateInfo, async (rate) => {
            if (rate._id) {
               const user = _.find(props.users, (x) => x._id?.toString() === rate.user_id?.toString());

               historicalRates.push({
                  ...rate,
                  id: rate._id,
                  startDate: fromUTC(rate?.startDate),
                  endDate: fromUTC(rate?.endDate),
                  userName: rate.systemGenerated
                     ? rate.systemGenerated
                     : user
                        ? `${user.firstname} ${user.lastname}`
                        : "N/A",
               });
            }
         });
      }

      historicalRates = _.orderBy(historicalRates, ["add_date"], ["desc"]);

      setTableHistoricalRates(historicalRates);
      setGridLoadingHistoricalRates(false);
   }

   const errorCheck = () => {
      return false;
   }

   function handleChange(event, value = "") {
      try {
         var newRateInfoList = _.cloneDeep(rateInfoList);

         if (value) {
            newRateInfoList[rateInfoIndex][value] =
               value === "startDate" || value === "endDate" ? event : event.target.value;
            newRateInfoList[rateInfoIndex].user_id = props.user._id;
            newRateInfoList[rateInfoIndex].modified = true;
            setRateInfoList(newRateInfoList);

            //Need to enforce start date and end date being within 364 days.
            if (value === "startDate" || value === "endDate") {
               //No value. Return.
               if (!event) {
                  return;
               }

               //Invalid date. Return.
               if (event.toString() === "Invalid Date") {
                  return;
               }

               //Get date formatted.
               const startDate = new Date(newRateInfoList[rateInfoIndex].startDate);

               //Not done entering date. Return.
               if (startDate.getFullYear().toString().length < 4) {
                  return;
               }

               const startDateFormatted = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

               //Get date formatted.
               const endDate = new Date(newRateInfoList[rateInfoIndex].endDate);

               //Not done entering date. Return.
               if (endDate.getFullYear().toString().length < 4) {
                  return;
               }

               const endDateFormatted = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

               // Do the math.
               var millisecondsPerDay = 1000 * 60 * 60 * 24;
               var millisBetween = endDateFormatted.getTime() - startDateFormatted.getTime();
               var days = millisBetween / millisecondsPerDay;

               if (days > 364 || days < -1) {
                  newRateInfoList[rateInfoIndex].endDate = startDateFormatted.setDate(
                     startDateFormatted.getDate() + 364,
                  );
               }
            }

            return;
         }

         if (event.target.tagName === 'TEXTAREA') {
            newRateInfoList[rateInfoIndex].notes = event.target.value;
         } else {
            if (!isNaN(event.target.value)) {
               if (event.target.value < 0) {
                  event.target.value = 0;
               }
               if (event.target.name === 'pprValue' && event.target.value > 99.99) {
                  return
               }
               if (regexField.includes(event.target.name)) {
                  if (!regex.test(event.target.value) && event.target.value > 0) {
                     if (error.indexOf(event.target.name) === -1) {
                        let tempArray = error;
                        tempArray.push(event.target.name);
                        setError(tempArray);
                     }
                  }
                  else {
                     if (error.indexOf(event.target.name) > -1) {
                        let tempArray = error;
                        tempArray.splice(tempArray.indexOf(event.target.name), 1)
                        setError(tempArray);
                     }
                  }
               }
               else {
                  event.target.value = parseInt(+event.target.value, 10);
               }
            }
         }
         newRateInfoList[rateInfoIndex][event.target.name] = event.target.value ? event.target.value : 0;
         newRateInfoList[rateInfoIndex].user_id = props.user._id;
         newRateInfoList[rateInfoIndex].modified = true;
         setRateInfoList(newRateInfoList);
      } catch (err) {
         handleError(err);
      }
   }

   function handleNewRate() {
      try {
         let newRateInfoList = _.cloneDeep(rateInfoList);
         const newIndex = newRateInfoList.length;
         let newEndDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
         newEndDate = new Date(newEndDate).setDate(new Date(newEndDate).getDate() - 1);

         if (!newRateInfoList[rateInfoIndex]) {
            newRateInfoList.push({
               id: newIndex.toString(),
               _id: null,
               miles: 0,
               tolls: 0,
               cost: 0,
               base_rate: 0,
               bobtail: 0,
               lane_type: "LOCAL",
               service: true,
               shipment_type: null,
               add_date: Date.now(),
               startDate: Date.now(),
               endDate: new Date(newEndDate),
               fileName: null,
               azureFileName: null,
               user_id: props.user._id,
               date_uploaded: null,
               modified: true,
               notes: null,
               fixedFuelAmount: 0,
               fixedFuelType: null,
               pprValue: 0,
               dpmValue: 0
            });
         } else {
            newRateInfoList.push({
               id: newIndex.toString(),
               _id: null,
               miles: newRateInfoList[rateInfoIndex].miles || 0,
               tolls: newRateInfoList[rateInfoIndex].tolls || 0,
               cost: newRateInfoList[rateInfoIndex].cost || 0,
               base_rate: newRateInfoList[rateInfoIndex].base_rate || 0,
               bobtail: newRateInfoList[rateInfoIndex].bobtail || 0,
               lane_type: newRateInfoList[rateInfoIndex].lane_type || "LOCAL",
               service: newRateInfoList[rateInfoIndex].base_rate == 0 ? true :
                  newRateInfoList[rateInfoIndex].service || false,
               shipment_type: newRateInfoList[rateInfoIndex].shipment_type || null,
               add_date: Date.now(),
               startDate: Date.now(),
               endDate: new Date(newEndDate),
               fileName: newRateInfoList[rateInfoIndex].fileName || null,
               azureFileName: newRateInfoList[rateInfoIndex].azureFileName || null,
               user_id: props.user._id,
               date_uploaded: null,
               modified: true,
               notes: newRateInfoList[rateInfoIndex].notes || null,
               fixedFuelAmount: newRateInfoList[rateInfoIndex].fixedFuelAmount || 0,
               fixedFuelType: newRateInfoList[rateInfoIndex].fixedFuelType || null,
               pprValue: newRateInfoList[rateInfoIndex].pprValue || 0,
               dpmValue: newRateInfoList[rateInfoIndex].dpmValue || 0,
            });
         }

         setRateInfoList(newRateInfoList);
         setRateInfoIndex(newIndex);
         setSelectedRateInfo(newIndex.toString());
      } catch (err) {
         handleError(err);
      }
   }

   function handleDeleteRate() {
      try {
         if (rateInfoList.length === 1 && !props.rate.customer_id) {
            openAlertMessage("You must have at least one record on the rate!", "warning");
            return;
         }

         if (rateInfoList.length === 1 && props.rate.customer_id) {
            setRateInfoList([]);
            setRateInfoIndex(0);
            setSelectedRateInfo([]);
            return;
         }

         let newRateInfoList = _.cloneDeep(rateInfoList);
         newRateInfoList = [...newRateInfoList.slice(0, rateInfoIndex), ...newRateInfoList.slice(rateInfoIndex + 1)];

         if (newRateInfoList.length - 1 < rateInfoIndex) {
            const newIndex = newRateInfoList.length - 1 < 0 ? 0 : newRateInfoList.length - 1;

            setRateInfoList(newRateInfoList);
            setRateInfoIndex(newIndex);
            setSelectedRateInfo(newRateInfoList[newIndex].id.toString());
            return;
         }

         setRateInfoList(newRateInfoList);
         setSelectedRateInfo(newRateInfoList[rateInfoIndex].id.toString());
      } catch (err) {
         handleError(err);
      }
   }

   function handleSelectRow(selectedId = "") {
      try {
         if (!selectedId) return;
         let newIndex = rateInfoList.findIndex(({ id }) => id.toString() === selectedId.toString());
         if (newIndex < 0) newIndex = 0;
         setRateInfoIndex(newIndex);
         setSelectedRateInfo(rateInfoList[newIndex] ? rateInfoList[newIndex].id.toString() : selectedId.toString());
      } catch (err) {
         handleError(err);
      }
   }

   async function saveRate() {
      let newSelectedRate = { ...selectedRate };

      var newRateInfoList = _.cloneDeep(rateInfoList);

      //Check if more than one rate Infor has null for an End Date.
      let nullStartDateRateInfo = _.filter(rateInfoList, (x) => x.startDate === null);

      if (nullStartDateRateInfo.length > 0) {
         openAlertMessage(`Start Dates must be entered for all date ranges.`, "warning");
         return;
      }

      for (const [i, rateInfo_i] of newRateInfoList.entries()) {
         if (!rateInfo_i.lane_type) {
            openAlertMessage(`You must select a Lane Type for Row ${i + 1}!`, "warning");
            return;
         }

         if (!["REGIONAL", "LOCAL", "OTR"].includes(rateInfo_i.lane_type)) {
            openAlertMessage(`You must select a valid Lane Type for Row ${i + 1}!`, "warning");
            return;
         }

         if (!rateInfo_i.endDate) {
            openAlertMessage(`You must enter an End Date on Row ${i + 1}!`, "warning");
            return;
         }

         const startDate_i = new Date(new Date(rateInfo_i.startDate).setHours(0, 0, 0, 0)).getTime();

         const endDate_i = rateInfo_i.endDate
            ? new Date(new Date(rateInfo_i.endDate).setHours(23, 59, 59, 59)).getTime()
            : new Date(8640000000000000).getTime();

         if (startDate_i >= endDate_i) {
            openAlertMessage(`Start Date must be before the End Date on Row ${i + 1}!`, "warning");
            return;
         }

         for (const [j, rateInfo_j] of newRateInfoList.entries()) {
            if (i !== j) {
               const startDate_j = new Date(new Date(rateInfo_j.startDate).setHours(0, 0, 0, 0)).getTime();
               const endDate_j = rateInfo_j.endDate
                  ? new Date(new Date(rateInfo_j.endDate).setHours(23, 59, 59, 59)).getTime()
                  : new Date(8640000000000000).getTime();

               const dateOverlapped = isOverlapped(startDate_i, endDate_i, startDate_j, endDate_j);

               if (dateOverlapped) {
                  openAlertMessage(`Row ${i + 1} overlaps the rate date range with Row ${j + 1}`, "warning");
                  return;
               }
            }
         }
      }

      const currentRateInfo = rateInfoList.filter(
         (rate) =>
            new Date(new Date(rate.startDate).setHours(0, 0, 0, 0)).getTime() < new Date().getTime() &&
            new Date(new Date(rate.endDate).setHours(23, 59, 59, 59)).getTime() > new Date().getTime(),
      );

      if (currentRateInfo.length === 0 && !selectedRate.customer_id) {
         openAlertMessage("You must enter a current date range that falls into today's date!", "warning");
         return;
      }

      if (currentRateInfo.length === 0) {
         newSelectedRate.rateInfo = null;
      } else {
         delete currentRateInfo[0].id;

         newSelectedRate.rateInfo = _.cloneDeep(currentRateInfo[0]);
      }

      if (!selectedRate.historicalRateInfo) {
         selectedRate.historicalRateInfo = [];
      }

      //Add changes to historical rateInfo as well.
      const changedRateInfo = rateInfoList.filter((rate) => rate.modified === true);

      if (changedRateInfo.length > 0) {
         changedRateInfo.forEach((changedRate) => {
            const originalRate = originalRateInfoList.filter((rate) => rate._id === changedRate._id);

            if (originalRate.length > 0) {
               originalRate[0].add_date = Date.now();
               originalRate[0].user_id = props.user._id;
               originalRate[0]._id = null;
               newSelectedRate.historicalRateInfo = [...newSelectedRate.historicalRateInfo, ...originalRate];
            } else {
               const newHistoricalRate = changedRateInfo.filter((rate) => rate.id === changedRate.id);
               newHistoricalRate[0].add_date = Date.now();
               newHistoricalRate[0].user_id = props.user._id;
               newHistoricalRate[0]._id = null;

               newSelectedRate.historicalRateInfo = [...newSelectedRate.historicalRateInfo, ...newHistoricalRate];
            }
         });
      }

      let futureRateInfo = _.filter(
         rateInfoList,
         (rate) => new Date(new Date(rate.startDate).setHours(0, 0, 0, 0)).getTime() > new Date().getTime(),
      );

      futureRateInfo = _.orderBy(futureRateInfo, ["startDate"], ["asc"]);

      newSelectedRate.futureRateInfo = _.cloneDeep(futureRateInfo);
      newSelectedRate.fuelTariff_id = fuelTariffId || null;

      if (newSelectedRate.rateInfo) {
         newSelectedRate.rateInfo.startDate = localToUTC(
            new Date(newSelectedRate.rateInfo.startDate).setHours(0, 0, 0, 0),
         );
         newSelectedRate.rateInfo.endDate = localToUTC(
            new Date(newSelectedRate.rateInfo.endDate).setHours(23, 59, 59, 59),
         );
         newSelectedRate.rateInfo.pprValue = (fuelTariffNameFixed && newSelectedRate.rateInfo?.fixedFuelType === 'ppr') ?
            newSelectedRate.rateInfo?.pprValue : null;
         newSelectedRate.rateInfo.dpmValue = (fuelTariffNameFixed && newSelectedRate.rateInfo?.fixedFuelType === 'dpm') ?
            newSelectedRate.rateInfo?.dpmValue : null;
         newSelectedRate.rateInfo.fixedFuelAmount = fuelTariffNameFixed ? newSelectedRate.rateInfo?.fixedFuelAmount : null;
         newSelectedRate.rateInfo.fixedFuelType = fuelTariffNameFixed ? newSelectedRate.rateInfo?.fixedFuelType : null;
      }

      if (newSelectedRate.futureRateInfo.length > 0) {
         _.forEach(newSelectedRate.futureRateInfo, (x) => {
            x.startDate = localToUTC(new Date(x.startDate).setHours(0, 0, 0, 0));
            x.endDate = localToUTC(new Date(x.endDate).setHours(23, 59, 59, 59));
            x.pprValue = (fuelTariffNameFixed && x?.fixedFuelType === 'ppr') ? x?.pprValue : null;
            x.dpmValue = (fuelTariffNameFixed && x?.fixedFuelType === 'dpm') ? x?.dpmValue : null;
            x.fixedFuelAmount = fuelTariffNameFixed ? x?.fixedFuelAmount : null;
            x.fixedFuelType = fuelTariffNameFixed ? x?.fixedFuelType : null;
         });
      }

      setSaving(true);

      await rateApi
         .saveRate(newSelectedRate)
         .then((resp) => {
            setSelectedRate(resp);

            const currentRate = resp.rateInfo ? { ...resp.rateInfo } : null;
            const futureRates = resp.futureRateInfo && [...resp.futureRateInfo];

            newRateInfoList =
               currentRate && futureRates
                  ? [currentRate, ...futureRates]
                  : futureRates
                     ? [...futureRates]
                     : currentRate
                        ? [currentRate]
                        : [];

            // add id property
            if (newRateInfoList.length > 0) {
               newRateInfoList.map((rate) => {
                  (rate.startDate = fromUTC(rate.startDate)),
                     (rate.endDate = fromUTC(rate.endDate)),
                     (rate.id = rate._id);
               });
            }

            setRateInfoList(newRateInfoList);

            let historicalRates = [];

            if (resp.historicalRateInfo) {
               _.forEach(resp.historicalRateInfo, async (rate) => {
                  if (rate._id) {
                     const user = _.find(props.users, (x) => x._id?.toString() === rate.user_id?.toString());

                     historicalRates.push({
                        ...rate,
                        id: rate._id,
                        startDate: fromUTC(rate?.startDate),
                        endDate: fromUTC(rate?.endDate),
                        userName: rate.systemGenerated
                           ? rate.systemGenerated
                           : user
                              ? `${user.firstname} ${user.lastname}`
                              : "N/A",
                     });
                  }
               });
            }

            historicalRates = _.orderBy(historicalRates, ["add_date"], ["desc"]);

            setTableHistoricalRates(historicalRates);
            setGridLoadingHistoricalRates(false);

            openAlertMessage("Rate succesfully saved.", "success");
            setSaving(false);
         })
         .catch((err) => {
            openAlertMessage(`Error saving rate: ${err}`, "error");
            setSaving(false);
         });
   }

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   const handleTabsChange = (event, newValue) => {
      setTabsValue(newValue);
   };

   const a11yProps = (index) => ({
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   });

   //data scrubbing function to return string values for display in table
   const dateFormatter = (value) => {
      try {
         var dateString = value;
         var newDate = new Date(dateString);
         const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(newDate);
         const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(newDate);
         const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(newDate);
         return month + "/" + day + "/" + year;
      } catch {
         return "00/00/0000";
      }
   };

   //Calculate CPG rates
   const calcCPGrate = (base_rate, tolls, bobtail) => {
      try {
         return +(base_rate ? base_rate : 0) + +(tolls ? tolls : 0) + +(bobtail ? bobtail : 0);
      } catch (err) {
         handleError(err);
      }
   };

   return (
      <>
         <div id={"rateManagementDetails"} className={classes.layoutMain}>
            <div id={"rateManagementDetailsHeader"} className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center" p="2px 0px">
                  <Grid item xs={4}>
                     <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                        <Link color="inherit" style={{ cursor: "pointer" }} onClick={props.close}>
                           <h3 style={{ fontWeight: "500" }}>{"Rate Management"}</h3>
                        </Link>
                        <Link color="inherit" style={{ cursor: "pointer" }}>
                           <h3 style={{ fontWeight: "500" }}>{"Rate Details"}</h3>
                        </Link>
                     </Breadcrumbs>
                  </Grid>
                  <Grid item xs={4}>
                     <Autocomplete
                        id="combo-box-customer"
                        options={props.customers}
                        onChange={(e, values) => setSelectedCustomerObj(values)}
                        value={selectedCustomerObj}
                        getOptionLabel={(option) => {
                           return customerCodeFormatter(option.code) + " | " + option.name;
                        }}
                        style={{ backgroundColor: "white" }}
                        renderInput={(params) => (
                           <TextField
                              sx={{ display: selectedRate.customer_id && "none" }}
                              {...params}
                              label="Select Customer"
                              variant="outlined"
                           />
                        )}
                        autoSelect={true}
                     />
                  </Grid>
                  <Grid item xs={4}>
                     {props.user.security.admin && <Box display="flex" justifyContent="flex-end" pr="1.5rem">
                        {!selectedRate.customer_id && selectedCustomerObj && (
                           <Tooltip title={`Save rate for ${selectedCustomerObj.name}`} arrow>
                              <Button
                                 sx={{ mr: ".5rem" }}
                                 variant="contained"
                                 color="primary"
                                 disabled={props.readOnly || savingCustomer || !selectedCustomerObj || saving || error.length !== 0}
                                 onClick={getRateValidate}
                              >
                                 {savingCustomer ? (
                                    <CircularProgress size={24} className={classes.buttonProgress} />
                                 ) : (
                                    <SaveAsIcon sx={{ fontSize: "1.5rem", color: "#FFF", m: "0 .5rem" }} />
                                 )}
                                 Save as Customer Rate
                              </Button>
                           </Tooltip>
                        )}
                        <Tooltip title="Save New" arrow>
                           <Button
                              variant="contained"
                              color="primary"
                              onClick={() => saveRate()}
                              disabled={props.readOnly || saving || savingCustomer || error.length !== 0}
                           >
                              {saving ? (
                                 <CircularProgress size={24} className={classes.buttonProgress} />
                              ) : (
                                 <SaveIcon sx={{ fontSize: "1.5rem", color: "#FFF", m: "0 .5rem" }} />
                              )}
                              Save
                           </Button>
                        </Tooltip>
                     </Box>}
                  </Grid>
               </Grid>
            </div>
            <div id={"rateManagementDetailsBody"} className={classes.layoutBody}>
               <Paper elevation={3} style={{ paddingBottom: "1rem" }}>
                  <Collapse in={showAlert}>
                     <Alert
                        style={{ color: "#FFFFFF" }}
                        variant="filled"
                        severity={alertType ? alertType : "success"}
                        action={
                           <IconButton
                              aria-label="close"
                              variant="contained"
                              color="white"
                              size="small"
                              onClick={() => {
                                 {
                                    closeAlertMessage();
                                 }
                              }}
                           >
                              <CloseIcon fontSize="inherit" />
                           </IconButton>
                        }
                     >
                        {alertMessage}
                     </Alert>
                  </Collapse>
                  <AppBar
                     position="static"
                     color="default"
                     className={classes.appBar}
                     sx={{
                        boxShadow: "none",
                        borderBottom: "2px solid #A9A9A9",
                     }}
                  >
                     <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabsChange}
                        value={tabsValue}
                        aria-label="tabs"
                     >
                        <Tab
                           sx={{
                              fontSize: "1rem",
                              fontWeight: "700",
                              letterSpacing: "0px",
                              textTransform: "none",
                           }}
                           label="Rate Details"
                           {...a11yProps(0)}
                        />
                        <Tab
                           sx={{
                              fontSize: "1rem",
                              fontWeight: "700",
                              letterSpacing: "0px",
                              textTransform: "none",
                           }}
                           label="Activity Logging"
                           {...a11yProps(1)}
                        />
                     </Tabs>
                  </AppBar>
                  <Grid container direction={"row"} spacing={1}>
                     <Grid item xs={1}>
                        <Tooltip title="Back" arrow>
                           <IconButton variant="contained" onClick={props.close}>
                              <ArrowBackIcon />
                           </IconButton>
                        </Tooltip>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography className={classes.resultsTitle}>
                           <WarehouseIcon fontSize={"large"} /> <div className={classes.resultsText}>Origin</div>
                        </Typography>
                        <Typography align="left">
                           {_handleObjectProperty(
                              props.terminals.find((terminal) => {
                                 return terminal._id === props.rate.terminal_id;
                              }),
                              "name",
                           )}
                        </Typography>
                     </Grid>
                     <Grid item xs={1}>
                        <Typography className={classes.resultsTitle}>
                           <ArrowForwardIcon fontSize={"large"} />
                        </Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography className={classes.resultsTitle}>
                           <LocationCityIcon fontSize={"large"} />{" "}
                           <div className={classes.resultsText}>Destination</div>
                        </Typography>
                        <Typography align="left">
                           {props.rate.destination.city +
                              ", " +
                              props.rate.destination.state +
                              "  " +
                              props.rate.destination.zip}
                        </Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography className={classes.resultsTitle}>
                           <AssignmentIcon fontSize={"large"} /> <div className={classes.resultsText}>Tariff</div>
                        </Typography>
                        <Typography align="left">{headerTariff}</Typography>
                     </Grid>
                  </Grid>
                  {tabsValue === 0 && (
                     <>
                        <Grid container direction={"row"} spacing={1} style={{ marginTop: ".5rem" }}>
                           <Grid item xs={3}>
                              <Paper elevation={3} style={{ paddingBottom: "1rem" }}>
                                 <Box
                                    sx={{
                                       height: "350px",
                                       width: 1,
                                       "& .selected-left": {
                                          bgcolor: "selectedGrey.main",
                                          color: "white.main",
                                          borderBottomLeftRadius: "15px",
                                          borderTopLeftRadius: "15px",
                                       },
                                       "& .selected-right": {
                                          bgcolor: "selectedGrey.main",
                                          color: "white.main",
                                          borderBottomRightRadius: "15px",
                                          borderTopRightRadius: "15px",
                                       },
                                       "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                          outline: "none !important",
                                       },
                                       "& .MuiDataGrid-root .MuiDataGrid-cell:hover": {
                                          cursor: "pointer",
                                       },
                                    }}
                                 >
                                    <StyledDataGrid
                                       hideFooter={true}
                                       pagination={false}
                                       gridHeight={"350px"}
                                       loading={gridLoadingDateRange}
                                       disableMultipleSelection={true}
                                       getCellClassName={(params) => {
                                          if (params.id === selectedRateInfo) {
                                             if (params.field === "startDate") {
                                                return `selected-left`;
                                             } else {
                                                return `selected-right`;
                                             }
                                          } else {
                                             return null;
                                          }
                                       }}
                                       onSelectionModelChange={(newSelectionModel) => {
                                          handleSelectRow(newSelectionModel);
                                       }}
                                       selectionModel={selectedRateInfo}
                                       columns={[
                                          {
                                             field: "startDate",
                                             headerName: "Start Date",
                                             description: "When the rate will start.",
                                             type: "date",
                                             valueFormatter: ({ value }) =>
                                                value !== null ? dateFormatter(value) : null,
                                             flex: 0.5,
                                          },
                                          {
                                             field: "endDate",
                                             headerName: "End Date",
                                             description: "When the rate will end.",
                                             type: "date",
                                             valueFormatter: ({ value }) =>
                                                value !== null ? dateFormatter(value) : null,
                                             renderCell: (params) => (
                                                <>
                                                   {params.row.endDate !== null
                                                      ? dateFormatter(params.row.endDate)
                                                      : null}
                                                   {params.row.modified && (
                                                      <CircleIcon className={classes.circleIcon} />
                                                   )}
                                                </>
                                             ),
                                             flex: 0.5,
                                          },
                                       ]}
                                       rows={rateInfoList}
                                    ></StyledDataGrid>
                                 </Box>
                              </Paper>
                           </Grid>
                           <Grid item xs={9}>
                              <Paper elevation={3} style={{ paddingBottom: "1rem", minHeight: "366px" }}>
                                 <Grid
                                    container
                                    direction={"row"}
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    style={{ marginBottom: ".5rem" }}
                                 >
                                    <Grid item xs={7}>
                                       <Typography className={classes.rateDetailsTitle}>
                                          <div className={classes.resultsText}>Rate Details</div>
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                       {props.user.security.admin && <Box display="flex" justifyContent="flex-end" alignItems="center">
                                          <Divider orientation="vertical" className={classes.divider} />
                                          <Button
                                             variant="contained"
                                             color="primary"
                                             style={{ marginTop: "1rem", marginRight: "1rem" }}
                                             onClick={() => handleNewRate()}
                                          >
                                             <AddIcon /> New Rate
                                          </Button>
                                          <Button
                                             variant="contained"
                                             color="secondary"
                                             style={{ marginTop: "1rem", marginRight: "1rem" }}
                                             onClick={() => handleDeleteRate()}
                                             disabled={rateInfoList.length === 0}
                                          >
                                             <DeleteForeverIcon /> Delete Rate
                                          </Button>
                                       </Box>}
                                    </Grid>
                                 </Grid>
                                 <Divider />
                                 <Grid
                                    container
                                    direction={"row"}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    style={{ marginBottom: ".5rem" }}
                                 >
                                    <Grid item xs={2}>
                                       <TextField
                                          type="number"
                                          variant="outlined"
                                          id="tf-miles"
                                          label="Miles"
                                          name="miles"
                                          value={rateInfoList[rateInfoIndex] ? rateInfoList[rateInfoIndex].miles : ""}
                                          style={{ margin: "1rem" }}
                                          onChange={props.user.security.admin && handleChange}
                                          disabled={rateInfoList.length === 0}
                                       />
                                    </Grid>
                                    <Grid item xs={2}>
                                       <TextField
                                          error={error.includes('cost')}
                                          type="number"
                                          variant="outlined"
                                          id="tf-cost"
                                          label="Cost"
                                          name="cost"
                                          value={rateInfoList[rateInfoIndex] ? rateInfoList[rateInfoIndex].cost : ""}
                                          style={{ margin: "1rem" }}
                                          InputProps={{
                                             startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                          }}
                                          onChange={props.user.security.admin && handleChange}
                                          disabled={rateInfoList.length === 0}
                                          helperText={error.includes('cost') ? `Allowed Upto two decimal places` : null}
                                       />

                                    </Grid>
                                    <Grid item xs={2}>
                                       <TextField
                                          error={error.includes('base_rate')}
                                          type="number"
                                          variant="outlined"
                                          id="tf-baseRate"
                                          label="Base Rate"
                                          name="base_rate"
                                          value={
                                             rateInfoList[rateInfoIndex] ? rateInfoList[rateInfoIndex].base_rate : ""
                                          }
                                          style={{ margin: "1rem" }}
                                          InputProps={{
                                             startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                          }}
                                          inputProps={{
                                             step: 0.01
                                          }}
                                          onChange={props.user.security.admin && handleChange}
                                          disabled={rateInfoList.length === 0}
                                          helperText={error.includes('base_rate') ? `Allowed Upto two decimal places` : null}
                                       />
                                    </Grid>
                                    <Grid item xs={2}>
                                       <TextField
                                          error={error.includes('bobtail')}
                                          type="number"
                                          variant="outlined"
                                          id="tf-bobtail"
                                          label="Bobtail"
                                          name="bobtail"
                                          value={rateInfoList[rateInfoIndex] ? rateInfoList[rateInfoIndex].bobtail : ""}
                                          style={{ margin: "1rem" }}
                                          InputProps={{
                                             startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                          }}
                                          inputProps={{
                                             step: 0.01
                                          }}
                                          onChange={props.user.security.admin && handleChange}
                                          disabled={rateInfoList.length === 0}
                                          helperText={error.includes('bobtail') ? `Allowed Upto two decimal places` : null}
                                       />
                                    </Grid>
                                    <Grid item xs={2}>
                                       <TextField
                                          error={error.includes('tolls')}
                                          type="number"
                                          variant="outlined"
                                          id="tf-tolls"
                                          label="Tolls"
                                          name="tolls"
                                          value={rateInfoList[rateInfoIndex] ? rateInfoList[rateInfoIndex].tolls : ""}
                                          style={{ margin: "1rem" }}
                                          InputProps={{
                                             startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                          }}
                                          inputProps={{
                                             step: 0.01
                                          }}
                                          onChange={props.user.security.admin && handleChange}
                                          disabled={rateInfoList.length === 0}
                                          helperText={error.includes('tolls') ? `Allowed Upto two decimal places` : null}
                                       />
                                    </Grid>
                                    <Grid item xs={2}>
                                       <TextField
                                          disabled={true}
                                          type="number"
                                          variant="outlined"
                                          id="tf-cpgRate"
                                          label="CPG Rate"
                                          name="cpg_rate"
                                          value={
                                             rateInfoList[rateInfoIndex]
                                                ? calcCPGrate(
                                                   rateInfoList[rateInfoIndex].base_rate,
                                                   rateInfoList[rateInfoIndex].tolls,
                                                   rateInfoList[rateInfoIndex].bobtail,
                                                )
                                                : ""
                                          }
                                          style={{ margin: "1rem" }}
                                          InputProps={{
                                             startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                          }}
                                       />
                                    </Grid>
                                    <Grid item xs={2}>
                                       <FormControl fullWidth>
                                          <InputLabel id="il-laneType" style={{ margin: "1rem" }}>
                                             Lane Type
                                          </InputLabel>
                                          <Select
                                             labelId="il-laneType"
                                             id="select-laneType"
                                             value={
                                                rateInfoList[rateInfoIndex]
                                                   ? rateInfoList[rateInfoIndex].lane_type
                                                   : "LOCAL"
                                             }
                                             label="Lane Type"
                                             name="lane_type"
                                             style={{ margin: "1rem" }}
                                             onChange={(event) => props.user.security.admin && handleChange(event, "lane_type")}
                                             disabled={rateInfoList.length === 0}
                                          >
                                             <MenuItem key={`mi-laneTypeLocal`} value={"LOCAL"}>
                                                LOCAL
                                             </MenuItem>
                                             <MenuItem key={`mi-laneTypeOtr`} value={"OTR"}>
                                                OTR
                                             </MenuItem>
                                             <MenuItem key={`mi-laneTypeRegional`} value={"REGIONAL"}>
                                                REGIONAL
                                             </MenuItem>
                                          </Select>
                                       </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                       <FormControl fullWidth>
                                          <InputLabel id="il-service" style={{ margin: "1rem" }}>
                                             Service?
                                          </InputLabel>
                                          <Select
                                             labelId="il-service"
                                             id="select-service"
                                             value={
                                                rateInfoList[rateInfoIndex]
                                                   ? rateInfoList[rateInfoIndex].service
                                                   : false
                                             }
                                             label="Service?"
                                             name="service"
                                             style={{ margin: "1rem" }}
                                             onChange={(event) => props.user.security.admin && handleChange(event, "service")}
                                             disabled={rateInfoList.length === 0}
                                          >
                                             <MenuItem key={`mi-serviceTrue`} value={true}>
                                                Yes
                                             </MenuItem>
                                             <MenuItem key={`mi-serviceFalse`} value={false}>
                                                No
                                             </MenuItem>
                                          </Select>
                                       </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                       <FormControl fullWidth>
                                          <InputLabel id="il-fuelTariff" style={{ margin: "1rem" }}>
                                             Fuel Tariff
                                          </InputLabel>
                                          <Select
                                             labelId="il-fuelTariff"
                                             id="select-fuelTariff"
                                             value={fuelTariffId || ""}
                                             label="Fuel Tariff"
                                             name="fuelTariff_id"
                                             style={{ margin: "1rem" }}
                                             onChange={(event) => props.user.security.admin && setFuelTariffId(event.target.value)}
                                             disabled={rateInfoList.length === 0}
                                          >
                                             <MenuItem key={`mi-fuelNoValue`} value={""} style={{ minHeight: "2rem" }}>
                                                {" "}
                                             </MenuItem>
                                             {sortAlphabetically(props.fuelTariffs).map((tar) => {
                                                return (
                                                   <MenuItem key={`mi-${tar._id}`} value={tar._id}>
                                                      {tar.name}
                                                   </MenuItem>
                                                );
                                             })}
                                          </Select>
                                       </FormControl>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                       <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={defaultDayjs}>
                                          <DatePicker
                                             label="Start Date"
                                             value={
                                                rateInfoList[rateInfoIndex]
                                                   ? rateInfoList[rateInfoIndex].startDate
                                                   : null
                                             }
                                             name="startDate"
                                             onChange={(event) => props.user.security.admin && handleChange(event, "startDate")}
                                             renderInput={(params) => (
                                                <TextField {...params} style={{ margin: "1rem" }} />
                                             )}
                                             disabled={rateInfoList.length === 0}
                                          />
                                       </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                       <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={defaultDayjs}>
                                          <DatePicker
                                             label="End Date"
                                             value={
                                                rateInfoList[rateInfoIndex] ? rateInfoList[rateInfoIndex].endDate : null
                                             }
                                             name="endDate"
                                             onChange={(event) => props.user.security.admin && handleChange(event, "endDate")}
                                             renderInput={(params) => (
                                                <TextField {...params} style={{ margin: "1rem" }} />
                                             )}
                                             disabled={rateInfoList.length === 0}
                                          />
                                       </LocalizationProvider>
                                    </Grid>
                                    {fuelTariffNameFixed ? <>
                                       <Grid item xs={2}>
                                          <TextField
                                             error={error.includes('fixedFuelAmount')}
                                             type="number"
                                             variant="outlined"
                                             id="tf-fixedFuelAmount"
                                             label="Fixed Fuel Amount"
                                             name="fixedFuelAmount"
                                             value={rateInfoList[rateInfoIndex] ? rateInfoList[rateInfoIndex].fixedFuelAmount : ""}
                                             style={{ margin: "1rem" }}
                                             onChange={props.user.security.admin && handleChange}
                                             disabled={rateInfoList.length === 0}
                                             InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                             }}
                                             helperText={error.includes('fixedFuelAmount') ? `Allowed Upto two decimal places` : null}
                                          />
                                       </Grid>
                                       <Grid item xs={2.5}>
                                          <FormControl fullWidth>
                                             <InputLabel id="il-fixedFuelType" style={{ margin: "1rem" }}>
                                                Fixed Fuel Type
                                             </InputLabel>
                                             <Select
                                                labelId="il-fixedFuelType"
                                                id="select-fixedFuelType"
                                                value={
                                                   rateInfoList[rateInfoIndex]
                                                      ? rateInfoList[rateInfoIndex].fixedFuelType
                                                      : ""
                                                }
                                                label="Fixed Fuel Type"
                                                name="fixedFuelType"
                                                style={{ margin: "1rem" }}
                                                onChange={(event) => props.user.security.admin && handleChange(event, "fixedFuelType")}
                                                disabled={rateInfoList.length === 0}
                                             >
                                                <MenuItem key={`mi-fixedFuelType-percentage`} value={"ppr"}>
                                                   PPR - %
                                                </MenuItem>
                                                <MenuItem key={`mi-fixedFuelType-dollar`} value={"dpm"}>
                                                   DPM - $
                                                </MenuItem>
                                             </Select>
                                          </FormControl>
                                       </Grid>

                                       {rateInfoList[rateInfoIndex].fixedFuelType === 'ppr' ?
                                          <Grid item xs={2}>
                                             <TextField
                                                error={error.includes('pprValue')}
                                                type="number"
                                                variant="outlined"
                                                id="tf-ppr"
                                                label="PPR"
                                                name="pprValue"
                                                value={rateInfoList[rateInfoIndex].pprValue ? rateInfoList[rateInfoIndex].pprValue : ""}
                                                style={{ margin: "1rem" }}
                                                onChange={props.user.security.admin && handleChange}
                                                disabled={rateInfoList.length === 0}
                                                InputProps={{
                                                   endAdornment: <InputAdornment position="start">%</InputAdornment>
                                                }}
                                                helperText={error.includes('pprValue') ? `Allowed Upto two decimal places` : null}
                                             />
                                          </Grid> :
                                          <Grid item xs={2}>
                                             <TextField
                                                error={error.includes('dpmValue')}
                                                type="number"
                                                variant="outlined"
                                                id="tf-dpm"
                                                label={
                                                   rateInfoList[rateInfoIndex].fixedFuelType === 'dpm' ? "DPM" : "PPR/DPM ?"}
                                                name="dpmValue"
                                                value={rateInfoList[rateInfoIndex].dpmValue ? rateInfoList[rateInfoIndex].dpmValue : ""}
                                                style={{ margin: "1rem" }}
                                                onChange={props.user.security.admin && handleChange}
                                                disabled={rateInfoList.length === 0 || rateInfoList[rateInfoIndex].fixedFuelType !== 'dpm'}
                                                InputProps={{
                                                   startAdornment: rateInfoList[rateInfoIndex].fixedFuelType === "dpm" && <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                                helperText={error.includes('dpmValue') ? `Allowed Upto two decimal places` : null}
                                             />
                                          </Grid>}
                                    </> : null}
                                    {props.user.security.admin && (rateInfoList.length > 0) ?
                                       <Grid item
                                          xs={fuelTariffNameFixed ? 5 : 12}
                                          style={{ margin: "1rem" }}
                                       >
                                          <TextField
                                             id="outlined-multiline-flexible"
                                             label="Notes"
                                             multiline
                                             maxRows={4}
                                             fullWidth={true}
                                             value={
                                                rateInfoList[rateInfoIndex] ? rateInfoList[rateInfoIndex].notes : ''
                                             }
                                             onChange={handleChange}
                                          />
                                       </Grid> : null}
                                 </Grid>
                              </Paper>
                           </Grid>
                        </Grid>
                        <Grid container direction={"row"} spacing={1} style={{ marginTop: ".5rem" }}>
                           <Grid item xs={12}>
                              <Paper elevation={3} style={{ paddingBottom: "1rem" }}>
                                 <Grid item xs={12}>
                                    <Typography className={classes.rateDetailsTitle}>
                                       <div className={classes.resultsText}>Historical Rates</div>
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <StyledDataGrid
                                       gridHeight={"350px"}
                                       loading={gridLoadingHistoricalRates}
                                       columns={props.user.security.admin ? tableHeaderAdmin : tableHeader}
                                       rows={tableHistoricalRates ? tableHistoricalRates : null}
                                    ></StyledDataGrid>
                                 </Grid>
                              </Paper>
                           </Grid>
                        </Grid>
                     </>
                  )}
                  {tabsValue === 1 && (
                     <Box p={3}>
                        <ActivityLogGrid
                           columns={[
                              {
                                 field: "date",
                                 headerName: "Date",
                                 description: "Date",
                                 type: "date",
                                 flex: 0.1,
                              },
                              {
                                 field: "hour",
                                 headerName: "Hour",
                                 description: "Hour",
                                 type: "string",
                                 flex: 0.1,
                              },

                              {
                                 field: "event",
                                 headerName: "Event",
                                 description: "Event",
                                 type: "string",
                                 flex: 0.2,
                              },
                              {
                                 field: "description",
                                 headerName: "Event Description",
                                 description: "Event Description",
                                 type: "string",
                                 flex: 0.6,
                              },
                           ]}
                           tableData={activityTableData || []}
                        />
                     </Box>
                  )}
               </Paper>
            </div>
         </div>
      </>
   );
};

function mapStateToProps(state, ownProps) {
   return {
      terminals: state.terminal.terminals || [],
      user: state.user.currentUser,
      customers: state.customer !== null ? state.customer.customer : [],
      fuelTariffs: state.fuel.tariff || [],
      accessorialTariffs: state.accessorial.tariff || [],
      users: state.user.internalUsers || [],
   };
}

RateManagementDetails.propTypes = {
   readOnly: PropTypes.bool.isRequired,
   accessorialTariffs: PropTypes.array.isRequired,
   customers: PropTypes.array.isRequired,
   fuelTariffs: PropTypes.array.isRequired,
   terminals: PropTypes.array.isRequired,
   users: PropTypes.array.isRequired,
   rate: PropTypes.object.isRequired,
   selectedCustomer: PropTypes.object,
   user: PropTypes.object.isRequired,
   update: PropTypes.func.isRequired,
   close: PropTypes.func.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
};

RateManagementDetails.defaultProps = {
   readOnly: false,
   accessorialTariffs: [],
   customers: [],
   fuelTariffs: [],
   terminals: [],
   users: [],
   rate: {},
   selectedRate: {},
   user: {},
   update: () => {
      return;
   },
   close: () => {
      return;
   },
   openAlertMessage: () => {
      return;
   },
};

export default connect(mapStateToProps)(RateManagementDetails);
