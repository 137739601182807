import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import SaveIcon from "@mui/icons-material/Save";
import { Avatar, Divider, Grid, IconButton, Paper, TextField } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as azureStorage from "../../common/azureStorage";
import * as userActions from "../../redux/actions/userActions";
import defaultuser from "../Logo/defaultuser.png";
var _ = require("lodash");
var fileReader = new FileReader();

const Profile = (props) => {
   var history = useHistory();
   var handleError = useErrorHandler();

   const [user, setUser] = useState(props.user);

   //avatar hook also acts as array buffer
   const [avatarSrc, setAvatarSrc] = useState(defaultuser);

   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         borderBottom: "1px solid #2F3136",
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         fontSize: "2rem",
      },
      save: {
         float: "right",
         fontSize: "2rem",
         marginRight: "1rem",
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      inputRoot: {},
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
      listDrawer: {
         width: "300px",
         maxWidth: 360,
         backgroundColor: theme.palette.background.paper,
      },
   }));

   const classes = useStyles();

   useEffect(() => {
      setUser(props.user);

      if (props.avatar) {
         setAvatarSrc(props.avatar);
      }

      return () => {
         setUser(null);
         setAvatarSrc(null);
      };
   }, [props]);

   function onChangeHandler(event) {
      try {
         var newUser = _.cloneDeep(props.user);
         newUser[event.target.name] = event.target.value;
         setUser(newUser);
      } catch (err) {
         handleError(err);
      }
   }

   async function onChangeFile(evt) {
      try {
         //add onload handler for file buffer
         fileReader.onload = async function (event) {
            if (event.target.result !== null) {
               setAvatarSrc(event.target.result);
            }
         };

         //convert to url so it can be displayed on web page
         fileReader.readAsDataURL(evt.target.files[0]);
      } catch (err) {
         handleError(err);
      }
   }

   function saveUser() {
      try {
         var newUser = _.cloneDeep(user);
         props.saveUser(newUser);

         //call upload to azure blob storage
         azureStorage.uploadAvatarToStorage(avatarSrc, props.user._id);
      } catch (err) {
         handleError(err);
      }
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   return (
      <>
         <div id={"profile"} className={classes.layoutMain}>
            <div className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                  <Grid item xs={6}>
                     <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                        <Link
                           color="inherit"
                           style={{ cursor: "pointer" }}
                           onClick={() => breadcrumbOnClick("/profile")}
                        >
                           <h3 style={{ fontWeight: "500" }}>{user.firstname + " " + user.lastname}</h3>
                        </Link>
                     </Breadcrumbs>
                  </Grid>
                  <Grid item xs={6}>
                     <div>
                        <Tooltip title="Save" arrow>
                           <IconButton variant="contained" color="primary" className={classes.save} onClick={saveUser}>
                              <SaveIcon style={{ fontSize: "2rem" }} />
                           </IconButton>
                        </Tooltip>
                     </div>
                  </Grid>
               </Grid>
            </div>
            <div className={classes.layoutBody}>
               <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
                  <div style={{ width: "75%", margin: "0 auto", marginTop: "1rem" }}>
                     <Paper elevation={3} style={{ padding: "3%" }}>
                        <Divider />
                        <h4
                           style={{
                              fontFamily: "Helvetica Neue, Helvetica, Arial",
                              textAlign: "center",
                              marginBottom: "0",
                           }}
                        >
                           {props.user.email}
                        </h4>
                        <div style={{ display: "flex" }}>
                           <Avatar src={avatarSrc} style={{ width: "7rem", height: "7rem", marginLeft: "3rem" }} />
                           <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="icon-button-file"
                              type="file"
                              onChange={onChangeFile}
                           />
                           <label htmlFor="icon-button-file">
                              <IconButton color="primary" aria-label="upload picture" component="span">
                                 <PhotoCamera />
                              </IconButton>
                           </label>
                           <Grid Item style={{ alignSelf: "center", width: "-webkit-fill-available" }}>
                              <TextField
                                 label="First Name"
                                 name="firstname"
                                 style={{ marginLeft: "3rem" }}
                                 value={user.firstname}
                                 onChange={onChangeHandler}
                                 variant="standard"
                              />
                              <TextField
                                 label="Last Name"
                                 name="lastname"
                                 style={{ marginLeft: "3rem" }}
                                 value={user.lastname}
                                 onChange={onChangeHandler}
                                 variant="standard"
                              />
                           </Grid>
                        </div>
                     </Paper>
                  </div>
               </Grid>
            </div>
         </div>
      </>
   );
};

function mapStateToProps(state, ownProps) {
   return {
      user: state.user.currentUser || {},
      avatar: state.user.avatar,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      saveUser: (user) => dispatch(userActions.saveUserAction(user)),
   };
}

Profile.propTypes = {
   user: PropTypes.object.isRequired,
   avatar: PropTypes.any,
   saveUser: PropTypes.func.isRequired,
};

Profile.defaultProps = {
   user: {},
   avatar: null,
   saveUser: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
