//Function that will get the fuel tariff based off the heirarchy
import { _handleObjectProperty } from "./convert";
import { dateFormatter } from "./dateFormatter";
var _ = require("lodash");

//this function gets the full fuel tariff of every rate. Primarily used for rate search and quote screen.
export const getFuelTariffs = async (rates, fuelTariffs = [], customers = [], selectedCustomer, terminals = []) => {
   for (const rateArray in rates) {
      for (const rate in rates[rateArray]) {
         rates[rateArray][rate].fuelTariff = {};

         if (rates[rateArray][rate].groupTerminal) {
            rates[rateArray][rate].fuelTariff = await getFuelTariff(
               rates[rateArray][rate],
               fuelTariffs,
               customers,
               selectedCustomer,
               terminals,
               rates[rateArray][rate].groupTerminal,
               true,
            );
         } else {
            rates[rateArray][rate].fuelTariff = await getFuelTariff(
               rates[rateArray][rate],
               fuelTariffs,
               customers,
               selectedCustomer,
               terminals,
            );
         }
      }
   }

   return rates;
};

export const getFuelTariff = async (
   rate,
   fuelTariffs = [],
   customers = [],
   selectedCustomer,
   terminals = [],
   groupTerminal = null,
   isTerminalGroup = false,
) => {
   let tariff = {};
   const tariffList = _.cloneDeep(fuelTariffs);
   const rateCustID = rate.customer_id ? rate.customer_id : selectedCustomer ? selectedCustomer._id : null;

   //check to see if rate has a tariff assigned to it

   var rateTariff = _.filter(tariffList, (x) => x._id === rate.fuelTariff_id);
   if (rateTariff.length > 0) {
      tariff = rateTariff[0];
   }

   //if tariff is empty, then attempt to get customer to terminals
   if (_.isEmpty(tariff) && rateCustID != null && customers.length > 0) {
      var cust = _.filter(customers, (x) => x._id.toString() === rateCustID.toString());

      if (_handleObjectProperty(cust[0], "fuelTariffs")) {
         let fuelTariffIndex = null;

         if (isTerminalGroup) {
            fuelTariffIndex = _.findIndex(cust[0].fuelTariffs, function (x) {
               return x.terminal_ids.includes(groupTerminal);
            });
         } else {
            fuelTariffIndex = _.findIndex(cust[0].fuelTariffs, function (x) {
               return x.terminal_ids.includes(rate.terminal_id);
            });
         }

         if (fuelTariffIndex > -1) {
            var custTariff = _.filter(
               tariffList,
               (x) => x._id.toString() == cust[0].fuelTariffs[fuelTariffIndex].fuelTariff_id.toString(),
            );

            if (custTariff.length > 0) {
               tariff = custTariff[0];
            }
         }
      }
   }

   //if tariff is empty, then attempt to get terminal default
   if (_.isEmpty(tariff)) {
      //get terminal object based on terminal_id from rate
      var term = _.filter(terminals, (t) => t._id === rate.terminal_id);

      //get tariff based on default tariff ID from the terminal object
      var termTariff = _.filter(tariffList, (t) => t._id === term[0].fuelTariff_id);

      if (termTariff.length > 0) {
         tariff = termTariff[0];
      }
   }

   //get current tariff info
   if (!_.isEmpty(tariff)) {
      //get the current tariff info
      var tariffInfo = _.filter(tariff.tariffInfo, (c) => c.endDate === null);

      tariff.tariffInfo = tariffInfo[0];

      //get the current over under info based on miles
      if (rate.rateInfo) {
         const overUnder = tariff.overUnder.filter((o) => {
            // check minMiles <= miles <= maxMiles
            if (o.minMiles <= rate.rateInfo.miles && o.maxMiles >= rate.rateInfo.miles) {
               // check startDate <= today's date <= endDate
               if (o.startDate && o.endDate) {
                  // both values startDate and endDate exists
                  if (
                     new Date(dateFormatter(o.startDate)).getTime() <= new Date().getTime() &&
                     new Date(dateFormatter(o.endDate)).getTime() >= new Date().getTime()
                  ) {
                     return o;
                  }
               } else if (o.startDate && !o.endDate) {
                  // only startDate exist
                  if (new Date(dateFormatter(o.startDate)).getTime() <= new Date().getTime()) {
                     return o;
                  }
               } else if (!o.startDate && o.endDate) {
                  // only endDate exist
                  if (new Date(dateFormatter(o.endDate)).getTime() >= new Date().getTime()) {
                     return o;
                  }
               }
            }
         });

         tariff.overUnder = overUnder[0];
      }
   }

   return tariff;
};
