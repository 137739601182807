import Alert from "@mui/material/Alert";
import PropTypes from "prop-types";
import { useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as userActions from "../../redux/actions/userActions";
import RegistrationForm from "./RegistrationForm";
var _ = require("lodash");

const RegisterPage = (props) => {
   var handleError = useErrorHandler();

   let [state, setState] = useState({
      confirm: "",
      password: "",
      firstName: "",
      lastName: "",
      invToken: props.match.params.id,
      email: props.match.params.email,
      customerId: props.match.params.customerid ? props.match.params.customerid : null,
   });

   let [error, setError] = useState("");
   let [success, setSuccess] = useState(false);
   let [policy, setPolicy] = useState({
      policy1: false,
      policy2: false,
      policy3: false,
      policy4: false,
   });
   const [isSubmitLoading, setIsSubmitLoading] = useState(false);

   function checkPolicy(password) {
      try {
         var newPolicy = _.cloneDeep(policy);
         var lowerCase = new RegExp(/[a-z]/);
         var upperCase = new RegExp(/[A-Z]/);
         var specialChar = new RegExp(/[!@#\$%\^\&*\)\(+=._-]/);
         var minChar = new RegExp(/.{8,}/);
         var minNum = new RegExp(/(?=.*?[0-9])/);

         //do password validate for policy 1 then set the object true or false
         if (password.match(lowerCase) && password.match(upperCase)) {
            newPolicy.policy1 = true;
         } else {
            newPolicy.policy1 = false;
         }

         if (password.match(minNum)) {
            newPolicy.policy2 = true;
         } else {
            newPolicy.policy2 = false;
         }

         if (password.match(specialChar)) {
            newPolicy.policy3 = true;
         } else {
            newPolicy.policy3 = false;
         }

         if (password.match(minChar)) {
            newPolicy.policy4 = true;
         } else {
            newPolicy.policy4 = false;
         }

         //check other ones as well

         //set the hook
         setPolicy(newPolicy);
      } catch (err) {
         handleError(err);
      }
   }

   function validate() {
      try {
         if (!state.password || !state.confirm) {
            setError("Password and Confirm Password are required.");
            return false;
         }
         if (state.password !== state.confirm) {
            setError("Password and Confirm Password do not match.");
            return false;
         }
         if (!state.firstName) {
            setError("First Name and Last Name are required");
            return false;
         }
         if (!state.lastName) {
            setError("First Name and Last Name are required");
            return false;
         }

         setError("");
         return true;
      } catch (err) {
         handleError(err);
      }
   }

   function onSubmit(event) {
      setIsSubmitLoading(true);
      event.preventDefault();

      if (!validate()) {
         setIsSubmitLoading(false);
         return;
      }

      props.submit(state).then((resp) => {
         if (resp.success) {
            setIsSubmitLoading(false);
            setSuccess(true);
         } else {
            setIsSubmitLoading(false);
            setError(resp.err);
         }
      });
   }

   function onPasswordChange(event) {
      try {
         checkPolicy(event.target.value);
         setState({
            ...state,
            password: event.target.value,
         });
      } catch (err) {
         handleError(err);
      }
   }

   function onConfirmChange(event) {
      try {
         setState({
            ...state,
            confirm: event.target.value,
         });
      } catch (err) {
         handleError(err);
      }
   }

   function onFirstNameChange(event) {
      try {
         setState({
            ...state,
            firstName: event.target.value,
         });
      } catch (err) {
         handleError(err);
      }
   }

   function onLastNameChange(event) {
      try {
         setState({
            ...state,
            lastName: event.target.value,
         });
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <div>
         {success && <Redirect to="/login"></Redirect>}
         {error && (
            <Alert style={{ color: "#FFFFFF" }} severity="error">
               {" "}
               {error}{" "}
            </Alert>
         )}
         <RegistrationForm
            handleSubmit={onSubmit}
            handlePasswordChange={onPasswordChange}
            handleConfirmChange={onConfirmChange}
            handleFirstNameChange={onFirstNameChange}
            handleLastNameChange={onLastNameChange}
            isSubmitLoading={isSubmitLoading}
            firstName={state.firstName}
            policy={policy}
            lastName={state.lastName}
            password={state.password}
            confirm={state.confirm}
         />
      </div>
   );
};

function mapStateToProps(state, ownProps) {
   return {};
}

function mapDispatchToProps(dispatch) {
   return {
      submit: (newUser) => dispatch(userActions.submitNewUser(newUser)),
   };
}

RegisterPage.propTypes = {
   match: PropTypes.shape({
      params: PropTypes.shape({
         id: PropTypes.string.isRequired,
         email: PropTypes.string.isRequired,
         customerid: PropTypes.string.isRequired,
      }),
   }),
   submit: PropTypes.func.isRequired,
};

RegisterPage.defaultProps = {
   match: { params: { id: "", email: "", customerid: "" } },
   submit: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
