import React from "react";
import { Route, Switch } from "react-router-dom";
import FuelEdit from "./FuelEdit";
import FuelView from "./FuelView";

const Fuel = () => {
   return (
      <>
         <Switch>
            <Route path="/fuel" exact component={FuelView} />
            <Route path="/fuel/details/:id?" exact component={FuelEdit} />
         </Switch>
      </>
   );
};

export default Fuel;
