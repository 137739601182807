const { DateTime } = require("luxon");

/**
 * Definition: This function formats date values coming from the api.
 *
 * Example:
 * dateTimeFormatter("2022-03-14T14:26:49.671Z")
 *
 * Should Return:
 * "03/14/2022 02:26PM"
 *
 */

export const dateTimeFormatter = (date = "") => {
   try {
      var luxonDate = DateTime.fromISO(date).setZone("America/New_York").toISO();

      const newDate = new Date(luxonDate ? luxonDate : date);
      const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(newDate);
      const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(newDate);
      const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(newDate);
      const hour = new Intl.DateTimeFormat("en", { hour: "2-digit" }).format(newDate).substring(0, 2);
      const minute = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(newDate).padStart(2, "0");
      const AMPM = new Intl.DateTimeFormat("en", { hour: "2-digit" }).format(newDate).substring(2, 5);
      return `${month}/${day}/${year} ${hour}:${minute}${AMPM}`;
   } catch (error) {
      return "00/00/0000 12:00 AM";
   }
};
