import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Autocomplete } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import parse from "autosuggest-highlight/parse";
import _ from "lodash";
import throttle from "lodash/throttle";
import PropTypes from "prop-types";
import React from "react";

function loadScript(src, position, id) {
   if (!position) {
      return;
   }

   const script = document.createElement("script");
   script.setAttribute("async", "");
   script.setAttribute("id", id);
   script.src = src;
   position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
   icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
   },
}));

export default function PlaceSearch(props) {
   const classes = useStyles();
   const [inputValue, setInputValue] = React.useState("");
   const [options, setOptions] = React.useState([]);
   const loaded = React.useRef(false);

   if (typeof window !== "undefined" && !loaded.current) {
      if (!document.querySelector("#google-maps")) {
         loadScript(
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyDdtwiev0wNVg2fNuTiP1iRUVEu9asAmok&libraries=places&language=en",
            document.querySelector("head"),
            "google-maps",
         );
      }

      loaded.current = true;
   }

   const handleChange = (event) => {
      setInputValue(event.target.value);
   };

   const onChange = (event, values) => {};

   const onKeyUp = (event, values) => {};

   const fetch = React.useMemo(
      () =>
         throttle((request, callback) => {
            var finalRequest = {
               ...request,
               componentRestrictions: { country: "us" },
               types: ["geocode"], //SHOULD BE: ['(regions)', 'geocode'] THIS IS GOOGLE'S FAULT. TOO LIMITED. MAYBE REVISIT????
            };

            autocompleteService.current.getPlacePredictions(finalRequest, callback);
         }, 200),
      [],
   );

   React.useEffect(() => {
      let active = true;

      if (!autocompleteService.current && window.google) {
         autocompleteService.current = new window.google.maps.places.AutocompleteService();
      }
      if (!autocompleteService.current) {
         return undefined;
      }

      if (inputValue === "") {
         setOptions([]);
         return undefined;
      }

      if (inputValue.length === 5 && inputValue.match(/^[0-9]+$/) !== null) {
         fetch({ input: "postalcode " + inputValue }, (results) => {
            fetch({ input: inputValue }, (resultsNoPostalCode) => {
               if (active) {
                  if (results === null) {
                     //No postalcode results.
                     setOptions(resultsNoPostalCode || []);
                  } else if (resultsNoPostalCode === null) {
                     //No results.
                     setOptions(results || []);
                  } else {
                     //results for both. Merge.
                     results.map((result) => {
                        var placeFound = _.find(resultsNoPostalCode, (x) => x.place_id === result.place_id);

                        if (!placeFound) {
                           resultsNoPostalCode.unshift(result);
                        }
                     });

                     resultsNoPostalCode = resultsNoPostalCode.slice(0, 5);

                     setOptions(resultsNoPostalCode || []);
                  }
               }
            });
         });
      } else {
         fetch({ input: inputValue }, (results) => {
            if (active) {
               setOptions(results || []);
            }
         });
      }

      return () => {
         active = false;
      };
   }, [inputValue, fetch]);

   return (
      <Autocomplete
         id="google-map"
         getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
         filterOptions={(x) => x}
         options={options}
         onChange={props.onChange ? props.onChange : onChange}
         value={props.destination}
         autoComplete
         disabled={props.disabled ? true : false}
         includeInputInList
         filterSelectedOptions
         renderInput={(params) => (
            <TextField {...params} label="Add a location" variant="outlined" fullWidth onChange={handleChange} />
         )}
         autoSelect={true}
         onKeyUp={props.onKeyUp ? props.onKeyUp : onKeyUp}
         renderOption={(props, option) => {
            const matches = option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
               option.structured_formatting.main_text,
               matches.map((match) => [match.offset, match.offset + match.length]),
            );

            return (
               <li {...props}>
                  <Grid container alignItems="center">
                     <Grid item>
                        <Box component={LocationOnIcon} sx={{ color: "text.secondary", mr: 2 }} />
                     </Grid>
                     <Grid item xs>
                        {parts.map((part, index) => (
                           <span
                              key={index}
                              style={{
                                 fontWeight: part.highlight ? 700 : 400,
                              }}
                           >
                              {part.text}
                           </span>
                        ))}

                        <Typography variant="body2" color="text.secondary">
                           {option.structured_formatting.secondary_text}
                        </Typography>
                     </Grid>
                  </Grid>
               </li>
            );
         }}
      />
   );
}

PlaceSearch.propTypes = {
   destination: PropTypes.string.isRequired,
   disabled: PropTypes.func,
   onChange: PropTypes.func,
   onKeyUp: PropTypes.func,
};

PlaceSearch.defaultProps = {
   destination: "",
};
