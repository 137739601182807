import React from "react";
import { Route, Switch } from "react-router-dom";
import UserEdit from "./UserDetails";
import UserView from "./UserView";
var _ = require("lodash");

const User = (props) => {
   return (
      <>
         <Switch>
            <Route path="/user" exact component={UserView} />
            <Route path="/user/details/:id?" exact component={UserEdit} />
         </Switch>
      </>
   );
};

export default User;
