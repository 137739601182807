import { HandleError, HandleResponse } from "./apiUtils";
import { HEADERS } from "./headers";
const baseUrl = "/api/fuel/";

export function getTariffs(tariffID) {


   let url = baseUrl + "getTariff?";
   if (tariffID) {
      url += "tariffID=" + tariffID;
   }
   return fetch(url, {
      method: "GET",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function setTariffs(tariff) {


   return fetch(baseUrl + "setTariff", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ tariff }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteTariff(tariffID) {


   let url = baseUrl + "deleteTariff?";
   if (tariffID) {
      url += "tariffID=" + tariffID;
   }
   return fetch(url, {
      method: "DELETE",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteOverUnder(tariffId, overUnderId) {

   return fetch(`${baseUrl}deleteOverUnder`, {
      method: "DELETE",
      headers: HEADERS(),
      body: JSON.stringify({
         tariffId,
         overUnderId,
      }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}
