import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React from "react";
import useStyle from "./styles";

export default function StyledDataGrid(props) {
   const classes = useStyle();

   return (
      <div className={classes.tableGridContainer} style={{ height: props.gridHeight ? props.gridHeight : null }}>
         <DataGridPro
            pagination
            pageSize={100}
            rowsPerPageOptions={[100]}
            {...props}
            components={{
               ColumnSortedAscendingIcon: ArrowDropUpIcon,
               ColumnSortedDescendingIcon: ArrowDropDownIcon,
            }}
         />
      </div>
   );
}
