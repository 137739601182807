import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import { green, red } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import * as userApi from "../../api/userApi";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
   wrapper: {
      margin: theme.spacing(1),
      position: "relative",
   },
   buttonProgress: {
      color: green[500],
   },
   checkIcon: {
      color: red[500],
   },
}));

const CustomerEmailInvite = (props) => {
   const classes = useStyles();
   var handleError = useErrorHandler();

   let [emailAddresses, setEmailAddresses] = useState([]);
   let [emailAddress, setEmailAddress] = useState("");
   let [invalidEmail, setInvalidEmail] = useState("Enter an Email");
   let [alertMessage, setAlertMessage] = useState(null);
   let [alertType, setAlertType] = useState("");
   let [sendInviteButton, setSendInviteButton] = useState(false);

   //Reset all variables on load.
   useEffect(() => {
      setAlertType("");
      setAlertMessage(null);
      setEmailAddresses([]);
      setEmailAddress("");
      setInvalidEmail("Enter an Email");
   }, [props.open]);

   function handleAddEmail(email) {
      try {
         emailAddresses.push(email);
         setEmailAddress("");
         setInvalidEmail("Enter an Email");
         setEmailAddresses(emailAddresses);
      } catch (err) {
         handleError(err);
      }
   }

   function handleDeleteEmail(chip) {
      try {
         setEmailAddresses(emailAddresses.filter((c) => c !== chip));
      } catch (err) {
         handleError(err);
      }
   }

   function validateEmailAddress(email) {
      try {
         let topLevelDomain = "";

         if (email === null || email === "") {
            setInvalidEmail("Enter an Email");
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp("@", "g")) || []).length === 0) {
            setInvalidEmail(`Invalid Email. "@" Character is Required`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp("@", "g")) || []).length > 1) {
            setInvalidEmail(`Invalid Email. Only One "@" Character Allowed`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp("[.]", "g")) || []).length === 0) {
            setInvalidEmail(`Invalid Email. "." Character is Required`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp(`["(),:;<>]`, "g")) || []).length > 0) {
            setInvalidEmail(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp(`[[]`, "g")) || []).length > 0) {
            setInvalidEmail(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp(`]`, "g")) || []).length > 0) {
            setInvalidEmail(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`);
            setEmailAddress(email);
            return;
         }

         if (email.indexOf(`\\`) >= 0) {
            setInvalidEmail(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`);
            setEmailAddress(email);
            return;
         }

         if (email.substring(0, 1) === "@") {
            setInvalidEmail(`Invalid Email. Recipient Name is Required. Email Cannot Begin With @ Character`);
            setEmailAddress(email);
            return;
         }

         if ((email.substring(email.indexOf("@")).match(new RegExp("[.]", "g")) || []).length === 0) {
            setInvalidEmail(`Invalid Email. Top Level Domain (.com, .net, .org, etc) Required`);
            setEmailAddress(email);
            return;
         }

         topLevelDomain = email.substring(email.indexOf("@"));

         if (topLevelDomain.indexOf(".") === 1) {
            setInvalidEmail(`Invalid Email. Domain Name (@gmail, @yahoo, @outlook) Required`);
            setEmailAddress(email);
            return;
         }

         topLevelDomain = topLevelDomain.substring(topLevelDomain.indexOf(".") + 1);

         if (topLevelDomain === "" || topLevelDomain === null) {
            setInvalidEmail(`Invalid Email. Top Level Domain (.com, .net, .org, etc) Required`);
            setEmailAddress(email);
            return;
         }

         let emailExists = emailAddresses.includes(email);

         if (emailExists) {
            setInvalidEmail(`Invalid Email. Email Already Exists to Send`);
            setEmailAddress(email);
            return;
         }

         setInvalidEmail(null);
         setEmailAddress(email);
      } catch (err) {
         handleError(err);
      }
   }

   const sendInvite = () => {
      if (emailAddresses.length === 0) {
         setAlertType("warning");
         setAlertMessage("You must enter at least one email address.");
         return;
      }

      setSendInviteButton(true);

      //emailAddresses should be filled in from your hook
      userApi
         .inviteCustomerUser(emailAddresses, props.customerId, props.customerName)
         .then((resp) => {
            if (resp) {
               if (resp.success) {
                  setAlertType("success");
                  setAlertMessage("Sent invite to addresses.");
                  setSendInviteButton(false);
               } else {
                  setAlertType("error");
                  setAlertMessage(`Error sending invite: ${resp.message}`);
                  setSendInviteButton(false);
               }
            } else {
               setAlertType("error");
               setAlertMessage(`Error sending invite.`);
               setSendInviteButton(false);
            }
         })
         .catch((err) => {
            setAlertType("error");
            setAlertMessage(`Error sending invite: ${err.message}`);
            setSendInviteButton(false);
         });

      //Clean up.
      setEmailAddresses([]);
      setEmailAddress("");
      setInvalidEmail("Enter an Email");
   };

   return (
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted>
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            Invite User(s) For Customer: {props.customerName}
         </DialogTitle>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               style={{ color: "#FFFFFF" }}
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage(null);
                     }}
                  >
                     <CloseIcon style={{ fontSize: "2rem" }} />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <DialogContent style={{ marginTop: "1.5rem" }}>
            <Grid container direction={"row"} xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               <Grid item xs={10}>
                  <TextField
                     id="ci-email"
                     style={{ width: "30rem", color: "#002D72" }}
                     value={emailAddress}
                     onChange={(event) => validateEmailAddress(event.target.value ? event.target.value : null)}
                     label="Email"
                     variant="outlined"
                     InputProps={
                        invalidEmail
                           ? {
                                startAdornment: (
                                   <Tooltip title={invalidEmail} arrow>
                                      <InputAdornment position="start">
                                         <CloseIcon className={classes.checkIcon} />
                                      </InputAdornment>
                                   </Tooltip>
                                ),
                             }
                           : {
                                startAdornment: (
                                   <Tooltip title={"Valid Email"} arrow>
                                      <InputAdornment position="start">
                                         <CheckIcon className={classes.buttonProgress} />
                                      </InputAdornment>
                                   </Tooltip>
                                ),
                             }
                     }
                  />
               </Grid>
               <Grid item xs={2}>
                  <Tooltip title="Add User Email" arrow>
                     <IconButton
                        id="btn-email"
                        style={{ float: "right" }}
                        variant="contained"
                        color="primary"
                        disabled={invalidEmail ? true : false}
                        onClick={() => handleAddEmail(emailAddress)}
                     >
                        <AddIcon style={{ fontSize: "2rem" }}></AddIcon>
                     </IconButton>
                  </Tooltip>
               </Grid>
            </Grid>
            <Grid container direction={"row"} xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               {emailAddresses.map((email, index) => {
                  return (
                     <Grid key={`gi-${index}`} item>
                        <Chip
                           style={{ marginTop: "1rem" }}
                           label={email}
                           id="ci-emailAddresses"
                           color="primary"
                           onDelete={() => handleDeleteEmail(email)}
                           size="small"
                        />
                     </Grid>
                  );
               })}
            </Grid>
            <Grid container direction={"row"} xs={12} style={{ margin: "auto", paddingTop: "2%" }}>
               <Grid item xs={12}>
                  <Tooltip title="Exit" arrow>
                     <IconButton
                        id="btn-exit"
                        variant="contained"
                        color="secondary"
                        style={{ float: "right" }}
                        onClick={props.handleClose}
                        disabled={sendInviteButton}
                     >
                        <ExitToAppRoundedIcon style={{ fontSize: "2rem" }} />
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Send Invite" arrow>
                     <IconButton
                        id="btn-send"
                        variant="contained"
                        color="primary"
                        style={{ float: "right" }}
                        onClick={sendInvite}
                        disabled={sendInviteButton}
                     >
                        {sendInviteButton ? (
                           <CircularProgress style={{ fontSize: "2rem" }} className={classes.buttonProgress} />
                        ) : (
                           <EmailRoundedIcon style={{ fontSize: "2rem" }} />
                        )}
                     </IconButton>
                  </Tooltip>
               </Grid>
            </Grid>
         </DialogContent>
      </Dialog>
   );
};

CustomerEmailInvite.propTypes = {
   open: PropTypes.bool.isRequired,
   customerId: PropTypes.string.isRequired,
   customerName: PropTypes.string.isRequired,
   userId: PropTypes.string.isRequired,
   handleClose: PropTypes.func.isRequired,
};

CustomerEmailInvite.defaultProps = {
   open: false,
   customerId: "",
   customerName: "",
   userId: "",
   handleClose: () => {
      return;
   },
};

export default CustomerEmailInvite;
