import { HandleError, HandleResponse } from "./apiUtils";
import { HEADERS, HEADERS_WITH_AUTHORIZATION } from "./headers";
const baseUrl = "/api/application/";

export function getTerminals(termID) {

   let url = baseUrl + "getTerminals?";
   if (termID) {
      url += `termID=${termID}`;
   }

   return fetch(url, {
      method: "GET",
      headers: HEADERS_WITH_AUTHORIZATION(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function saveTerminal(terminal, isNew = false) {


   return fetch(baseUrl + "saveTerminal", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ terminal, isNew }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteTerminal(termID) {


   let url = baseUrl + "deleteTerminal?";
   if (termID) {
      url += "termID=" + termID;
   }
   return fetch(url, {
      method: "DELETE",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getLookup(code) {


   let url = baseUrl + "getLookup?";
   if (code) {
      url += "code=" + code;
   }
   return fetch(url, {
      method: "GET",
      headers: HEADERS_WITH_AUTHORIZATION(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getAlias(aliasID) {

   let url = baseUrl + "getAlias?";
   if (aliasID) {
      url += `aliasID=${aliasID}`;
   }

   return fetch(url, {
      method: "GET",
      headers: HEADERS_WITH_AUTHORIZATION(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function saveAlias(alias) {


   return fetch(baseUrl + "saveAlias", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ alias }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteAlias(aliasID) {


   let url = baseUrl + "deleteAlias?";
   if (aliasID) {
      url += "aliasID=" + aliasID;
   }
   return fetch(url, {
      method: "DELETE",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getConnectionString() {


   let url = baseUrl + "getConnectionString";

   return fetch(url, {
      method: "GET",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getAzureShare() {


   let url = baseUrl + "getAzureShare";

   return fetch(url, {
      method: "GET",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}
