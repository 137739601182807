import React from "react";
import { Route, Switch } from "react-router-dom";
import AccessorialTariffEdit from "./AccessorialTariffEdit";
import AccessorialTariffView from "./AccessorialTariffView";

const AccessorialTariff = () => {
   return (
      <>
         <Switch>
            <Route path="/accessorialtariff" exact component={AccessorialTariffView} />
            <Route path="/accessorialtariff/details/:id?" exact component={AccessorialTariffEdit} />
         </Switch>
      </>
   );
};

export default AccessorialTariff;
