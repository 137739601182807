import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "./Header.css";
import NotificationsDropdown from "./NotificationsDropdown";
import ProfileDropdown from "./ProfileDropdown";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
   containerStyle: {
      [theme.breakpoints.up("sm")]: {
         width: `calc(100% - ${drawerWidth}px)`,
         marginLeft: drawerWidth,
      },
      backgroundColor: "White",
      zIndex: "2",
      width: "100%",
   },
   title: {
      flexGrow: 1,
      float: "left",
      color: "black",
   },
   menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
         display: "none",
      },
   },
   headerButtons: {
      backgroundColor: "white",
      color: "#9AA7C4",
      paddingRight: "1rem",
      paddingLeft: "1rem",
      "&:hover": {
         color: "#010440",
      },
   },
   headerDivider: {
      color: "#9AA7C4",
      height: "3.1rem",
   },
}));

function Header(props) {
   const history = useHistory();
   const classes = useStyles();
   const headerStyle = {
      containerStyle: {
         background: "white",
         height: "50px",
         position: "fixed",
         zIndex: "2",
         width: "100%",
      },
   };

   function handleClick(path) {
      history.push(path);
   }

   return (
      <AppBar position="fixed" className={classes.containerStyle}>
         <Toolbar style={{ backgroundColor: "white", borderBottom: "solid 1px #f2f2f2" }}>
            <IconButton
               variant="contained"
               color="primary"
               onClick={props.handleDrawerToggle}
               className={classes.menuButton}
            >
               <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
               {props.content}
            </Typography>
            <div style={{ marginTop: "0.40rem" }}>
               <NotificationsDropdown></NotificationsDropdown>
            </div>
            <div className={classes.headerButtons}>
               <ProfileDropdown handleClick={handleClick}></ProfileDropdown>
            </div>
         </Toolbar>
      </AppBar>
   );
}

function mapStateToProps(state, ownProps) {
   return {
      user: state.user,
      content: ownProps.content,
      setParentState: ownProps.setParentState,
   };
}

Header.propTypes = {
   user: PropTypes.object.isRequired,
   content: PropTypes.string.isRequired,
   handleDrawerToggle: PropTypes.func.isRequired,
};

Header.defaultProps = {
   user: {},
   content: "",
   handleDrawerToggle: () => {
      return;
   },
};

export default connect(mapStateToProps)(Header);
