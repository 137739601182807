import ClearAllIcon from "@mui/icons-material/ClearAll";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";

var _ = require("lodash");

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

export default function FuelOverUnderModal(props) {
   var handleError = useErrorHandler();

   const [overUnder, setOverUnder] = useState({
      ppr: null,
      cpm: null,
      minMiles: null,
      maxMiles: null,
   });

   useEffect(() => {
      setOverUnder({
         ppr: null,
         cpm: null,
         minMiles: null,
         maxMiles: null,
      });
   }, []);

   const onChange = (event) => {
      try {
         var newOverUnder = _.cloneDeep(overUnder);
         newOverUnder[event.target.name] = event.target.value;
         setOverUnder(newOverUnder);
      } catch (err) {
         handleError(err);
      }
   };

   function newOverUnder() {
      try {
         var tariff = _.cloneDeep(props.selectedTariff);

         if (+overUnder.minMiles >= +overUnder.maxMiles) {
            alert("Minimum milage cannot be more than or equal to maximum milage");
            return;
         }

         if (overUnder.ppr == null && overUnder.cpm == null) {
            alert("Pease enter a DPM or PPR.");
            return;
         }

         //check to see if milage falls within range of an existing overunder row
         var overUnderCheck = _.filter(
            tariff.overUnder,
            (ou) => ou.endDate === null && +ou.maxMiles >= +overUnder.minMiles,
         );

         //check array length
         if (overUnderCheck.length > 0) {
            alert("Need to make mile range larger than what already exists");
            return;
         }

         var newUnOv = {
            _id: null,
            add_date: Date.now(),
            endDate: null,
            ppr: overUnder.ppr,
            cpm: overUnder.cpm,
            minMiles: overUnder.minMiles,
            maxMiles: overUnder.maxMiles,
         };

         tariff.overUnder.push(newUnOv);
         props.setSelectedTariff(tariff);
         props.handleClose(false);
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.handleClose}>
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            {"New Under/Over"}
         </DialogTitle>
         <DialogContent>
            <FormControl style={{ padding: "1%" }}>
               <InputLabel htmlFor="ppr">PPR</InputLabel>
               <Input
                  name="ppr"
                  value={overUnder.ppr}
                  onChange={onChange}
                  disabled={overUnder.cpm !== null ? true : false}
               />
            </FormControl>
            <FormControl style={{ padding: "1%" }}>
               <InputLabel htmlFor="cpm">DPM</InputLabel>
               <Input
                  name="cpm"
                  value={overUnder.cpm}
                  onChange={onChange}
                  disabled={overUnder.ppr !== null ? true : false}
               />
            </FormControl>
            <FormControl style={{ padding: "1%" }}>
               <InputLabel htmlFor="minMiles">Min Miles</InputLabel>
               <Input name="minMiles" value={overUnder.minMiles} onChange={onChange} />
            </FormControl>
            <FormControl style={{ padding: "1%" }}>
               <InputLabel htmlFor="maxMiles">Max Miles</InputLabel>
               <Input name="maxMiles" value={overUnder.maxMiles} onChange={onChange} />
            </FormControl>
         </DialogContent>
         <DialogActions>
            <Tooltip title="Cancel" arrow>
               <IconButton onClick={props.handleClose} variant="contained" color="info">
                  <CloseIcon />
               </IconButton>
            </Tooltip>
            <Tooltip title="Clear" arrow>
               <IconButton
                  onClick={() => setOverUnder({ ppr: null, cpm: null, minMiles: null, maxMiles: null })}
                  variant="contained"
                  color="secondary"
               >
                  <ClearAllIcon />
               </IconButton>
            </Tooltip>
            <Tooltip title="Save" arrow>
               <IconButton onClick={newOverUnder} variant="contained" color="primary">
                  <SaveIcon />
               </IconButton>
            </Tooltip>
         </DialogActions>
      </Dialog>
   );
}

FuelOverUnderModal.propTypes = {
   open: PropTypes.bool.isRequired,
   selectedTariff: PropTypes.object.isRequired,
   handleClose: PropTypes.func.isRequired,
   setSelectedTariff: PropTypes.func.isRequired,
};

FuelOverUnderModal.defaultProps = {
   open: false,
   selectedTariff: {},
   handleClose: () => {
      return;
   },
   setSelectedTariff: () => {
      return;
   },
};
