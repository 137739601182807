export const fromUTC = (datetime) => {
   if (!datetime) {
      return null;
   }

   datetime = datetime.toString().replace("z", "");
   datetime = datetime.toString().replace("Z", "");

   return datetime;
};
