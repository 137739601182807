import { HandleError, HandleResponse } from "./apiUtils";
import { HEADERS } from './headers';
const baseUrl = "/api/accessorial/";

export function getTariffs(tariffID) {
   let url = baseUrl + "getTariff?";

   if (tariffID) {
      url += "tariffID=" + tariffID;
   }

   return fetch(url, {
      method: "GET",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function setTariffs(tariff) {
   return fetch(baseUrl + "setTariff", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ tariff }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteTariff(tariffID) {
   let url = baseUrl + "deleteTariff?";

   if (tariffID) {
      url += "tariffID=" + tariffID;
   }

   return fetch(url, {
      method: "DELETE",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getGuides(guideID) {
   let url = baseUrl + "getAccessorialGuide";

   if (guideID) {
      url += "?accessorialGuideID=" + guideID;
   }

   return fetch(url, {
      method: "GET",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}


export function setGuides(accessorialGuide) {

   return fetch(baseUrl + "setAccessorialGuide", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ accessorialGuide }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteGuide(accessorialGuideID) {
   let url = baseUrl + "deleteAccessorialGuide?";

   if (accessorialGuideID) {
      url += "accessorialGuideID=" + accessorialGuideID;
   }

   return fetch(url, {
      method: "DELETE",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}
