//format the csv export so it can be used to import
export const headerCSV = [
   { label: "Terminal ID", key: "_id" },
   { label: "Code", key: "code" },
   { label: "Name", key: "name" },
   { label: "Address 1", key: "address.address1" },
   { label: "Address 2", key: "address.address2" },
   { label: "City", key: "address.city" },
   { label: "State", key: "address.state" },
   { label: "Zip", key: "address.zip" },
   { label: "Is Active", key: "active" },
   { label: "Lane Date Added", key: "add_date" },
];
