import { decode } from "jsonwebtoken";
import * as userApi from "../../api/userApi";
import * as cookieTools from "../../cookieTools";
import actionTypes from "./actionTypes";

export function setAvatar(avatar) {
   return { type: actionTypes.SET_AVATAR, avatar };
}

export function saveUserAction(user) {
   return function (dispatch) {
      return userApi
         .saveUser(user)
         .then((resp) => {
            dispatch({ type: actionTypes.SAVE_USER, user: user });
         })
         .catch((error) => {
            return;
         });
   };
}

//thunk
export function loadUser() {
   return function (dispatch) {
      let userID = cookieTools.getCookie("trt-rate-guide-userID");

      return userApi
         .getUsers(userID)
         .then((resp) => {
            dispatch({ type: actionTypes.LOGIN_USER, user: resp.users[0] });
         })
         .catch((error) => {
            return;
         });
   };
}

export function getInternalUsers() {
   return function (dispatch) {
      return userApi
         .getUsers(null, null, true)
         .then((resp) => {
            dispatch({ type: actionTypes.LOAD_USERS, internalUsers: resp.users });
         })
         .catch((error) => {
            console.error("Getting internal user error: " + error);
            return;
         });
   };
}

export function loginUser(sentUser) {
   return function (dispatch) {
      return userApi
         .loginUser(sentUser)
         .then((resp) => {
            if (!resp.success) {
               return false;
            }
            let decodedToken = decode(resp.token);
            cookieTools.setCookie("trt-rate-guide-token", resp.token, new Date(decodedToken.exp * 1000).toUTCString());
            cookieTools.setCookie(
               "trt-rate-guide-userID",
               resp.user._id,
               new Date(decodedToken.exp * 1000).toUTCString(),
            );
            let user = resp.user;
            let customer = resp.customer;

            dispatch({ type: actionTypes.LOGIN_USER, user });
            dispatch({ type: actionTypes.GET_CUSTOMERS, customer });

            if (user) {
               return true;
            }
         })
         .catch((error) => {
            return false;
         });
   };
}

export function logoutCurrentUser() {
   return function (dispatch) {
      return userApi
         .logoutCurrentUser()
         .then((resp) => {
            if (resp.success) {
               dispatch({ type: actionTypes.LOGOUT });
               return true;
            }
            return false;
         })
         .catch((error) => {
            console.error("Logging out error: " + error);
            return;
         });
   };
}

export function submitNewUser(newUser) {
   return function (dispatch) {
      return userApi
         .submitNewUser(newUser)
         .then((resp) => {
            if (resp.success) {
               let decodedToken = decode(resp.token);

               cookieTools.setCookie(
                  "trt-rate-guide-token",
                  resp.token,
                  new Date(decodedToken.exp * 1000).toUTCString(),
               );
               cookieTools.setCookie(
                  "trt-rate-guide-userID",
                  resp._id,
                  new Date(decodedToken.exp * 1000).toUTCString(),
               );

               let user = resp.user;
               let customer = resp.customer;

               dispatch({ type: actionTypes.LOGIN_USER, user });
               dispatch({ type: actionTypes.GET_CUSTOMERS, customer });
            }

            return resp;
         })
         .catch((error) => {
            console.error("Creating new user error: " + error);
            return;
         });
   };
}
