import { HighlightOff, Notifications } from "@mui/icons-material";
import { Badge, Button, List, ListItem, ListItemText, Popover } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as notificationActions from "../../redux/actions/notificationActions";
import notificationMp3 from "./notification.mp3";

function NotificationsDropdown(props) {
   const [anchorEl, setAnchorEl] = useState(null);
   const [count, setCount] = useState(0);
   var history = useHistory();

   useEffect(() => {
      //trigger when notification store gets updated with new notification
      if (props.notifications.length > count) {
         const audioEl = document.getElementsByClassName("audio-element")[0];
         audioEl.play();
      }

      //set count and init
      setCount(props.notifications.length);
   }, [props.notifications]);

   const handleOpen = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   function notificationView() {
      history.push(`/Notification/NotificationView`);
      setAnchorEl(null);
   }

   const useStyles = makeStyles({
      headerButtons: {
         backgroundColor: "white",
         maxWidth: "min-content",
         paddingRight: "1rem",
         paddingLeft: "1rem",
         color: "#9AA7C4",
         "&:hover": {
            backgroundColor: "white",
            color: "#010440",
         },
         "&:focus": {
            outline: "white",
         },
      },
   });

   const classes = useStyles();

   return (
      <>
         <div>
            <Button className={classes.headerButtons} onClick={handleOpen}>
               <Badge color="secondary" badgeContent={props.notifications.length}>
                  <Notifications />
               </Badge>
            </Button>
            <Popover
               style={{ top: "50px" }}
               anchorEl={anchorEl}
               keepMounted
               open={Boolean(anchorEl)}
               onClose={handleClose}
            >
               <h2
                  style={{
                     paddingLeft: "1rem",
                     backgroundColor: "#010440",
                     color: "white",
                     opacity: "90%",
                     marginBottom: "0",
                     marginTop: "0",
                     paddingBottom: "0.50rem",
                     paddingTop: "0.50rem",
                     minWidth: "20rem",
                  }}
               >
                  Notifications
               </h2>

               <List style={{ height: "40vh", overflow: "auto", backgroundColor: "snow white" }}>
                  {props.notifications.map((not) => (
                     <ListItem key={`li-${not._id}`} style={{ borderBottom: "1px solid lightgrey" }}>
                        <ListItemText key={not._id}>
                           <div
                              style={{
                                 display: "-webkit-inline-box",
                                 width: "-webkit-fill-available",
                                 WebkitBoxAlign: "center",
                              }}
                           >
                              <div
                                 style={{ wordWrap: "break-word", fontSize: "12px", width: "240px", maxWidth: "240px" }}
                              >
                                 {not.notification}
                              </div>
                              <div>
                                 <Tooltip title="Delete" arrow>
                                    <Button
                                       variant="secondary"
                                       size="sm"
                                       onClick={() => props.removeNotification(not._id)}
                                       style={{ color: "red" }}
                                    >
                                       <HighlightOff />
                                    </Button>
                                 </Tooltip>
                              </div>
                           </div>
                        </ListItemText>
                     </ListItem>
                  ))}
                  <Button
                     style={{ fontSize: "x-small", float: "right", marginRight: "0.25rem" }}
                     onClick={() => notificationView()}
                  >
                     Show all
                  </Button>
               </List>
            </Popover>
            <div>
               <audio className="audio-element">
                  <source src={notificationMp3}></source>
               </audio>
            </div>
         </div>
      </>
   );
}

function mapStateToProps(state, ownProps) {
   return {
      notifications: state.notification,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      removeNotification: (notification_id) => dispatch(notificationActions.removeNotification(notification_id)),
   };
}

NotificationsDropdown.propTypes = {
   notifications: PropTypes.array.isRequired,
   removeNotification: PropTypes.func.isRequired,
};

NotificationsDropdown.defaultProps = {
   notifications: [],
   removeNotification: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsDropdown);
