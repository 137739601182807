import { HandleError, HandleResponse } from "./apiUtils";
import { HEADERS } from "./headers";
const baseUrl = "/api/activity/";

export function getActivity(activityID, service, activity_code, activityOptions) {

   return fetch(baseUrl + "getActivity", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ activityID, service, activity_code, activityOptions }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function saveActivity(activity) {

   return fetch(baseUrl + "saveActivity", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify(activity),
   })
      .then(HandleResponse)
      .catch(HandleError);
}
