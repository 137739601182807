import { GetApp } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, InputAdornment, Paper, TextField, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as customerApi from "../../api/customerApi";
import * as userApi from "../../api/userApi";
import { escapeRegExp } from "../../common/escapeRegExp";
import * as customerActions from "../../redux/actions/customerActions";
import DeleteDialog from "../Delete/DeleteDialog";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";
import CustomerNew from "./CustomerNew";
import { headerCSV } from "./ExportHeader";

const CustomerManagement = (props) => {
   //////////////////////////init //////////////////////////////////////////////////////////
   const history = useHistory();
   const handleError = useErrorHandler();

   const [tableData, setTableData] = useState([]);
   const [gridLoading, setGridLoading] = useState(true);
   const [newCustomerModal, setNewCustomerModal] = useState(false);
   const [deleteModal, setDeleteModal] = useState(false);
   const [selectedCustomer, setSelectedCustomer] = useState(null);
   const [openDrawer, setOpenDrawer] = useState(false);
   const [showAlert, setShowAlert] = useState(false);
   const [alertMessage, setAlertMessage] = useState(null);
   const [alertType, setAlertType] = useState("");
   const [readOnly, setReadOnly] = useState(false);
   const [exportingExternalUsers, setExportingExternalUsers] = useState(false);

   useEffect(() => {
      var newCustomerObject = _.cloneDeep(props.customers);

      if (newCustomerObject) {
         if (newCustomerObject.length) {
            Object.keys(newCustomerObject).forEach(function (key) {
               newCustomerObject[key].id = newCustomerObject[key]._id;
            });

            setTableData(newCustomerObject);
            setGridLoading(false);
         }
      }
   }, [props.customers]);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "customermanagement");
      var userReadOnly = true;
      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         borderBottom: "1px solid #2F3136",
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      save: {
         backgroundColor: "#002D72",
         float: "right",
         color: "white",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "#010440",
         },
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      inputRoot: {},
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
      listDrawer: {
         width: "300px",
         maxWidth: 360,
         backgroundColor: theme.palette.background.paper,
      },
   }));

   const classes = useStyles();

   function deleteCust() {
      setDeleteModal(!deleteModal);

      customerApi
         .deleteCustomer(selectedCustomer._id)
         .then((resp) => {
            //success
            openAlertMessage("Customer Successfully Deleted.", "success");

            //update original record in termlist
            props.getCustomers();
         })
         .catch((err) => openAlertMessage(`Error saving new customer: ${err}`, "error"));
   }

   var headerData = [
      { id: "name", allign: "left", disablePadding: false, label: "Customer Name", type: "string" },
      { id: "code", allign: "center", disablePadding: false, label: "Code", type: "string" },
      { id: "active", allign: "center", disablePadding: false, label: "Active", type: "boolean" },
      { id: "add_date", allign: "center", disablePadding: false, label: "Date Added", type: "date" },
   ];

   ////////////////////////// Table Functions ////////////////////////////////////////
   const handleDeleteClick = (cust) => {
      try {
         setSelectedCustomer(cust.row);
         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   };

   function handleEditClick(cust) {
      try {
         history.push({ pathname: `/customer/details/${cust.row._id}` });
      } catch (err) {
         handleError(err);
      }
   }

   function newCustClick() {
      try {
         setNewCustomerModal(true);
         setOpenDrawer(false);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeFilter(event, searchValue = null) {
      try {
         //create blank array for return
         var filteredObjects = [];
         //this turns values into lowercase strings
         var regValue = new RegExp(
            searchValue ? searchValue.toLowerCase() : escapeRegExp(event.target.value).toLowerCase(),
            "g",
         );
         //loop through header data since it contains the field names we need to check
         headerData.map((header) => {
            var filter = [];
            //we can only do this on strings, so make sure the type is filled in via the parent component
            if (header.type === "string") {
               //fill the filter on the original table data that was passed in from parent
               filter = props.customers.filter((filt) => {
                  //return a match where the filter index is the name of the header id
                  return filt[header.id].toLowerCase().match(regValue);
               });
            } else {
               //fill the filter on the original table data that was passed in from parent
               filter = props.customers.filter(
                  (filt) => filt[header.id === (searchValue ? searchValue : event.target.value)],
               );
            }
            //check to see if it returned objects
            if (filter.length > 0) {
               //concat merges arrays since we are adding data from one array to another.  Push only adds a single item/object to an existing array
               filteredObjects = filteredObjects.concat(filter);
            }
         });
         //remove duplicates
         filteredObjects = _.uniqBy(filteredObjects, "_id");

         if (filteredObjects) {
            if (filteredObjects.length) {
               Object.keys(filteredObjects).forEach(function (key) {
                  filteredObjects[key].id = filteredObjects[key]._id;
               });
            }
         }

         //set the hook with the newly filtered data and it will render
         setTableData(filteredObjects);
         setGridLoading(false);
      } catch (err) {
         setGridLoading(false);
         handleError(err);
      }
   }

   async function exportExternalUsers() {
      setExportingExternalUsers(true);
      await userApi
         .exportExternalUsers(props.user._id)
         .then(() => {
            //success
            openAlertMessage(
               "External Users Export has started. You will receive a notification and email once it has completed",
               "success",
            );
            setExportingExternalUsers(false);
         })
         .catch((err) => {
            openAlertMessage(`${err}`, "error");
            setExportingExternalUsers(false);
         });
   }

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   //data scrubbing function to return string values for display in table
   const dateFormatter = (value) => {
      try {
         var dateString = value;
         var newDate = new Date(dateString);
         const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(newDate);
         const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(newDate);
         const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(newDate);
         return month + "/" + day + "/" + year;
      } catch {
         return "00/00/0000";
      }
   };

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   return (
      <>
         <div id={"customerView"} className={classes.layoutMain}>
            <div id={"customerViewHeader"} className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                  <Grid item xs={7}>
                     <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                        <Link
                           color="inherit"
                           style={{ cursor: "pointer" }}
                           onClick={() => breadcrumbOnClick("/customer")}
                        >
                           <h3 style={{ fontWeight: "500" }}>{"Customers"}</h3>
                        </Link>
                     </Breadcrumbs>
                  </Grid>
                  <Grid item xs={3} display="flex" justifyContent="flex-end">
                     <TextField
                        style={{
                           width: "100%",
                           borderRadius: "3px",
                        }}
                        id="filled-basic"
                        placeholder="Search"
                        size="small"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <SearchIcon style={{ color: "#000000DE" }} />
                              </InputAdornment>
                           ),
                        }}
                        onChange={onChangeFilter}
                     />
                  </Grid>
                  <Grid item xs={2}>
                     <Tooltip title="Export External Users" arrow>
                        <IconButton
                           style={{ marginRight: "1rem", float: "right" }}
                           variant="contained"
                           color="primary"
                           onClick={exportExternalUsers}
                           disabled={props.user.security.admin ? false : true}
                        >
                           {exportingExternalUsers ? (
                              <CircularProgress size={32} className={classes.buttonProgress} />
                           ) : (
                              <ImportExportIcon style={{ fontSize: "2rem" }} />
                           )}
                        </IconButton>
                     </Tooltip>
                     <CSVLink
                        style={{ WebkitTextFillColor: "white", width: "-webkit-fill-available" }}
                        data={props.customers ? props.customers : []}
                        headers={headerCSV}
                        filename={Date.now() + "_customers.csv"}
                     >
                        <Tooltip title="Export Customers" arrow>
                           <IconButton style={{ float: "right" }} variant="contained" color="primary">
                              <GetApp style={{ fontSize: "2rem" }} />
                           </IconButton>
                        </Tooltip>
                     </CSVLink>
                     <Tooltip title="New Customer" arrow>
                        <IconButton
                           style={{ float: "right" }}
                           variant="contained"
                           color="primary"
                           onClick={newCustClick}
                           disabled={readOnly}
                        >
                           <AddIcon style={{ fontSize: "2rem" }} />
                        </IconButton>
                     </Tooltip>
                  </Grid>
               </Grid>
               <Collapse in={showAlert}>
                  <Alert
                     style={{ color: "#FFFFFF" }}
                     variant="filled"
                     severity={alertType ? alertType : "success"}
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                              {
                                 closeAlertMessage();
                              }
                           }}
                        >
                           <CloseIcon fontSize="inherit" />
                        </IconButton>
                     }
                  >
                     {alertMessage}
                  </Alert>
               </Collapse>
            </div>
            <div id={"customerViewBody"} className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}>
               <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
                  <Grid item xs={12}>
                     <Paper elevation={3}>
                        <StyledDataGrid
                           gridHeight={"785px"}
                           loading={gridLoading}
                           columns={[
                              {
                                 field: "name",
                                 headerName: "Customer Name",
                                 description: "Name description of the customer.",
                                 type: "string",
                                 flex: 1,
                              },
                              {
                                 field: "code",
                                 headerName: "Code",
                                 description: "Code ID of the customer.",
                                 type: "string",
                                 flex: 0.3,
                              },
                              {
                                 field: "active",
                                 headerName: "Active",
                                 description: "Active True|False.",
                                 type: "string",
                                 flex: 0.2,
                              },
                              {
                                 field: "add_date",
                                 headerName: "Date Added",
                                 description: "Date when customer was first added.",
                                 type: "date",
                                 valueFormatter: ({ value }) => dateFormatter(value),
                                 flex: 0.2,
                              },
                              {
                                 field: "_id",
                                 headerName: " ", //This is a space because material-UI default to field unless there is a value.
                                 type: "string",
                                 sortable: false,
                                 filterable: false,
                                 renderCell: (params) => (
                                    <div>
                                       <Tooltip title="Edit Customer" arrow>
                                          <IconButton
                                             variant="contained"
                                             color="primary"
                                             size="small"
                                             onClick={() => handleEditClick(params)}
                                          >
                                             <EditIcon />
                                          </IconButton>
                                       </Tooltip>
                                       |
                                       <Tooltip title="Delete Customer" arrow>
                                          <IconButton
                                             variant="contained"
                                             color="secondary"
                                             size="small"
                                             onClick={() => handleDeleteClick(params)}
                                             disabled={readOnly}
                                          >
                                             <DeleteForeverIcon />
                                          </IconButton>
                                       </Tooltip>
                                    </div>
                                 ),
                                 flex: 0.1,
                              },
                           ]}
                           rows={tableData ? tableData : null}
                        ></StyledDataGrid>
                     </Paper>
                  </Grid>
               </Grid>
               <DeleteDialog
                  open={deleteModal}
                  handleCancel={() => setDeleteModal(!deleteModal)}
                  deleteFunc={deleteCust}
                  title={selectedCustomer && `Delete ${selectedCustomer.name}`}
                  text={selectedCustomer && `Are you sure you want to delete ${selectedCustomer.name} from customers?`}
               />
               <CustomerNew
                  open={newCustomerModal}
                  handleClose={() => setNewCustomerModal(!newCustomerModal)}
                  getCustomers={props.getCustomers}
                  openAlertMessage={openAlertMessage}
                  user={props.user}
               />
            </div>
         </div>
      </>
   );
};

function mapStateToProps(state) {
   return {
      customers: state.customer !== null ? state.customer.customer : [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getCustomers: () => dispatch(customerActions.getCustomers()),
   };
}

CustomerManagement.propTypes = {
   user: PropTypes.object.isRequired,
   customers: PropTypes.array.isRequired,
   getCustomers: PropTypes.func.isRequired,
};

CustomerManagement.defaultProps = {
   user: {},
   customers: [],
   getCustomers: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerManagement);
