import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActivity } from "../../../api/activityApi";
import { dateFormatter } from "../../../common/dateFormatter";
import { timeFormatter } from "../../../common/timeFormatter";
import ActivityLogGrid from "../../Activity/ActivityLogGrid";

const columns = [
   {
      field: "date",
      headerName: "Date",
      description: "Date",
      flex: 0.1,
   },
   {
      field: "hour",
      headerName: "Hour",
      description: "Hour",
      flex: 0.1,
   },

   {
      field: "event",
      headerName: "Event",
      description: "Event",
      flex: 0.2,
   },
   {
      field: "description",
      headerName: "Event Description",
      description: "Event Description",
      flex: 0.6,
   },
];

const parseData = (data) => {
   if (!data.length) return [];

   // Extract activities
   const activities = [];
   for (let row of data) {
      const date = dateFormatter(row.add_date);
      const hour = timeFormatter(row.add_date);
      for (let i in row.activity) {
         activities.push({
            id: row._id + "-" + i,
            date,
            hour,
            event: row.activity[i].event_code,
            description: row.activity[i].event,
         });
      }
   }
   return activities;
};

const militaryTimeToRegularTime = (time) => {
   const [hour, minute] = time.split(":");
   const hourInt = parseInt(hour);
   const minuteInt = parseInt(minute);
   const ampm = hourInt >= 12 ? "PM" : "AM";
   const hour12 = hourInt > 12 ? hourInt - 12 : hourInt;
   const minuteString = minuteInt < 10 ? `0${minuteInt}` : minuteInt;
   return `${hour12}:${minuteString} ${ampm}`;
};

const parseDate = (date) => {
   if (!date) return ["", ""];
   const dateString = date.split("T")[0];
   const rawHour = date.split("T")[1];
   const hour = `${militaryTimeToRegularTime(rawHour)}`;
   const dateSplited = dateString.split("-");
   return [`${dateSplited[1]}/${dateSplited[2]}/${dateSplited[0]}`, hour];
};

export default function MessageActivity() {
   const { id } = useParams();
   const [data, setData] = useState([]);

   const activity = async () => {
      try {
         const { activities } = await getActivity(null, null, null, [
            {
               module: "MESSAGE",
               record_id: id,
               rows: null,
            },
         ]);
         const dataParsed = parseData(activities);
         setData(dataParsed);
      } catch (error) {
         console.log("error: ", error);
      }
   };

   useEffect(() => {
      activity();
   }, []);

   return (
      <>
         <ActivityLogGrid columns={columns} tableData={data} />
      </>
   );
}
