import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import * as activityApi from "../../api/activityApi";
import { dateTimeFormatter } from "../../common/dateTimeFormatter";

const Activity = (props) => {
   let [activities, setActivities] = useState();
   let [loading, setLoading] = useState(true);

   useEffect(() => {
      if (props.loadActivity) {
         setLoading(true);
         let activityFilter = [];

         activityApi
            .getActivity(null, null, null, [props.activityOptions])
            .then((resp) => {
               if (resp.activities) {
                  if (props.activityOptions.rows) {
                     _.forEach(resp.activities, (activity) => {
                        _.forEach(activity.activity, (act) => {
                           if (activityFilter.length < props.activityOptions.rows) {
                              let formatted = {
                                 _id: activity._id,
                                 firstname: act.firstname,
                                 lastname: act.lastname,
                                 event: act.event,
                                 add_date: dateTimeFormatter(activity.add_date),
                              };

                              activityFilter = activityFilter.concat(formatted);
                           } else {
                              return false;
                           }
                        });
                     });

                     setActivities(activityFilter);
                     setLoading(false);
                     props.setLoadActivity();
                  } else {
                     setActivities(resp.activities);
                     setLoading(false);
                     props.setLoadActivity();
                  }
               } else {
                  setActivities(null);
                  setLoading(false);
                  props.setLoadActivity();
               }
            })
            .catch((err) => console.error(err));
      }
   }, [props.loadActivity]);

   Number.prototype.padLeft = function (base, chr) {
      var len = String(base || 10).length - String(this).length + 1;
      return len > 0 ? new Array(len).join(chr || "0") + this : this;
   };

   return (
      <>
         <h2 style={{ paddingTop: "1rem", marginLeft: "1rem", fontWeight: "500" }}>Activity Log</h2>
         {loading ? (
            <div></div>
         ) : (
            <Timeline position="alternate">
               {props.activityOptions.rows
                  ? activities.map((activity, i) => {
                       return (
                          <TimelineItem key={`tli-${activity._id}-${i}`}>
                             <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                             </TimelineSeparator>
                             <TimelineContent style={{ lineBreak: "anywhere" }}>
                                <Paper style={{ padding: "3%", border: "1px solid dimgrey" }}>
                                   <Typography
                                      style={{ wordWrap: "break-word", fontSize: "12px" }}
                                   >{`${activity.add_date}: ${activity.firstname} ${activity.lastname} ${activity.event}`}</Typography>
                                </Paper>
                             </TimelineContent>
                          </TimelineItem>
                       );
                    })
                  : activities.map((activity) =>
                       activity.activity.map((act, i) => {
                          return (
                             <TimelineItem key={`tli-${activity._id}-${i}`}>
                                <TimelineSeparator>
                                   <TimelineDot />
                                   <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent key={activity._id} style={{ lineBreak: "anywhere" }}>
                                   <Paper style={{ padding: "3%", border: "1px solid dimgrey" }}>
                                      <Typography
                                         style={{ wordWrap: "break-word", fontSize: "12px" }}
                                      >{`${dateTimeFormatter(activity.add_date)}: ${act.firstname} ${act.lastname} ${
                                         act.event
                                      }`}</Typography>
                                   </Paper>
                                </TimelineContent>
                             </TimelineItem>
                          );
                       }),
                    )}
            </Timeline>
         )}
      </>
   );
};

Activity.propTypes = {
   loadActivity: PropTypes.bool.isRequired,
   activityOptions: PropTypes.object.isRequired,
   setLoadActivity: PropTypes.func.isRequired,
};

Activity.defaultProps = {
   loadActivity: false,
   activityOptions: {},
   setLoadActivity: () => {
      return;
   },
};

export default Activity;
