import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import { green, red } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import * as orderApi from "../../api/orderApi";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
   wrapper: {
      margin: theme.spacing(1),
      position: "relative",
   },
   buttonProgress: {
      color: green[500],
   },
   checkIcon: {
      color: red[500],
   },
}));

const OrdersNewEmail = (props) => {
   const classes = useStyles();
   var handleError = useErrorHandler();

   let [emailAddress, setEmailAddress] = useState("");
   let [emailAddresses, setEmailAddresses] = useState([]);
   let [invalidEmail, setInvalidEmail] = useState("Enter an Email");
   let [emailingPDF, setEmailingPDF] = useState(false);
   let [alertMessage, setAlertMessage] = useState(null);
   let [alertType, setAlertType] = useState("");

   //Reset all variables on load.
   useEffect(() => {
      setAlertType("");
      setAlertMessage(null);
      setEmailingPDF(false);
      setEmailAddresses([]);
      setEmailAddress("");
      setInvalidEmail("Enter an Email");
   }, [props.open]);

   function handleAddEmail(email) {
      try {
         emailAddresses.push(email);
         setEmailAddress("");
         setInvalidEmail("Enter an Email");
         setEmailAddresses(emailAddresses);
      } catch (err) {
         handleError(err);
      }
   }

   function handleDeleteEmail(email) {
      try {
         setEmailAddresses(emailAddresses.filter((c) => c !== email));
      } catch (err) {
         handleError(err);
      }
   }

   const emailPDF = () => {
      if (emailAddresses.length === 0) {
         setAlertType("warning");
         setAlertMessage("You must enter at least one email address.");
         return;
      }

      setEmailingPDF(true);

      //emailAddresses should be filled in from your hook
      orderApi
         .sendRateSheetEmailPortal(emailAddresses, props.newOrderResponse._id, props.newOrderResponse.items[0]._id)
         .then((resp) => {
            let newOrderUpdate = { ...props.newOrderResponse };

            if (newOrderUpdate.status !== "COMPLETED") {
               //Set to completed.
               newOrderUpdate.status = "COMPLETED";

               orderApi
                  .checkout(newOrderUpdate)
                  .then((resp) => {
                     setAlertType("success");
                     setAlertMessage("Sent Email to addresses.");
                     setEmailingPDF(false);
                  })
                  .catch((err) => {
                     setAlertType("error");
                     setAlertMessage(`Error sending email: ${err}.`);
                     setEmailingPDF(false);
                  });
            } else {
               setAlertType("success");
               setAlertMessage("Sent Email to addresses.");
               setEmailingPDF(false);
            }
         })
         .catch((err) => {
            setAlertType("error");
            setAlertMessage(`Error sending email: ${err}.`);
            setEmailingPDF(false);
         });
   };

   function validateEmailAddress(email) {
      try {
         let topLevelDomain = "";

         if (email === null || email === "") {
            setInvalidEmail("Enter an Email");
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp("@", "g")) || []).length === 0) {
            setInvalidEmail(`Invalid Email. "@" Character is Required`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp("@", "g")) || []).length > 1) {
            setInvalidEmail(`Invalid Email. Only One "@" Character Allowed`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp("[.]", "g")) || []).length === 0) {
            setInvalidEmail(`Invalid Email. "." Character is Required`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp(`["(),:;<>]`, "g")) || []).length > 0) {
            setInvalidEmail(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp(`[[]`, "g")) || []).length > 0) {
            setInvalidEmail(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp(`]`, "g")) || []).length > 0) {
            setInvalidEmail(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`);
            setEmailAddress(email);
            return;
         }

         if (email.indexOf(`\\`) >= 0) {
            setInvalidEmail(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`);
            setEmailAddress(email);
            return;
         }

         if (email.substring(0, 1) === "@") {
            setInvalidEmail(`Invalid Email. Recipient Name is Required. Email Cannot Begin With @ Character`);
            setEmailAddress(email);
            return;
         }

         if ((email.substring(email.indexOf("@")).match(new RegExp("[.]", "g")) || []).length === 0) {
            setInvalidEmail(`Invalid Email. Top Level Domain (.com, .net, .org, etc) Required`);
            setEmailAddress(email);
            return;
         }

         topLevelDomain = email.substring(email.indexOf("@"));

         if (topLevelDomain.indexOf(".") === 1) {
            setInvalidEmail(`Invalid Email. Domain Name (@gmail, @yahoo, @outlook) Required`);
            setEmailAddress(email);
            return;
         }

         topLevelDomain = topLevelDomain.substring(topLevelDomain.indexOf(".") + 1);

         if (topLevelDomain === "" || topLevelDomain === null) {
            setInvalidEmail(`Invalid Email. Top Level Domain (.com, .net, .org, etc) Required`);
            setEmailAddress(email);
            return;
         }

         let emailExists = emailAddresses.includes(email);

         if (emailExists) {
            setInvalidEmail(`Invalid Email. Email Already Exists to Send`);
            setEmailAddress(email);
            return;
         }

         setInvalidEmail(null);
         setEmailAddress(email);
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <Dialog open={props.open} TransitionComponent={Transition} onClose={props.handleClose}>
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            Download/Email Rate Sheet For Quote #: {props.newOrderResponse ? props.newOrderResponse.orderNumber : null}
         </DialogTitle>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               style={{ color: "#FFFFFF" }}
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage(null);
                     }}
                     style={{ marginBottom: ".25rem" }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <DialogContent style={{ marginTop: "1.5rem" }}>
            <Grid container direction={"row"} xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               <Grid item xs={10}>
                  <TextField
                     id="ci-email"
                     style={{ width: "30rem", color: "#002D72" }}
                     value={emailAddress}
                     onChange={(event) => validateEmailAddress(event.target.value ? event.target.value : null)}
                     label="Email"
                     variant="outlined"
                     InputProps={
                        invalidEmail
                           ? {
                                startAdornment: (
                                   <Tooltip title={invalidEmail} arrow>
                                      <InputAdornment position="start">
                                         <CloseIcon className={classes.checkIcon} />
                                      </InputAdornment>
                                   </Tooltip>
                                ),
                             }
                           : {
                                startAdornment: (
                                   <Tooltip title={"Valid Email"} arrow>
                                      <InputAdornment position="start">
                                         <CheckIcon className={classes.buttonProgress} />
                                      </InputAdornment>
                                   </Tooltip>
                                ),
                             }
                     }
                  />
               </Grid>
               <Grid item xs={2}>
                  <Tooltip title="Add User Email" arrow>
                     <IconButton
                        id="btn-email"
                        style={{ float: "right" }}
                        variant="contained"
                        color="primary"
                        disabled={invalidEmail ? true : false}
                        onClick={() => handleAddEmail(emailAddress)}
                     >
                        <AddIcon style={{ fontSize: "2rem" }}></AddIcon>
                     </IconButton>
                  </Tooltip>
               </Grid>
            </Grid>
            <Grid container direction={"row"} xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               {emailAddresses.map((email, index) => {
                  return (
                     <Grid key={`gi-${index}`} item>
                        <Chip
                           style={{ marginTop: "1rem" }}
                           label={email}
                           id="ci-emailAddresses"
                           color="primary"
                           onDelete={() => handleDeleteEmail(email)}
                           size="small"
                        />
                     </Grid>
                  );
               })}
            </Grid>
            <Grid container direction={"row"} xs={12} style={{ margin: "auto", paddingTop: "2%" }}>
               <Grid item xs={12}>
                  <Tooltip title="Exit" arrow>
                     <IconButton
                        id="btn-exit"
                        variant="contained"
                        color="secondary"
                        style={{ float: "right" }}
                        onClick={props.handleClose}
                     >
                        <ExitToAppRoundedIcon style={{ fontSize: "2rem" }} />
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Send Email" arrow>
                     <IconButton
                        id="btn-email"
                        style={{ float: "right" }}
                        variant="contained"
                        color="primary"
                        disabled={emailingPDF}
                        onClick={() => emailPDF()}
                     >
                        {emailingPDF ? (
                           <CircularProgress size={24} className={classes.buttonProgress} />
                        ) : (
                           <EmailRoundedIcon style={{ fontSize: "2rem" }}></EmailRoundedIcon>
                        )}
                     </IconButton>
                  </Tooltip>
               </Grid>
            </Grid>
         </DialogContent>
      </Dialog>
   );
};

OrdersNewEmail.propTypes = {
   open: PropTypes.bool.isRequired,
   newOrderResponse: PropTypes.object.isRequired,
   handleClose: PropTypes.func.isRequired,
};

OrdersNewEmail.defaultProps = {
   open: false,
   newOrderResponse: {},
   handleClose: () => {
      return;
   },
};

export default OrdersNewEmail;
