import * as fuelApi from "../../api/fuelApi";
import actionTypes from "./actionTypes";

export function getTariffs() {
   return function (dispatch) {
      fuelApi
         .getTariffs()
         .then((resp) => {
            dispatch({ type: actionTypes.GET_FUEL_TARIFFS, tariffs: resp || [] });
         })
         .catch((err) => {
            console.error("Getting fuel tariffs error: " + err);
            return [];
         });
   };
}

export function setSearchQuery(searchQuery) {
   return { type: actionTypes.SET_SEARCH_QUERY, searchQuery };
}

export function updateTariff(tariff) {
   return { type: actionTypes.UPDATE_FUEL_TARIFF, tariff };
}
