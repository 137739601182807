import CloseIcon from "@mui/icons-material/Close";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import SaveIcon from "@mui/icons-material/Save";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import PropTypes from "prop-types";
import React from "react";
import "./ExpireRatesDialog.css";

const StyledDialogTitle = styled(DialogTitle)(() => ({
   backgroundColor: "#002D72",
   color: "white",
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
}));

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const intermittent = keyframes`
   0%{
      opacity:0;
   }

   10%{
      opacity:0.2;
   }

   20%{
      opacity:0.4;
   }
   
   30%{
      opacity:0.6;
   }

   40%{
      opacity:0.8;
   }
   
   50%{
      opacity:1.0;
   }

   60%{
      opacity:0.8;
   }
   
   70%{
      opacity:0.6;
   }

   80%{
      opacity:0.4;
   }
   
   90%{
      opacity:0.2;
   }
   
   100%{
      opacity:0;
   }
`;

const StyledNewReleasesIcon = styled(NewReleasesIcon)(() => ({
   animation: `${intermittent} 2s linear infinite`,
}));

const ExpireRatesDialog = (props) => {
   var expireRatesTitle = props.title || "Expire Rates?";
   var expireRatesText =
      props.text || "Are you sure you want to expire all rates for all lanes? Do you wish to continue?";

   return (
      <Dialog
         className="expire-rates-modal"
         open={props.open || false}
         TransitionComponent={Transition}
         keepMounted
         onClose={props.handleClose}
      >
         <StyledDialogTitle className="dialog-title">
            <StyledNewReleasesIcon sx={{ marginRight: 2 }} />
            {expireRatesTitle}
         </StyledDialogTitle>
         <DialogContent className="dialog-content">
            <DialogContentText>{expireRatesText}</DialogContentText>
         </DialogContent>
         <DialogActions className="actions">
            <Tooltip title="Cancel" arrow>
               <IconButton onClick={props.handleCancel} variant="contained" color="default">
                  <CloseIcon />
               </IconButton>
            </Tooltip>
            <Tooltip title="Expire Rates" arrow>
               <IconButton onClick={props.expireRatesFunc} variant="contained" color="primary">
                  <SaveIcon />
               </IconButton>
            </Tooltip>
         </DialogActions>
      </Dialog>
   );
};

ExpireRatesDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   text: PropTypes.string.isRequired,
   title: PropTypes.string.isRequired,
   expireRatesFunc: PropTypes.func.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleCancel: PropTypes.func.isRequired,
};

ExpireRatesDialog.defaultProps = {
   open: false,
   text: "",
   title: "",
   expireRatesFunc: () => {
      return;
   },
   handleClose: () => {
      return;
   },
   handleCancel: () => {
      return;
   },
};

export default ExpireRatesDialog;
