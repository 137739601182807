import PropTypes from "prop-types";
import React from "react";

const useTypeDelay = (initialText = "", time = 400) => {
   const [textInput, setTextInput] = React.useState("");
   const [textOutput, setTextOutput] = React.useState(initialText);
   React.useEffect(() => {
      const timeout = setTimeout(() => {
         setTextOutput(textInput);
      }, time);
      return () => clearTimeout(timeout);
   }, [textInput]);

   return [textOutput, setTextInput];
};

useTypeDelay.protoType = {
   initialText: PropTypes.string,
   time: PropTypes.number,
};

export default useTypeDelay;
