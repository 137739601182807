import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { dateFormatter } from "../../common/dateFormatter";
import ExtendRatesDialog from "../ExtendRatesDialog/ExtendRatesDialog";
import "./TerminalRatesDialog.css";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const TerminalRatesDialog = (props) => {
   const [extendButtonDisabled, setExtendButtonDisabled] = useState(true);

   useEffect(() => {
      checkExtendButtonSecurity();
   }, [props.extendDate]);

   function checkExtendButtonSecurity() {
      if (!props.user) {
         setExtendButtonDisabled(true);
         return;
      }

      if (!props.user.security.admin) {
         setExtendButtonDisabled(true);
         return;
      }

      if (!props.extendDate) {
         setExtendButtonDisabled(true);
         return;
      }

      var d = new Date();
      d.setHours(0, 0, 0, 0);

      var extendDateFormatted = new Date(props.extendDate);

      var extendDateUtc = new Date(
         extendDateFormatted.getUTCFullYear(),
         extendDateFormatted.getUTCMonth(),
         extendDateFormatted.getUTCDate(),
         extendDateFormatted.getUTCHours(),
         extendDateFormatted.getUTCMinutes(),
         extendDateFormatted.getUTCSeconds(),
      );

      if (extendDateUtc.getTime() < d.getTime()) {
         setExtendButtonDisabled(true);
         return;
      }

      setExtendButtonDisabled(false);
   }

   return (
      <>
         <Dialog
            className="terminal-rates-modal"
            open={props.open || false}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.handleClose}
            maxWidth="md"
         >
            <DialogTitle className="dialog-title">{"Extend Rates"}</DialogTitle>
            <DialogContent className="dialog-content">
               <TextField
                  id="extendDate"
                  label="Extend Rates to Date"
                  type="date"
                  value={props.extendDate ? props.extendDate : ""}
                  onChange={(event) => props.setExtendDate(event.target.value ? event.target.value : null)}
                  InputLabelProps={{
                     shrink: true,
                  }}
                  variant="outlined"
                  style={{ marginRight: "1rem" }}
                  disabled={!props.user || !props.user.security.admin}
               />
               <Button
                  sx={{
                     display: "inline-flex",
                     flexDirection: "column",
                     alignItems: "center",
                     fontSize: "11px",
                     height: "55px",
                     lineHeight: "13px",
                     marginRight: "1rem",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => props.confirmExtendRates()}
                  disabled={extendButtonDisabled}
               >
                  <DateRangeIcon sx={{ marginBottom: "0.1rem" }} />
                  Extend Rates
               </Button>
            </DialogContent>
            <DialogActions className="actions">
               <Tooltip title="Cancel" arrow>
                  <IconButton onClick={props.handleCancel} variant="contained" color="default">
                     <CloseIcon />
                  </IconButton>
               </Tooltip>
            </DialogActions>
         </Dialog>
         <ExtendRatesDialog
            open={props.confirmExtendRatesModal}
            handleCancel={() => props.closeConfirmExtendRates()}
            extendRatesFunc={props.extendRatesFunc}
            title={`Extend Rates For The CPG Default Tariff`}
            text={`You are about to extend all rates for all lanes for all terminals for the CPG default tariff ending on ${dateFormatter(
               props.extendDate,
            )}. Extending all rates for all terminals should only be done outside of business hours. Do you wish to continue?`}
         />
      </>
   );
};

TerminalRatesDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   handleCancel: PropTypes.func.isRequired,
   extendDate: PropTypes.any,
   setExtendDate: PropTypes.func.isRequired,
   extendRatesFunc: PropTypes.func.isRequired,
   confirmExtendRates: PropTypes.func.isRequired,
   confirmExtendRatesModal: PropTypes.bool.isRequired,
   closeConfirmExtendRates: PropTypes.func.isRequired,
   expireDate: PropTypes.any,
   setExpireDate: PropTypes.func.isRequired,
   expireRatesFunc: PropTypes.func.isRequired,
   confirmExpireRates: PropTypes.func.isRequired,
   confirmExpireRatesModal: PropTypes.bool.isRequired,
   closeConfirmExpireRates: PropTypes.func.isRequired,
};

TerminalRatesDialog.defaultProps = {
   open: false,
   handleCancel: () => {
      return;
   },
   extendDate: null,
   setExtendDate: () => {
      return;
   },
   extendRatesFunc: () => {
      return;
   },
   confirmExtendRates: () => {
      return;
   },
   confirmExtendRatesModal: false,
   closeConfirmExtendRates: () => {
      return;
   },
   expireDate: null,
   setExpireDate: () => {
      return;
   },
   expireRatesFunc: () => {
      return;
   },
   confirmExpireRates: () => {
      return;
   },
   confirmExpireRatesModal: false,
   closeConfirmExpireRates: () => {
      return;
   },
};

function mapStateToProps(state) {
   return {
      user: state.user.currentUser,
   };
}

export default connect(mapStateToProps)(TerminalRatesDialog);
