import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SaveIcon from "@mui/icons-material/Save";
import { AppBar, Box, Button, Paper, Tab, Tabs, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as accessorialApi from "../../api/accessorialApi";
import * as activityApi from "../../api/activityApi";
import * as activityCompareObjects from "../../common/activityCompareObjects";
import { dateFormatter } from "../../common/dateFormatter";
import * as setActivity from "../../common/setActivity";
import { timeFormatter } from "../../common/timeFormatter";
import * as accessorialGuideActions from "../../redux/actions/accessorialGuideActions";
import "../../styles/truck.css";
import ActivityDialog from "../Activity/ActivityDialog";
import ActivityLogGrid from "../Activity/ActivityLogGrid";
import containerLogo from "../Logo/ContainerPort-Logo-white.png";

const AccessorialGuideEdit = (props) => {
   var history = useHistory();
   var handleError = useErrorHandler();

   let [loading, setLoading] = useState(true);
   let [selectedGuide, setSelectedGuide] = useState({});
   let [readOnly, setReadOnly] = useState(false);
   let [showAlert, setShowAlert] = useState(false);
   let [alertMessage, setAlertMessage] = useState(null);
   let [alertType, setAlertType] = useState("");
   let [loadActivity, setLoadActivity] = useState(false);
   let [activityModal, setActivityModal] = useState(false);
   const [tabsValue, setTabsValue] = useState(0);
   const [originalGuide, setOriginalGuide] = useState(null);
   const [tableData, setTableData] = useState(null);
   const [saving, setSaving] = useState(false);

   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         borderBottom: "1px solid #2F3136",
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         fontSize: "2rem",
      },
      save: {
         float: "right",
         fontSize: "2rem",
         marginRight: "1rem",
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
      listDrawer: {
         width: "300px",
         maxWidth: 360,
         backgroundColor: theme.palette.background.paper,
      },
      appBar: {
         "& .MuiButtonBase-root": {
            padding: "1.25rem 2.25rem",
         },
         "& .MuiTabs-indicator": {
            height: "4px",
         },
      },
      buttonProgress: {
         color: green[500],
      },
   }));

   const classes = useStyles();

   useEffect(() => {
      setLoading(true);

      //retrieve record from API
      accessorialApi
         .getGuides(props.match.params.id)
         .then((resp) => {
            if (resp.accessorialGuide) {
               const newSelectedGuide = resp.accessorialGuide[0];

               setOriginalGuide(newSelectedGuide);
               setSelectedGuide(newSelectedGuide);
               setLoading(false);
            }
         })
         .catch((err) => {
            handleError(err);
         });
   }, []);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "accessorial");
      var userReadOnly = true;

      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   useEffect(() => {
      activityApi
         .getActivity(null, null, null, [
            {
               module: "ACCESSORIAL",
               record_id: props.match.params.id,
               rows: null,
            },
         ])
         .then((resp) => {
            //Build the tableData
            const newTableData = [];

            resp.activities.forEach((activityObjectResp) => {
               activityObjectResp.activity.forEach((activity, i) => {
                  const newActivityObj = {};

                  newActivityObj["_id"] = activityObjectResp._id;
                  newActivityObj["id"] = `${activityObjectResp._id}-${i}`;
                  newActivityObj["date"] = dateFormatter(activityObjectResp.add_date);
                  newActivityObj["hour"] = timeFormatter(activityObjectResp.add_date);
                  newActivityObj["event"] = activity.event_code || "N/A";
                  newActivityObj["description"] = activity.event || "N/A";

                  newTableData.push(newActivityObj);
               });
            });

            setTableData(newTableData);
         })
         .catch((err) => console.error(err));
   }, [loadActivity]);

   const updateEditGuide = async () => {
      if (!selectedGuide.name) {
         openAlertMessage("You must enter a guide name!", "warning");
         return;
      }

      setSaving(true);

      accessorialApi
         .setGuides(selectedGuide)
         .then((resp) => {
            try {
               if (JSON.stringify(selectedGuide) === JSON.stringify(originalGuide)) {
                  //No changes found. Mark as updated with no changes.
                  const activity = {
                     module: "ACCESSORIAL",
                     record_id: selectedGuide._id,
                     event: `Updated accessorial guide: ${selectedGuide.name}. No changes found.`,
                     user_id: props.user._id,
                  };

                  setActivity.setActivity("TRTSITE", "USERLOG", activity);
               } else {
                  //Changes found. Mark them.
                  let activity = [];

                  activity = activityCompareObjects.activityCompareObjects(
                     "ACCESSORIAL",
                     selectedGuide,
                     originalGuide,
                     props.user._id,
                  );

                  setActivity.setActivity("TRTSITE", "USERLOG", activity);
               }
            } catch (err) {
               console.error(err);
            }

            //success
            setSaving(false);
            openAlertMessage("Guide Successfully Saved.", "success");

            //Refresh activity
            setLoadActivity(true);
            props.getAccessorialGuides();
         })
         .catch((err) => {
            setSaving(false);
            openAlertMessage(`Error Saving Guide: ${err}`, "error");
         });
   };

   function onChange(event) {
      try {
         var newSelectedGuide = _.cloneDeep(selectedGuide);
         newSelectedGuide[event.target.name] = event.target.value;
         setSelectedGuide(newSelectedGuide);
      } catch (err) {
         handleError(err);
      }
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   function a11yProps(index) {
      return {
         id: `simple-tab-${index}`,
         "aria-controls": `simple-tabpanel-${index}`,
      };
   }

   const handleTabsChange = (event, newValue) => {
      setTabsValue(newValue);
   };

   return (
      <>
         <div id={"accessorialGuideEdit"} className={classes.layoutMain}>
            <div id={"accessorialGuideEditHeader"} className={classes.secondaryHeader}>
               <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item xs={4}>
                     {!loading ? (
                        <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                           <Link
                              color="inherit"
                              style={{ cursor: "pointer" }}
                              onClick={() => breadcrumbOnClick("/accessorialguide")}
                           >
                              <h3 style={{ fontWeight: "500" }}>{"Accessorial Guides"}</h3>
                           </Link>
                           <Link
                              color="inherit"
                              style={{ cursor: "pointer" }}
                              onClick={() => breadcrumbOnClick("/accessorialguide/details/" + props.match.params.id)}
                           >
                              <h3 style={{ fontWeight: "500" }}>{selectedGuide.name ? selectedGuide.name : null}</h3>
                           </Link>
                        </Breadcrumbs>
                     ) : null}
                  </Grid>
                  <Grid item xs={8}>
                     <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "1.5rem" }}>
                        <Tooltip title="Save" arrow>
                           <Button
                              variant="contained"
                              color="primary"
                              onClick={updateEditGuide}
                              disabled={readOnly || saving}
                           >
                              {saving ? (
                                 <CircularProgress size={24} className={classes.buttonProgress} />
                              ) : (
                                 <SaveIcon style={{ fontSize: "1.5rem", color: "#FFF", marginRight: "0.5rem" }} />
                              )}
                              Save
                           </Button>
                        </Tooltip>
                     </div>
                  </Grid>
               </Grid>
            </div>
            <Collapse in={showAlert}>
               <Alert
                  style={{ color: "#FFFFFF" }}
                  variant="filled"
                  severity={alertType ? alertType : "success"}
                  action={
                     <IconButton
                        id="btn-alertClose"
                        aria-label="close"
                        color="inherit"
                        size="small"
                        sx={{ marginBottom: "0.25rem" }}
                        onClick={() => {
                           closeAlertMessage();
                        }}
                     >
                        <CloseIcon fontSize="inherit" />
                     </IconButton>
                  }
               >
                  {alertMessage}
               </Alert>
            </Collapse>
            <div
               id={"accessorialGuideEditBody"}
               className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}
            >
               {loading ? (
                  <div className="loader-wrapper">
                     <div className="truck-wrapper">
                        <div className="truck">
                           <div className="truck-container">
                              <img
                                 style={{ width: "65px", paddingLeft: "5px", paddingTop: "15px" }}
                                 src={containerLogo}
                              />
                           </div>
                           <div className="glases"></div>
                           <div className="bonet"></div>
                           <div className="base"></div>
                           <div className="base-aux"></div>
                           <div className="wheel-back"></div>
                           <div className="wheel-front"></div>
                           <div className="smoke"></div>
                        </div>
                     </div>
                  </div>
               ) : (
                  <Grid container direction={"row"} justifyContent="space-between">
                     <Grid item xs={12}>
                        <Grid container direction={"column"} justifyContent="space-between" alignItems="center">
                           <Grid item style={{ width: "100%" }}>
                              <div style={{ padding: "1%", width: "100%" }}>
                                 <Paper elevation={3}>
                                    <AppBar
                                       position="static"
                                       color="default"
                                       className={classes.appBar}
                                       sx={{
                                          boxShadow: "none",
                                          borderBottom: "1px solid black",
                                       }}
                                    >
                                       <Tabs
                                          indicatorColor="primary"
                                          textColor="primary"
                                          onChange={handleTabsChange}
                                          value={tabsValue}
                                          aria-label="tabs"
                                       >
                                          <Tab
                                             sx={{
                                                fontSize: "1rem",
                                                fontWeight: "700",
                                                letterSpacing: "0px",
                                                textTransform: "none",
                                             }}
                                             label="Accessorial Guide"
                                             {...a11yProps(0)}
                                          />
                                          <Tab
                                             sx={{
                                                fontSize: "1rem",
                                                fontWeight: "700",
                                                letterSpacing: "0px",
                                                textTransform: "none",
                                             }}
                                             label="Activity"
                                             {...a11yProps(1)}
                                          />
                                       </Tabs>
                                    </AppBar>
                                    {tabsValue === 0 ? (
                                       <Box p={3}>
                                          <form noValidate autoComplete="off" style={{ textAlign: "center" }}>
                                             <Grid
                                                item
                                                style={{
                                                   margin: "2rem 0rem",
                                                }}
                                             >
                                                <TextField
                                                   id="outlined-name"
                                                   style={{ marginRight: "2rem", width: "20rem" }}
                                                   label="Name"
                                                   variant="outlined"
                                                   name="name"
                                                   onChange={onChange}
                                                   value={selectedGuide.name}
                                                   disabled={readOnly}
                                                />
                                                <TextField
                                                   id="outlined-pdfVerbiage"
                                                   style={{ marginRight: "2rem", width: "20rem" }}
                                                   label="PDF Verbiage"
                                                   variant="outlined"
                                                   name="pdfVerbiage"
                                                   onChange={onChange}
                                                   type="string"
                                                   value={selectedGuide.pdfVerbiage}
                                                   disabled={readOnly}
                                                />
                                                <TextField
                                                   id="outlined-add_date"
                                                   style={{ marginRight: "2rem", width: "9rem" }}
                                                   label="Date Added"
                                                   variant="outlined"
                                                   name="add_date"
                                                   type="text"
                                                   value={dateFormatter(selectedGuide.add_date)}
                                                   disabled
                                                />
                                                <TextField
                                                   id="outlined-edit_date"
                                                   style={{ width: "9rem" }}
                                                   label="Date Modified"
                                                   variant="outlined"
                                                   name="edit_date"
                                                   type="string"
                                                   value={dateFormatter(selectedGuide.edit_date)}
                                                   disabled
                                                />
                                             </Grid>
                                          </form>
                                       </Box>
                                    ) : (
                                       <></>
                                    )}
                                    {tabsValue === 1 ? (
                                       <Box p={3}>
                                          <ActivityLogGrid
                                             columns={[
                                                {
                                                   field: "date",
                                                   headerName: "Date",
                                                   description: "Date",
                                                   type: "date",
                                                   flex: 0.1,
                                                },
                                                {
                                                   field: "hour",
                                                   headerName: "Hour",
                                                   description: "Hour",
                                                   type: "time",
                                                   flex: 0.1,
                                                },

                                                {
                                                   field: "event",
                                                   headerName: "Event",
                                                   description: "Event",
                                                   type: "string",
                                                   flex: 0.2,
                                                },
                                                {
                                                   field: "description",
                                                   headerName: "Event Description",
                                                   description: "Event Description",
                                                   type: "string",
                                                   flex: 0.6,
                                                },
                                             ]}
                                             tableData={tableData}
                                          />
                                       </Box>
                                    ) : (
                                       <></>
                                    )}
                                 </Paper>
                              </div>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               )}
               <ActivityDialog
                  open={activityModal}
                  handleClose={() => setActivityModal(!activityModal)}
                  activityOptions={{ module: "ACCESSORIAL", record_id: props.match.params.id, rows: null }}
               />
            </div>
         </div>
      </>
   );
};

function mapStateToProps(state) {
   return {
      accessorialGuides: state.accessorialGuide.accessorialGuide || [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getAccessorialGuides: () => dispatch(accessorialGuideActions.getGuides()),
   };
}

AccessorialGuideEdit.propTypes = {
   match: PropTypes.shape({
      params: PropTypes.shape({
         id: PropTypes.string.isRequired,
      }),
   }),
   accessorialGuides: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   getAccessorialGuides: PropTypes.func.isRequired,
};

AccessorialGuideEdit.defaultProps = {
   match: { params: { id: "" } },
   accessorialGuides: [],
   user: {},
   getAccessorialGuides: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessorialGuideEdit);
