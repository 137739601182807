import actionTypes from "../actions/actionTypes";

const initialState = [];

export default function accessorialReducer(state = initialState, action) {
   switch (action.type) {
      case actionTypes.GET_ACCESSORIAL_TARIFFS:
         return action.tariffs;
      default:
         return state;
   }
}
