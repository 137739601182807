/**
 * Definition: This function formats customer code replacing "-" by " ,"
 *
 * Example:
 * masterAccountFormatter("123-456-789")
 *
 * Should Return:
 * "123 ,456 ,789"
 *
 */

export const masterAccountFormatter = (code) => {
   const arr = code.split("");
   arr.map((c, i) => {
      if (c === "-") {
         arr.splice(i, 1, " ,");
      }
   });
   return arr.join("");
};
