import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Switch, useHistory, useLocation } from "react-router-dom";
import socketIOClient from "socket.io-client";
import { getUserMessages } from "../api/adminMessageApi";
import * as cookieTools from "../cookieTools";
import * as accessorialActions from "../redux/actions/accessorialActions";
import * as accessorialGuideActions from "../redux/actions/accessorialGuideActions";
import actionTypes from "../redux/actions/actionTypes";
import * as customerActions from "../redux/actions/customerActions";
import * as fuelActions from "../redux/actions/fuelActions";
import * as notificationActions from "../redux/actions/notificationActions";
import * as terminalActions from "../redux/actions/terminalActions";
import * as userActions from "../redux/actions/userActions";
import "../styles/truck.css";
import AccessorialGuide from "./AccessorialGuide/AccessorialGuide";
import AccessorialTariff from "./AccessorialTariff/AccessorialTariff";
import Alias from "./Alias/AliasView";
import CustomerManagement from "./customermanagement/CustomerManagement";
import Fuel from "./fuel/fuel";
import Header from "./header/Header";
import containerLogo from "./Logo/ContainerPort-Logo-white.png";
import AdminMessageDialog from "./MessageManagement/AdminMessageDialog";
import MessageManagementRouter from "./MessageManagement/MessageManagementRouter";
import NotificationView from "./Notification/NotificationView";
import Orders from "./Orders/Orders";
import Profile from "./profile/Profile";
import RateManagementSearch from "./ratemanagement/RateManagementSearch";
import SecureRoute from "./SecureRoute/SecureRoute";
import Sidebar from "./Sidebar";
import Terminal from "./Terminal/Terminal";
import User from "./User/User";

//This unlocks our DataGridPro component.
LicenseInfo.setLicenseKey(
   "d8c9f71dc7a219ab2dd9fd728b4a34ebTz01NDIyMixFPTE2OTk4MDQzNjU2MDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

const drawerWidth = 240;
const headerHeight = 50;

const useStyles = makeStyles((theme) => ({
   contStyle: {
      margin: "0px",
   },
   mainControl: {
      [theme.breakpoints.up("sm")]: {
         width: `calc(100% - ${drawerWidth}px)`,
         marginLeft: drawerWidth,
         marginTop: headerHeight,
      },
   },
   sidebarStyle: {
      paddingRight: "11.9%",
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
   },
}));

function App(props) {
   const classes = useStyles();
   const history = useHistory();
   const location = useLocation();
   var inactiveTimerId;

   let [state, setState] = useState({
      selectedComponent: "ratemanagementsearch",
   });
   const [messages, setMessages] = useState([]);
   let [loading, setLoading] = useState(true);
   let [loggedOut, setLoggedOut] = useState(null);

   /**
    * Get messages to read for the user.
    */
   const getMessages = async () => {
      try {
         const resp = await getUserMessages();
         if (resp.length) {
            setMessages(resp);
         }
      } catch (error) {
         console.log("error in getMessages", error.message);
      }
      setLoading(false);
   };

   useEffect(() => {
      if (cookieTools.checkCookie("trt-rate-guide-token") && cookieTools.checkCookie("trt-rate-guide-userID")) {
         let cookieToken = cookieTools.getCookie("trt-rate-guide-token");
         let cookieUserID = cookieTools.getCookie("trt-rate-guide-userID");
         if (cookieToken && cookieUserID) {
            try {
               //check to see if this was filled in at login, no need to get it again
               _.isEmpty(props.user) && props.loadUser(cookieUserID);

               //get settings for redux store
               props.getCustomers();
               props.getTerminals();
               props.getInternalUsers();
               props.getNotifications();
               props.getFuelTariffs();
               props.getAccessorialTariffs();
               props.getAccessorialGuides();

               //socket connection
               const socket = socketIOClient("", { query: { user_id: cookieUserID, token: cookieToken } });

               socket.on("notification", (data) => {
                  props.getNotifications();
               });

               socket.on("newUser", (data) => {
                  props.getInternalUsers();
               });
            } catch (err) {
               console.error(err);
            }
            getMessages();

            //default view to rate management until we get a dashboard created if going to root
            if (location.pathname === "/") {
               history.push("/ratemanagement");
            }
         }
      }
   }, []);

   //check to see if user still exists and to log them out
   useEffect(() => {
      if (
         _.isEmpty(props.user) &&
         !cookieTools.checkCookie("trt-rate-guide-token") &&
         !cookieTools.checkCookie("trt-rate-guide-userID")
      ) {
         if (loggedOut) {
            window.location.replace(`/login${history.location.pathname}/loggedout`);
         } else {
            window.location.replace(`/login${history.location.pathname}`);
         }
      }
   }, [props.user]);

   //Load Hotjar if user is authenticated.
   useEffect(() => {
      if (!loading && !_.isEmpty(props.user)) {
         const s = document.createElement("script");
         s.type = "text/javascript";
         s.async = true;
         s.innerHTML = `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; 
                           h._hjSettings={hjid:2912726,hjsv:6}; 
                           a=o.getElementsByTagName('head')[0]; 
                           r=o.createElement('script');
                           r.async=1; 
                           r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); 
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

         document.body.appendChild(s);
      }
   }, [props.user, loading]);

   function inactiveListeners() {
      window.addEventListener("mousemove", resetTimer, false);
      window.addEventListener("mousedown", resetTimer, false);
      window.addEventListener("keypress", resetTimer, false);
      window.addEventListener("DOMMouseScroll", resetTimer, false);
      window.addEventListener("mousewheel", resetTimer, false);
      window.addEventListener("touchmove", resetTimer, false);
      window.addEventListener("MSPointerMove", resetTimer, false);

      startTimer();
   }

   inactiveListeners();

   function startTimer() {
      // wait 1 hour before logging user out.
      inactiveTimerId = window.setTimeout(goInactive, 3600000);
   }

   function resetTimer(e) {
      window.clearTimeout(inactiveTimerId);
      goActive();
   }

   function goInactive() {
      setLoggedOut("loggedout");
      props.logout();
   }

   function goActive() {
      startTimer();
   }

   const handleAcknowledgeFcn = () => {
      setMessages([]);
   };

   //need the user state to fill in for the settings on the screen
   return !loading && !_.isEmpty(props.user) ? (
      <>
         <Sidebar setParentState={setState} parentState={state} />
         <Header content={<p> Welcome {props.user.firstname}!</p>} setParentState={setState} />
         <Grid item xs>
            <div className={classes.mainControl}>
               <Switch>
                  <SecureRoute path="/profile" component={Profile} module="profile" />
                  <SecureRoute path="/ratemanagement/:id?" component={RateManagementSearch} module="ratemanagement" />
                  <SecureRoute path="/alias" component={Alias} module="alias" />
                  <SecureRoute path="/terminal" component={Terminal} module="termmanagement" />
                  <SecureRoute path="/customer" component={CustomerManagement} module="customermngmt" />
                  <SecureRoute path="/fuel" component={Fuel} module="fuel" />
                  <SecureRoute path="/user" component={User} module="intusermanagement" />
                  <SecureRoute path="/accessorialtariff" component={AccessorialTariff} module="accessorialtariff" />
                  <SecureRoute path="/accessorialguide" component={AccessorialGuide} module="accessorialguide" />
                  <SecureRoute path="/orders" component={Orders} module="orders" />
                  <SecureRoute path="/notification" component={NotificationView} module="notification" />
                  <SecureRoute
                     path="/adminmessagemanagement"
                     component={MessageManagementRouter}
                     module="adminmessagemanagement"
                  />
               </Switch>
            </div>
         </Grid>
         {messages.length && <AdminMessageDialog messages={messages} acknowledgeFcn={handleAcknowledgeFcn} />}
      </>
   ) : (
      <>
         <div className="loader-wrapper">
            <div className="truck-wrapper-fullscreen">
               <div className="truck">
                  <div className="truck-container">
                     <img style={{ width: "65px", paddingLeft: "5px", paddingTop: "15px" }} src={containerLogo} />
                  </div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
               </div>
            </div>
         </div>
      </>
   );
}

function mapStateToProps(state, ownProps) {
   return {
      user: state.user.currentUser || null,
      customer: state.customer !== null ? state.customer.customer : [],
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getInternalUsers: () => dispatch(userActions.getInternalUsers()),
      getCustomers: () => dispatch(customerActions.getCustomers()),
      getTerminals: () => dispatch(terminalActions.getTerminals()),
      getNotifications: () => dispatch(notificationActions.getUnreadNotifications()),
      getFuelTariffs: () => dispatch(fuelActions.getTariffs()),
      getAccessorialTariffs: () => dispatch(accessorialActions.getTariffs()),
      getAccessorialGuides: () => dispatch(accessorialGuideActions.getGuides()),
      saveUser: (user) => dispatch({ type: actionTypes.LOGIN_USER, user }),
      saveSocket: (socket) => dispatch({ type: actionTypes.ADD_SOCKET, socket }),
      saveCustomer: (customer) => dispatch({ type: actionTypes.GET_CUSTOMERS, customer }),
      loadUser: () => dispatch(userActions.loadUser()),
      logout: () => dispatch(userActions.logoutCurrentUser()),
   };
}

App.propTypes = {
   customer: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   getAccessorialTariffs: PropTypes.func.isRequired,
   getAccessorialGuides: PropTypes.func.isRequired,
   getCustomers: PropTypes.func.isRequired,
   getFuelTariffs: PropTypes.func.isRequired,
   getInternalUsers: PropTypes.func.isRequired,
   getNotifications: PropTypes.func.isRequired,
   getTerminals: PropTypes.func.isRequired,
   loadUser: PropTypes.func.isRequired,
   logout: PropTypes.func.isRequired,
};

App.defaultProps = {
   customer: [],
   user: {},
   getAccessorialTariffs: () => {
      return;
   },
   getAccessorialGuides: () => {
      return;
   },
   getCustomers: () => {
      return;
   },
   getFuelTariffs: () => {
      return;
   },
   getInternalUsers: () => {
      return;
   },
   getNotifications: () => {
      return;
   },
   getTerminals: () => {
      return;
   },
   loadUser: () => {
      return;
   },
   logout: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
