/* eslint-disable react/react-in-jsx-scope */
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SaveIcon from "@mui/icons-material/Save";
import { AppBar, Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as activityApi from "../../api/activityApi";
import { HandleError } from "../../api/apiUtils";
import * as fuelApi from "../../api/fuelApi";
import { dateFormatter } from "../../common/dateFormatter";
import { isOverlapped } from "../../common/isOverlapped";
import { masterAccountFormatter } from "../../common/masterAccountFormatter";
import { timeFormatter } from "../../common/timeFormatter";
import * as fuelActions from "../../redux/actions/fuelActions";
import "../../styles/truck.css";
import ActivityDialog from "../Activity/ActivityDialog";
import ActivityLogGrid from "../Activity/ActivityLogGrid";
import DeleteDialog from "../Delete/DeleteDialog";
import containerLogo from "../Logo/ContainerPort-Logo-white.png";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";
import "./fuel.css";
import FuelOverUnderModal from "./FuelOverUnderModal";
var _ = require("lodash");

const useStyles = makeStyles((theme) => ({
   secondaryHeader: {
      borderBottom: "1px solid #2F3136",
      zIndex: "3",
      width: "100%",
   },
   layoutMain: {
      marginTop: "2rem",
      position: "fixed",
      width: "inherit",
      height: "100%",
   },
   layoutBody: {
      position: "absolute",
      height: "calc(100% - 9.5rem)",
      width: "100%",
      overflowY: "scroll",
   },
   layoutBody2: {
      position: "absolute",
      height: "calc(100% - 12.5rem)",
      width: "100%",
      overflowY: "scroll",
   },
   search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      border: "1px solid black",
      backgroundColor: "#ffff",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "30%",
      float: "right",
      [theme.breakpoints.up("sm")]: {
         marginLeft: theme.spacing(3),
         width: "auto",
      },
   },
   searchIcon: {
      color: "black",
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   save: {
      float: "right",
      fontSize: "2rem",
      marginRight: "1rem",
   },
   back: {
      color: "white",
      backgroundColor: "#2F3136",
      float: "right",
      marginRight: "1rem",
      "&:hover": {
         backgroundColor: "black",
      },
   },
   inputRoot: {},
   inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
         width: "20ch",
      },
   },
   listDrawer: {
      width: "300px",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
   },
   appBar: {
      "& .MuiButtonBase-root": {
         padding: "1.25rem 2.25rem",
      },
      "& .MuiTabs-indicator": {
         height: "4px",
      },
   },
   circleIcon: {
      // color: "#F50057",
      fontSize: ".5rem",
      // position: "absolute",
      // right: "1rem",
   },
}));

const FuelEdit = (props) => {
   const history = useHistory();
   const classes = useStyles();

   const [activityModal, setActivityModal] = useState(false);
   const [activityTableData, setActivityTableData] = useState(null);
   const [alertMessage, setAlertMessage] = useState(null);
   const [alertType, setAlertType] = useState("");
   const [customersArr, setCustomersArr] = useState([]);
   const [deleteModal, setDeleteModal] = useState(false);
   const [loadActivity, setLoadActivity] = useState(true);
   const [loading, setLoading] = useState(true);
   const [overUnderTableData, setOverUnderTableData] = useState(null);
   const [readOnly, setReadOnly] = useState(false);
   const [saving, setSaving] = useState(false);
   const [selectedOverUnder, setSelectedOverUnder] = useState(null);
   const [selectedRow, setSelectedRow] = useState(null);
   const [selectedTariff, setSelectedTariff] = useState(null);
   const [showAlert, setShowAlert] = useState(false);
   const [showUnderOverModal, setUnderOverModal] = useState(false);
   const [tableData, setTableData] = useState(null);
   const [tabsValue, setTabsValue] = useState(0);

   const getCustomerNamesAndCodes = (tariff) => {
      const customers = [];
      props.customers.forEach((customer) => {
         if (customer.fuelTariffs && customer.fuelTariffs.length > 0) {
            customer.fuelTariffs.forEach(({ fuelTariff_id }) => {
               if (fuelTariff_id === tariff._id) {
                  customers.push(customer);
               }
            });
         }
      });

      setCustomersArr(customers);

      customers.map((customer) => {
         customer.id = customer._id;
      });

      setTableData(customers);
   };

   useEffect(() => {
      if (selectedTariff) {
         getCustomerNamesAndCodes(selectedTariff);
      }
   }, [selectedTariff]);

   useEffect(() => {
      //set loading to true
      setLoading(true);

      //retrieve record from API
      fuelApi
         .getTariffs(props.match.params.id)
         .then((resp) => {
            if (resp.tariff.length > 0) {
               var newSelectedTariff = resp.tariff[0];

               if (!newSelectedTariff.effectiveDate) {
                  newSelectedTariff.effectiveDate = setDefaultDateField(Date.now());
               }

               setSelectedTariff(newSelectedTariff);

               // build overUnder table data
               const tableData = newSelectedTariff.overUnder.map((overUnder) => ({
                  ...overUnder,
                  id: overUnder._id,
                  pprCpm: overUnder.ppr ? `${overUnder.ppr}%` : overUnder.cpm ? `$${overUnder.cpm}` : "",
                  modified: false,
               }));

               setOverUnderTableData(tableData);
               setSelectedRow(tableData.length > 0 ? tableData[0]._id : null);
               setSelectedOverUnder(tableData.length > 0 ? tableData[0] : null);
               setLoading(false);
            }
         })
         .catch((err) => {
            console.error(err);
         });
   }, [props.tariffs]);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "fuel");
      var userReadOnly = true;
      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   useEffect(() => {
      if (loadActivity) {
         activityApi
            .getActivity(null, null, null, [
               {
                  module: "FUEL",
                  record_id: props.match.params.id,
                  rows: null,
               },
            ])
            .then((resp) => {
               //Build the tableData
               const newTableData = [];

               resp.activities.forEach((activityObjectResp) => {
                  activityObjectResp.activity.forEach((activity, i) => {
                     const newActivityObj = {};

                     newActivityObj["_id"] = activityObjectResp._id;
                     newActivityObj["id"] = `${activityObjectResp._id}-${i}`;
                     newActivityObj["date"] = dateFormatter(activityObjectResp.add_date);
                     newActivityObj["hour"] = timeFormatter(activityObjectResp.add_date);
                     newActivityObj["event"] = activity.event_code || "N/A";
                     newActivityObj["description"] = activity.event || "N/A";

                     newTableData.push(newActivityObj);
                  });
               });

               setActivityTableData(newTableData);
               setLoadActivity(false);
            })
            .catch((err) => console.error(err));
      }
   }, [loadActivity, props.tariffs]);

   const handleTabsChange = (event, newValue) => {
      setTabsValue(newValue);
   };

   const onChange = (e) => {
      const { name, value } = e.target;
      const clonedSelectedTariff = JSON.parse(JSON.stringify(selectedTariff));
      clonedSelectedTariff[name] = value;
      setSelectedTariff(clonedSelectedTariff);
   };

   const onDPMorPPRChange = (e) => {
      const { name, value } = e.target;
      const clonedSelectedTariff = JSON.parse(JSON.stringify(selectedTariff));
      clonedSelectedTariff.tariffInfo[0][name] = value;
      setSelectedTariff(clonedSelectedTariff);
   };

   const updateEditTariff = async () => {
      if (selectedTariff.customer_id !== null && selectedTariff.terminal_ids.length <= 0) {
         openAlertMessage("Please select terminals with customer selection.", "warning");
         return;
      }

      if (!selectedTariff.name) {
         openAlertMessage("Please enter a Fuel Tariff Name value", "warning");
         return;
      }

      if (!selectedTariff.fscSchedule) {
         openAlertMessage("Please select a FSC Schedule option", "warning");
         return;
      }

      if (!selectedTariff.fscBasedOn) {
         openAlertMessage("Please select a FSC Based On option", "warning");
         return;
      }

      if (!selectedTariff.tariffInfo[0].ppr && !selectedTariff.tariffInfo[0].cpm) {
         openAlertMessage("Please enter a PPR or DPM value", "warning");
         return;
      }

      if (!selectedTariff.effectiveDate) {
         openAlertMessage("Please select a Effective Date", "warning");
         return;
      }

      setSaving(true);

      let selectedTariffCopy;
      let validation = true;

      if (overUnderTableData.length > 0) {
         const clonedSelectedTariff = _.cloneDeep(selectedTariff);
         const clonedOverUnderTableData = _.cloneDeep(overUnderTableData);
         clonedSelectedTariff.overUnder = clonedOverUnderTableData;
         selectedTariffCopy = clonedSelectedTariff;

         // validate text fields in over/under
         for (const [i, overUnder] of clonedOverUnderTableData.entries()) {
            if (!overUnder.ppr && !overUnder.cpm) {
               openAlertMessage(`Please enter a PPR or DPM value in row ${i + 1}`, "warning");
               setSaving(false);
               return;
            }

            if (!overUnder.minMiles && !overUnder.maxMiles) {
               openAlertMessage(`Please enter a Minimum Miles or a Maximum value in row ${i + 1}`, "warning");
               setSaving(false);
               return;
            }

            if (!overUnder.startDate && !overUnder.endDate) {
               openAlertMessage(`Please select a Start Date or End Date in row ${i + 1}`, "warning");
               setSaving(false);
               return;
            }

            if (overUnder.minMiles && overUnder.maxMiles && overUnder.minMiles >= overUnder.maxMiles) {
               openAlertMessage(
                  `Maximum Miles value must be greater than Minimum Miles value in row ${i + 1}`,
                  "warning",
               );
               setSaving(false);
               return;
            }

            if (
               overUnder.startDate &&
               overUnder.endDate &&
               new Date(dateFormatter(overUnder.startDate)).getTime() >=
                  new Date(dateFormatter(overUnder.endDate)).getTime()
            ) {
               openAlertMessage(`End Date must be after Start Date in row ${i + 1}`, "warning");
               setSaving(false);
               return;
            }
         }

         // validate no overlap
         for (const [i, overUnder_i] of clonedOverUnderTableData.entries()) {
            for (const [j, overUnder_j] of clonedOverUnderTableData.entries()) {
               if (i !== j) {
                  const milesOverlapped = isOverlapped(
                     overUnder_i.minMiles ? overUnder_i.minMiles : overUnder_i.maxMiles,
                     overUnder_i.maxMiles ? overUnder_i.maxMiles : overUnder_i.minMiles,
                     overUnder_j.minMiles ? overUnder_j.minMiles : overUnder_j.maxMiles,
                     overUnder_j.maxMiles ? overUnder_j.maxMiles : overUnder_j.minMiles,
                  );

                  const dateOverlapped = isOverlapped(
                     overUnder_i.startDate
                        ? new Date(dateFormatter(overUnder_i.startDate)).getTime()
                        : new Date(dateFormatter(overUnder_i.endDate)).getTime(),
                     overUnder_i.endDate
                        ? new Date(dateFormatter(overUnder_i.endDate)).getTime()
                        : new Date(dateFormatter(overUnder_i.startDate)).getTime(),
                     overUnder_j.startDate
                        ? new Date(dateFormatter(overUnder_j.startDate)).getTime()
                        : new Date(dateFormatter(overUnder_j.endDate)).getTime(),
                     overUnder_j.endDate
                        ? new Date(dateFormatter(overUnder_j.endDate)).getTime()
                        : new Date(dateFormatter(overUnder_j.startDate)).getTime(),
                  );

                  if (milesOverlapped && dateOverlapped) {
                     openAlertMessage(`Row ${i + 1} overlaps in Date/Miles with row ${j + 1}`, "warning");
                     setSaving(false);
                     return;
                  }
               }
            }
         }

         clonedOverUnderTableData.forEach((o, i) => {
            clonedOverUnderTableData[i].modified = false;
         });

         setOverUnderTableData(clonedOverUnderTableData);
      }

      await fuelApi
         .setTariffs(selectedTariffCopy || selectedTariff)
         .then((resp) => {
            //success
            setSaving(false);
            openAlertMessage("Tariff Successfully Saved.", "success");

            //Refresh activity
            setLoadActivity(true);
         })
         .catch((err) => {
            setSaving(false);
            openAlertMessage(`Error saving tariff: ${err}`, "error");
         });

      props.getTariffs();
   };

   const onDateChange = (newValue) => {
      const clonedSelectedTariff = _.cloneDeep(selectedTariff);
      clonedSelectedTariff.effectiveDate = newValue;
      setSelectedTariff(clonedSelectedTariff);
   };

   const setDefaultDateField = (date) => {
      let defaultDate = new Date(date);
      const dayOfTheWeek = defaultDate.getUTCDay();
      //check if day === tuesday, if not set the default date to the tuesday of the current week
      if (dayOfTheWeek !== 2) {
         //calculate the difference between the tuesday and the current day, then substract or add those days in miliseconds
         const days = (dayOfTheWeek - 2) * 86400000;
         defaultDate = new Date(date - days);
      }
      return defaultDate;
   };

   const handleOnRowClick = (params) => {
      setSelectedRow(params.id);
      setSelectedOverUnder(params.row);
   };

   const onOverUnderFieldChange = (event) => {
      const { value, name } = event.target;
      const clonedSelectedOverUnder = _.cloneDeep(selectedOverUnder);

      if (Number(value) < 0) {
         clonedSelectedOverUnder[name] = 0;
      } else {
         clonedSelectedOverUnder[name] = +value;
      }

      clonedSelectedOverUnder.pprCpm = clonedSelectedOverUnder.ppr
         ? `${clonedSelectedOverUnder.ppr}%`
         : clonedSelectedOverUnder.cpm
         ? `$${clonedSelectedOverUnder.cpm}`
         : "";

      // check if there are changes between current and original obj
      if (clonedSelectedOverUnder._id) {
         const originalOverUnder = selectedTariff.overUnder.find(
            (overUnder) => overUnder._id === clonedSelectedOverUnder._id,
         );

         for (const prop in originalOverUnder) {
            if (originalOverUnder[prop] != clonedSelectedOverUnder[prop]) {
               clonedSelectedOverUnder.modified = true;
               break;
            } else {
               clonedSelectedOverUnder.modified = false;
            }
         }
      }

      setSelectedOverUnder(clonedSelectedOverUnder);

      const clonedOverUnderTableData = _.cloneDeep(overUnderTableData);
      const index = clonedOverUnderTableData.findIndex((overUnder) => overUnder.id === clonedSelectedOverUnder.id);
      clonedOverUnderTableData[index] = clonedSelectedOverUnder;
      setOverUnderTableData(clonedOverUnderTableData);
   };

   const addNewOverUnder = () => {
      const clonedOverUnderTableData = _.cloneDeep(overUnderTableData);
      const newOVerUnder = {
         // assign a random temporally id
         id: Math.random().toString(36).slice(2),
         _id: null,
         cpm: null,
         ppr: null,
         minMiles: null,
         maxMiles: null,
         startDate: null,
         endDate: null,
      };

      // append new over/under to the grid
      clonedOverUnderTableData.push(newOVerUnder);

      // update grid
      setOverUnderTableData(clonedOverUnderTableData);
      setSelectedRow(newOVerUnder.id);
      setSelectedOverUnder(newOVerUnder);
   };

   const handleDeleteClick = () => {
      try {
         setDeleteModal(true);
      } catch (err) {
         HandleError(err);
      }
   };

   const deleteOverUnder = () => {
      setDeleteModal(!deleteModal);

      if (selectedOverUnder._id) {
         fuelApi
            .deleteOverUnder(props.match.params.id, selectedOverUnder._id)
            .then(() => {
               openAlertMessage("Over/Under Successfully Deleted.", "success");
            })
            .catch((err) => {
               openAlertMessage(`Error deleting Over/Under: ${err}`, "error");
            });
      }
      // in case over/under isn't saved yet in db, id does not exists, so just remove it from table data in client
      const clonedOverUnderTableData = _.cloneDeep(overUnderTableData);
      const index = clonedOverUnderTableData.findIndex(({ id }) => id === selectedOverUnder.id);
      clonedOverUnderTableData.splice(index, 1);

      setOverUnderTableData(clonedOverUnderTableData);

      const selected =
         clonedOverUnderTableData.length > 0 && clonedOverUnderTableData[index]
            ? clonedOverUnderTableData[index]
            : clonedOverUnderTableData[index - 1];

      setSelectedRow(clonedOverUnderTableData.length > 0 ? selected.id : null);
      setSelectedOverUnder(clonedOverUnderTableData.length > 0 ? selected : null);
   };

   function a11yProps(index) {
      return {
         id: `simple-tab-${index}`,
         "aria-controls": `simple-tabpanel-${index}`,
      };
   }

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   return (
      <>
         <div id={"fuelEdit"} className={classes.layoutMain}>
            <div id={"fuelEditHeader"} className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                  <Grid item xs={6}>
                     {!loading ? (
                        <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                           <Link
                              color="inherit"
                              style={{ cursor: "pointer" }}
                              onClick={() => breadcrumbOnClick("/fuel")}
                           >
                              <h3 style={{ fontWeight: "500" }}>{"Fuel"}</h3>
                           </Link>
                           <Link
                              color="inherit"
                              style={{ cursor: "pointer" }}
                              onClick={() => breadcrumbOnClick("/fuel/details/" + props.match.params.id)}
                           >
                              <h3 style={{ fontWeight: "500" }}>{selectedTariff.name ? selectedTariff.name : null}</h3>
                           </Link>
                        </Breadcrumbs>
                     ) : null}
                  </Grid>
                  <Grid item xs={6}>
                     <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "1.5rem" }}>
                        {tabsValue === 1 && (
                           <Button
                              variant="contained"
                              color="primary"
                              sx={{ marginRight: "1.5rem" }}
                              onClick={addNewOverUnder}
                              disabled={readOnly}
                           >
                              <AddIcon sx={{ fontSize: "1.5rem", color: "#FFF", marginRight: "0.5rem" }} />
                              New Over/Under
                           </Button>
                        )}
                        <Button
                           variant="contained"
                           color="primary"
                           onClick={updateEditTariff}
                           disabled={readOnly || saving}
                        >
                           {saving ? (
                              <CircularProgress size={24} className={classes.buttonProgress} />
                           ) : (
                              <SaveIcon style={{ fontSize: "1.5rem", color: "#FFF", marginRight: "0.5rem" }} />
                           )}
                           Save
                        </Button>
                     </div>
                  </Grid>
               </Grid>
               <Collapse in={showAlert}>
                  <Alert
                     style={{ color: "#FFFFFF" }}
                     variant="filled"
                     severity={alertType ? alertType : "success"}
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           sx={{ marginBottom: "0.25rem" }}
                           onClick={() => {
                              {
                                 closeAlertMessage();
                              }
                           }}
                        >
                           <CloseIcon fontSize="inherit" />
                        </IconButton>
                     }
                  >
                     {alertMessage}
                  </Alert>
               </Collapse>
            </div>
            <div id={"fuelEditBody"} className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}>
               {loading ? (
                  <div className="loader-wrapper">
                     <div className="truck-wrapper">
                        <div className="truck">
                           <div className="truck-container">
                              <img
                                 style={{ width: "65px", paddingLeft: "5px", paddingTop: "15px" }}
                                 src={containerLogo}
                              />
                           </div>
                           <div className="glases"></div>
                           <div className="bonet"></div>
                           <div className="base"></div>
                           <div className="base-aux"></div>
                           <div className="wheel-back"></div>
                           <div className="wheel-front"></div>
                           <div className="smoke"></div>
                        </div>
                     </div>
                  </div>
               ) : (
                  <Grid container direction={"row"} justifyContent="space-between">
                     <Grid item xs={12}>
                        <Grid container direction={"column"} justifyContent="space-between" alignItems="center">
                           <Grid item style={{ width: "100%" }}>
                              <div style={{ padding: "1%", width: "100%" }}>
                                 <Paper elevation={3}>
                                    <AppBar
                                       position="static"
                                       color="default"
                                       className={classes.appBar}
                                       sx={{
                                          boxShadow: "none",
                                          borderBottom: "1px solid black",
                                       }}
                                    >
                                       <Tabs
                                          indicatorColor="primary"
                                          textColor="primary"
                                          onChange={handleTabsChange}
                                          value={tabsValue}
                                          aria-label="tabs"
                                       >
                                          <Tab
                                             sx={{
                                                fontSize: "1rem",
                                                fontWeight: "700",
                                                letterSpacing: "0px",
                                                textTransform: "none",
                                             }}
                                             label="Fuel Details"
                                             {...a11yProps(0)}
                                          />
                                          <Tab
                                             sx={{
                                                fontSize: "1rem",
                                                fontWeight: "700",
                                                letterSpacing: "0px",
                                                textTransform: "none",
                                             }}
                                             label="Over/Under"
                                             {...a11yProps(1)}
                                          />
                                          <Tab
                                             sx={{
                                                fontSize: "1rem",
                                                fontWeight: "700",
                                                letterSpacing: "0px",
                                                textTransform: "none",
                                             }}
                                             label="Activity"
                                             {...a11yProps(2)}
                                          />
                                       </Tabs>
                                    </AppBar>
                                    {tabsValue === 0 && (
                                       <Box p={3}>
                                          <Grid container spacing={3} style={{ padding: "1rem 2rem" }}>
                                             <Grid item xs={3}>
                                                <TextField
                                                   style={{ width: "100%", margin: "1.5rem 0" }}
                                                   id="fuel-tariff-name-field"
                                                   label="Fuel Tariff Name"
                                                   name="name"
                                                   type="text"
                                                   value={selectedTariff.name}
                                                   variant="outlined"
                                                   onChange={onChange}
                                                />
                                             </Grid>
                                             <Grid item xs={3}>
                                                <TextField
                                                   style={{ width: "100%", margin: "1.5rem 0" }}
                                                   id="customer-name-field"
                                                   label="Customer Name(s)"
                                                   variant="outlined"
                                                   type="text"
                                                   disabled
                                                   value={
                                                      customersArr
                                                         ? customersArr.length === 0
                                                            ? ""
                                                            : customersArr.length === 1
                                                            ? customersArr[0].name
                                                            : "Multiple"
                                                         : ""
                                                   }
                                                />
                                             </Grid>
                                             <Grid item xs={3}>
                                                <TextField
                                                   style={{ width: "100%", margin: "1.5rem 0" }}
                                                   id="master-account-field"
                                                   label="Master Account(s)"
                                                   variant="outlined"
                                                   type="text"
                                                   disabled
                                                   value={
                                                      customersArr
                                                         ? customersArr.length === 0
                                                            ? ""
                                                            : customersArr.length === 1
                                                            ? masterAccountFormatter(customersArr[0].code)
                                                            : "Multiple"
                                                         : ""
                                                   }
                                                />
                                             </Grid>
                                             <Grid item xs={3}>
                                                <FormControl
                                                   variant="outlined"
                                                   style={{ width: "100%", margin: "1.5rem 0" }}
                                                >
                                                   <InputLabel id="fsc-schedule-select-label">FSC Schedule</InputLabel>
                                                   <Select
                                                      labelId="fsc-schedule-select-label"
                                                      id="fsc-schedule-select"
                                                      label="FSC Schedule"
                                                      name="fscSchedule"
                                                      value={selectedTariff.fscSchedule}
                                                      onChange={onChange}
                                                   >
                                                      <MenuItem value={""}>{""}</MenuItem>
                                                      <MenuItem value={"Customer Matrix (Weekly)"}>
                                                         {"Customer Matrix (Weekly)"}
                                                      </MenuItem>
                                                      <MenuItem value={"Customer Matrix (Mon to Sun)"}>
                                                         {"Customer Matrix (Mon to Sun)"}
                                                      </MenuItem>
                                                      <MenuItem value={"Customer Matrix (Wed to Tue)"}>
                                                         {"Customer Matrix (Wed to Tue)"}
                                                      </MenuItem>
                                                      <MenuItem value={"Customer Matrix (Sun to Sat)"}>
                                                         {"Customer Matrix (Sun to Sat)"}
                                                      </MenuItem>
                                                      <MenuItem value={"Customer Matrix (Monthly)"}>
                                                         {"Customer Matrix (Monthly)"}
                                                      </MenuItem>
                                                      <MenuItem value={"Customer Email (Weekly)"}>
                                                         {"Customer Email (Weekly)"}
                                                      </MenuItem>
                                                      <MenuItem value={"Customer Email (Mon to Sun)"}>
                                                         {"Customer Email (Mon to Sun)"}
                                                      </MenuItem>
                                                      <MenuItem value={"Customer Email (Wed to Tue)"}>
                                                         {"Customer Email (Wed to Tue)"}
                                                      </MenuItem>
                                                      <MenuItem value={"Customer Email (Monthly)"}>
                                                         {"Customer Email (Monthly)"}
                                                      </MenuItem>
                                                      <MenuItem value={"Customer Email (Quarterly)"}>
                                                         {"Customer Email (Quarterly)"}
                                                      </MenuItem>
                                                      <MenuItem value={"Customer Portal (Weekly)"}>
                                                         {"Customer Portal (Weekly)"}
                                                      </MenuItem>
                                                      <MenuItem value={"Customer Portal (Mon to Sun)"}>
                                                         {"Customer Portal (Mon to Sun)"}
                                                      </MenuItem>
                                                      <MenuItem value={"Customer Portal (Monthly)"}>
                                                         {"Customer Portal (Monthly)"}
                                                      </MenuItem>
                                                      <MenuItem value={"CPG Matrix (Monthly)"}>
                                                         {"CPG Matrix (Monthly)"}
                                                      </MenuItem>
                                                      <MenuItem value={"CPG Matrix (Quarterly)"}>
                                                         {"CPG Matrix (Quarterly)"}
                                                      </MenuItem>
                                                   </Select>
                                                </FormControl>
                                             </Grid>

                                             <Grid item xs={3} display="flex" alignItems="center">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                   <DatePicker
                                                      label="Effective Date"
                                                      value={
                                                         selectedTariff.effectiveDate
                                                            ? selectedTariff.effectiveDate
                                                            : dateFormatter(setDefaultDateField(Date.now()))
                                                      }
                                                      onChange={(newValue) => onDateChange(newValue)}
                                                      renderInput={(params) => (
                                                         <TextField
                                                            sx={{ width: "100%", margin: "1.5rem 0" }}
                                                            {...params}
                                                         />
                                                      )}
                                                   />
                                                </LocalizationProvider>
                                             </Grid>
                                             <Grid item xs={3}>
                                                <FormControl
                                                   variant="outlined"
                                                   style={{ width: "100%", margin: "1.5rem 0" }}
                                                >
                                                   <InputLabel id="fsc-based-on-select-label">FSC Based On</InputLabel>
                                                   <Select
                                                      id="fsc-based-on-select"
                                                      label="FSC Based On"
                                                      labelId="fsc-based-on-select-label"
                                                      name="fscBasedOn"
                                                      value={selectedTariff.fscBasedOn}
                                                      onChange={onChange}
                                                   >
                                                      <MenuItem value={""}>{""}</MenuItem>
                                                      <MenuItem value={"Work Order Date"}>{"Work Order Date"}</MenuItem>
                                                      <MenuItem value={"Delivery Date"}>{"Delivery Date"}</MenuItem>
                                                   </Select>
                                                </FormControl>
                                             </Grid>
                                             <Grid item xs={3}>
                                                <TextField
                                                   style={{ width: "100%", margin: "1.5rem 0" }}
                                                   disabled={selectedTariff.tariffInfo[0].cpm ? true : false}
                                                   id="ppr-field"
                                                   InputProps={{
                                                      endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                   }}
                                                   label="PPR"
                                                   name="ppr"
                                                   type="text"
                                                   value={selectedTariff.tariffInfo[0].ppr}
                                                   variant="outlined"
                                                   onChange={onDPMorPPRChange}
                                                />
                                             </Grid>
                                             <Grid item xs={3}>
                                                <TextField
                                                   style={{ width: "100%", margin: "1.5rem 0" }}
                                                   disabled={selectedTariff.tariffInfo[0].ppr ? true : false}
                                                   id="dpm-field"
                                                   InputProps={{
                                                      startAdornment: (
                                                         <InputAdornment position="start">$</InputAdornment>
                                                      ),
                                                   }}
                                                   label="DPM"
                                                   name="cpm"
                                                   type="text"
                                                   value={selectedTariff.tariffInfo[0].cpm}
                                                   variant="outlined"
                                                   onChange={onDPMorPPRChange}
                                                />
                                             </Grid>
                                          </Grid>
                                          {customersArr.length > 1 && (
                                             <Box>
                                                <StyledDataGrid
                                                   columns={[
                                                      {
                                                         field: "name",
                                                         headerName: "Customer Name(s)",
                                                         description: "Customer Name",
                                                         type: "string",
                                                         flex: 0.5,
                                                      },
                                                      {
                                                         field: "code",
                                                         headerName: "Master Account(s)",
                                                         description: "Master Account",
                                                         type: "string",
                                                         valueFormatter: ({ value }) => masterAccountFormatter(value),
                                                         flex: 0.5,
                                                      },
                                                   ]}
                                                   rows={tableData ? tableData : null}
                                                ></StyledDataGrid>
                                             </Box>
                                          )}
                                       </Box>
                                    )}
                                    {tabsValue === 1 && (
                                       <Box p={3}>
                                          <Grid container spacing={3} style={{ padding: "1rem 2rem" }}>
                                             <Grid item xs={2}>
                                                <TextField
                                                   style={{ width: "100%", margin: "1.5rem 0" }}
                                                   disabled={
                                                      overUnderTableData.length === 0 || selectedOverUnder?.cpm
                                                         ? true
                                                         : false
                                                   }
                                                   id="ppr-field"
                                                   InputProps={{
                                                      endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                   }}
                                                   label="PPR"
                                                   name="ppr"
                                                   type="number"
                                                   value={selectedOverUnder?.ppr || ""}
                                                   onChange={onOverUnderFieldChange}
                                                   onKeyPress={(e) => {
                                                      if (e.code === "Minus") {
                                                         e.preventDefault();
                                                      }
                                                   }}
                                                   required={selectedOverUnder?.ppr ? true : false}
                                                />
                                             </Grid>
                                             <Grid item xs={2}>
                                                <TextField
                                                   style={{ width: "100%", margin: "1.5rem 0" }}
                                                   disabled={
                                                      overUnderTableData.length === 0 || selectedOverUnder?.ppr
                                                         ? true
                                                         : false
                                                   }
                                                   id="dpm-field"
                                                   InputProps={{
                                                      startAdornment: (
                                                         <InputAdornment position="start">$</InputAdornment>
                                                      ),
                                                   }}
                                                   label="DPM"
                                                   name="cpm"
                                                   type="number"
                                                   value={selectedOverUnder?.cpm || ""}
                                                   onChange={onOverUnderFieldChange}
                                                   onKeyPress={(e) => {
                                                      if (e.code === "Minus") {
                                                         e.preventDefault();
                                                      }
                                                   }}
                                                   required={selectedOverUnder?.cpm ? true : false}
                                                />
                                             </Grid>
                                             <Grid item xs={2}>
                                                <TextField
                                                   disabled={overUnderTableData.length === 0}
                                                   sx={{ width: "100%", margin: "1.5rem 0" }}
                                                   id="minimum-miles"
                                                   label="Minimum Miles"
                                                   name="minMiles"
                                                   type="number"
                                                   value={selectedOverUnder?.minMiles || ""}
                                                   onChange={onOverUnderFieldChange}
                                                   onKeyPress={(e) => {
                                                      if (e.code === "Minus") {
                                                         e.preventDefault();
                                                      }
                                                   }}
                                                />
                                             </Grid>
                                             <Grid item xs={2}>
                                                <TextField
                                                   disabled={overUnderTableData.length === 0}
                                                   sx={{ width: "100%", margin: "1.5rem 0" }}
                                                   id="minimum-miles"
                                                   label="Maximum Miles"
                                                   name="maxMiles"
                                                   type="number"
                                                   value={selectedOverUnder?.maxMiles || ""}
                                                   onChange={onOverUnderFieldChange}
                                                   onKeyPress={(e) => {
                                                      if (e.code === "Minus") {
                                                         e.preventDefault();
                                                      }
                                                   }}
                                                />
                                             </Grid>

                                             <Grid item xs={2} display="flex" alignItems="center">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                   <DatePicker
                                                      disabled={overUnderTableData.length === 0}
                                                      label="Start Date"
                                                      value={selectedOverUnder?.startDate}
                                                      onChange={(newValue) => {
                                                         const clonedSelectedOverUnder = _.cloneDeep(selectedOverUnder);
                                                         clonedSelectedOverUnder.startDate = newValue;
                                                         setSelectedOverUnder(clonedSelectedOverUnder);

                                                         const clonedOverUnderTableData =
                                                            _.cloneDeep(overUnderTableData);
                                                         const index = clonedOverUnderTableData.findIndex(
                                                            (overUnder) => overUnder.id === clonedSelectedOverUnder.id,
                                                         );
                                                         clonedOverUnderTableData[index] = clonedSelectedOverUnder;
                                                         setOverUnderTableData(clonedOverUnderTableData);
                                                      }}
                                                      renderInput={(params) => (
                                                         <TextField
                                                            sx={{
                                                               width: "500px",
                                                               margin: "1.5rem 0",
                                                            }}
                                                            {...params}
                                                         />
                                                      )}
                                                   />
                                                </LocalizationProvider>
                                             </Grid>
                                             <Grid item xs={2} display="flex" alignItems="center">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                   <DatePicker
                                                      disabled={overUnderTableData.length === 0}
                                                      label="End Date"
                                                      value={selectedOverUnder?.endDate}
                                                      onChange={(newValue) => {
                                                         const clonedSelectedOverUnder = _.cloneDeep(selectedOverUnder);
                                                         clonedSelectedOverUnder.endDate = newValue;
                                                         setSelectedOverUnder(clonedSelectedOverUnder);

                                                         const clonedOverUnderTableData =
                                                            _.cloneDeep(overUnderTableData);
                                                         const index = clonedOverUnderTableData.findIndex(
                                                            (overUnder) => overUnder.id === clonedSelectedOverUnder.id,
                                                         );
                                                         clonedOverUnderTableData[index] = clonedSelectedOverUnder;
                                                         setOverUnderTableData(clonedOverUnderTableData);
                                                      }}
                                                      renderInput={(params) => (
                                                         <TextField
                                                            sx={{
                                                               width: "500px",
                                                               margin: "1.5rem 0",
                                                            }}
                                                            {...params}
                                                         />
                                                      )}
                                                   />
                                                </LocalizationProvider>
                                             </Grid>
                                          </Grid>
                                          <Box
                                             sx={{
                                                "& .selected": {
                                                   backgroundColor: "#002D7230",
                                                },
                                             }}
                                          >
                                             <StyledDataGrid
                                                onRowClick={handleOnRowClick}
                                                getCellClassName={(params) => {
                                                   if (!params.id) return "";
                                                   return params.id === selectedRow && "selected";
                                                }}
                                                columns={[
                                                   {
                                                      field: "pprCpm",
                                                      headerName: "PPR/DPM",
                                                      description: "PPR/DPM",
                                                      type: "string",
                                                      flex: 0.15,
                                                   },
                                                   {
                                                      field: "minMiles",
                                                      headerName: "Minimum Miles",
                                                      description: "Minimum Miles",
                                                      type: "string",
                                                      flex: 0.2,
                                                   },
                                                   {
                                                      field: "maxMiles",
                                                      headerName: "Maximum Miles",
                                                      description: "Maximum Miles",
                                                      type: "string",
                                                      flex: 0.2,
                                                   },
                                                   {
                                                      field: "startDate",
                                                      headerName: "Start Date",
                                                      description: "Start Date",
                                                      valueFormatter: ({ value }) => {
                                                         if (value) {
                                                            return dateFormatter(value);
                                                         } else {
                                                            return "";
                                                         }
                                                      },
                                                      type: "date",
                                                      flex: 0.2,
                                                   },
                                                   {
                                                      field: "endDate",
                                                      headerName: "End Date",
                                                      description: "End Date",
                                                      valueFormatter: ({ value }) => {
                                                         if (value) {
                                                            return dateFormatter(value);
                                                         } else {
                                                            return "";
                                                         }
                                                      },
                                                      type: "date",
                                                      flex: 0.2,
                                                   },
                                                   {
                                                      field: "_id",
                                                      headerName: " ",
                                                      type: "string",
                                                      sortable: false,
                                                      filterable: false,
                                                      renderCell: (params) => (
                                                         <div>
                                                            <Tooltip title="Delete Over/Under" arrow>
                                                               <IconButton
                                                                  variant="contained"
                                                                  color="secondary"
                                                                  size="small"
                                                                  onClick={handleDeleteClick}
                                                                  disabled={readOnly}
                                                               >
                                                                  <DeleteForeverIcon />
                                                               </IconButton>
                                                            </Tooltip>
                                                         </div>
                                                      ),
                                                      flex: 0.025,
                                                   },
                                                   {
                                                      field: "modified",
                                                      headerName: " ",
                                                      type: "string",
                                                      sortable: false,
                                                      filterable: false,
                                                      renderCell: (params) => {
                                                         if (params.value) {
                                                            return (
                                                               <Box>
                                                                  <IconButton variant="contained" color="secondary">
                                                                     <CircleIcon className={classes.circleIcon} />
                                                                  </IconButton>
                                                               </Box>
                                                            );
                                                         }
                                                         return "";
                                                      },
                                                      flex: 0.025,
                                                   },
                                                ]}
                                                rows={overUnderTableData ? overUnderTableData : []}
                                             ></StyledDataGrid>
                                          </Box>
                                       </Box>
                                    )}
                                    {tabsValue === 2 && (
                                       <Box p={3}>
                                          <ActivityLogGrid
                                             columns={[
                                                {
                                                   field: "date",
                                                   headerName: "Date",
                                                   description: "Date",
                                                   type: "date",
                                                   flex: 0.1,
                                                },
                                                {
                                                   field: "hour",
                                                   headerName: "Hour",
                                                   description: "Hour",
                                                   type: "time",
                                                   flex: 0.1,
                                                },

                                                {
                                                   field: "event",
                                                   headerName: "Event",
                                                   description: "Event",
                                                   type: "string",
                                                   flex: 0.2,
                                                },
                                                {
                                                   field: "description",
                                                   headerName: "Event Description",
                                                   description: "Event Description",
                                                   type: "string",
                                                   flex: 0.6,
                                                },
                                             ]}
                                             tableData={activityTableData ? activityTableData : null}
                                          />
                                       </Box>
                                    )}
                                 </Paper>
                              </div>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               )}
               {/* add modals */}
               <FuelOverUnderModal
                  open={showUnderOverModal}
                  handleClose={() => setUnderOverModal(false)}
                  setSelectedTariff={setSelectedTariff}
                  selectedTariff={selectedTariff}
               />
               <DeleteDialog
                  open={deleteModal}
                  handleCancel={() => setDeleteModal(!deleteModal)}
                  deleteFunc={deleteOverUnder}
                  title={`Delete Over/Under`}
                  text={`Are you sure you want to delete the Over/Under record?`}
               />
               <ActivityDialog
                  open={activityModal}
                  handleClose={() => setActivityModal(!activityModal)}
                  activityOptions={{ module: "FUEL", record_id: props.match.params.id, rows: null }}
               />
            </div>
         </div>
      </>
   );
};

function mapStateToProps(state) {
   return {
      tariffs: state.fuel.tariff || [],
      terminals: state.terminal.terminals || [],
      customers: state.customer !== null ? state.customer.customer : [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getTariffs: () => dispatch(fuelActions.getTariffs()),
      getCustomers: () => dispatch(customerActions.getCustomers()),
   };
}

FuelEdit.propTypes = {
   match: PropTypes.shape({
      params: PropTypes.shape({
         id: PropTypes.string.isRequired,
      }),
   }),
   terminals: PropTypes.array.isRequired,
   customers: PropTypes.array.isRequired,
   tariffs: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   getTariffs: PropTypes.func.isRequired,
   getCustomers: PropTypes.func.isRequired,
};

FuelEdit.defaultProps = {
   match: { params: { id: "" } },
   terminals: [],
   customers: [],
   tariffs: [],
   user: {},
   getTariffs: () => {
      return;
   },
   getCustomers: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelEdit);
