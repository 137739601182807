const { DateTime } = require("luxon");

/**
 * Definition: This function formats date values coming from the api.
 *
 * Example:
 * dateFormatter("2022-03-14T14:26:49.671Z")
 *
 * Should Return:
 * "03/14/2022"
 *
 */

export const dateFormatter = (date = "") => {
   try {
      var luxonDate = DateTime.fromISO(date).setZone("America/New_York").toISO();

      const newDate = new Date(luxonDate ? luxonDate : date);
      const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(newDate);
      const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(newDate);
      const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(newDate);
      return `${month}/${day}/${year}`;
   } catch (error) {
      return "00/00/0000";
   }
};
