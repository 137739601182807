import { CssBaseline } from "@mui/material";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { render } from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "./components/App";
import ErrorHandler from "./components/ErrorHandler";
import forgotPassword from "./components/forgotPassword/forgotPassword";
import LoginPage from "./components/login/LoginPage";
import RegistrationPage from "./components/registration/RegistrationPage";
import ResetPasswordPage from "./components/resetPassword/ResetPasswordPage";
import store from "./redux/configureStore";
import "./styles/index.css";

const theme = createTheme({
   palette: {
      primary: {
         main: "#002D72",
      },
      primaryLight: {
         main: "#0020FF",
      },
      secondary: {
         main: "#F50057",
      },
      background: {
         default: "#f2f2f2",
      },
      success: {
         main: "#4CAF50",
      },
      white: {
         main: "#FFFFFF",
      },
      warning: {
         main: "#FF9800",
      },
      error: {
         main: "#F44336",
      },
      selectedGrey: {
         main: "#999AB2",
      },
   },
});

render(
   <ErrorBoundary FallbackComponent={ErrorHandler}>
      <ReduxProvider store={store}>
         {/** This error handler is global. We can use it to wrap individual components as well. */}
         <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
               <CssBaseline />
               <Router>
                  <Switch>
                     <Route path="/user/register/:id/:email/:customerid" component={RegistrationPage} />
                     <Route path="/user/register/:id/:email" component={RegistrationPage} />
                     <Route path="/user/resetPassword/:jwt/:uid" component={ResetPasswordPage} />
                     <Route path="/login/forgotPassword" component={forgotPassword} />
                     <Route path="/login/:redirectURL?/:loggedOut?" component={LoginPage} />
                     <Route path="/" component={App} />
                  </Switch>
               </Router>
            </StyledEngineProvider>
         </ThemeProvider>
      </ReduxProvider>
   </ErrorBoundary>,
   document.getElementById("root"),
);
