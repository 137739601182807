import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import * as FuelApi from "../../api/fuelApi";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const FuelNew = (props) => {
   var handleError = useErrorHandler();

   let [saving, setSaving] = useState(false);
   let [saveErrors, setSaveErrors] = useState(null);

   let [newTariff, setNewTariff] = useState({
      _id: null,
      name: "",
      tariffInfo: [{}],
      active: true,
   });

   useEffect(() => {
      setNewTariff({
         _id: null,
         name: "",
         tariffInfo: [
            {
               ppr: "",
               cpm: "",
            },
         ],
         active: true,
      });
   }, [props]);

   function saveNewTariff() {
      if (newTariff.name == "") {
         setSaveErrors("Please enter a tariff name.");
         return;
      }

      if (
         (newTariff.tariffInfo[0].cpm === null || newTariff.tariffInfo[0].cpm == "") &&
         (newTariff.tariffInfo[0].ppr === null || newTariff.tariffInfo[0].ppr == "")
      ) {
         setSaveErrors("Please enter a DPM or PPR.");
         return;
      }

      setSaving(true);

      if (newTariff.name) {
         FuelApi.setTariffs(newTariff)
            .then((resp) => {
               //success
               props.openAlertMessage("Fuel Tariff Successfully Added.", "success");
               props.getTariffs();
               props.handleClose();
               setSaving(false);
            })
            .catch((err) => {
               setSaving(false);
               setSaveErrors(`Could Not Save New Tariff: ${err}`);
            });
      } else {
         setSaving(false);
         setSaveErrors("Name field is empty. Please enter a Fuel Tariff name", "error");
      }
   }

   function onChange(event) {
      try {
         var selectedTariff = _.cloneDeep(newTariff);
         selectedTariff[event.target.name] =
            event.target.type === "checkbox" ? event.target.checked : event.target.value;
         setNewTariff(selectedTariff);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeTariffInfo(event) {
      try {
         var selectedTariff = _.cloneDeep(newTariff);
         selectedTariff.tariffInfo[0][event.target.name] = event.target.value;
         setNewTariff(selectedTariff);
      } catch (err) {
         handleError(err);
      }
   }

   const inputProps = {
      step: 0.01,
   };

   return (
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.handleClose}>
         <Collapse in={saveErrors ? true : false}>
            <Alert
               style={{ color: "#FFFFFF" }}
               variant="filled"
               severity="error"
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     sx={{ marginBottom: "0.25rem" }}
                     onClick={() => {
                        setSaveErrors(null);
                     }}
                  >
                     <CloseIcon fontSize="inherit" />
                  </IconButton>
               }
            >
               {saveErrors}
            </Alert>
         </Collapse>
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            New Fuel Tariff
         </DialogTitle>
         <DialogContent>
            <form noValidate autoComplete="off" style={{ textAlign: "center" }}>
               <Grid item style={{ marginTop: "2rem" }}>
                  <TextField
                     id="outlined-basic"
                     style={{ padding: "1%" }}
                     label="Price Per Rate"
                     variant="outlined"
                     name="ppr"
                     onChange={onChangeTariffInfo}
                     type="number"
                     inputProps={inputProps}
                     value={newTariff.tariffInfo[0].ppr}
                     disabled={newTariff.tariffInfo[0].cpm ? true : false}
                  />
                  <TextField
                     id="outlined-basic"
                     style={{ padding: "1%" }}
                     label="Dollar Per Mile"
                     variant="outlined"
                     name="cpm"
                     onChange={onChangeTariffInfo}
                     type="number"
                     inputProps={inputProps}
                     value={newTariff.tariffInfo[0].cpm}
                     disabled={newTariff.tariffInfo[0].ppr ? true : false}
                  />
                  <TextField
                     id="outlined-basic"
                     style={{ padding: "1%" }}
                     label="Tariff Name"
                     variant="outlined"
                     name="name"
                     onChange={onChange}
                     type="text"
                     value={newTariff.name}
                  />
               </Grid>
            </form>
         </DialogContent>
         <DialogActions>
            <Tooltip title="Cancel" arrow>
               <IconButton onClick={props.handleClose} variant="contained" color="default">
                  <CloseIcon />
               </IconButton>
            </Tooltip>
            <Tooltip title="Save" arrow>
               <IconButton disabled={saving} onClick={saveNewTariff} variant="contained" color="primary">
                  <SaveIcon />
               </IconButton>
            </Tooltip>
         </DialogActions>
      </Dialog>
   );
};

FuelNew.propTypes = {
   open: PropTypes.bool.isRequired,
   user: PropTypes.object.isRequired,
   getTariffs: PropTypes.func.isRequired,
   handleClose: PropTypes.func.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
};

FuelNew.defaultProps = {
   open: false,
   user: {},
   getTariffs: () => {
      return;
   },
   handleClose: () => {
      return;
   },
   openAlertMessage: () => {
      return;
   },
};

export default FuelNew;
