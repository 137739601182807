import actionTypes from "../actions/actionTypes";

const initialState = [];

export default function fuelReducer(state = initialState, action) {
   switch (action.type) {
      case actionTypes.GET_FUEL_TARIFFS:
         return {
            ...state,
            ...action.tariffs,
         };
      case actionTypes.SET_SEARCH_QUERY:
         return {
            ...state,
            searchQuery: action.searchQuery,
         };
      default:
         return state;
   }
}
