import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Grid, IconButton, Paper, TextField, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Collapse from "@mui/material/Collapse";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as accessorialApi from "../../api/accessorialApi";
import { sortAlphabetically } from "../../common/sortAlphabetically";
import * as accessorialGuideActions from "../../redux/actions/accessorialGuideActions";
import DeleteDialog from "../Delete/DeleteDialog";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";
import AccessorialGuideNew from "./AccessorialGuideNew";

const AccessorialGuideView = (props) => {
   const history = useHistory();
   const handleError = useErrorHandler();

   const [guideList, setGuideList] = useState([]);
   const [tableData, setTableData] = useState([]);
   const [gridLoading, setGridLoading] = useState(true);
   const [deleteModal, setDeleteModal] = useState(false);
   const [newGuideModal, setNewGuideModal] = useState(false);
   const [selectedGuide, setSelectedGuide] = useState(null);
   const [openDrawer, setOpenDrawer] = useState(false);
   const [showAlert, setShowAlert] = useState(false);
   const [alertMessage, setAlertMessage] = useState(null);
   const [alertType, setAlertType] = useState("");
   const [search, setSearch] = useState("");
   const [readOnly, setReadOnly] = useState(false);

   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         borderBottom: "1px solid #2F3136",
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      save: {
         backgroundColor: "#002D72",
         float: "right",
         color: "white",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "#010440",
         },
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
      listDrawer: {
         width: "300px",
         maxWidth: 360,
         backgroundColor: theme.palette.background.paper,
      },
   }));

   const classes = useStyles();

   function newGuideClick() {
      try {
         setNewGuideModal(true);
         setOpenDrawer(false);
      } catch (err) {
         handleError(err);
      }
   }

   //create header state
   var headerData = [
      { id: "name", align: "left", label: "Name", edit: false, type: "string" },
      { id: "pdfVerbiage", align: "center", label: "PDF Verbiage", edit: false, type: "decimal" },
      { id: "add_date", align: "center", label: "Date Added", edit: false, type: "date" },
      { id: "edit_date", align: "center", label: "Date Modified", edit: false, type: "date" },
   ];

   useEffect(() => {
      var newAccessorialGuide = _.cloneDeep(props.accessorialGuides);

      if (newAccessorialGuide) {
         if (newAccessorialGuide.length) {
            Object.keys(newAccessorialGuide).forEach(function (key) {
               newAccessorialGuide[key].id = newAccessorialGuide[key]._id;
            });

            newAccessorialGuide = sortAlphabetically(newAccessorialGuide, "name");

            setTableData(newAccessorialGuide);
            setGuideList(newAccessorialGuide);
            setGridLoading(false);
         }
      }
   }, [props.accessorialGuides]);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "accessorial");
      var userReadOnly = true;

      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   function onChangeFilter(searchValue = null) {
      try {
         //create blank array for return
         var filteredObjects = [];

         //this turns values into lowercase strings
         var regValue = new RegExp(searchValue !== null ? searchValue.toLowerCase() : search.toLowerCase(), "g");

         //loop through header data since it contains the field names we need to check
         headerData.forEach((header) => {
            var filter = [];

            //we can only do this on strings, so make sure the type is filled in via the parent component
            if (header.type === "string") {
               //fill the filter on the original table data that was passed in from parent
               filter = guideList.filter((filt) => {
                  //return a match where the filter index is the name of the header id
                  return filt[header.id] ? filt[header.id].toLowerCase().match(regValue) : null;
               });
            } else {
               //fill the filter on the original table data that was passed in from parent
               filter = guideList.filter((filt) => filt[header.id === (searchValue !== null ? searchValue : search)]);
            }
            //check to see if it returned objects
            if (filter.length > 0) {
               //concat merges arrays since we are adding data from one array to another.  Push only adds a single item/object to an existing array
               filteredObjects = filteredObjects.concat(filter);
            }
         });

         //remove duplicates
         filteredObjects = _.uniqBy(filteredObjects, "_id");

         if (filteredObjects) {
            if (filteredObjects.length) {
               Object.keys(filteredObjects).forEach(function (key) {
                  filteredObjects[key].id = filteredObjects[key]._id;
               });
            }
         }

         //set the hook with the newly filtered data and it will render
         setTableData(filteredObjects);
         setGridLoading(false);
      } catch (err) {
         setGridLoading(false);
         handleError(err);
      }
   }

   function handleSearchOnChange(event) {
      try {
         setSearch(event.target.value ? event.target.value : "");

         if (!event.target.value) {
            onChangeFilter("");
         }
      } catch (err) {
         handleError(err);
      }
   }

   function handleEditClick(guide) {
      try {
         var newSelectedGuide = _.cloneDeep(guide);
         history.push({ pathname: `/accessorialguide/details/${newSelectedGuide.row._id}` });
      } catch (err) {
         handleError(err);
      }
   }

   function handleDeleteClick(guide) {
      try {
         setSelectedGuide(guide.row);
         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   const deleteGuide = () => {
      setDeleteModal(!deleteModal);

      accessorialApi
         .deleteGuide(selectedGuide._id)
         .then((resp) => {
            openAlertMessage("Guide Successfully Deleted.", "success");
            props.getAccessorialGuides();
         })
         .catch((err) => {
            openAlertMessage(`Error deleting guide: ${err}`, "error");
         });
   };

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   //data scrubbing function to return string values for display in table
   const dateFormatter = (value) => {
      try {
         var dateString = value;
         var newDate = new Date(dateString);
         const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(newDate);
         const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(newDate);
         const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(newDate);
         return month + "/" + day + "/" + year;
      } catch {
         return "00/00/0000";
      }
   };

   return (
      <>
         <div id={"accessorialGuideView"} className={classes.layoutMain}>
            <div id={"accessorialGuideViewHeader"} className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                  <Grid item xs={8}>
                     <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                        <Link
                           color="inherit"
                           style={{ cursor: "pointer" }}
                           onClick={() => breadcrumbOnClick("/accessorialguide")}
                        >
                           <h3 style={{ fontWeight: "500" }}>{"Accessorial Guides"}</h3>
                        </Link>
                     </Breadcrumbs>
                  </Grid>
                  <Grid item xs={4}>
                     <Button
                        style={{ float: "right", marginRight: "1rem" }}
                        variant="contained"
                        color="primary"
                        onClick={newGuideClick}
                        disabled={readOnly}
                     >
                        <AddIcon style={{ fontSize: "2rem" }} />
                        Add Accessorial
                     </Button>
                  </Grid>
               </Grid>
               <Collapse in={showAlert}>
                  <Alert
                     style={{ color: "#FFFFFF" }}
                     variant="filled"
                     severity={alertType ? alertType : "success"}
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           sx={{ marginBottom: "0.25rem" }}
                           onClick={() => {
                              {
                                 closeAlertMessage();
                              }
                           }}
                        >
                           <CloseIcon fontSize="inherit" />
                        </IconButton>
                     }
                  >
                     {alertMessage}
                  </Alert>
               </Collapse>
            </div>
            <div
               id={"accessorialGuideViewBody"}
               className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}
            >
               <Paper elevation={3}>
                  <Grid container direction={"row"} justifyContent="left" alignItems="center">
                     <Grid item xs={4} style={{ padding: "1%" }}>
                        <TextField
                           style={{
                              width: "100%",
                              borderRadius: "3px",
                           }}
                           id="filled-basic"
                           placeholder="Search"
                           variant="outlined"
                           size="small"
                           InputProps={{
                              startAdornment: (
                                 <InputAdornment position="start">
                                    <SearchIcon style={{ color: "#000000DE" }} />
                                 </InputAdornment>
                              ),
                           }}
                           onChange={handleSearchOnChange}
                        />
                     </Grid>
                     <Grid item xs={2} style={{ padding: "1%" }}>
                        <Button
                           variant="contained"
                           color="primary"
                           onClick={() => onChangeFilter(null)}
                           disabled={readOnly}
                        >
                           Search
                        </Button>
                     </Grid>
                     <Grid item xs={6} style={{ padding: "1%" }} />
                  </Grid>
                  <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
                     <Grid item xs={12}>
                        <StyledDataGrid
                           loading={gridLoading}
                           columns={[
                              {
                                 field: "name",
                                 headerName: "Name",
                                 description: "Name of the guide.",
                                 type: "string",
                                 flex: 0.3,
                              },
                              {
                                 field: "pdfVerbiage",
                                 headerName: "PDF Verbiage",
                                 description: "Verbiage that will go into the PDF.",
                                 type: "string",
                                 flex: 0.4,
                              },
                              {
                                 field: "add_date",
                                 headerName: "Date Added",
                                 description: "Date when guide was first added.",
                                 type: "date",
                                 valueFormatter: ({ value }) => dateFormatter(value),
                                 flex: 0.2,
                              },
                              {
                                 field: "edit_date",
                                 headerName: "Date Modified",
                                 description: "Date when guide was last edited.",
                                 type: "date",
                                 valueFormatter: ({ value }) => dateFormatter(value),
                                 flex: 0.2,
                              },
                              {
                                 field: "_id",
                                 headerName: "Actions", //This is a space because material-UI default to field unless there is a value.
                                 type: "string",
                                 sortable: false,
                                 filterable: false,
                                 renderCell: (params) => (
                                    <div>
                                       <Tooltip title="Edit Accessorial" arrow>
                                          <IconButton
                                             variant="contained"
                                             color="primary"
                                             size="small"
                                             onClick={() => handleEditClick(params)}
                                          >
                                             <EditIcon />
                                          </IconButton>
                                       </Tooltip>
                                       |
                                       <Tooltip title="Delete Accessorial" arrow>
                                          <IconButton
                                             variant="contained"
                                             color="secondary"
                                             size="small"
                                             onClick={() => handleDeleteClick(params)}
                                             disabled={readOnly}
                                          >
                                             <DeleteForeverIcon />
                                          </IconButton>
                                       </Tooltip>
                                    </div>
                                 ),
                                 flex: 0.1,
                              },
                           ]}
                           rows={tableData ? tableData : null}
                        ></StyledDataGrid>
                     </Grid>
                  </Grid>
               </Paper>
            </div>

            {/* Modals */}
            <AccessorialGuideNew
               open={newGuideModal}
               handleClose={() => setNewGuideModal(!newGuideModal)}
               getGuides={props.getAccessorialGuides}
               openAlertMessage={openAlertMessage}
               user={props.user}
            />
            <DeleteDialog
               open={deleteModal}
               handleCancel={() => setDeleteModal(!deleteModal)}
               deleteFunc={deleteGuide}
               title={selectedGuide && `Delete ${selectedGuide.name}`}
               text={
                  selectedGuide && `Are you sure you want to delete ${selectedGuide.name} from the accessorial guides?`
               }
            />
         </div>
      </>
   );
};

function mapStateToProps(state, ownProps) {
   return {
      accessorialGuides: state.accessorialGuide.accessorialGuide || [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getAccessorialGuides: () => dispatch(accessorialGuideActions.getGuides()),
   };
}

AccessorialGuideView.propTypes = {
   accessorialGuides: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   getAccessorialGuides: PropTypes.func.isRequired,
};

AccessorialGuideView.defaultProps = {
   accessorialGuides: [],
   user: {},
   getAccessorialGuides: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessorialGuideView);
