/**
 * Definition:
 * This function takes an array of objects and sort it alphabetically depending of the key which by default is set to "name".
 *
 * Example:
 * const myArray = [{ id: 1, name "c" }, { id: 2, name: "a" }, { id: 3, name: "b" }];
 * sortAlphabetically(myArray);
 *
 * Expected Outpud:
 * => [{ id: 2, name "a" }, { id: 3, name: "b" }, { id: 1, name: "c" }];
 */

export const sortAlphabetically = (arr, key = "name") =>
   arr.sort((a, b) => a[key].normalize().localeCompare(b[key].normalize()));
