import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// Material
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, IconButton, InputAdornment, Paper, TextField, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
// Components
import { deleteAdminMessage, getAdminMessage, setAdminMessage } from "../../api/adminMessageApi";
import DeleteDialog from "../Delete/DeleteDialog";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";
import MessageManagementModal from "./MessageManagementModal";
// Hooks
import useTypeDelay from "../../hooks/useTypeDelay";

const useStyles = makeStyles((theme) => ({
   secondaryHeader: {
      borderBottom: "1px solid #2F3136",
      zIndex: "3",
      width: "100%",
   },
   layoutMain: {
      marginTop: "2rem",
      position: "fixed",
      width: "inherit",
      height: "100%",
   },
   layoutBody: {
      position: "absolute",
      height: "calc(100% - 9.5rem)",
      width: "100%",
      overflowY: "scroll",
   },
   layoutBodyNarrow: {
      position: "absolute",
      height: "calc(100% - 12.5rem)",
      width: "100%",
      overflowY: "scroll",
   },
}));

const searchInGrid = (dataMessage, textSearching) => {
   const dataFiltered = dataMessage.filter((data) => {
      if (data.message?.toLowerCase().includes(textSearching.toLowerCase())) {
         return data;
      }

      if (data.schedule?.toLowerCase().includes(textSearching.toLowerCase())) {
         return data;
      }

      if (data.internal_external?.toLowerCase().includes(textSearching.toLowerCase())) {
         return data;
      }
   });
   return dataFiltered;
};

function SearchInput({ onChangeEvent }) {
   const [textSearching, setTextSearching] = useTypeDelay();

   const handleChangeFilter = (event) => {
      setTextSearching(event.target.value);
   };

   React.useEffect(() => {
      onChangeEvent(textSearching);
   }, [textSearching]);

   return (
      <TextField
         style={{
            width: "100%",
            borderRadius: "3px",
         }}
         placeholder="Search"
         size="small"
         InputProps={{
            startAdornment: (
               <InputAdornment position="start">
                  <SearchIcon style={{ color: "#000000DE" }} />
               </InputAdornment>
            ),
         }}
         onChange={handleChangeFilter}
      />
   );
}

export default function MessageManagement() {
   const classes = useStyles();
   const history = useHistory();
   const [gridLoading, setGridLoading] = React.useState(true);
   const [showAlert, setShowAlert] = React.useState(false);
   const [readOnly, setReadOnly] = React.useState(false);
   const [showDeleteModal, setShowDeleteModal] = React.useState(false);
   const [showCreateModal, setShowCreateModal] = React.useState(false);
   const [isLoadingProccess, setIsLoadingProccess] = React.useState(false);
   const [messageSelected, setMessageSelected] = React.useState(null);
   const [textSearching, setTextSearching] = React.useState("");
   const [messageType, setMessageType] = React.useState(null);
   const [alertMessage, setAlertMessage] = React.useState(null);
   const [dataMessage, setDataMessage] = React.useState([]);
   const [dataMessageFiltered, setDataMessageFiltered] = React.useState([]);
   const currentUser = useSelector((state) => state.user.currentUser);

   /**
    * Call API service: Get all messages
    */
   const getMessages = async () => {
      try {
         const result = await getAdminMessage();

         if (!result) {
            showErrorAlert("Error to get Messages");
            return;
         }

         setDataMessage(result);
         setDataMessageFiltered(result);
      } catch (error) {
         showErrorAlert(error.message);
         return;
      }

      setGridLoading(false);
   };

   /**
    * Call API service: save message
    * @param {obj} message
    */
   const saveNewMessage = async (message) => {
      let result;
      setIsLoadingProccess(true);
      try {
         result = await setAdminMessage(message);
      } catch (error) {
         showErrorAlert(error.message);
         return;
      } finally {
         setIsLoadingProccess(false);
      }

      setShowCreateModal(false);
      showSuccessAlert("Message created successfully");
      getMessages();
   };

   /**
    * Call service Delete message
    */
   const deleteMessage = async () => {
      let result;
      setShowDeleteModal(false);
      try {
         result = await deleteAdminMessage(messageSelected._id);
      } catch (error) {
         showErrorAlert(error.message);
         return;
      }

      if (!result.success) {
         showErrorAlert(result.message);
         return;
      }

      const data = dataMessageFiltered.filter((item) => item._id !== messageSelected._id);
      const dataAux = dataMessage.filter((item) => item._id !== messageSelected._id);
      showSuccessAlert(result.message);
      setDataMessageFiltered(data);
      setDataMessage(dataAux);
      setMessageSelected(null);
   };

   const handleBreadcrumb = (path) => {
      path && history.push(path);
   };

   const handleChangeFilter = (text) => {
      setTextSearching(text);
   };

   const handleDeleteAdminMessage = (message) => {
      setMessageSelected(message);
      setShowDeleteModal(true);
   };

   const showErrorAlert = (error = "Error") => {
      setMessageType("error");
      setAlertMessage(error);
      setShowAlert(true);
      handleCloseAlert();
   };

   const showSuccessAlert = (message = "Success") => {
      setMessageType("success");
      setAlertMessage(message);
      setShowAlert(true);
      handleCloseAlert();
   };

   const handleCloseAlert = () => {
      setTimeout(() => {
         setShowAlert(false);
      }, 3000);
   };

   React.useEffect(() => {
      if (currentUser) {
         if (!currentUser.security.admin) {
            setReadOnly(true);
         }
      }
   }, [currentUser]);

   React.useEffect(() => {
      const data = searchInGrid(dataMessage, textSearching);
      setDataMessageFiltered(data);
   }, [textSearching]);

   React.useEffect(() => {
      getMessages();
   }, []);

   return (
      <div className={classes.layoutMain}>
         {/* Header */}
         <div className={classes.secondaryHeader}>
            <Grid container direction="rows" justifyContent="space-between" alignItems="center">
               <Grid item xs={7}>
                  <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                     <Link
                        color="inherit"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleBreadcrumb("/adminmessagemanagement")}
                     >
                        <h3 style={{ fontWeight: "500" }}>{"Message Management"}</h3>
                     </Link>
                  </Breadcrumbs>
               </Grid>
               <Grid item xs={3} display="flex" justifyContent="flex-end">
                  <SearchInput onChangeEvent={handleChangeFilter} />
               </Grid>
               <Grid item xs={2}>
                  <Tooltip title="New Message" arrow>
                     <IconButton
                        style={{ float: "right", marginRight: "1rem" }}
                        variant="contained"
                        color="primary"
                        onClick={() => setShowCreateModal(true)}
                        /*eslint-disable*/
                        disabled={readOnly}
                     >
                        <AddIcon style={{ fontSize: "2rem" }} />
                     </IconButton>
                  </Tooltip>
               </Grid>
            </Grid>
            {/* Alert */}
            <Collapse in={showAlert}>
               <Alert
                  variant="filled"
                  severity={messageType ? messageType : "success"}
                  sx={{ color: "#FFFFFF" }}
                  action={
                     <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        sx={{ marginBottom: "0.25rem", color: "#FFFFFF" }}
                        onClick={() => setShowAlert(false)}
                     >
                        <CloseIcon fontSize="inherit" />
                     </IconButton>
                  }
               >
                  {alertMessage}
               </Alert>
            </Collapse>
         </div>
         {/* Table */}
         <div className={showAlert ? classes.layoutBodyNarrow : classes.layoutBody}>
            <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
               <Grid item xs={12}>
                  <Paper elevation={3}>
                     <StyledDataGrid
                        gridHeight={"785px"}
                        loading={gridLoading}
                        getRowId={(row) => row._id}
                        columns={[
                           {
                              field: "schedule",
                              headerName: "Schedule",
                              description: "Frecuency to send the message.",
                              type: "string",
                              flex: 0.16,
                           },
                           {
                              field: "message",
                              headerName: "Message",
                              description: "Content of the Message",
                              type: "string",
                              flex: 0.3,
                           },
                           {
                              field: "internal_external",
                              headerName: "Internal/External",
                              description: "Kind of user to receive the message",
                              type: "string",
                              flex: 0.15,
                           },
                           {
                              field: "active",
                              headerName: "Active",
                              description: "Kind of user to receive the message",
                              type: "string",
                              flex: 0.15,
                           },
                           {
                              field: "edit_date",
                              headerName: "Date Modified",
                              description: "Last edited date",
                              type: "string",
                              flex: 0.15,
                              valueGetter: (data) => {
                                 if (!data.row.add_date) return "";
                                 const dateString = data.row.add_date.split("T")[0];
                                 const dateSplited = dateString.split("-");
                                 return `${dateSplited[1]}/${dateSplited[2]}/${dateSplited[0]}`;
                              },
                           },
                           {
                              field: "actions",
                              headerName: " ", //This is a space because material-UI default to field unless there is a value.
                              type: "string",
                              sortable: false,
                              filterable: false,
                              renderCell: (params) => (
                                 <Box sx={{ display: params.value && "none" }}>
                                    <Tooltip title="Edit Message" arrow>
                                       <IconButton
                                          variant="contained"
                                          color="primary"
                                          size="small"
                                          onClick={() => history.push(`adminmessagemanagement/${params.row._id}`)}
                                       >
                                          <EditIcon />
                                       </IconButton>
                                    </Tooltip>
                                    |
                                    <Tooltip title="Delete Message" arrow>
                                       <IconButton
                                          variant="contained"
                                          color="secondary"
                                          size="small"
                                          onClick={() => handleDeleteAdminMessage(params.row)}
                                          disabled={readOnly}
                                       >
                                          <DeleteForeverIcon />
                                       </IconButton>
                                    </Tooltip>
                                 </Box>
                              ),
                              flex: 0.06,
                           },
                        ]}
                        rows={dataMessageFiltered}
                     ></StyledDataGrid>
                  </Paper>
               </Grid>
            </Grid>
            {showDeleteModal && (
               <DeleteDialog
                  open={showDeleteModal}
                  handleCancel={() => setShowDeleteModal(false)}
                  deleteFunc={deleteMessage}
                  title="Delete Message"
                  text="Are you sure you want to delete the message?"
               />
            )}
            {showCreateModal && (
               <MessageManagementModal
                  open={showCreateModal}
                  isLoadingProccess={isLoadingProccess}
                  handleClose={() => setShowCreateModal(false)}
                  saveMessage={saveNewMessage}
               />
            )}
         </div>
      </div>
   );
}
