import { applyMiddleware, compose, createStore } from "redux";
//import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

function configureStore(initialState) {
   const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //add support for redux dev tools

   return createStore(
      rootReducer,
      initialState,
      //composeEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant()))
      composeEnhancers(applyMiddleware(thunk)),
   );
}

const store = configureStore();

export default store;
