import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { Button, Divider, FormControl, Grid, List, ListItem, Paper, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import React from "react";
import cpgLogo from "../Logo/ContainerPort-Full-Color-Logo-RGB-300dpi.png";

const RegistrationForm = (props) => {
   return (
      <Grid
         container
         direction={"row"}
         justifyContent="space-between"
         alignItems="center"
         style={{ marginTop: "5rem" }}
      >
         <div style={{ width: "406px", maxWidth: "406px", margin: "0 auto" }}>
            <Paper elevation={3} style={{ padding: "3%" }}>
               <div>
                  <img src={cpgLogo} style={{ width: "10rem" }} />
               </div>

               <Divider />

               <h3 style={{ fontFamily: "Helvetica Neue, Helvetica, Arial", textAlign: "center" }}>Register</h3>

               <form onSubmit={props.handleSubmit}>
                  <div style={{ textAlign: "-webkit-center" }}>
                     <div style={{ display: "flex" }}>
                        <FormControl
                           controlid="firstname"
                           style={{ marginBottom: "1rem", marginLeft: "3rem", marginRight: "2rem" }}
                        >
                           <TextField
                              type="text"
                              autoFocus
                              value={props.firstName}
                              onChange={props.handleFirstNameChange}
                              placeholder="First Name"
                           />
                        </FormControl>

                        <FormControl controlid="lastname" style={{ marginBottom: "2rem", marginRight: "3rem" }}>
                           <TextField
                              type="text"
                              value={props.lastName}
                              onChange={props.handleLastNameChange}
                              placeholder="Last Name"
                           />
                        </FormControl>
                     </div>

                     <FormControl controlid="password" style={{ width: "75%", marginBottom: "1rem" }}>
                        <TextField
                           type="password"
                           value={props.password}
                           onChange={props.handlePasswordChange}
                           placeholder="Password"
                        />
                     </FormControl>

                     <FormControl controlid="password" style={{ width: "75%" }}>
                        <TextField
                           type="password"
                           value={props.confirm}
                           onChange={props.handleConfirmChange}
                           placeholder="Confirm Password"
                        />
                     </FormControl>

                     <div style={{ marginTop: "2rem" }}>
                        <div style={{ marginLeft: "1rem", marginBottom: "0.50rem", float: "left" }}>
                           {"Your password must have: "}
                        </div>
                        <List>
                           <ListItem>
                              <div style={{ display: "inline-flex" }}>
                                 {" "}
                                 <div style={{ marginRight: "0.25rem" }}>
                                    {props.policy.policy1 ? (
                                       <DoneIcon style={{ color: "green", fontSize: "large" }} />
                                    ) : (
                                       <ClearIcon style={{ color: "red", fontSize: "large" }} />
                                    )}
                                 </div>
                                 <div>{"Lowercase and uppercase letters"}</div>
                              </div>{" "}
                              <br></br>
                           </ListItem>
                           <ListItem>
                              <div style={{ display: "inline-flex" }}>
                                 <div style={{ marginRight: "0.25rem" }}>
                                    {props.policy.policy2 ? (
                                       <DoneIcon style={{ color: "green", fontSize: "large" }} />
                                    ) : (
                                       <ClearIcon style={{ color: "red", fontSize: "large" }} />
                                    )}
                                 </div>
                                 <div>{"Contain 1 number"}</div>
                              </div>{" "}
                              <br></br>
                           </ListItem>
                           <ListItem>
                              <div style={{ display: "inline-flex" }}>
                                 <div style={{ marginRight: "0.25rem" }}>
                                    {props.policy.policy3 ? (
                                       <DoneIcon style={{ color: "green", fontSize: "large" }} />
                                    ) : (
                                       <ClearIcon style={{ color: "red", fontSize: "large" }} />
                                    )}
                                 </div>
                                 <div>{"Contain 1 special character (!,@,$)"}</div>
                              </div>{" "}
                              <br></br>
                           </ListItem>
                           <ListItem>
                              <div style={{ display: "inline-flex" }}>
                                 {" "}
                                 <div style={{ marginRight: "0.25rem" }}>
                                    {props.policy.policy4 ? (
                                       <DoneIcon style={{ color: "green", fontSize: "large" }} />
                                    ) : (
                                       <ClearIcon style={{ color: "red", fontSize: "large" }} />
                                    )}
                                 </div>
                                 <div>{"8 or more characters"}</div>
                              </div>
                           </ListItem>
                        </List>
                     </div>
                  </div>

                  <div style={{ marginTop: "2rem", textAlignLast: "center" }}>
                     <div style={{ marginBottom: "1rem" }}>
                        {props.policy.policy1 &&
                        props.policy.policy2 &&
                        props.policy.policy3 &&
                        props.policy.policy4 ? (
                           <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={props.isSubmitLoading ? true : false}
                           >
                              {props.isSubmitLoading && (
                                 <CircularProgress
                                    style={{ width: "20px", height: "20px", marginRight: "5px", color: "green" }}
                                 />
                              )}{" "}
                              Submit{" "}
                           </Button>
                        ) : (
                           <Button variant="contained" color="primary" disabled={true} type="submit">
                              {" "}
                              Submit{" "}
                           </Button>
                        )}
                     </div>
                  </div>
               </form>
            </Paper>
         </div>
      </Grid>
   );
};

RegistrationForm.propTypes = {
   confirm: PropTypes.string.isRequired,
   password: PropTypes.string.isRequired,
   firstName: PropTypes.string.isRequired,
   lastName: PropTypes.string.isRequired,
   policy: PropTypes.object.isRequired,
   handleFirstNameChange: PropTypes.func.isRequired,
   handleLastNameChange: PropTypes.func.isRequired,
   handleConfirmChange: PropTypes.func.isRequired,
   handlePasswordChange: PropTypes.func.isRequired,
   handleSubmit: PropTypes.func.isRequired,
};

RegistrationForm.defaultProps = {
   confirm: "",
   password: "",
   firstName: "",
   lastName: "",
   policy: {},
   handleFirstNameChange: () => {
      return;
   },
   handleLastNameChange: () => {
      return;
   },
   handleConfirmChange: () => {
      return;
   },
   handlePasswordChange: () => {
      return;
   },
   handleSubmit: () => {
      return;
   },
};

export default RegistrationForm;
