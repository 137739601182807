import { HandleError, HandleResponse } from "./apiUtils";
import { DELETE_COOKIE, HEADERS, HEADERS_WITH_AUTHORIZATION, HEADERS_WITH_CONTENT } from "./headers";
const baseUrl = "/api/user/";

export function getUsers(uid = null, custId = null, internalUser = null) {

   let url = baseUrl + "getUser?";
   if (uid) {
      url += "uid=" + uid;
   }
   if (custId) {
      url += (uid ? "&" : "") + "custid=" + custId;
   }
   if (internalUser !== null) {
      url += (uid || custId ? "&" : "") + "internalUser=" + internalUser;
   }

   return fetch(url, {
      method: "GET",
      headers: HEADERS_WITH_AUTHORIZATION(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function loginUser(user) {
   return fetch(baseUrl + "login", {
      method: "POST",
      body: JSON.stringify({ email: user.email, password: user.password, internal: true }),
      headers: HEADERS_WITH_CONTENT(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function logoutCurrentUser() {

   return fetch(baseUrl + "logout", {
      method: "POST",
      headers: HEADERS_WITH_AUTHORIZATION(),
   })
      .then(() => {
         DELETE_COOKIE();
         HandleResponse();
         window.location.replace("/login");
      })
      .catch(HandleError);
}

export function submitNewUser(newUser) {
   return fetch(baseUrl + "register", {
      method: "POST",
      body: JSON.stringify(newUser),
      headers: HEADERS_WITH_CONTENT(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function requestPasswordReset(email) {
   return fetch(baseUrl + "reset", {
      method: "POST",
      headers: HEADERS_WITH_CONTENT(),
      body: JSON.stringify({ email }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function resetPassword(uid, token, newPassword) {
   let body = {
      userID: uid,
      token: token,
      newPassword: newPassword,
   };
   return fetch(baseUrl + "resetPassword", {
      method: "POST",
      headers: HEADERS_WITH_CONTENT(),
      body: JSON.stringify(body),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function saveUser(user) {


   return fetch(baseUrl + "saveProfile", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ user }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function inviteUser(email) {


   return fetch(baseUrl + "inviteNewUser", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ email }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function inviteCustomerUser(emailAddresses, custID, customerName) {


   return fetch(baseUrl + "inviteNewCustomerUser", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ emailAddresses, custID, customerName }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getCustomerInvites(custID) {


   return fetch(baseUrl + "getCustomerInvites", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ custID }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function resendCustomerInvite(id, customerName) {


   return fetch(baseUrl + "resendCustomerInvite", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ id, customerName }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteCustomerInvite(id) {


   return fetch(baseUrl + "deleteCustomerInvite", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ id }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function verifyToken() {


   return fetch(baseUrl, {
      method: "GET",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteUser(userID) {

   let url = baseUrl + "deleteUser?";

   if (userID) {
      url += "userID=" + userID;
   }

   return fetch(url, {
      method: "DELETE",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function exportExternalUsers(userID) {
   let url = baseUrl + "exportExternalUsers";

   return fetch(url, {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ userID: userID }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}
