import * as cookieTools from "../cookieTools";

export async function HandleResponse(response) {
   if (response.ok) {
      var responseData = await response.json();

      if (responseData.data) {
         return responseData.data;
      } else {
         return responseData;
      }
   }

   if (response.status === 400) {
      // So, a server-side validation error occurred.
      // Server side validation returns a string error message, so parse as text instead of json.
      const error = await response.json();

      if (error.msg) {
         throw new Error(error.msg);
      } else if (error.message) {
         throw new Error(error.message);
      } else {
         throw new Error(error);
      }
   }

   if (response.status === 401) {
      // User is logged in and has an active session but doesnt have the privilage of performing function
      // Usually occurs if an user tries to peform an admin function
      const error = await response.json();

      if (error.msg) {
         throw new Error(error.msg);
      } else if (error.message) {
         throw new Error(error.message);
      } else {
         throw new Error(error);
      }
   }

   if (response.status === 403) {
      // This means that the JWT was expired or the user has been deleted/deactivated
      // clear out the cookie and redirect to login screen
      cookieTools.deleteCookie("trt-rate-guide-token");
      cookieTools.deleteCookie("trt-rate-guide-userID");
      window.location.replace("/login");
      return;
   }

   const error = await response.json();

   if (error.msg) {
      throw new Error(error.msg);
   } else if (error.message) {
      throw new Error(error.message);
   } else {
      throw new Error("Network response was not ok.");
   }
}

export async function HandleError(error) {
   console.error("API call failed. " + error);
   throw error;
}
