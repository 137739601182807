import React from "react";
import { Route, Switch } from "react-router-dom";
import AccessorialGuideEdit from "./AccessorialGuideEdit";
import AccessorialGuideView from "./AccessorialGuideView";

const AccessorialGuide = () => {
   return (
      <>
         <Switch>
            <Route path="/accessorialguide" exact component={AccessorialGuideView} />
            <Route path="/accessorialguide/details/:id?" exact component={AccessorialGuideEdit} />
         </Switch>
      </>
   );
};

export default AccessorialGuide;
