import React from "react";
import { Route, Switch } from "react-router-dom";
import TerminalEdit from "./TerminalDetails";
import TerminalView from "./TerminalView";
var _ = require("lodash");

const Terminal = () => {
   return (
      <>
         <Switch>
            <Route path="/terminal" exact component={TerminalView} />
            <Route path="/terminal/details/:id?" exact component={TerminalEdit} />
         </Switch>
      </>
   );
};

export default Terminal;
