import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useErrorHandler } from "react-error-boundary";

const useStyles = makeStyles((theme) => ({
   paper: {
      width: (props) => props.width || "auto",
      height: (props) => props.height || 230,
      overflow: "auto",
   },
   button: {
      margin: theme.spacing(0.5, 0),
   },
}));

function not(a, b) {
   return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
   return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList(props) {
   var handleError = useErrorHandler();

   const classes = useStyles(props);
   const [checked, setChecked] = React.useState([]);
   const [left, setLeft] = React.useState(props.leftItems);
   const [right, setRight] = React.useState(props.rightItems);
   const [leftValue, setLeftValue] = React.useState(null);
   const [rightValue, setRightValue] = React.useState(null);

   //get lists for parent component function
   const getLists = (left, right) => {
      try {
         return props.getLists ? props.getLists(left, right) : () => {};
      } catch (err) {
         handleError(err);
      }
   };

   React.useEffect(() => {
      setLeft(props.leftItems);
      setRight(props.rightItems);
   }, [props]);

   const leftChecked = intersection(checked, left);
   const rightChecked = intersection(checked, right);

   const handleToggle = (value) => () => {
      try {
         const currentIndex = checked.indexOf(value);
         const newChecked = [...checked];

         if (currentIndex === -1) {
            newChecked.push(value);
         } else {
            newChecked.splice(currentIndex, 1);
         }

         setChecked(newChecked);
      } catch (err) {
         handleError(err);
      }
   };

   const handleAllRight = () => {
      try {
         setRight(right.concat(left));
         setLeft([]);
         getLists([], right.concat(left));
      } catch (err) {
         handleError(err);
      }
   };

   const handleCheckedRight = () => {
      try {
         setRight(right.concat(leftChecked));
         setLeft(not(left, leftChecked));
         setChecked(not(checked, leftChecked));
         getLists(not(left, leftChecked), right.concat(leftChecked));
      } catch (err) {
         handleError(err);
      }
   };

   const handleCheckedLeft = () => {
      try {
         setLeft(left.concat(rightChecked));
         setRight(not(right, rightChecked));
         setChecked(not(checked, rightChecked));
         getLists(left.concat(rightChecked), not(right, rightChecked));
      } catch (err) {
         handleError(err);
      }
   };

   const handleAllLeft = () => {
      try {
         setLeft(left.concat(right));
         setRight([]);
         getLists(left.concat(right), []);
      } catch (err) {
         handleError(err);
      }
   };

   //drag drop
   const reOrderArray = (value, listSide) => {
      try {
         var newLeft = _.cloneDeep(left);
         var newRight = _.cloneDeep(right);

         var to = _.findIndex(listSide === "left" ? newLeft : newRight, (x) => x._id === value._id);
         var from = _.findIndex(listSide === "left" ? newLeft : newRight, (x) =>
            listSide === "left" ? x._id === leftValue._id : x._id === rightValue._id,
         );

         listSide === "left"
            ? newLeft.splice(to, 0, newLeft.splice(from, 1)[0])
            : newRight.splice(to, 0, newRight.splice(from, 1)[0]);

         setLeft(newLeft);
         setRight(newRight);
         getLists(newLeft, newRight);
      } catch (err) {
         handleError(err);
      }
   };

   const onDrag = (event, value, listSide) => {
      event.stopPropagation();
      listSide === "left" && setLeftValue(value);
      listSide === "right" && setRightValue(value);
   };

   const onDragOver = (event, value) => {
      event.preventDefault();
   };

   const onDrop = (event, value, listSide) => {
      event.preventDefault();
      reOrderArray(value, listSide);
   };

   const customList = (items, listLabel, listSide) => (
      <Paper className={classes.paper} id={`paper-${listSide}`}>
         <List
            id={"list-" + listSide}
            dense
            component="div"
            role="list"
            subheader={
               <ListSubheader
                  component="div"
                  id={"nested-list-subheader-" + listSide}
                  style={{ backgroundColor: "white", zIndex: "1" }}
               >
                  {listLabel}
               </ListSubheader>
            }
         >
            {items.map((value) => {
               const labelId = `transfer-list-item-${value._id}-label-${listSide}`;

               return (
                  <ListItem
                     id={labelId}
                     key={value._id}
                     button={false}
                     role="listitem"
                     onDrag={(event) => onDrag(event, value, listSide)}
                     onDragOver={(event) => onDragOver(event, value, listSide)}
                     onDrop={(event) => onDrop(event, value, listSide)}
                     draggable={true}
                  >
                     <ListItemIcon>
                        <Checkbox
                           checked={checked.indexOf(value) !== -1}
                           tabIndex={-1}
                           disableRipple
                           inputProps={{ "aria-labelledby": labelId }}
                           onClick={handleToggle(value)}
                        />
                     </ListItemIcon>
                     <ListItemText id={labelId + "-text"} primary={value.name} />
                  </ListItem>
               );
            })}
         </List>
      </Paper>
   );

   return (
      <Grid container justifyContent="center" alignItems="center" style={{ padding: "0rem 2rem 3rem 2rem" }}>
         <Grid item xs={5}>
            {customList(left, props.leftLabel, "left")}
         </Grid>
         <Grid item xs={2}>
            <Grid container direction="column" alignItems="center">
               <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                  aria-label="move all right"
                  style={{ padding: "0.7rem 1.6rem", fontWeight: "700" }}
                  color="inherit"
               >
                  ≫
               </Button>
               <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                  style={{ padding: "0.5rem 0", fontWeight: "700" }}
                  color="inherit"
               >
                  &gt;
               </Button>
               <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                  style={{ padding: "0.5rem 0", fontWeight: "700" }}
                  color="inherit"
               >
                  &lt;
               </Button>
               <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  aria-label="move all left"
                  style={{ padding: "0.7rem 1.6rem", fontWeight: "700" }}
                  color="inherit"
               >
                  ≪
               </Button>
            </Grid>
         </Grid>
         <Grid item xs={5}>
            {customList(right, props.rightLabel, "right")}
         </Grid>
      </Grid>
   );
}

TransferList.propTypes = {
   leftLabel: PropTypes.string.isRequired,
   rightLabel: PropTypes.string.isRequired,
   leftItems: PropTypes.array.isRequired,
   rightItems: PropTypes.array.isRequired,
   getLists: PropTypes.func.isRequired,
};

TransferList.defaultProps = {
   terminals: [],
   user: {},
   getTerminals: () => {
      return;
   },
};
