import { HandleError, HandleResponse } from "./apiUtils";
import { HEADERS } from "./headers";
const baseUrl = "/api/notification/";

export function getNotifications(read = null, page = 0) {
   let url = baseUrl + "getNotifications";
   if (read != null) {
      url += "?read=" + read;
   }
   url += read != null ? `&page=${page}` : `?page=${page}`;

   return fetch(url, {
      method: "GET",
      headers: HEADERS(),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function removeNotification(notification_id) {

   return fetch(baseUrl + "removeNotification", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ notification_id }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}
