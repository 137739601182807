import * as cookieTools from "../cookieTools";

export let HEADERS_WITH_CONTENT = () => {
   return {
      "Content-Type": "application/json",
      "Cache-Control": "public, max-age=0, must-revalidate",
      'Content-Security-Policy': "default-src 'self'; script-src 'self'; style-src 'self'; font-src 'self'; img-src 'self'; frame-src 'self'"
   }
}

export let HEADERS = () => {
   return {
      "Content-Type": "application/json",
      "Cache-Control": "public, max-age=0, must-revalidate",
      Authorization: "Bearer " + cookieTools.getCookie("trt-rate-guide-token"),
      'Content-Security-Policy': "default-src 'self'; script-src 'self'; style-src 'self'; font-src 'self'; img-src 'self'; frame-src 'self'"
   }
}

export let DELETE_COOKIE = () => {
   return (
      cookieTools.deleteCookie("trt-rate-guide-token"),
      cookieTools.deleteCookie("trt-rate-guide-userID")
   )
}

export let HEADERS_WITH_AUTHORIZATION = () => {
   return {
      Authorization: "Bearer " + cookieTools.getCookie("trt-rate-guide-token"),
      "Cache-Control": "public, max-age=0, must-revalidate",
      'Content-Security-Policy': "default-src 'self'; script-src 'self'; style-src 'self'; font-src 'self'; img-src 'self'; frame-src 'self'"
   }
}

