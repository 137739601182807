import actionTypes from "../actions/actionTypes";

const initialState = [];

export default function notificationReducer(state = initialState, action) {
   switch (action.type) {
      case actionTypes.GET_NOTIFICATIONS:
         //this is for the activity page to see notifications list
         return action.notifications;
      case actionTypes.GET_UNREAD_NOTIFICATIONS:
         //this will be called for the drop down in the header
         return action.notifications;
      case actionTypes.REMOVE_NOTIFICATIONS:
         return state.filter((notification) => notification._id !== action.notification_id);
      default:
         return state;
   }
}
