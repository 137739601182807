import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import { Button, CircularProgress, InputAdornment, List, ListItemButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Collapse from "@mui/material/Collapse";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as customerApi from "../../api/customerApi";
import { sortAlphabetically } from "../../common/sortAlphabetically";
import * as customerActions from "../../redux/actions/customerActions";
import "../../styles/truck.css";
import containerLogo from "../Logo/ContainerPort-Logo-white.png";

const useStyles = makeStyles((theme) => ({
   secondaryHeader: {
      borderBottom: "1px solid #2F3136",
      zIndex: "3",
      width: "100%",
   },
   layoutMain: {
      marginTop: "2rem",
      position: "fixed",
      width: "inherit",
      height: "100%",
   },
   layoutBody: {
      position: "absolute",
      height: "calc(100% - 9.5rem)",
      width: "100%",
      overflowY: "scroll",
   },
   layoutBody2: {
      position: "absolute",
      height: "calc(100% - 12.5rem)",
      width: "100%",
      overflowY: "scroll",
   },
   search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      border: "1px solid black",
      backgroundColor: "#ffff",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "30%",
      float: "right",
      [theme.breakpoints.up("sm")]: {
         marginLeft: theme.spacing(3),
         width: "auto",
      },
   },
   searchIcon: {
      color: "black",
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   save: {
      fontSize: "2rem",
      marginRight: ".25rem",
   },
   back: {
      color: "white",
      backgroundColor: "#2F3136",
      float: "right",
      marginRight: "1rem",
      "&:hover": {
         backgroundColor: "black",
      },
   },
   inputRoot: {},
   inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
         width: "20ch",
      },
   },
   listDrawer: {
      width: "300px",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
   },
   buttonProgress: {
      color: green[500],
      marginRight: "0.5rem",
   },
   circleIcon: {
      color: "#F50057",
      fontSize: ".5rem",
      position: "absolute",
      right: "1rem",
   },
   button: {
      color: "white",
      backgroundColor: "#002D72",
      marginTop: "1rem",
   },
   label: {
      flexDirection: "column",
      fontSize: "11px",
   },
   icon: {
      fontSize: "24px",
      marginBottom: theme.spacing.unit,
   },
   list: {
      "& .MuiListItem-root .Mui-selected": {
         backgroundColor: "#2B3D808A",
         borderRadius: "5px",
      },
      "& .MuiListItem-root .Mui-selected span": {
         color: "#FFF",
      },
      "& .MuiListItem-root span": {
         fontSize: "14px",
         fontWeight: 600,
         color: "#002D72",
      },
   },
}));

const CustomerSpecificAccessorials = (props) => {
   const history = useHistory();
   const classes = useStyles();

   const [alertMessage, setAlertMessage] = useState(null);
   const [alertType, setAlertType] = useState("");
   const [listData, setListData] = useState([]);
   const [listDataInitialValues, setListDataInitialValues] = useState([]);
   const [loading, setLoading] = useState(true);
   const [readOnly, setReadOnly] = useState(false);
   const [saving, setSaving] = useState(false);
   const [search, setSearch] = useState("");
   const [selectedCustomer, setSelectedCustomer] = useState(null);
   const [selectedListItem, setSelectedListItem] = useState(0);
   const [selectedTerminal, setSelectedTerminal] = useState([]);
   const [showAlert, setShowAlert] = useState(false);

   const terminalMatchWithCustomer = useCallback(
      (terminal) => {
         const customer = selectedCustomer?.accessorialTariffs?.find(({ terminal_id }) => terminal_id === terminal._id);

         if (!customer) return null;
         return customer.accessorialGuide;
      },
      [selectedCustomer],
   );

   const terminalMatchWithAccessorialTariff = useCallback(
      (terminal) => {
         const tariff = props.accessorialTariffs.find(({ _id }) => _id === terminal.accessorialTariff_id);

         if (!tariff) return null;
         return tariff.accessorialGuide;
      },
      [props.accessorialTariffs],
   );

   const getAccessorialGuideByName = (n, match) => {
      const accessorialGuide = match?.find(({ name }) => name === n);

      if (!accessorialGuide) return null;
      return accessorialGuide;
   };

   const buildAccessorialGuideArray = useCallback(
      (customerMatch, terminalMatch) => {
         return sortAlphabetically(props.accessorialGuides).map(({ name }) => {
            const match = customerMatch
               ? getAccessorialGuideByName(name, customerMatch)
                  ? getAccessorialGuideByName(name, customerMatch)
                  : terminalMatch
                  ? getAccessorialGuideByName(name, terminalMatch)
                     ? getAccessorialGuideByName(name, terminalMatch)
                     : null
                  : null
               : terminalMatch
               ? getAccessorialGuideByName(name, terminalMatch)
                  ? getAccessorialGuideByName(name, terminalMatch)
                  : null
               : null;

            return {
               name,
               _id: match ? match._id : null,
               value: match ? (match.value ? match.value : 0) : 0,
               pdfVerbiage: match ? (match.pdfVerbiage ? match.pdfVerbiage : "") : "",
               terminalValue: terminalMatch
                  ? getAccessorialGuideByName(name, terminalMatch)
                     ? getAccessorialGuideByName(name, terminalMatch).value
                        ? getAccessorialGuideByName(name, terminalMatch).value
                        : 0
                     : 0
                  : 0,
               terminalPdfVerbiage: terminalMatch
                  ? getAccessorialGuideByName(name, terminalMatch)
                     ? getAccessorialGuideByName(name, terminalMatch).pdfVerbiage
                        ? getAccessorialGuideByName(name, terminalMatch).pdfVerbiage
                        : ""
                     : ""
                  : "",
            };
         });
      },
      [props.accessorialGuides],
   );

   const buildAccordionList = async () => {
      const accordions = [];

      const sortedTerminals = await sortAlphabetically(props.terminals).filter((t) => !t.group.isGroup);

      sortedTerminals.map(async (terminal) => {
         const customerMatch = await terminalMatchWithCustomer(terminal);
         const terminalMatch = await terminalMatchWithAccessorialTariff(terminal);

         const accordion = {
            name: terminal.name,
            code: terminal.code,
            display: true,
            modified: false,
            terminal_id: terminal._id,
            accessorialGuide: await buildAccessorialGuideArray(customerMatch, terminalMatch),
         };
         accordions.push(accordion);
      });

      setListDataInitialValues(accordions);
      setListData(accordions);
   };

   const getTerminalNameAndCode = (id) => {
      const terminal = props.terminals.find((t) => t._id === id);

      if (!terminal) return "";
      return `${terminal.name} - ${terminal.code}`;
   };

   const onSearch = () => {
      const clonedAccordionList = JSON.parse(JSON.stringify(listData));

      if (!search) {
         clonedAccordionList.forEach((accordion) => {
            accordion.display = true;
         });

         setListData(clonedAccordionList);
      } else {
         const positions = [];

         clonedAccordionList.forEach(({ name, code }, i) => {
            if (
               `${name}`.toLowerCase().includes(search.toLowerCase()) ||
               `${code}`.toLowerCase().includes(search.toLowerCase())
            ) {
               positions.push(i);
            }
         });

         clonedAccordionList.forEach((accordion) => {
            accordion.display = false;
         });

         positions.forEach((position) => {
            clonedAccordionList[position].display = true;
         });

         setListData(clonedAccordionList);
      }
   };

   const onChangeGuideField = (event, i) => {
      const { name, value } = event.target;
      const clonedListData = JSON.parse(JSON.stringify(listData));
      clonedListData[selectedListItem].accessorialGuide[i][name] = value;

      clonedListData[selectedListItem].modified = false;

      // check if there was a change in list item, if so, display the circle status icon
      for (let j = 0; j < clonedListData[selectedListItem].accessorialGuide.length; j++) {
         if (
            `${clonedListData[selectedListItem].accessorialGuide[j].value}` !==
               `${listDataInitialValues[selectedListItem].accessorialGuide[j].value}` ||
            `${clonedListData[selectedListItem].accessorialGuide[j].pdfVerbiage}` !==
               `${listDataInitialValues[selectedListItem].accessorialGuide[j].pdfVerbiage}`
         ) {
            clonedListData[selectedListItem].modified = true;
            break;
         }
      }

      setListData(clonedListData);
   };

   const resetToDefault = () => {
      const clonedListData = JSON.parse(JSON.stringify(listData));
      clonedListData[selectedListItem].accessorialGuide.forEach(({ terminalValue, terminalPdfVerbiage }, i) => {
         clonedListData[selectedListItem].accessorialGuide[i].value = terminalValue;
         clonedListData[selectedListItem].accessorialGuide[i].pdfVerbiage = terminalPdfVerbiage;
      });

      // check if there was a change in list item, if so, display the circle status icon
      for (let j = 0; j < clonedListData[selectedListItem].accessorialGuide.length; j++) {
         if (
            `${clonedListData[selectedListItem].accessorialGuide[j].value}` !==
               `${listDataInitialValues[selectedListItem].accessorialGuide[j].value}` ||
            `${clonedListData[selectedListItem].accessorialGuide[j].pdfVerbiage}` !==
               `${listDataInitialValues[selectedListItem].accessorialGuide[j].pdfVerbiage}`
         ) {
            clonedListData[selectedListItem].modified = true;
            break;
         }
      }

      setListData(clonedListData);
   };

   const saveCustomer = async () => {
      setSaving(true);

      let validation = true;
      const clonedListData = JSON.parse(JSON.stringify(listData));
      const clonedCustomer = JSON.parse(JSON.stringify(selectedCustomer));
      const accessorialTariffs = [];

      clonedListData.forEach(({ terminal_id, accessorialGuide }, index) => {
         // reset the modified indicator on save
         clonedListData[index].modified = false;

         const accessorialTariff = {
            terminal_id,
            accessorialGuide: [],
         };
         accessorialGuide.forEach(({ _id, name, value, pdfVerbiage, terminalValue, terminalPdfVerbiage }) => {
            // validate there is no blank input fields
            if (value.length === 0) {
               setSaving(false);
               openAlertMessage(
                  `Please enter a value in the ${name} field at ${getTerminalNameAndCode(terminal_id)} terminal`,
                  "warning",
               );
               validation = false;
               return;
            }

            // check if value and pdfVerbiage changed vs original values, if so save them
            if (value !== terminalValue || pdfVerbiage !== terminalPdfVerbiage) {
               const obj = {
                  _id,
                  name,
                  value: +value,
                  pdfVerbiage,
               };
               accessorialTariff.accessorialGuide.push(obj);
            }
         });
         if (accessorialTariff.accessorialGuide.length > 0) {
            accessorialTariffs.push(accessorialTariff);
         }
      });

      if (!validation) return;

      clonedCustomer.accessorialTariffs = accessorialTariffs;

      await customerApi
         .saveCustomer(clonedCustomer)
         .then((resp) => {
            if (!resp.success) {
               openAlertMessage(resp.message, "error");
            }
         })
         .catch((err) => {
            setSaving(false);
            openAlertMessage(`Error saving customer: ${err}`, "error");
         });

      props.getCustomers();
      setListData(clonedListData);
      setListDataInitialValues(clonedListData);
      setSaving(false);
      openAlertMessage("Customer Successfully Saved.", "success");
   };

   const handleListItem = (event, index) => setSelectedListItem(index);

   const openAlertMessage = (alertMessage, alertType) => {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   };

   const closeAlertMessage = () => setShowAlert(false);

   const breadcrumbOnClick = (path) => path && history.push(path);
   useEffect(() => {
      setLoading(true);

      //retrieve record from API
      customerApi.getCustomer(props.match.params.id).then((resp) => {
         if (resp.customer.length > 0) {
            const newSelectedCustomer = resp.customer[0];

            setSelectedCustomer(newSelectedCustomer);
            setLoading(false);
         }
      });
   }, []);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "customermanagement");
      var userReadOnly = true;
      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }
      setReadOnly(userReadOnly);
   }, [props.user]);

   useEffect(() => {
      buildAccordionList();
   }, [props.terminals, props.accessorialTariffs, props.accessorialGuides, selectedCustomer]);

   useEffect(() => {
      if (listData.length > 0) {
         setSelectedTerminal(listData[selectedListItem].accessorialGuide);
      }
   }, [selectedListItem, listData]);

   useEffect(() => {
      if (!search) onSearch();
   }, [search]);

   return (
      <>
         <div id={"customerDetails"} className={classes.layoutMain}>
            <div id={"customerDetailsHeader"} className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center" height="65.52px">
                  <Grid item xs={6}>
                     {!loading ? (
                        <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                           <Link
                              color="inherit"
                              style={{ cursor: "pointer" }}
                              onClick={() => breadcrumbOnClick("/customer")}
                           >
                              <h3 style={{ fontWeight: "500" }}>{"Customers"}</h3>
                           </Link>
                           <Link
                              color="inherit"
                              style={{ cursor: "pointer" }}
                              onClick={() => breadcrumbOnClick("/customer/details/" + props.match.params.id)}
                           >
                              <h3 style={{ fontWeight: "500" }}>
                                 {selectedCustomer.name ? selectedCustomer.name : null}
                              </h3>
                           </Link>
                           <Link
                              color="inherit"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                 breadcrumbOnClick(
                                    "/customer/details/" + props.match.params.id + "/customer-specific-accessorials",
                                 )
                              }
                           >
                              <h3 style={{ fontWeight: "500" }}>{"Customer Specific Accessorials"}</h3>
                           </Link>
                        </Breadcrumbs>
                     ) : null}
                  </Grid>
                  <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                     <Box
                        display="flex"
                        alignItems="center"
                        style={{
                           height: "80%",
                           paddingRight: "1rem",
                        }}
                     >
                        <TextField
                           autoComplete="off"
                           id="accessorial-search"
                           style={{
                              marginRight: "1rem",
                              borderRadius: "3px",
                           }}
                           size="small"
                           name="search"
                           placeholder="Search"
                           variant="outlined"
                           InputProps={{
                              startAdornment: (
                                 <InputAdornment position="start">
                                    <SearchIcon style={{ color: "#000000DE" }} />
                                 </InputAdornment>
                              ),
                           }}
                           type="text"
                           disabled={readOnly || loading}
                           value={search}
                           onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button
                           style={{ marginRight: "1rem" }}
                           variant="contained"
                           color="primary"
                           disabled={readOnly || loading}
                           onClick={onSearch}
                        >
                           Search
                        </Button>
                        <Button
                           sx={{ mr: "0.5rem" }}
                           variant="contained"
                           color="primary"
                           disabled={readOnly || saving || loading}
                           onClick={saveCustomer}
                        >
                           {saving ? (
                              <CircularProgress size={24} className={classes.buttonProgress} />
                           ) : (
                              <SaveIcon sx={{ fontSize: "1.5rem", color: "#FFF", marginRight: "0.5rem" }} />
                           )}
                           Save
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
               <Collapse in={showAlert}>
                  <Alert
                     style={{ color: "#FFFFFF" }}
                     variant="filled"
                     severity={alertType ? alertType : "success"}
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                              {
                                 closeAlertMessage();
                              }
                           }}
                        >
                           <CloseIcon fontSize="inherit" />
                        </IconButton>
                     }
                  >
                     {alertMessage}
                  </Alert>
               </Collapse>
            </div>
            <div id={"customerDetailsBody"} className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}>
               {loading ? (
                  <div className="loader-wrapper">
                     <div className="truck-wrapper">
                        <div className="truck">
                           <div className="truck-container">
                              <img
                                 style={{ width: "65px", paddingLeft: "5px", paddingTop: "15px" }}
                                 src={containerLogo}
                              />
                           </div>
                           <div className="glases"></div>
                           <div className="bonet"></div>
                           <div className="base"></div>
                           <div className="base-aux"></div>
                           <div className="wheel-back"></div>
                           <div className="wheel-front"></div>
                           <div className="smoke"></div>
                        </div>
                     </div>
                  </div>
               ) : (
                  <Grid container direction={"row"} justifyContent="space-between">
                     <Grid item xs={12}>
                        <Grid container direction={"column"} justifyContent="space-between" alignItems="center">
                           <Grid item style={{ width: "100%" }}>
                              <div style={{ padding: "1%", width: "100%" }}>
                                 <Paper elevation={3} sx={{ p: "1%" }}>
                                    <Grid container spacing={2}>
                                       <Grid item xs={3}>
                                          <Paper elevation={3} sx={{ p: "1%", height: "1024px", overflowY: "scroll" }}>
                                             <Typography
                                                variant="h4"
                                                sx={{
                                                   p: "8px 16px",
                                                   mb: "16px",
                                                   fontSize: "18px",
                                                   fontWeight: 700,
                                                   color: "#002D72",
                                                }}
                                             >
                                                Terminals
                                             </Typography>
                                             <List className={classes.list}>
                                                {listData.map(({ terminal_id, display, modified }, index) => (
                                                   <ListItem
                                                      key={`LI${terminal_id}${index}`}
                                                      sx={{ position: "relative" }}
                                                      disablePadding
                                                   >
                                                      <ListItemButton
                                                         sx={{ display: !display && "none" }}
                                                         selected={selectedListItem === index}
                                                         onClick={(e) => handleListItem(e, index)}
                                                      >
                                                         <ListItemText
                                                            primary={getTerminalNameAndCode(terminal_id)}
                                                            sx={{ pr: "2rem" }}
                                                         />
                                                      </ListItemButton>
                                                      {modified && <CircleIcon className={classes.circleIcon} />}
                                                   </ListItem>
                                                ))}
                                             </List>
                                          </Paper>
                                       </Grid>
                                       <Grid item xs={9}>
                                          <Paper elevation={3} sx={{ p: "1%" }}>
                                             <Box display="flex" width="100%" pb="1rem" justifyContent="flex-end">
                                                <Button
                                                   variant="contained"
                                                   color="primary"
                                                   disabled={readOnly || loading || saving}
                                                   onClick={resetToDefault}
                                                >
                                                   <RestartAltIcon sx={{ mr: ".5rem" }} />
                                                   Reset to Default Tariff
                                                </Button>
                                             </Box>
                                             <Grid container>
                                                {selectedTerminal.map((guide, i) => (
                                                   <React.Fragment key={`AAG-${guide._id && guide._id}${i}`}>
                                                      <Grid
                                                         item
                                                         xs={6}
                                                         pb="1rem"
                                                         sx={{ borderTop: "0.15rem solid #0000004D" }}
                                                      >
                                                         <Box p="0.5rem 1rem">
                                                            <Typography
                                                               variant="h4"
                                                               sx={{
                                                                  fontSize: "14px",
                                                                  fontWeight: 700,
                                                                  m: "0 0 1rem .5rem",
                                                                  color: "#002D72",
                                                               }}
                                                            >
                                                               {guide.name}
                                                            </Typography>
                                                            <Box>
                                                               <TextField
                                                                  error={guide.value?.length === 0}
                                                                  helperText={
                                                                     guide.value?.length === 0 && "Enter a Value"
                                                                  }
                                                                  id="outlined-value"
                                                                  sx={{ m: "0rem .5rem", width: "calc(30% - 1rem)" }}
                                                                  label="Value"
                                                                  name="value"
                                                                  InputProps={{
                                                                     startAdornment: (
                                                                        <InputAdornment position="start">
                                                                           $
                                                                        </InputAdornment>
                                                                     ),
                                                                  }}
                                                                  type="number"
                                                                  disabled={readOnly || saving}
                                                                  value={guide.value}
                                                                  onChange={(e) => onChangeGuideField(e, i)}
                                                               />
                                                               <TextField
                                                                  id="outlined-pdfVerbiage"
                                                                  sx={{ m: "0rem .5rem", width: "calc(70% - 1rem)" }}
                                                                  label="PDF Verbiage"
                                                                  name="pdfVerbiage"
                                                                  type="text"
                                                                  disabled={readOnly || saving}
                                                                  value={guide.pdfVerbiage}
                                                                  onChange={(e) => onChangeGuideField(e, i)}
                                                               />
                                                            </Box>
                                                         </Box>
                                                      </Grid>
                                                      {selectedTerminal.length % 2 !== 0 &&
                                                         i === selectedTerminal.length - 1 && (
                                                            <Grid
                                                               item
                                                               xs={6}
                                                               pb="1rem"
                                                               sx={{ borderTop: "0.15rem solid #0000004D" }}
                                                            ></Grid>
                                                         )}
                                                   </React.Fragment>
                                                ))}
                                             </Grid>
                                          </Paper>
                                       </Grid>
                                    </Grid>
                                 </Paper>
                              </div>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               )}
            </div>
         </div>
      </>
   );
};

function mapStateToProps(state) {
   return {
      terminals: state.terminal.terminals || [],
      customers: state.customer !== null ? state.customer.customer : [],
      user: state.user.currentUser,
      accessorialTariffs: state.accessorial.tariff || [],
      accessorialGuides: state.accessorialGuide.accessorialGuide || [],
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getAccessorialGuides: () => dispatch(accessorialGuideActions.getGuides()),
      getTerminals: () => dispatch(terminalActions.getTerminals()),
      getCustomers: () => dispatch(customerActions.getCustomers()),
   };
}

CustomerSpecificAccessorials.propTypes = {
   match: PropTypes.shape({
      params: PropTypes.shape({
         id: PropTypes.string.isRequired,
      }),
   }),
   accessorialTariffs: PropTypes.array.isRequired,
   accessorialGuides: PropTypes.array.isRequired,
   terminals: PropTypes.array.isRequired,
   customers: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   getCustomers: PropTypes.func.isRequired,
   getTerminals: PropTypes.func.isRequired,
};

CustomerSpecificAccessorials.defaultProps = {
   match: { params: { id: "" } },
   accessorialTariffs: [],
   accessorialGuides: [],
   terminals: [],
   customers: [],
   user: {},
   getAccessorialGuides: () => {
      return;
   },
   getCustomers: () => {
      return;
   },
   getTerminals: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSpecificAccessorials);
