import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SaveIcon from "@mui/icons-material/Save";
import { AppBar, Box, Button, CircularProgress, InputAdornment } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Collapse from "@mui/material/Collapse";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as accessorialApi from "../../api/accessorialApi";
import * as activityApi from "../../api/activityApi";
import { dateFormatter } from "../../common/dateFormatter";
import { sortAlphabetically } from "../../common/sortAlphabetically";
import { timeFormatter } from "../../common/timeFormatter";
import * as accessorialActions from "../../redux/actions/accessorialActions";
import "../../styles/truck.css";
import ActivityLogGrid from "../Activity/ActivityLogGrid";
import containerLogo from "../Logo/ContainerPort-Logo-white.png";

const AccessorialTariffEdit = (props) => {
   var history = useHistory();
   var handleError = useErrorHandler();

   let [transferListLeft, setTransferListLeft] = useState([]);
   let [transferListRight, setTransferListRight] = useState([]);
   const [selectedTariff, setSelectedTariff] = useState(null);
   let [loading, setLoading] = useState(true);
   let [readOnly, setReadOnly] = useState(false);
   let [showAlert, setShowAlert] = useState(false);
   let [alertMessage, setAlertMessage] = useState(null);
   let [alertType, setAlertType] = useState("");
   let [loadActivity, setLoadActivity] = useState(true);
   let [activityModal, setActivityModal] = useState(false);
   const [tabsValue, setTabsValue] = useState(0);
   const [tableData, setTableData] = useState([]);
   const [saving, setSaving] = useState(false);

   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         borderBottom: "1px solid #2F3136",
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         fontSize: "2rem",
      },
      save: {
         float: "right",
         fontSize: "2rem",
         marginRight: "1rem",
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
      listDrawer: {
         width: "300px",
         maxWidth: 360,
         backgroundColor: theme.palette.background.paper,
      },
      formControl: {
         margin: theme.spacing(1),
         minWidth: 120,
         "& .MuiSelect-outlined.MuiSelect-outlined": {
            textAlign: "left",
         },
      },
      selectEmpty: {
         marginTop: theme.spacing(2),
      },
      appBar: {
         "& .MuiButtonBase-root": {
            padding: "1.25rem 2.25rem",
         },
         "& .MuiTabs-indicator": {
            height: "4px",
         },
      },
      transferList: {
         "& .MuiListSubheader-sticky": {
            marginTop: "1rem",
            color: "#002D72",
            fontWeight: "500",
            fontSize: "1rem",
         },
      },
      buttonProgress: {
         color: green[500],
      },
   }));

   const classes = useStyles();

   useEffect(() => {
      if (loadActivity) {
         activityApi
            .getActivity(null, null, null, [
               {
                  module: "ACCESSORIAL",
                  record_id: props.match.params.id,
                  rows: null,
               },
            ])
            .then((resp) => {
               //Build the tableData
               const newTableData = [];

               resp.activities.forEach((activityObjectResp) => {
                  activityObjectResp.activity.forEach((activity, i) => {
                     const newActivityObj = {};

                     newActivityObj["_id"] = activityObjectResp._id;
                     newActivityObj["id"] = `${activityObjectResp._id}-${i}`;
                     newActivityObj["date"] = dateFormatter(activityObjectResp.add_date);
                     newActivityObj["hour"] = timeFormatter(activityObjectResp.add_date);
                     newActivityObj["event"] = activity.event_code || "N/A";
                     newActivityObj["description"] = activity.event || "N/A";

                     newTableData.push(newActivityObj);
                  });
               });

               setTableData(newTableData);
               setLoadActivity(false);
            })
            .catch((err) => {
               console.error(err);
            });
      }
   }, [loadActivity]);

   useEffect(() => {
      //set loading to true
      setLoading(true);

      if (props.terminals.length <= 0 || props.accessorialGuides.length <= 0) {
         return;
      }

      //retrieve record from API
      accessorialApi
         .getTariffs(props.match.params.id)
         .then((resp) => {
            if (resp.tariff.length > 0) {
               var newSelectedTariff = resp.tariff[0];

               //load left and right columns of transfer list
               var leftList = [];
               var rightList = [];

               props.terminals.map((term) => {
                  var termFound = _.find(newSelectedTariff.terminal_ids, (t) => t === term._id);

                  if (termFound) {
                     rightList.push({ _id: term._id, name: term.name });
                  } else {
                     leftList.push({ _id: term._id, name: term.name });
                  }
               });

               //get all guides that aren't on the tariff.
               props.accessorialGuides.forEach((guide) => {
                  const guideExists = _.find(newSelectedTariff.accessorialGuide, (x) => x._id === guide._id);

                  if (!guideExists) {
                     newSelectedTariff.accessorialGuide.push({
                        _id: guide._id,
                        name: guide.name,
                        pdfVerbiage: guide.pdfVerbiage,
                        value: 0,
                     });
                  }
               });

               //get all guides that shouldn't be on tariff.
               newSelectedTariff.accessorialGuide.forEach((guide, index) => {
                  const guideRemoved = _.find(props.accessorialGuides, (x) => x._id === guide._id);

                  if (!guideRemoved) {
                     newSelectedTariff.accessorialGuide.splice(index, 1);
                  } else {
                     newSelectedTariff.accessorialGuide[index].name = guideRemoved.name;
                  }
               });

               //sort by name
               newSelectedTariff.accessorialGuide = sortAlphabetically(newSelectedTariff.accessorialGuide);

               setTransferListLeft(leftList);
               setTransferListRight(rightList);
               setSelectedTariff(newSelectedTariff);
               setLoading(false);
            }
         })
         .catch((err) => {
            handleError(err);
         });
   }, [props.terminals, props.accessorialGuides]);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "accessorial");
      var userReadOnly = true;

      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   const getLists = (leftList, rightList) => {
      try {
         setTransferListLeft(leftList);
         setTransferListRight(rightList);

         //update selected tariff
         var newSelectedTariff = _.cloneDeep(selectedTariff);
         newSelectedTariff.terminal_ids = [];

         rightList.map((item) => {
            newSelectedTariff.terminal_ids.push(item._id);
         });

         setSelectedTariff(newSelectedTariff);
      } catch (err) {
         handleError(err);
      }
   };

   function onChange(event) {
      const { name, value } = event.target;
      const clonedSelectedTariff = _.cloneDeep(selectedTariff);
      clonedSelectedTariff[name] = value;
      setSelectedTariff(clonedSelectedTariff);
   }

   function onChangeCustomer(event) {
      try {
         var newSelectedTariff = _.cloneDeep(selectedTariff);
         newSelectedTariff.customer_id = event.target.value;
         setSelectedTariff(newSelectedTariff);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeGuide(event, index) {
      let { name, value } = event.target;
      const clonedSelectedTariff = { ...selectedTariff };
      if (name === "value") {
         value = +value;
      }
      clonedSelectedTariff.accessorialGuide[index][name] = value;
      setSelectedTariff(clonedSelectedTariff);
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   const updateEditTariff = async () => {
      if (selectedTariff.customer_id !== null && selectedTariff.terminal_ids.length <= 0) {
         openAlertMessage("Please select terminals with customer selection", "warning");
         return;
      }

      //validate 1 by 1 of the fields
      setSaving(true);

      accessorialApi
         .setTariffs(selectedTariff)
         .then((resp) => {
            //success
            setSaving(false);
            openAlertMessage("Tariff Successfully Saved.", "success");

            //Refresh activity
            setLoadActivity(true);
            props.getAccessorialTariffs();
         })
         .catch((err) => {
            setSaving(false);
            openAlertMessage(`Error saving tariff: ${err}`, "error");
         });

      props.getAccessorialTariffs();
   };

   const inputProps = {
      step: 0.01,
   };

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   function a11yProps(index) {
      return {
         id: `simple-tab-${index}`,
         "aria-controls": `simple-tabpanel-${index}`,
      };
   }

   const handleTabsChange = (event, newValue) => {
      setTabsValue(newValue);
   };

   return (
      <>
         <div id={"accessorialTariffEdit"} className={classes.layoutMain}>
            <div id={"accessorialTariffEditHeader"} className={classes.secondaryHeader}>
               <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item xs={4}>
                     {!loading ? (
                        <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                           <Link
                              color="inherit"
                              style={{ cursor: "pointer" }}
                              onClick={() => breadcrumbOnClick("/accessorialtariff")}
                           >
                              <h3 style={{ fontWeight: "500" }}>{"Accessorial Tariffs"}</h3>
                           </Link>
                           <Link
                              color="inherit"
                              style={{ cursor: "pointer" }}
                              onClick={() => breadcrumbOnClick("/accessorialtariff/details/" + props.match.params.id)}
                           >
                              <h3 style={{ fontWeight: "500" }}>{selectedTariff.name ? selectedTariff.name : null}</h3>
                           </Link>
                        </Breadcrumbs>
                     ) : null}
                  </Grid>
                  <Grid item xs={8}>
                     <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "1.5rem" }}>
                        <Tooltip title="Save" arrow>
                           <Button
                              variant="contained"
                              color="primary"
                              className={classes.saveButton}
                              onClick={updateEditTariff}
                              disabled={readOnly || saving}
                           >
                              {saving ? (
                                 <CircularProgress size={24} className={classes.buttonProgress} />
                              ) : (
                                 <>
                                    <SaveIcon style={{ fontSize: "1.5rem", color: "#FFF", marginRight: "0.5rem" }} />
                                 </>
                              )}
                              Save
                           </Button>
                        </Tooltip>
                     </div>
                  </Grid>
               </Grid>
            </div>
            <Collapse in={showAlert}>
               <Alert
                  style={{ color: "#FFFFFF" }}
                  variant="filled"
                  severity={alertType ? alertType : "success"}
                  action={
                     <IconButton
                        id="btn-alertClose"
                        aria-label="close"
                        color="inherit"
                        size="small"
                        sx={{ marginBottom: "0.25rem" }}
                        onClick={() => {
                           closeAlertMessage();
                        }}
                     >
                        <CloseIcon fontSize="inherit" />
                     </IconButton>
                  }
               >
                  {alertMessage}
               </Alert>
            </Collapse>
            <div
               id={"accessorialTariffEditBody"}
               className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}
            >
               {loading ? (
                  <div className="loader-wrapper">
                     <div className="truck-wrapper">
                        <div className="truck">
                           <div className="truck-container">
                              <img
                                 style={{ width: "65px", paddingLeft: "5px", paddingTop: "15px" }}
                                 src={containerLogo}
                              />
                           </div>
                           <div className="glases"></div>
                           <div className="bonet"></div>
                           <div className="base"></div>
                           <div className="base-aux"></div>
                           <div className="wheel-back"></div>
                           <div className="wheel-front"></div>
                           <div className="smoke"></div>
                        </div>
                     </div>
                  </div>
               ) : (
                  <Grid container direction={"row"} justifyContent="space-between">
                     <Grid item xs={12}>
                        <Grid container direction={"column"} justifyContent="space-between" alignItems="center">
                           <Grid item style={{ width: "100%" }}>
                              <div style={{ padding: "1%", width: "100%" }}>
                                 <Paper elevation={3}>
                                    <AppBar
                                       position="static"
                                       color="default"
                                       className={classes.appBar}
                                       sx={{
                                          boxShadow: "none",
                                          borderBottom: "1px solid black",
                                       }}
                                    >
                                       <Tabs
                                          indicatorColor="primary"
                                          textColor="primary"
                                          onChange={handleTabsChange}
                                          value={tabsValue}
                                          aria-label="tabs"
                                       >
                                          <Tab
                                             sx={{
                                                fontSize: "1rem",
                                                fontWeight: "700",
                                                letterSpacing: "0px",
                                                textTransform: "none",
                                             }}
                                             label="Accessorial Details"
                                             {...a11yProps(0)}
                                          />
                                          {/* <Tab
                                             sx={{
                                                fontSize: "1rem",
                                                fontWeight: "700",
                                                letterSpacing: "0px",
                                                textTransform: "none",
                                             }}
                                             label="Terminals"
                                             {...a11yProps(1)}
                                          /> */}
                                          <Tab
                                             sx={{
                                                fontSize: "1rem",
                                                fontWeight: "700",
                                                letterSpacing: "0px",
                                                textTransform: "none",
                                             }}
                                             label="Activity"
                                             {...a11yProps(2)}
                                          />
                                       </Tabs>
                                    </AppBar>
                                    {tabsValue === 0 ? (
                                       <Box style={{ padding: "0 2rem 1rem 0" }}>
                                          <TextField
                                             id="outlined-name"
                                             style={{
                                                margin: "1.5rem 1.5rem",
                                                width: "calc(50% - 3rem)",
                                             }}
                                             label="Tariff Name"
                                             variant="outlined"
                                             name="name"
                                             onChange={onChange}
                                             disabled={readOnly}
                                             value={selectedTariff.name}
                                             type="text"
                                          />
                                          <Box
                                             style={{
                                                display: "grid",
                                                gridTemplateColumns: "repeat(2, 1fr)",
                                             }}
                                          >
                                             {selectedTariff.accessorialGuide.map(
                                                ({ _id, name, value, pdfVerbiage }, i) => (
                                                   <React.Fragment key={`RF-${_id} ${i}`}>
                                                      <Box
                                                         style={{
                                                            borderTop: "0.15rem solid rgba(0, 0, 0, 0.3)",
                                                            paddingBottom: "1rem",
                                                         }}
                                                      >
                                                         <Box style={{ padding: "0.5rem 1rem" }}>
                                                            <Typography
                                                               variant="h4"
                                                               component="h4"
                                                               sx={{
                                                                  fontSize: "0.875rem",
                                                                  fontWeight: 700,
                                                                  m: "0 0 1rem 0.5rem ",
                                                                  color: "#002D72",
                                                               }}
                                                            >
                                                               {name}
                                                            </Typography>
                                                            <Box>
                                                               <TextField
                                                                  id="outlined-value"
                                                                  style={{
                                                                     margin: "0rem 0.5rem",
                                                                     width: "calc(30% - 1rem)",
                                                                  }}
                                                                  label="Value"
                                                                  variant="outlined"
                                                                  name="value"
                                                                  InputProps={{
                                                                     startAdornment: (
                                                                        <InputAdornment position="start">
                                                                           $
                                                                        </InputAdornment>
                                                                     ),
                                                                  }}
                                                                  onChange={(e) => onChangeGuide(e, i)}
                                                                  disabled={readOnly}
                                                                  value={value}
                                                                  type="number"
                                                               />
                                                               <TextField
                                                                  id="outlined-pdfVerbiage"
                                                                  style={{
                                                                     margin: "0rem 0.5rem",
                                                                     width: "calc(70% - 1rem)",
                                                                  }}
                                                                  label="PDF Verbiage"
                                                                  variant="outlined"
                                                                  name="pdfVerbiage"
                                                                  onChange={(e) => onChangeGuide(e, i)}
                                                                  value={pdfVerbiage}
                                                                  disabled={readOnly}
                                                                  type="text"
                                                               />
                                                            </Box>
                                                         </Box>
                                                      </Box>
                                                      {selectedTariff.accessorialGuide.length % 2 !== 0 &&
                                                         i === selectedTariff.accessorialGuide.length - 1 && (
                                                            <Box
                                                               style={{
                                                                  borderTop: "0.15rem solid rgba(0, 0, 0, 0.3)",
                                                                  paddingBottom: "1rem",
                                                               }}
                                                            ></Box>
                                                         )}
                                                   </React.Fragment>
                                                ),
                                             )}
                                          </Box>
                                       </Box>
                                    ) : (
                                       <></>
                                    )}
                                    {/* {tabsValue === 1 ? (
                                       <Box style={{ paddingBottom: "1rem" }}>
                                          <Grid item xs={12}>
                                             <Box style={{ width: "100%" }}>
                                                <Box
                                                   style={{
                                                      width: "100%",
                                                      textAlign: "-webkit-center",
                                                      padding: "1.5rem 4rem 1.5rem 3rem",
                                                   }}
                                                >
                                                   <FormControl
                                                      variant="outlined"
                                                      className={classes.formControl}
                                                      style={{ width: "100%" }}
                                                   >
                                                      <InputLabel id="select-outlined-label">
                                                         Select Customer
                                                      </InputLabel>
                                                      <Select
                                                         labelId="select-outlined-label"
                                                         label="Select Customer"
                                                         style={{ width: "100%" }}
                                                         onChange={onChangeCustomer}
                                                         value={selectedTariff?.customer_id || ""}
                                                         disabled={readOnly}
                                                      >
                                                         {props.customers.map((customer) => {
                                                            return (
                                                               <MenuItem
                                                                  value={customer._id}
                                                                  key={`menuItem-${customer._id}`}
                                                               >
                                                                  {customer.name}
                                                               </MenuItem>
                                                            );
                                                         })}
                                                      </Select>
                                                   </FormControl>
                                                </Box>
                                                <Divider
                                                   style={{
                                                      height: "0.15rem",
                                                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                                                   }}
                                                />

                                                <Box className={classes.transferList}>
                                                   <TransferList
                                                      leftItems={transferListLeft}
                                                      rightItems={transferListRight}
                                                      height={800}
                                                      getLists={getLists}
                                                      leftLabel="Available Terminals"
                                                      rightLabel="Assigned Terminals"
                                                      disabled={readOnly}
                                                   />
                                                </Box>
                                             </Box>
                                          </Grid>
                                       </Box>
                                    ) : (
                                       <></>
                                    )} */}
                                    {tabsValue === 1 ? (
                                       <Box p={3}>
                                          <ActivityLogGrid
                                             columns={[
                                                {
                                                   field: "date",
                                                   headerName: "Date",
                                                   description: "Date",
                                                   type: "date",
                                                   flex: 0.1,
                                                },
                                                {
                                                   field: "hour",
                                                   headerName: "Hour",
                                                   description: "Hour",
                                                   type: "time",
                                                   flex: 0.1,
                                                },

                                                {
                                                   field: "event",
                                                   headerName: "Event",
                                                   description: "Event",
                                                   type: "string",
                                                   flex: 0.2,
                                                },
                                                {
                                                   field: "description",
                                                   headerName: "Event Description",
                                                   description: "Event Description",
                                                   type: "string",
                                                   flex: 0.6,
                                                },
                                             ]}
                                             tableData={tableData}
                                          />
                                       </Box>
                                    ) : (
                                       <></>
                                    )}
                                 </Paper>
                              </div>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               )}
            </div>
         </div>
      </>
   );
};

function mapStateToProps(state) {
   return {
      tariffs: state.accessorial.tariff || [],
      terminals: state.terminal.terminals || [],
      customers: state.customer !== null ? state.customer.customer : [],
      accessorialGuides: state.accessorialGuide.accessorialGuide || [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getAccessorialTariffs: () => dispatch(accessorialActions.getTariffs()),
      getAccessorialGuides: () => dispatch(accessorialGuideActions.getGuides()),
   };
}

AccessorialTariffEdit.propTypes = {
   match: PropTypes.shape({
      params: PropTypes.shape({
         id: PropTypes.string.isRequired,
      }),
   }),
   customers: PropTypes.array.isRequired,
   tariffs: PropTypes.array.isRequired,
   terminals: PropTypes.array.isRequired,
   accessorialGuides: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   getAccessorialTariffs: PropTypes.func.isRequired,
   getAccessorialGuides: PropTypes.func.isRequired,
};

AccessorialTariffEdit.defaultProps = {
   match: { params: { id: "" } },
   customers: [],
   tariffs: [],
   terminals: [],
   accessorialGuides: [],
   user: {},
   getAccessorialTariffs: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessorialTariffEdit);
