const emailValid = new RegExp(/^.+@(.+)[.](.+)$/);

export function validateEmail(email = "") {
   let validationMessage = "";

   if (!email.match(emailValid)) {
      validationMessage = "Must be a valid email address.";
   }

   return validationMessage;
}
