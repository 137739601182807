/* eslint-disable-prettier/prettier */
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import GetApp from "@mui/icons-material/GetApp";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import WarningIcon from "@mui/icons-material/Warning";
import { Autocomplete } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import "date-fns";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as applicationApi from "../../api/applicationApi";
import * as orderApi from "../../api/orderApi";
import { dateFormatter } from "../../common/dateFormatter";
import { fscFormatter } from "../../common/fscFormatter";
import { stateList } from "../../common/stateListAbbreviation";
import PlaceSearch from "../Google/PlaceSearch";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";
import { headerCSV } from "./ExportHeader";

var _ = require("lodash");

const OrdersView = (props) => {
   var history = useHistory();
   var handleError = useErrorHandler();

   const [page, setPage] = useState(0);
   const [orderCount, setOrderCount] = useState(0);
   const [dataGridPage, setDataGridPage] = useState(0);
   const [orderStatuses, setOrderStatuses] = useState([]);
   const [loadingStatuses, setLoadingStatuses] = useState(true);
   const [showAlert, setShowAlert] = useState(false);
   const [alertMessage, setAlertMessage] = useState(null);
   const [alertType, setAlertType] = useState("");
   const [csvOrders, setCsvOrders] = useState([]);
   const [openDrawer, setOpenDrawer] = useState(false);
   const [gridLoading, setGridLoading] = useState(true);
   const [readOnly, setReadOnly] = useState(false);
   const [tableData, setTableData] = useState([]);
   const [gridHeight, setGridHeight] = useState("790px");
   const [searchQuery, setSearchQuery] = useState("");
   const [orderQuery, setOrderQuery] = useState({
      destination: null,
      order_id: null,
      customer_id: null,
      user_id: null,
      terminal_id: null,
      rate_id: null,
      add_date: null,
      startDate: null,
      endDate: null,
      minRate: null,
      maxRate: null,
      status: null,
      lane_type: null,
      minMiles: null,
      maxMiles: null,
      internalExternal: null,
   });

   const [filterOrderQuery, setFilterOrderQuery] = useState({
      destination: null,
      order_id: null,
      customer_id: null,
      user_id: null,
      terminal_id: null,
      rate_id: null,
      add_date: null,
      startDate: null,
      endDate: null,
      minRate: null,
      maxRate: null,
      status: null,
      lane_type: null,
      minMiles: null,
      maxMiles: null,
      internalExternal: null,
      archived: false
   });

   const [sortModel, setSortModel] = useState([
      {
         field: "add_date",
         sort: "desc",
      },
   ]);

   const laneTypes = ["", "LOCAL", "OTR", "REGIONAL"];

   const internalExternal = ["", "INTERNAL", "EXTERNAL"];

   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         fontSize: "2rem",
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      inputRoot: {},
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         // vertical padding + font size from searchIcon
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
      listDrawer: {
         width: "375px",
         maxWidth: "375px",
         backgroundColor: theme.palette.background.paper,
      },
      divider: {
         backgroundColor: "#002D72",
         height: "45px",
         width: "1px",
         marginRight: "1.25rem",
         marginLeft: "1.25rem",
      },
      chipIcon: {
         "&.MuiChip-deleteIcon": {
            color: "#F50057",
            "&:hover": {
               color: "#F50057",
            },
         },
      },
      inProgressBadge: {
         backgroundColor: "#002D72",
         color: "white",
      },
      activeBadge: {
         color: "#54B159",
      },
      expiredBadge: {
         color: "#F50057",
      },
      inProgressFont: {
         color: "#002D72",
         fontSize: "12px",
         fontWeight: 700,
      },
      completedFont: {
         color: "#54B159",
         fontSize: "12px",
         fontWeight: 700,
      },
      expiredFont: {
         color: "#F50057",
         fontSize: "12px",
         fontWeight: 700,
      },
   }));

   const classes = useStyles();

   let tableHeader = [{
      field: "orderNumber",
      headerName: "Quote #",
      description: "The unique order #.",
      type: "string",
      flex: 0.15,
      minWidth: 125,
   },
   {
      field: "add_date",
      headerName: "Created At",
      description: "The date the quote was created.",
      type: "date",
      valueFormatter: ({ value }) => dateFormatter(value),
      flex: 0.15,
      minWidth: 125,
   },
   {
      field: "validFrom",
      headerName: "Valid From",
      description: "The start date of quote validity.",
      type: "date",
      valueFormatter: ({ value }) => dateFormatter(value),
      flex: 0.15,
      minWidth: 125,
   },
   {
      field: "expire_date",
      headerName: "Expiry Date",
      description: "The date the quote is expired.",
      type: "date",
      valueFormatter: ({ value }) => dateFormatter(value),
      flex: 0.15,
      minWidth: 125,
   },
   {
      field: "status",
      headerName: "Status",
      description: "The status of the quote.",
      type: "string",
      flex: 0.15,
      minWidth: 70,
      renderCell: (params) => (
         <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <CircleIcon
               className={
                  params.row.status === "expired"
                     ? classes.expiredBadge
                     : classes.activeBadge
               }
            />
         </div>
      ),
   },
   {
      field: "firstName",
      headerName: "Quoted By",
      description: "The user that created the quote.",
      type: "string",
      flex: 0.3,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => (
         <div>
            <Typography style={{ fontWeight: 700 }}>
               {`${params.row.firstName ? params.row.firstName : ""} ${params.row.lastName ? params.row.lastName : "N/A"
                  }`}
            </Typography>
            <Typography variant="body2" style={{ fontSize: "10px" }}>
               {`${params.row.email ? params.row.email : "Not Available"}`}
            </Typography>
         </div>
      ),
   },
   {
      field: "customerName",
      headerName: "Customer",
      description: "Customer the quote is for.",
      type: "string",
      flex: 0.2,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
         <div>
            <Typography style={{ fontWeight: 700 }}>
               {`${params.row.customerCode ? params.row.customerCode : "N/A"}`}
            </Typography>
            <Typography variant="body2" style={{ fontSize: "10px" }}>
               {`${params.row.customerName ? params.row.customerName : "Not Available"}`}
            </Typography>
         </div>
      ),
   },
   {
      field: "terminalName",
      headerName: "Origin",
      description: "Terminal the quote is assigned to.",
      type: "string",
      flex: 0.3,
      minWidth: 180,
   },
   {
      field: " ",
      headerName: " ",
      description: "",
      type: "string",
      flex: 0.03,
      minWidth: 50,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
         <div>
            <ArrowForwardIcon
               style={{
                  color: "#002D72",
                  fontWeight: 600,
                  marginTop: ".25rem",
                  alignItems: "center",
                  justifyContent: "center",
               }}
            />
         </div>
      ),
   },
   {
      field: "destination",
      headerName: "Destination",
      description: "Destination the quote is assigned to.",
      type: "string",
      flex: 0.3,
      minWidth: 180,
   },
   {
      field: "rate",
      headerName: "Rate",
      description: "The base rate of the quote.",
      type: "string",
      flex: 0.15,
      minWidth: 75,
      headerAlign: "left",
      renderCell: (params) => <div>{`$${params.row.rate}`}</div>,
   },
   {
      field: "fsc",
      headerName: "FSC",
      description: "The FSC of the quote.",
      type: "string",
      flex: 0.15,
      minWidth: 75,
      headerAlign: "left",
      sortable: false,
      renderCell: (params) => <div>{fscFormatter(params?.row?.fsc?.tariffInfo?.ppr, params?.row?.fsc?.tariffInfo?.cpm)}</div>,
   },
   {
      field: "_id",
      headerName: "Details",
      type: "string",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => (
         <IconButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => history.push(`./orders/details/${params.row._id}/`)}
         >
            <FindInPageIcon />
         </IconButton>
      ),
      flex: 0.15,
      minWidth: 75,
   },
   {
      field: "overrides",
      headerName: "Override",
      description: "Was the quote overridden.",
      type: "string",
      flex: 0.15,
      minWidth: 75,
      filterable: false,
      sortable: false,
      align: "center",
      renderCell: (params) => (
         <div>
            {params.row.overrides
               ? (
                  <WarningIcon
                     color="warning"
                     style={{
                        fontWeight: 600,
                        marginTop: ".5rem",
                        alignItems: "center",
                        justifyContent: "center",
                     }}
                  />
               ) : (
                  <></>
               )}
         </div>
      ),
   }
   ];

   let tableHeaderAdmin = [{
      field: "archived",
      headerName: "Is Archived",
      description: "Archive Quote",
      type: "string",
      flex: 0.15,
      minWidth: 100,
      renderCell: (params) => (
         <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Checkbox
               color="primary"
               checked={params?.row?.archived || false}
               onChange={(e) => onClickCheckBox(params, e)}
            />
         </div>
      ),
   }, ...tableHeader];

   useEffect(() => {
      if (localStorage.getItem("quoteSearch")) {
         setTableData([]);
         setGridLoading(true);
         let qry = JSON.parse(localStorage.getItem("quoteSearch"));
         let pair = {
            destination: {
               city: qry?.destination?.city,
               state: qry?.destination?.state,
               zip: qry?.destination?.zip,
               place_id: qry?.destination?.place_id,
            },
            terminal_id: qry?.selectedTerminal?._id,
            customer_id: qry?.selectedCustomer?._id,
         };
         setFilterOrderQuery((filterOrderQuery) => ({ ...filterOrderQuery, ...pair }));
         handleApplyClick({ ...filterOrderQuery, ...pair });
         localStorage.removeItem("quoteSearch");
         return;
      }
      getOrders();

      if (loadingStatuses) {
         getStatusList();
         setLoadingStatuses(false);
      }
   }, []);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "orders");
      var userReadOnly = true;

      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   useEffect(() => {
      if (Object.values(orderQuery).some((x) => x !== null && x !== "") === true) {
         setGridHeight("715px");
         return;
      }

      setGridHeight("790px");
   }, [orderQuery]);

   function checkExpiry(created, days = 60) {
      const today = new Date().getTime();
      const createdDate = new Date(created).getTime();
      const monthInMiliseconds = days * 24 * 60 * 60 * 1000;

      return today - createdDate > monthInMiliseconds;
   }

   const checkOverrides = (order) => {
      if (order?.overrides) {
         return order?.overrides;
      }

      let terminals = props?.accessorialTariffs ? props.accessorialTariffs.find(x => x?._id === order?.item?.accessorialTariff?._id) : [];
      let customer = props?.customers ? props.customers.find(x => x?.code === order?.customerCode) : [];
      let customerTerminal = customer?.accessorialTariffs ? customer.accessorialTariffs.find(x => x?.terminal_id === order?.terminalId) : [];
      let defaultVerbiage = props?.accessorialGuides || [];
      let ownVerbiage = order?.accessorialGuide || [];
      let customerVerbiage = customerTerminal?.accessorialGuide || [];
      let terminalVerbiage = terminals?.accessorialGuide || [];
      let overrideccessorialGuides = [];

      ownVerbiage.map((localAccessorialGuide, i) => {
         let defaultLevel = defaultVerbiage ? defaultVerbiage.find(x => x.name.trim() === localAccessorialGuide?.name.trim()) : [];
         ownVerbiage[i].pdfVerbiageDefaultLevel = defaultLevel?.pdfVerbiage || "";

         let customerLevel = customerVerbiage ? customerVerbiage.find(x => x.name.trim() === localAccessorialGuide?.name.trim()) : [];
         ownVerbiage[i].pdfVerbiageCustomerLevel = customerLevel?.pdfVerbiage || "";

         let terminalLevel = terminalVerbiage ? terminalVerbiage.find(x => x.name.trim() === localAccessorialGuide?.name.trim()) : [];
         ownVerbiage[i].pdfVerbiageTerminalLevel = terminalLevel?.pdfVerbiage || "";
         // check for override
         if (
            localAccessorialGuide?.pdfVerbiage !== localAccessorialGuide?.pdfVerbiageDefaultLevel &&
            localAccessorialGuide?.pdfVerbiage !== localAccessorialGuide?.pdfVerbiageCustomerLevel &&
            localAccessorialGuide?.pdfVerbiage !== localAccessorialGuide?.pdfVerbiageTerminalLevel
         ) {
            overrideccessorialGuides.push(localAccessorialGuide);
         }
      });
      return overrideccessorialGuides.length > 0 ? true : false;
   }

   function getOrders(searching = false, currentOrderQuery = {}) {
      var newOrders = searching ? [] : _.cloneDeep(tableData);
      setTableData([]);

      orderApi
         .getOrders(
            currentOrderQuery
               ? { ...currentOrderQuery, searchQuery: searchQuery, formatForDataGrid: true } //currentOrderQury is there to handle  issues with React Hooks.
               : { ...orderQuery, searchQuery: searchQuery, formatForDataGrid: true },
            page,
            false,
         )
         .then((resp) => {
            newOrders = newOrders.concat(resp.orders);
            newOrders = newOrders.map((order) => ({
               ...order,
               status: checkExpiry(order.add_date) ? "expired" : "active",
               overrides: checkOverrides(order)
            }));

            setTableData(newOrders);
            setGridLoading(false);
         })
         .catch((err) => {
            openAlertMessage(`Error Loading Quotes: ${err}`, "error");
            setGridLoading(false);
         });

      orderApi
         .getOrdersCount(
            currentOrderQuery
               ? { ...currentOrderQuery, searchQuery: searchQuery }
               : { ...orderQuery, searchQuery: searchQuery },
         )
         .then((resp) => {
            setOrderCount(resp.orderCount);
            setGridLoading(false);
         })
         .catch((err) => {
            openAlertMessage(`Error Loading Quotes: ${err}`, "error");
            setGridLoading(false);
         });
   }

   function OpenNewOrder() {
      try {
         history.push("/orders/new/");
      } catch (err) {
         handleError(err);
      }
   }

   function openAlertMessage(alertMessage, alertType) {
      try {
         setShowAlert(true);
         setAlertMessage(alertMessage);
         setAlertType(alertType);

         if (alertType === "success" || alertType === "warning") {
            setTimeout(function () {
               closeAlertMessage();
            }, 3000);
         }
      } catch (err) {
         handleError(err);
      }
   }

   function closeAlertMessage() {
      try {
         setShowAlert(false);
      } catch (err) {
         handleError(err);
      }
   }

   //Get a list of posible order statuses
   function getStatusList() {
      try {
         applicationApi
            .getLookup("ORDERSTATUS")
            .then((resp) => {
               if (resp.lookups !== "undefined") {
                  setOrderStatuses(resp.lookups[0].value.statuses[0]);
               }
            })
            .catch((err) => {
               openAlertMessage(`Error Statuses: ${err}`, "error");
            });
      } catch (err) {
         handleError(err);
      }
   }

   function onDestinationChange(event, values) {
      try {
         //need to check to see how many array items were returned in google api results
         var destination = {
            city: null,
            state: null,
            zip: null,
            place_id: null,
         };

         if (values) {
            //input defaults
            destination.city = values.terms[0].value;

            if (
               values.terms[1].value.length > 2 &&
               values.terms[1].value.toUpperCase() !== "USA" &&
               values.terms[1].value.toUpperCase() !== "US"
            ) {
               //See if terms[1] is state or space in city name.
               let state = _.filter(stateList, (x) => x.name.toUpperCase() === values.terms[1].value.toUpperCase());
               let abbreviation = _.filter(
                  stateList,
                  (x) => x.abbreviation.toUpperCase() === values.terms[1].value.toUpperCase(),
               );

               if (state.length > 0 || abbreviation.length > 0) {
                  if (state.length > 0) {
                     destination.state = state[0].abbreviation;

                     if (
                        values.terms[2].value.toUpperCase() !== "USA" &&
                        values.terms[2].value.toUpperCase() !== "US"
                     ) {
                        destination.zip = values.terms[2].value;
                     } else if (values.terms.length >= 4) {
                        if (
                           values.terms[3].value.toUpperCase() !== "USA" &&
                           values.terms[3].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[3].value;
                        }
                     }
                  } else {
                     destination.state = abbreviation[0].abbreviation;

                     if (
                        values.terms[2].value.toUpperCase() !== "USA" &&
                        values.terms[2].value.toUpperCase() !== "US"
                     ) {
                        destination.zip = values.terms[2].value;
                     } else if (values.terms.length >= 4) {
                        if (
                           values.terms[3].value.toUpperCase() !== "USA" &&
                           values.terms[3].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[3].value;
                        }
                     }
                  }
               } else {
                  destination.city += " " + values.terms[1].value;

                  if (values.terms[2].value.length > 2) {
                     destination.city += " " + values.terms[2].value;

                     destination.state = values.terms[3].value;

                     if (values.terms.length >= 5) {
                        if (
                           values.terms[4].value.toUpperCase() !== "USA" &&
                           values.terms[4].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[4].value;
                        }
                     }
                  } else {
                     destination.state = values.terms[2].value;

                     if (values.terms.length >= 5) {
                        destination.zip = values.terms[3].value;
                     }
                  }
               }
            } else {
               if (values.terms[1].value.toUpperCase() !== "USA" && values.terms[1].value.toUpperCase() !== "US") {
                  destination.state = values.terms[1].value;
               }

               //if searched by zip, then it will appear with 4 items in array, last one always being country
               if (values.terms.length >= 4) {
                  destination.zip = values.terms[2].value;
               }
            }

            destination.place_id = values.place_id;
         }

         setFilterOrderQuery({ ...filterOrderQuery, destination: destination });
      } catch (err) {
         handleError(err);
      }
   }

   //triggers when export to csv is clicked, returns true if successful to tell the csv process to continue
   function generateCSVData() {
      try {
         return new Promise((resolve, reject) => {
            var newOrderArr = [];

            tableData.map((x) => {
               newOrderArr.push({
                  orderNumber: x.orderNumber,
                  dateQuoted: dateFormatter(x.add_date),
                  quotedBy: `${x.firstName} ${x.lastName}`,
                  validFrom: x?.validFrom ? dateFormatter(x.validFrom) : dateFormatter(x.add_date),
                  validTill: dateFormatter(x.expire_date),
                  email: x.email,
                  customer: x.customerName,
                  terminal: x.terminalName,
                  destination: x.destination,
                  rate_total: x.rate,
                  fsc: fscFormatter(x?.fsc?.tariffInfo?.ppr, x?.fsc?.tariffInfo?.cpm),
                  isOverride: checkOverrides(x),
               });
            });

            setCsvOrders(newOrderArr);
            resolve(true);
         });
      } catch (err) {
         handleError(err);
      }
   }

   function breadcrumbOnClick(path) {
      try {
         if (path) {
            history.push(path);
         }
      } catch (err) {
         handleError(err);
      }
   }

   function handleDelete(key = "") {
      try {
         let newOrderQuery = { ...orderQuery };

         newOrderQuery[key] = null;

         setFilterOrderQuery(newOrderQuery);
         setOrderQuery(newOrderQuery);
         setDataGridPage(0);
         setGridLoading(true);
         setPage(0);
         setOrderCount(0);
         setTableData([]);
         getOrders(true, newOrderQuery);
      } catch (err) {
         handleError(err);
      }
   }

   function GetChipLabel(key = "") {
      try {
         switch (key) {
            case "destination":
               return "DESTINATION";
            case "customer_id":
               return "CUSTOMER";
            case "user_id":
               return "USER";
            case "terminal_id":
               return "TERMINAL";
            case "startDate":
               return "START DATE";
            case "endDate":
               return "END DATE";
            case "minRate":
               return "MIN RATE";
            case "maxRate":
               return "MAX RATE";
            case "status":
               return "STATUS";
            case "lane_type":
               return "LANE TYPE";
            case "minMiles":
               return "MIN MILES";
            case "maxMiles":
               return "MAX MILES";
            case "internalExternal":
               return "INTERNAL | EXTERNAL";
            case "archived":
               return "IS ARCHIVED ?"
         }
      } catch (err) {
         handleError(err);
      }
   }

   function handlePageClick(page) {
      try {
         setDataGridPage(page);

         if ((page + 1) * 100 > tableData.length && (page + 1) * 100 < orderCount) {
            setGridLoading(true);
            setPage(+page + 1);
            setOrderCount(0);
            getOrders();
         }
      } catch (err) {
         handleError(err);
      }
   }

   function handleSearchClick() {
      try {
         setDataGridPage(0);
         setGridLoading(true);
         setPage(0);
         setOrderCount(0);
         setTableData([]);
         getOrders(true);
      } catch (err) {
         handleError(err);
      }
   }

   function handleApplyClick(tempFilterOrderQuery) {
      try {
         setOpenDrawer(false);
         setDataGridPage(0);
         setGridLoading(true);
         setPage(0);
         setOrderCount(0);
         setTableData([]);
         setOrderQuery(tempFilterOrderQuery || { ...filterOrderQuery });
         getOrders(true, tempFilterOrderQuery || { ...filterOrderQuery });
      } catch (err) {
         handleError(err);
      }
   }

   function clearFilters() {
      try {
         setOpenDrawer(false);

         setFilterOrderQuery({
            destination: null,
            order_id: null,
            customer_id: null,
            user_id: null,
            terminal_id: null,
            rate_id: null,
            add_date: null,
            startDate: null,
            endDate: null,
            minRate: null,
            maxRate: null,
            status: null,
            lane_type: null,
            minMiles: null,
            maxMiles: null,
            internalExternal: null,
         });

         setOrderQuery({
            ...orderQuery,
            destination: null,
            order_id: null,
            customer_id: null,
            user_id: null,
            terminal_id: null,
            rate_id: null,
            add_date: null,
            startDate: null,
            endDate: null,
            minRate: null,
            maxRate: null,
            status: null,
            lane_type: null,
            minMiles: null,
            maxMiles: null,
            internalExternal: null,
         });

         setDataGridPage(0);
         setGridLoading(true);
         setPage(0);
         setOrderCount(0);
         setTableData([]);
         getOrders(true, {
            ...orderQuery,
            destination: null,
            order_id: null,
            customer_id: null,
            user_id: null,
            terminal_id: null,
            rate_id: null,
            add_date: null,
            startDate: null,
            endDate: null,
            minRate: null,
            maxRate: null,
            status: null,
            lane_type: null,
            minMiles: null,
            maxMiles: null,
            internalExternal: null,
         });
      } catch (err) {
         handleError(err);
      }
   }

   const inputProps = {
      step: 1.0,
   };

   const getTableData = (tableData) => {
      if (!tableData) {
         return null;
      }
      if (props.user.security.admin) {
         return tableData;
      }
      return tableData.filter(x => x.archived === false);

   }

   const onClickCheckBox = (params, event) => {
      if (props.user.security.admin) {
         setTableData(tableData.map(item =>
            item?._id === params.row._id ?
               { ...item, archived: event.target.checked } : item
         ));

         orderApi
            .setArchived({
               _id: params.row._id,
               archived: event.target.checked
            })
      }
   };

   return (
      <>
         <div id={"ordersView"} className={classes.layoutMain}>
            <div id={"ordersViewHeader"} className={classes.secondaryHeader}>
               <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Grid item xs={2}>
                     <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                        <Link
                           color="inherit"
                           style={{ cursor: "pointer" }}
                           onClick={() => breadcrumbOnClick("/orders")}
                        >
                           <h3 style={{ fontWeight: "500" }}>{"Quotes"}</h3>
                        </Link>
                     </Breadcrumbs>
                  </Grid>
                  <Grid item xs={4} container alignItems="center" justifyContent="flex-end">
                     <Button
                        variant="contained"
                        color="primary"
                        onClick={() => OpenNewOrder()}
                        style={{
                           marginRight: "1rem",
                        }}
                        disabled={readOnly}
                     >
                        <AddIcon />
                        New Quote
                     </Button>
                     <CSVLink
                        asyncOnClick={true}
                        onClick={(event, done) => {
                           generateCSVData()
                              .then(() => {
                                 done(true);
                              })
                              .catch((err) => {
                                 alert("CSV Link error: " + err);
                                 done(false);
                              });
                        }}
                        style={{
                           textDecoration: "none",
                        }}
                        data={csvOrders ? csvOrders : []}
                        headers={headerCSV}
                        filename={Date.now() + "_quotes.csv"}
                     >
                        <Button variant="contained" color="primary" disabled={gridLoading}>
                           <GetApp />
                           Export to CSV
                        </Button>
                     </CSVLink>
                  </Grid>
                  <Grid item xs={3}>
                     <TextField
                        style={{
                           width: "100%",
                           backgroundColor: "white",
                        }}
                        id="tf-search"
                        placeholder="Search"
                        variant="outlined"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <SearchIcon color="primary" />
                              </InputAdornment>
                           ),
                        }}
                        onChange={(event) => setSearchQuery(event.target.value ? event.target.value : null)}
                     />
                  </Grid>
                  <Grid item xs={3} container direction="row" alignItems="center" justifyContent="flex-start">
                     <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSearchClick()}
                        disabled={gridLoading}
                     >
                        Search
                     </Button>
                     <Divider orientation="vertical" className={classes.divider} />
                     <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenDrawer(!openDrawer)}
                        disabled={gridLoading}
                     >
                        <FilterListIcon />
                        Filters
                     </Button>
                  </Grid>
               </Grid>
               <Drawer
                  anchor={"right"}
                  open={openDrawer}
                  onClose={() => setOpenDrawer(false)}
                  onOpen={() => setOpenDrawer(true)}
                  PaperProps={{ square: false }}
               >
                  <div style={{ backgroundColor: "#002D72" }}>
                     <Grid container direction="row">
                        <Grid item xs={6} container direction="row" alignItems="center" justifyContent="flex-start">
                           <h3 style={{ marginLeft: "1rem", fontWeight: "700", color: "white" }}>Filters Menu</h3>
                        </Grid>
                        <Grid item xs={6} container direction="row" alignItems="center" justifyContent="flex-end">
                           <Button
                              variant="text"
                              style={{ marginRight: ".5rem", color: "white" }}
                              onClick={() => clearFilters()}
                           >
                              Clear Filters
                           </Button>
                        </Grid>
                     </Grid>
                  </div>
                  <List component="filters" className={classes.listDrawer}>
                     <ListItem style={{ height: "1rem" }}>
                        <h5 style={{ color: "#002D72" }}>Terminal</h5>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <Autocomplete
                           id="combo-box-terminal"
                           options={_.filter(props.terminals, (x) => x.active === true)}
                           value={_.find(props.terminals, (x) => x._id === filterOrderQuery.terminal_id)}
                           getOptionLabel={(option) => option.name}
                           onChange={(event, value) =>
                              setFilterOrderQuery({ ...filterOrderQuery, terminal_id: value ? value._id : null })
                           }
                           renderInput={(params) => (
                              <TextField {...params} label="Select Terminal" variant="outlined" />
                           )}
                        />
                     </ListItem>
                     <Divider />
                     <ListItem style={{ height: "2rem" }}>
                        <h5 style={{ color: "#002D72" }}>Customer</h5>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <Autocomplete
                           id="combo-box-customer"
                           options={props.customers}
                           value={_.find(props.customers, (x) => x._id === filterOrderQuery.customer_id)}
                           onChange={(event, value) =>
                              setFilterOrderQuery({ ...filterOrderQuery, customer_id: value ? value._id : null })
                           }
                           getOptionLabel={(option) => option.name}
                           renderInput={(params) => (
                              <TextField {...params} label="Select Customer" variant="outlined" />
                           )}
                        />
                     </ListItem>
                     <Divider />
                     <ListItem style={{ height: "2rem" }}>
                        <h5 style={{ color: "#002D72" }}>Lane Type</h5>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <Autocomplete
                           id="combo-box-laneType"
                           options={laneTypes}
                           value={filterOrderQuery.lane_type}
                           onChange={(event, value) =>
                              setFilterOrderQuery({ ...filterOrderQuery, lane_type: value ? value : "" })
                           }
                           getOptionLabel={(option) => option}
                           renderInput={(params) => (
                              <TextField {...params} label="Select Lane Type" variant="outlined" />
                           )}
                        />
                     </ListItem>
                     <Divider />
                     <ListItem style={{ height: "2rem" }}>
                        <h5 style={{ color: "#002D72" }}>Destination</h5>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <PlaceSearch
                           destination={
                              filterOrderQuery.destination &&
                                 filterOrderQuery.destination.city &&
                                 filterOrderQuery.destination.state
                                 ? `${filterOrderQuery.destination.city}, ${filterOrderQuery.destination.state}`
                                 : null
                           }
                           onChange={(event, values) => onDestinationChange(event, values)}
                        />
                     </ListItem>
                     <Divider />
                     <ListItem style={{ height: "2rem" }}>
                        <h5 style={{ color: "#002D72" }}>Status</h5>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <Autocomplete
                           id="combo-box-status"
                           options={[{ status: "ACTIVE" }, { status: "EXPIRED" }]}
                           onChange={(e, value) =>
                              setFilterOrderQuery({ ...filterOrderQuery, status: value ? value.status : "" })
                           }
                           value={filterOrderQuery.status}
                           getOptionLabel={(option) => {
                              return option.status ? option.status : option;
                           }}
                           renderInput={(params) => <TextField {...params} label="Status" variant="outlined" />}
                        />
                     </ListItem>
                     <Divider />
                     <ListItem style={{ height: "2rem" }}>
                        <h5 style={{ color: "#002D72" }}>Date Range</h5>
                     </ListItem>
                     <ListItem>
                        <TextField
                           id="startDate"
                           label="Start Date"
                           type="date"
                           onChange={(event) =>
                              setFilterOrderQuery({
                                 ...filterOrderQuery,
                                 startDate: event.target.value ? event.target.value : null,
                              })
                           }
                           value={filterOrderQuery.startDate}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           variant="outlined"
                           style={{ marginRight: "1rem" }}
                        />
                        <TextField
                           id="endDate"
                           label="End Date"
                           type="date"
                           onChange={(event) =>
                              setFilterOrderQuery({
                                 ...filterOrderQuery,
                                 endDate: event.target.value ? event.target.value : null,
                              })
                           }
                           value={filterOrderQuery.endDate}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           variant="outlined"
                        />
                     </ListItem>
                     <Divider />
                     <ListItem style={{ height: "2rem" }}>
                        <h5 style={{ color: "#002D72" }}>Rate Range</h5>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
                           <TextField
                              style={{ width: "47.5%" }}
                              type="number"
                              variant="outlined"
                              id="MinRate"
                              value={filterOrderQuery.minRate}
                              onChange={(event) =>
                                 setFilterOrderQuery({
                                    ...filterOrderQuery,
                                    minRate: event.target.value ? event.target.value : null,
                                 })
                              }
                              placeholder="Min Rate"
                              inputProps={inputProps}
                              InputProps={{
                                 startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                           />
                           <TextField
                              style={{ width: "47.5%" }}
                              type="number"
                              variant="outlined"
                              id="MaxRate"
                              placeholder="Max Rate"
                              value={filterOrderQuery.maxRate}
                              inputProps={inputProps}
                              InputProps={{
                                 startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              onChange={(event) =>
                                 setFilterOrderQuery({
                                    ...filterOrderQuery,
                                    maxRate: event.target.value ? event.target.value : null,
                                 })
                              }
                           />
                        </Grid>
                     </ListItem>
                     <Divider />
                     <ListItem style={{ height: "2rem" }}>
                        <h5 style={{ color: "#002D72" }}>Miles Range</h5>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
                           <TextField
                              style={{ width: "47.5%" }}
                              type="number"
                              variant="outlined"
                              id="MinMiles"
                              value={filterOrderQuery.minMiles}
                              onChange={(event) =>
                                 setFilterOrderQuery({
                                    ...filterOrderQuery,
                                    minMiles: event.target.value ? event.target.value : null,
                                 })
                              }
                              placeholder="Min Miles"
                              inputProps={inputProps}
                              InputProps={{
                                 endAdornment: <InputAdornment position="end">mi.</InputAdornment>,
                              }}
                           />
                           <TextField
                              style={{ width: "47.5%" }}
                              type="number"
                              variant="outlined"
                              id="MaxMiles"
                              placeholder="Max Miles"
                              value={filterOrderQuery.maxMiles}
                              inputProps={inputProps}
                              InputProps={{
                                 endAdornment: <InputAdornment position="end">mi.</InputAdornment>,
                              }}
                              onChange={(event) =>
                                 setFilterOrderQuery({
                                    ...filterOrderQuery,
                                    maxMiles: event.target.value ? event.target.value : null,
                                 })
                              }
                           />
                        </Grid>
                     </ListItem>
                     <Divider />
                     <ListItem style={{ height: "2rem" }}>
                        <h5 style={{ color: "#002D72" }}>Internal vs External</h5>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <Autocomplete
                           id="combo-box-internalExternal"
                           options={internalExternal}
                           value={filterOrderQuery.internalExternal}
                           onChange={(event, value) =>
                              setFilterOrderQuery({ ...filterOrderQuery, internalExternal: value ? value : "" })
                           }
                           getOptionLabel={(option) => option}
                           renderInput={(params) => (
                              <TextField {...params} label="Internal | External" variant="outlined" />
                           )}
                        />
                     </ListItem>
                     <Divider />
                     <ListItem style={{ height: "2rem" }}>
                        <h5 style={{ color: "#002D72" }}>User</h5>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <Autocomplete
                           id="combo-box-user"
                           options={props.users}
                           getOptionLabel={(option) => option.firstname + " " + option.lastname}
                           value={_.find(props.users, (x) => x._id === filterOrderQuery.user_id)}
                           onChange={(event, value) =>
                              setFilterOrderQuery({ ...filterOrderQuery, user_id: value ? value._id : null })
                           }
                           renderInput={(params) => <TextField {...params} label="Quoted User" variant="outlined" />}
                        />
                     </ListItem>
                     {props.user.security.admin ? <>
                        <Divider /><ListItem style={{ display: "list-item" }}>

                           <FormControlLabel
                              control={<Checkbox
                                 checked={
                                    filterOrderQuery.archived
                                 }
                                 onChange={(event) =>
                                    setFilterOrderQuery({ ...filterOrderQuery, archived: event.target.checked })
                                 }
                              />}
                              label="Is Archived ?"
                              labelPlacement="end"

                           // className={classes.MuiFormControlLabel}
                           /></ListItem></> : null}
                     <Divider />
                     <ListItem style={{ justifyContent: "flex-end" }}>
                        <Button
                           variant="text"
                           color="primary"
                           onClick={() => clearFilters()}
                           style={{ marginRight: "1rem" }}
                        >
                           Clear Filters
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => handleApplyClick()}>
                           Apply
                        </Button>
                     </ListItem>
                     <Divider light />
                  </List>
               </Drawer>
               <Collapse in={showAlert}>
                  <Alert
                     style={{ color: "#FFFFFF" }}
                     variant="filled"
                     severity={alertType ? alertType : "success"}
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => closeAlertMessage()}
                           style={{ marginBottom: ".25rem" }}
                        >
                           <CloseIcon />
                        </IconButton>
                     }
                  >
                     {alertMessage}
                  </Alert>
               </Collapse>
            </div>
            <div id={"ordersViewBody"} className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}>
               <Paper elevation={3}>
                  {Object.values(orderQuery).some((x) => x !== null && x !== "") === true ? (
                     <>
                        <Grid container direction="row" spacing={1}>
                           <Grid item xs={2} container alignItems="center" justifyContent="center">
                              <h4 style={{ fontWeight: "700", color: "#002D72" }}>Applied Filters:</h4>
                           </Grid>
                           <Grid item xs={10} container alignItems="center" justifyContent="flex-start">
                              {Object.keys(orderQuery).map(function (key) {
                                 if (!orderQuery[key]) {
                                    return null;
                                 }

                                 return (
                                    <Chip
                                       variant="outlined"
                                       color="primary"
                                       onDelete={() => handleDelete(key)}
                                       label={GetChipLabel(key)}
                                       style={{ borderRadius: "5px", fontWeight: 700, margin: ".5rem" }}
                                       deleteIcon={<CancelIcon className={classes.chipIcon} />}
                                    />
                                 );
                              })}
                           </Grid>
                        </Grid>
                        <Divider style={{ color: "#F1F1F1", height: "2px" }} />
                     </>
                  ) : (
                     <></>
                  )}
                  <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
                     <Grid item xs={12}>
                        <StyledDataGrid
                           gridHeight={gridHeight}
                           loading={gridLoading}
                           page={dataGridPage}
                           onPageChange={(newPage) => handlePageClick(newPage)}
                           columns={
                              props.user.security.admin ?
                                 tableHeaderAdmin : tableHeader
                           }
                           rows={getTableData(tableData)}
                           rowCount={orderCount ? orderCount : tableData.length}
                           sortModel={sortModel}
                           onSortModelChange={(model) => setSortModel(model)}
                        ></StyledDataGrid>
                     </Grid>
                  </Grid>
               </Paper>
            </div>
         </div>
      </>
   );
};

function mapStateToProps(state, ownProps) {
   return {
      terminals: state.terminal.terminals || [],
      user: state.user.currentUser,
      users: state.user.internalUsers || [],
      customers: state.customer !== null ? state.customer.customer : [],
      accessorialGuides: state.accessorialGuide.accessorialGuide || [],
      accessorialTariffs: state.accessorial.tariff || [],
   };
}

OrdersView.propTypes = {
   accessorialTariffs: PropTypes.array.isRequired,
   accessorialGuides: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   customers: PropTypes.array.isRequired,
   terminals: PropTypes.array.isRequired,
   users: PropTypes.array.isRequired,
};

OrdersView.defaultProps = {
   accessorialTariffs: [],
   accessorialGuides: [],
   user: {},
   customers: [],
   terminals: [],
   users: [],
};

export default connect(mapStateToProps)(OrdersView);
