const { DateTime } = require("luxon");

/**
 * Definition: This function formats time values coming from the api.
 *
 * Example:
 * timeFormatter("2022-03-14T14:26:49.671Z")
 *
 * Should Return:
 * "12:00PM"
 *
 */

export const timeFormatter = (date = "") => {
   try {
      var luxonDate = DateTime.fromISO(date).setZone("America/New_York").toISO();

      const newDate = new Date(luxonDate ? luxonDate : date);
      const hour = new Intl.DateTimeFormat("en", { hour: "2-digit" }).format(newDate).substring(0, 2);
      const minute = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(newDate).padStart(2, "0");
      const AMPM = new Intl.DateTimeFormat("en", { hour: "2-digit" }).format(newDate).substring(2, 5);
      return `${hour}:${minute}${AMPM}`;
   } catch (error) {
      return "12:00 AM";
   }
};
