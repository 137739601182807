export default {
   CREATE_USER: "CREATE_USER",
   LOAD_USERS: "LOAD_USERS",
   SAVE_USER: "SAVE_USER",
   LOGOUT: "LOGOUT",
   ADD_SOCKET: "ADD_SOCKET",
   LOAD_USER_SUCCESS: "LOAD_USERS_SUCCESS",
   LOGIN_USER: "LOGIN_USER",
   SUBMIT_NEW_USER: "SUBMIT_NEW_USER",
   GET_TERMINALS: "GET_TERMINALS",
   GET_CUSTOMERS: "GET_CUSTOMERS",
   GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
   GET_UNREAD_NOTIFICATIONS: "GET_UNREAD_NOTIFICATIONS",
   REMOVE_NOTIFICATIONS: "REMOVE_NOTIFICATIONS",
   GET_FUEL_TARIFFS: "GET_FUEL_TARIFFS",
   UPDATE_FUEL_TARIFF: "UPDATE_FUEL_TARIFF",
   GET_ACCESSORIAL_TARIFFS: "GET_ACCESSORIAL_TARIFFS",
   UPDATE_ACCESSORIAL_TARIFF: "UPDATE_ACCESSORIAL_TARIFF",
   GET_ACCESSORIAL_GUIDES: "GET_ACCESSORIAL_GUIDES",
   ENQUEUE_SNACKBAR: "ENQUEUE_SNACKBAR",
   CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
   REMOVE_SNACKBAR: "REMOVE_SNACKBAR",
   SET_AVATAR: "SET_AVATAR",
   SET_SEARCH_QUERY: "SET_SEARCH_QUERY",
};
