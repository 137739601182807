//Function that will get the accessorial tariff based off of heirarchy
var _ = require("lodash");

//this function gets the full fuel tariff of every rate. Primarily used for rate search.
export const getAccessorialTariffs = async (
   rates,
   accessorialTariffs = [],
   customers = [],
   selectedCustomer,
   terminals = [],
) => {
   for (const rateArray in rates) {
      for (const rate in rates[rateArray]) {
         rates[rateArray][rate].accessorialTariff = {};
         rates[rateArray][rate].accessorialTariff = await getAccessorialTariff(
            rates[rateArray][rate],
            accessorialTariffs,
            customers,
            selectedCustomer,
            terminals,
         );
      }
   }

   return rates;
};

export const getAccessorialTariff = async (
   rate,
   accessorialTariffs = [],
   customers = [],
   selectedCustomer,
   terminals = [],
) => {
   var tariff = {};
   var tariffList = _.cloneDeep(accessorialTariffs);
   var rateCustID = rate.customer_id ? rate.customer_id : selectedCustomer._id;

   //get customer object based on customer_id from rate
   var cust = _.filter(customers, (t) => t._id === rateCustID);
   var term = _.filter(terminals, (t) => t._id === rate.terminal_id);

   const accessorialGuideFromCustomer = cust[0].accessorialTariffs?.find(
      ({ terminal_id }) => terminal_id === term[0]._id,
   );

   //get tariff based on default tariff ID from the terminal object
   var termTariff = _.filter(tariffList, (t) => t._id === term[0].accessorialTariff_id);

   if (termTariff.length > 0) {
      //replace accessorialGuide values if there is a specific one in customer, otherwise keep terminal values
      termTariff[0].accessorialGuide = termTariff[0].accessorialGuide.map((custT) =>
         accessorialGuideFromCustomer?.accessorialGuide.find(({ name }) => name === custT.name)
            ? accessorialGuideFromCustomer?.accessorialGuide.find(({ name }) => name === custT.name)
            : custT,
      );

      tariff = termTariff[0];

      return tariff;
   }

   return {};
};
