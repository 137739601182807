import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// Material
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SaveIcon from "@mui/icons-material/Save";
import { AppBar, Box, Button, Grid, IconButton, Tab, Tabs } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// styles
import containerLogo from "../../Logo/ContainerPort-Logo-white.png";
import "./messageManagementEdit.css";
// Services
import { getAdminMessage, getUsersByMessageID, setAdminMessage } from "../../../api/adminMessageApi";
// Constants
import {
   currentDate,
   DAYS_OF_THE_WEEK,
   FRECUENCY,
   maxDate,
   maxDateMonth,
   minDate,
   minDateMonth,
   MONTHLY,
   // eslint-disable-next-line prettier/prettier
   YEARLY,
} from "../constants";

// Components
import MessageActivity from "./MessageActivity";
import MessageReaders, { SearchInput } from "./MessageReaders";

const montlySx = {
   "& .MuiCalendarPicker-root": {
      "& .PrivatePickersFadeTransitionGroup-root div": {
         color: "#FFFFFF",
      },
      "& .MuiCalendarPicker-viewTransitionContainer": {
         "& .MuiTypography-caption": {
            color: "white",
            height: 0,
            display: "none",
         },
      },
   },
};

const initialStateAlert = {
   open: false,
   message: "",
   severity: "success",
};

const initialState = {
   message: "",
   internal: false,
   external: false,
   schedule: "",
   message_date: "",
   dayOfTheMonth: null,
   dateOfYear: null,
   active: true,
};

const initialStateDaysOfWeek = {
   sunday: false,
   monday: false,
   tuesday: false,
   wednesday: false,
   thursday: false,
   friday: false,
   saturday: false,
};

function reducer(state, action) {
   switch (action.type) {
      case "message":
         return { ...state, message: action.payload };
      case "internal":
         return { ...state, internal: action.payload };
      case "external":
         return { ...state, external: action.payload };
      case "active":
         return { ...state, active: action.payload };
      case "schedule":
         return { ...state, schedule: action.payload };
      case "dayOfTheMonth":
         return { ...state, dayOfTheMonth: action.payload };
      case "dateOfYear":
         return { ...state, dateOfYear: action.payload };
      case "reset":
         return { ...initialState };
      case "all":
         return { ...initialState, ...action.payload };
      default:
         throw new Error("Error in reducer");
   }
}

function reducerDayOfWeek(state, action) {
   switch (action.type) {
      case "monday":
         return { ...state, monday: action.payload };
      case "tuesday":
         return { ...state, tuesday: action.payload };
      case "wednesday":
         return { ...state, wednesday: action.payload };
      case "thursday":
         return { ...state, thursday: action.payload };
      case "friday":
         return { ...state, friday: action.payload };
      case "saturday":
         return { ...state, saturday: action.payload };
      case "sunday":
         return { ...state, sunday: action.payload };
      case "reset":
         return { ...initialStateDaysOfWeek };
      case "all":
         return { ...action.payload };
      default:
         throw new Error();
   }
}

export function MessageManagementEdit() {
   const { id } = useParams();
   const history = useHistory();
   const currentUser = useSelector((state) => state.user.currentUser);
   const [state, dispatch] = useReducer(reducer, initialState);
   const [daysOfWeek, dispatchDaysOfWeek] = useReducer(reducerDayOfWeek, initialStateDaysOfWeek);
   const [isLoading, setIsLoading] = useState(true);
   const [readOnly, setReadOnly] = useState(false);
   const [currentMessage, setCurrentMessage] = useState({});
   const [usersData, setUsersData] = useState([]);
   const [usersDataFiltered, setUsersDataFiltered] = useState([]);
   const [isWeeklySelected, setIsWeeklySelected] = useState(false);
   const [typeOfDatePicker, setTypeOfDatePicker] = useState("");
   const [submit, setSubmit] = useState(false);
   const [isLoadingProccess, setIsLoadingProccess] = useState(false);

   const [tabValue, setTabValue] = useState(0);
   const [alertMessage, setAlertMessage] = useState(initialStateAlert);

   /**
    * Call service Update message
    */
   const updateMessage = async (newMessage) => {
      setIsLoadingProccess(true);
      try {
         //Remove this because the payload can get too large. It will be properly saved in the API.
         newMessage.usersRead = [];
         await setAdminMessage(newMessage);
         showSuccessAlert("Message updated successfully");
      } catch (error) {
         showErrorAlert(error.message);
      } finally {
         setIsLoadingProccess(false);
      }
      setSubmit(false);
      // dispatch({ type: "reset" });
      // dispatchDaysOfWeek({ type: "reset" });
   };

   /**
    * Call service Get message
    */
   const getMessage = async () => {
      try {
         const resp = await getAdminMessage(`adminMessageID=${id}`);

         if (!resp) {
            showErrorAlert("Error"); //TODO: improve the text of this error
            return;
         }

         if (resp.length) {
            const message = resp[0];

            if (message.scheduleDays?.length) {
               const scheduleDays = parseDaysNumber(message.scheduleDays);
               dispatchDaysOfWeek({ type: "all", payload: scheduleDays });
               setIsWeeklySelected(true);
            }
            if (message.schedule === YEARLY) {
               const dateSplit = message.message_date.split("/");
               setTypeOfDatePicker(message.schedule);
               message.dateOfYear = new Date(new Date().getFullYear(), +dateSplit[0], +dateSplit[1]); // virtual option
            }
            if (message.schedule === MONTHLY) {
               setTypeOfDatePicker(message.schedule);
               message.dayOfTheMonth = new Date(new Date().getFullYear(), 9, +message.message_date); // virtual option
            }
            setCurrentMessage(message);
            dispatch({ type: "all", payload: message });
         }
         setIsLoading(false);
      } catch (error) {
         showErrorAlert(error.message);
      }
   };

   /**
    * Call service Get users by messageID
    */
   const getUsers = async (id) => {
      try {
         const resp = await getUsersByMessageID(id);
         setUsersData(resp);
         setUsersDataFiltered(resp);
      } catch (error) {
         showErrorAlert(error.message);
      }
   };

   const handleSaveButton = async () => {
      if (isLoadingProccess) return;
      const { internal, external, message, schedule, dayOfTheMonth, dateOfYear } = state;
      const checkboxError = [internal, external].filter((v) => v).length < 1;
      const messageError = !message?.trim();
      const scheduleError = !schedule;
      let daysOfWeekError = false;
      if (schedule === DAYS_OF_THE_WEEK) {
         daysOfWeekError = Object.values(daysOfWeek).filter((v) => v).length < 1;
      }
      if (schedule === MONTHLY && !dayOfTheMonth) {
         monthlyError = true;
      }
      if (schedule === YEARLY && !dateOfYear) {
         yearlyError = true;
      }
      if (checkboxError || messageError || scheduleError || daysOfWeekError || monthlyError || yearlyError) {
         setSubmit(true);
         return;
      }

      // Fill days of the week
      const scheduleDays = [];
      if (schedule === DAYS_OF_THE_WEEK) {
         Object.keys(daysOfWeek).forEach((day, index) => {
            if (daysOfWeek[day]) {
               scheduleDays.push(index);
            }
         });
      }

      const newMessage = { ...currentMessage, ...state, scheduleDays };

      if (schedule === MONTHLY) newMessage.message_date = dayOfTheMonth.getDate() + "";
      else if (schedule === YEARLY) newMessage.message_date = dateOfYear.getMonth() + "/" + dateOfYear.getDate();
      else newMessage.message_date = "";

      delete newMessage.dayOfTheMonth;
      delete newMessage.dateOfYear;

      updateMessage(newMessage);
   };

   const handleTabsChange = (_, tabNumber) => {
      setTabValue(tabNumber);
   };

   const handleChange = (event) => {
      let value = event.target.value;
      if (event.target.name === "internal" || event.target.name === "external" || event.target.name === "active") {
         value = event.target.checked;
      }
      if (event.target.name === "schedule") {
         switch (value) {
            case DAYS_OF_THE_WEEK:
               setIsWeeklySelected(true);
               setTypeOfDatePicker("");
               break;
            case MONTHLY:
               setTypeOfDatePicker(MONTHLY);
               setIsWeeklySelected(false);
               break;
            case YEARLY:
               setTypeOfDatePicker(YEARLY);
               setIsWeeklySelected(false);
               break;
            default:
               setTypeOfDatePicker("");
               setIsWeeklySelected(false);
               break;
         }
      }
      dispatch({ type: event.target.name, payload: value });
   };

   const handleChangeDayOfWeek = (event) => {
      dispatchDaysOfWeek({ type: event.target.name, payload: event.target.checked });
   };

   const showErrorAlert = (error = "Error") => {
      setAlertMessage({
         severity: "error",
         message: error,
         open: true,
      });
      handleCloseAlert();
   };

   const showSuccessAlert = (message = "Success") => {
      setAlertMessage({
         severity: "success",
         message: message,
         open: true,
      });
      handleCloseAlert();
   };

   const handleCloseAlert = () => {
      setTimeout(() => {
         setAlertMessage((prev) => ({ ...prev, open: false }));
      }, 3000);
   };

   const handleBreadcrumb = (path) => {
      path && history.push(path);
   };

   // Parse day of the week from number to string
   const parseDaysNumber = (days = []) => {
      const scheduleDays = {};
      Object.keys(initialStateDaysOfWeek).forEach((key, i) => {
         scheduleDays[key] = days.includes(i);
      });
      return scheduleDays;
   };

   const filterReaders = (text) => {
      setUsersDataFiltered(
         usersData.filter((reader) => {
            if (reader.full_name.toLowerCase().includes(text.toLowerCase())) return reader;
            if (reader.email.toLowerCase().includes(text.toLowerCase())) return reader;
         }),
      );
   };

   useEffect(() => {
      if (id) {
         getMessage();
         getUsers(id);
      }
   }, [id]);

   useEffect(() => {
      if (currentUser) {
         if (!currentUser.security.admin) {
            setReadOnly(true);
         }
      }
   }, [currentUser]);

   const { internal, external, message, schedule, dayOfTheMonth, dateOfYear } = state;
   const checkboxError = [internal, external].filter((v) => v).length < 1;
   const messageError = !message?.trim();
   const scheduleError = !schedule;
   let daysOfWeekError = false;
   let monthlyError = false;
   let yearlyError = false;
   if (schedule === DAYS_OF_THE_WEEK) {
      daysOfWeekError = Object.values(daysOfWeek).filter((v) => v).length < 1;
   }
   if (schedule === MONTHLY && !dayOfTheMonth) {
      monthlyError = true;
   }
   if (schedule === YEARLY && !dateOfYear) {
      yearlyError = true;
   }

   return (
      <div className="layoutMain">
         {/* Header */}
         <div className="secondaryHeader">
            <Grid container direction="rows" justifyContent="space-between" alignItems="center">
               <Grid item xs={8}>
                  <Breadcrumbs separator={<NavigateNextIcon />}>
                     <Link
                        color="inherit"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleBreadcrumb(`/adminmessagemanagement`)}
                     >
                        <h3 style={{ fontWeight: "500" }}>Message Management</h3>
                     </Link>
                     <Link
                        color="inherit"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleBreadcrumb(`/adminmessagemanagement/${id}`)}
                     >
                        <h3 style={{ fontWeight: "500" }}>
                           {" "}
                           {isLoading ? "" : `${state.message?.substring(0, 20)}...`}{" "}
                        </h3>
                     </Link>
                  </Breadcrumbs>
               </Grid>
               <Grid item sm={2} lg={1} sx={{ marginLeft: "20px", display: "flex", justifyContent: "end" }}>
                  {!isLoading && !tabValue && (
                     <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveButton}
                        disabled={readOnly || isLoadingProccess}
                     >
                        {isLoadingProccess ? (
                           <CircularProgress size={24} sx={{ color: "#4CAF50" }} />
                        ) : (
                           <SaveIcon style={{ fontSize: "1.5rem", color: "#FFF", marginRight: "0.5rem" }} />
                        )}
                        Save
                     </Button>
                  )}
               </Grid>
            </Grid>
         </div>
         {/* Alert */}
         <Collapse in={alertMessage.open}>
            <Alert
               variant="filled"
               severity={alertMessage.severity}
               sx={{ color: "#FFFFFF" }}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     sx={{ marginBottom: "0.25rem", color: "#FFFFFF" }}
                     onClick={() => setAlertMessage((prev) => ({ ...prev, open: false }))}
                  >
                     <CloseIcon fontSize="inherit" />
                  </IconButton>
               }
            >
               {alertMessage.message}
            </Alert>
         </Collapse>
         {/* Body */}
         {isLoading && (
            <div className="loader-wrapper">
               <div className="truck-wrapper">
                  <div className="truck">
                     <div className="truck-container">
                        <img style={{ width: "65px", paddingLeft: "5px", paddingTop: "15px" }} src={containerLogo} />
                     </div>
                     <div className="glases"></div>
                     <div className="bonet"></div>
                     <div className="base"></div>
                     <div className="base-aux"></div>
                     <div className="wheel-back"></div>
                     <div className="wheel-front"></div>
                     <div className="smoke"></div>
                  </div>
               </div>
            </div>
         )}
         {!isLoading && (
            <Grid container direction="row" justifyContent="space-between">
               <Grid item xs={12}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="center">
                     <Grid item sx={{ width: "100%" }}>
                        <Box p="1%" width="100%">
                           <Paper elevation={3}>
                              <AppBar position="static" color="default" className="app-bar">
                                 <Tabs
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleTabsChange}
                                    value={tabValue}
                                    aria-label="tabs"
                                 >
                                    <Tab className="tab-item" label="Message Details" />
                                    <Tab className="tab-item" label="Activity" />
                                 </Tabs>
                              </AppBar>
                           </Paper>
                           <Paper elevation={3} sx={{ maxHeight: "calc(100vh - 220px)", overflowY: "auto" }}>
                              {tabValue === 0 && (
                                 <>
                                    <Paper elevation={3} className="paper-divisor">
                                       <section className="edit-content">
                                          <div className="edit-content--row space-between-row">
                                             <FormControl
                                                variant="standard"
                                                error={scheduleError && submit}
                                                className="content__select"
                                             >
                                                <InputLabel id="schedule-label">Schedule</InputLabel>
                                                <Select
                                                   labelId="schedule-select"
                                                   name="schedule"
                                                   value={state.schedule}
                                                   onChange={handleChange}
                                                   label="schedule"
                                                >
                                                   <MenuItem value="">
                                                      <em>None</em>
                                                   </MenuItem>
                                                   {FRECUENCY.map((frecuency) => (
                                                      <MenuItem key={frecuency} value={frecuency}>
                                                         {frecuency}
                                                      </MenuItem>
                                                   ))}
                                                </Select>
                                                {scheduleError && submit && (
                                                   <FormHelperText className="error-message">
                                                      Required Field
                                                   </FormHelperText>
                                                )}
                                             </FormControl>
                                             <FormControl error={checkboxError && submit}>
                                                <FormGroup className="edit-content--checkbox">
                                                   <FormControlLabel
                                                      className="edit-content--checkbox-item"
                                                      control={
                                                         <Checkbox
                                                            name="internal"
                                                            checked={state.internal}
                                                            onChange={handleChange}
                                                         />
                                                      }
                                                      label="Internal"
                                                   />
                                                   <FormControlLabel
                                                      className="edit-content--checkbox-item"
                                                      // labelPlacement="start"
                                                      control={
                                                         <Checkbox
                                                            name="external"
                                                            checked={state.external}
                                                            onChange={handleChange}
                                                         />
                                                      }
                                                      label="External"
                                                   />
                                                   <FormControlLabel
                                                      className="edit-content--checkbox-item"
                                                      control={
                                                         <Switch
                                                            name="active"
                                                            checked={state.active}
                                                            onChange={handleChange}
                                                         />
                                                      }
                                                      label="Active"
                                                   />
                                                </FormGroup>
                                                {checkboxError && submit && (
                                                   <FormHelperText className="error-message">
                                                      Required Field
                                                   </FormHelperText>
                                                )}
                                             </FormControl>
                                          </div>
                                          {isWeeklySelected && (
                                             <div className="space-between-row">
                                                <Divider textAlign="left" className="text--divider">
                                                   Choose the days of the week
                                                </Divider>
                                                <FormControl error={daysOfWeekError && submit}>
                                                   <FormGroup className="day-of-the-week">
                                                      <FormControlLabel
                                                         control={
                                                            <Checkbox
                                                               checked={daysOfWeek.monday}
                                                               onChange={handleChangeDayOfWeek}
                                                               name="monday"
                                                            />
                                                         }
                                                         label="Monday"
                                                      />
                                                      <FormControlLabel
                                                         control={
                                                            <Checkbox
                                                               checked={daysOfWeek.tuesday}
                                                               onChange={handleChangeDayOfWeek}
                                                               name="tuesday"
                                                            />
                                                         }
                                                         label="Tuesday"
                                                      />
                                                      <FormControlLabel
                                                         control={
                                                            <Checkbox
                                                               checked={daysOfWeek.wednesday}
                                                               onChange={handleChangeDayOfWeek}
                                                               name="wednesday"
                                                            />
                                                         }
                                                         label="Wednesday"
                                                      />
                                                      <FormControlLabel
                                                         control={
                                                            <Checkbox
                                                               checked={daysOfWeek.thursday}
                                                               onChange={handleChangeDayOfWeek}
                                                               name="thursday"
                                                            />
                                                         }
                                                         label="Thursday"
                                                      />
                                                      <FormControlLabel
                                                         control={
                                                            <Checkbox
                                                               checked={daysOfWeek.friday}
                                                               onChange={handleChangeDayOfWeek}
                                                               name="friday"
                                                            />
                                                         }
                                                         label="Friday"
                                                      />
                                                      <FormControlLabel
                                                         control={
                                                            <Checkbox
                                                               checked={daysOfWeek.saturday}
                                                               onChange={handleChangeDayOfWeek}
                                                               name="saturday"
                                                            />
                                                         }
                                                         label="Saturday"
                                                      />
                                                      <FormControlLabel
                                                         control={
                                                            <Checkbox
                                                               checked={daysOfWeek.sunday}
                                                               onChange={handleChangeDayOfWeek}
                                                               name="sunday"
                                                            />
                                                         }
                                                         label="Sunday"
                                                      />
                                                   </FormGroup>
                                                   {daysOfWeekError && submit && (
                                                      <FormHelperText className="error-message">
                                                         Required Field
                                                      </FormHelperText>
                                                   )}
                                                </FormControl>
                                             </div>
                                          )}
                                          {typeOfDatePicker === MONTHLY && (
                                             <div className="space-between-row">
                                                <Divider textAlign="left" className="text--divider">
                                                   Choose the day of the Month
                                                </Divider>
                                                <FormControl
                                                   variant="standard"
                                                   error={monthlyError && submit}
                                                   className="content__select"
                                                >
                                                   <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                      <DatePicker
                                                         views={["day"]}
                                                         label="Day of the Month"
                                                         inputFormat="dd"
                                                         mask="__"
                                                         PopperProps={{
                                                            sx: montlySx,
                                                         }}
                                                         minDate={minDateMonth}
                                                         maxDate={maxDateMonth}
                                                         value={state.dayOfTheMonth}
                                                         defaultCalendarMonth={currentDate}
                                                         onChange={(newValue) => {
                                                            dispatch({ type: "dayOfTheMonth", payload: newValue });
                                                         }}
                                                         renderInput={(params) => (
                                                            <TextField {...params} helperText={null} />
                                                         )}
                                                      />
                                                   </LocalizationProvider>
                                                   {monthlyError && submit && (
                                                      <FormHelperText className="error-message">
                                                         Required Field
                                                      </FormHelperText>
                                                   )}
                                                </FormControl>
                                             </div>
                                          )}
                                          {typeOfDatePicker === YEARLY && (
                                             <div className="space-between-row">
                                                <Divider textAlign="left" className="text--divider">
                                                   Choose the date in the Year
                                                </Divider>
                                                <FormControl
                                                   variant="standard"
                                                   error={yearlyError && submit}
                                                   className="content__select"
                                                >
                                                   <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                      <DatePicker
                                                         views={["month", "day"]}
                                                         label="Date of the year"
                                                         inputFormat="MM/dd"
                                                         mask="__/__"
                                                         minDate={minDate}
                                                         maxDate={maxDate}
                                                         value={state.dateOfYear}
                                                         onChange={(newValue) => {
                                                            dispatch({ type: "dateOfYear", payload: newValue });
                                                         }}
                                                         renderInput={(params) => (
                                                            <TextField {...params} helperText={null} />
                                                         )}
                                                      />
                                                   </LocalizationProvider>
                                                   {yearlyError && submit && (
                                                      <FormHelperText className="error-message">
                                                         Required Field
                                                      </FormHelperText>
                                                   )}
                                                </FormControl>
                                             </div>
                                          )}
                                          <div className="edit-content--row">
                                             <FormControl
                                                error={messageError && submit}
                                                className="content__input-message"
                                             >
                                                <TextField
                                                   error={messageError && submit}
                                                   name="message"
                                                   multiline
                                                   label="Message"
                                                   rows={4}
                                                   value={state.message}
                                                   onChange={handleChange}
                                                />
                                                {messageError && submit && (
                                                   <FormHelperText className="error-message">
                                                      Required Field
                                                   </FormHelperText>
                                                )}
                                             </FormControl>
                                          </div>
                                       </section>
                                    </Paper>
                                    <Paper elevation={3}>
                                       <section>
                                          <div className="reader-filter">
                                             <SearchInput onChangeEvent={filterReaders} />
                                          </div>
                                          <MessageReaders data={usersDataFiltered} />
                                       </section>
                                    </Paper>
                                 </>
                              )}
                              {tabValue === 1 && (
                                 <Box p={3}>
                                    <MessageActivity />
                                 </Box>
                              )}
                           </Paper>
                        </Box>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         )}
      </div>
   );
}

export default MessageManagementEdit;
