// #region Imports
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { Autocomplete, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import * as rateApi from "../../api/rateApi";
import { customerCodeFormatter } from "../../common/customerCodeFormatter";
import { stateList } from "../../common/stateListAbbreviation";
import PlaceSearch from "../Google/PlaceSearch";
// #endregion

// #region Transition
const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});
// #endregion

const RateNew = (props) => {
   var handleError = useErrorHandler();

   // #region Variables
   let [selectedTerminal, setSelectedTerminal] = useState(null);
   let [selectedCustomer, setSelectedCustomer] = useState(null);
   let [loading, setLoading] = useState(true);
   let [saving, setSaving] = useState(false);
   let [error, setError] = useState(false);

   let [newRate, setNewRate] = useState({
      _id: null,
      active: true,
      terminal_id: null,
      customer_id: null,
      destination: {
         city: null,
         state: null,
         zip: null,
         place_id: null,
      },
      rateInfo: {},
      historicalRateInfo: [],
   });

   let [newRateInfo, setNewRateInfo] = useState({
      lane_type: "",
      base_rate: "",
      service: true,
      miles: "",
      cost: "",
      tolls: "",
      bobtail: "",
      startDate: null,
      endDate: null,
   });
   // #endregion

   // #region UseEffect(s)
   useEffect(() => {
      if (loading === true) {
         setSelectedTerminal(null);
         setSelectedCustomer(null);
         setError(null);

         setNewRate({
            _id: null,
            active: true,
            terminal_id: "",
            customer_id: "",
            destination: {
               city: "",
               state: "",
               zip: "",
               place_id: "",
            },
            rateInfo: {},
            historicalRateInfo: [],
            insertNew: true,
         });

         setNewRateInfo({
            lane_type: "",
            base_rate: "",
            service: true,
            miles: "",
            cost: "",
            tolls: "",
            bobtail: "",
            startDate: null,
            endDate: null,
         });
      }

      setLoading(false);
   }, [props, loading, newRate]);
   // #endregion

   // #region Misc. Functions
   function handleClose() {
      try {
         setLoading(true);
         props.handleClose();
      } catch (err) {
         handleError(err);
      }
   }

   function onDestinationChange(event, values) {
      try {
         //need to check to see how many array items were returned in google api results
         var destination = _.cloneDeep(newRate.destination);

         if (values) {
            //input defaults
            destination.city = values.terms[0].value;

            if (
               values.terms[1].value.length > 2 &&
               values.terms[1].value.toUpperCase() !== "USA" &&
               values.terms[1].value.toUpperCase() !== "US"
            ) {
               //See if terms[1] is state or space in city name.
               let state = _.filter(stateList, (x) => x.name.toUpperCase() === values.terms[1].value.toUpperCase());
               let abbreviation = _.filter(
                  stateList,
                  (x) => x.abbreviation.toUpperCase() === values.terms[1].value.toUpperCase(),
               );

               if (state.length > 0 || abbreviation.length > 0) {
                  if (state.length > 0) {
                     destination.state = state[0].abbreviation;
                  } else {
                     destination.state = abbreviation[0].abbreviation;
                  }
               } else {
                  destination.city += " " + values.terms[1].value;

                  if (values.terms[2].value.length > 2) {
                     destination.city += " " + values.terms[2].value;

                     destination.state = values.terms[3].value;
                  } else {
                     destination.state = values.terms[2].value;
                  }
               }
            } else {
               if (values.terms[1].value.toUpperCase() !== "USA" && values.terms[1].value.toUpperCase() !== "US") {
                  destination.state = values.terms[1].value;
               }
            }

            destination.place_id = values.place_id;
         }

         var newSearch = _.cloneDeep(newRate);
         newSearch.destination = destination;

         setNewRate(newSearch);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeZip(event) {
      try {
         var newSearch = _.cloneDeep(newRate);
         newSearch.destination.zip = event.target.value;
         setNewRate(newSearch);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeRateInfo(event) {
      try {
         var newInfoObj = _.cloneDeep(newRateInfo);
         newInfoObj[event.target.name] = event.target.value;
         setNewRateInfo(newInfoObj);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeService(event) {
      try {
         var newInfo = _.cloneDeep(newRateInfo);
         newInfo.service = event.target.value;
         setNewRateInfo(newInfo);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeLaneType(event) {
      try {
         var newInfo = _.cloneDeep(newRateInfo);
         newInfo.lane_type = event.target.value;
         setNewRateInfo(newInfo);
      } catch (err) {
         handleError(err);
      }
   }
   // #endregion

   // #region Save Function
   function saveNewRate() {
      if (!selectedTerminal) {
         setError("Please select a terminal from the dropdown.");
         return;
      }

      if (!newRate.destination.city) {
         setError("Please select a destination from the dropdown.");
         return;
      }

      if (!newRateInfo.miles) {
         setError("Please enter miles for the new rate.");
         return;
      }

      if (!newRateInfo.lane_type) {
         setError("Please select a lane type from the dropdown.");
         return;
      }

      setSaving(true);

      newRateInfo.startDate = null;
      newRate.terminal_id = selectedTerminal._id;
      newRate.customer_id = selectedCustomer ? selectedCustomer._id : null;
      newRate.rateInfo = newRateInfo;

      rateApi
         .saveRate(newRate)
         .then((resp) => {
            //success
            props.openAlertMessage("New Rate Successfully Saved.", "success");
            handleClose();
            setSaving(false);
         })
         .catch((err) => {
            setSaving(false);
            setError(`API Error: ${err.message}`);
         });
   }
   // #endregion

   // #region React View
   return (
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth={"xl"}>
         {error && (
            <Alert style={{ color: "#FFFFFF" }} severity="error" onClose={() => setError(null)}>
               {" "}
               {error}{" "}
            </Alert>
         )}
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>New Rate</DialogTitle>
         <DialogContent>
            <form noValidate autoComplete="off" style={{ display: "inline-flex" }}>
               <Grid
                  container
                  direction={"column"}
                  style={{ marginRight: "2rem", marginTop: "1rem", marginBottom: "0.20rem" }}
               >
                  <Grid item style={{ marginBottom: "1rem" }}>
                     <Autocomplete
                        id="combo-box-terminal"
                        options={_.filter(props.terminals, (x) => x.active === true)}
                        getOptionLabel={(option) => {
                           return option.code + " | " + option.name;
                        }}
                        onChange={(event, values) => setSelectedTerminal(values)}
                        value={selectedTerminal}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Select Terminal" variant="outlined" />}
                     />
                  </Grid>
                  <Grid item>
                     <TextField
                        label="Zip"
                        variant="standard"
                        name="zip"
                        onChange={onChangeZip}
                        value={newRate.destination.zip}
                     />
                  </Grid>
                  <Grid item>
                     <TextField
                        label="Base Rate"
                        variant="standard"
                        name="base_rate"
                        onChange={onChangeRateInfo}
                        value={newRateInfo.base_rate}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                     />
                  </Grid>
                  <Grid item>
                     <TextField
                        label="Tolls"
                        variant="standard"
                        name="tolls"
                        onChange={onChangeRateInfo}
                        value={newRateInfo.tolls}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                     />
                  </Grid>
                  <Grid item>
                     <TextField
                        label="Miles"
                        variant="standard"
                        name="miles"
                        onChange={onChangeRateInfo}
                        value={newRateInfo.miles}
                     />
                  </Grid>
                  <Grid container direction={"row"} style={{ display: "block", paddingTop: "1rem" }}>
                     <InputLabel id="service-simple-select-label" style={{ fontSize: "smaller" }}>
                        Service?
                     </InputLabel>
                     <Select value={newRateInfo.service} onChange={onChangeService} variant="standard">
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                     </Select>
                  </Grid>
               </Grid>
               <Grid container direction={"column"} style={{ marginTop: "1rem" }}>
                  <Grid item style={{ width: 300, marginBottom: "1rem" }}>
                     <PlaceSearch
                        onChange={(e, values) => onDestinationChange(e, values)}
                        destination={
                           (newRate.destination.city, newRate.destination.state)
                              ? newRate.destination.city + ", " + newRate.destination.state
                              : null
                        }
                     />
                  </Grid>
                  <Grid item>
                     <TextField
                        label="Cost"
                        variant="standard"
                        name="cost"
                        onChange={onChangeRateInfo}
                        value={newRateInfo.cost}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                     />
                  </Grid>
                  <Grid item>
                     <TextField
                        label="Bobtail"
                        variant="standard"
                        name="bobtail"
                        onChange={onChangeRateInfo}
                        value={newRateInfo.bobtail}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                     />
                  </Grid>
                  <Grid item>
                     <InputLabel id="il-laneType" style={{ fontSize: "smaller" }}>
                        Lane Type
                     </InputLabel>
                     <Select
                        value={newRateInfo.lane_type}
                        onChange={onChangeLaneType}
                        style={{ minWidth: "11.5rem" }}
                        variant="standard"
                     >
                        <MenuItem value={"LOCAL"}>LOCAL</MenuItem>
                        <MenuItem value={"OTR"}>OTR</MenuItem>
                        <MenuItem value={"REGIONAL"}>REGIONAL</MenuItem>
                     </Select>
                  </Grid>
                  <Grid item>
                     <Autocomplete
                        id="combo-box-customer"
                        options={props.customers}
                        onChange={(event, values) => setSelectedCustomer(values)}
                        value={selectedCustomer}
                        getOptionLabel={(option) => {
                           return customerCodeFormatter(option.code) + " | " + option.name;
                        }}
                        style={{ backgroundColor: "white", marginTop: "1rem" }}
                        renderInput={(params) => <TextField {...params} label="Select Customer" variant="outlined" />}
                     />
                  </Grid>
               </Grid>
            </form>
         </DialogContent>
         <DialogActions>
            <Tooltip title="Cancel" arrow>
               <IconButton onClick={handleClose} variant="contained" color="default">
                  <CloseIcon />
               </IconButton>
            </Tooltip>
            <Tooltip title="Save New Rate" arrow>
               <IconButton disabled={saving} onClick={saveNewRate} variant="contained" color="primary">
                  <SaveIcon />
               </IconButton>
            </Tooltip>
         </DialogActions>
      </Dialog>
   );
};
// #endregion

// #region Redux Stores
function mapStateToProps(state, ownProps) {
   return {
      terminals: state.terminal.terminals || [],
      customers: state.customer !== null ? state.customer.customer : [],
      user: state.user.currentUser,
   };
}
// #endregion

// #region Prop Types
RateNew.propTypes = {
   open: PropTypes.bool.isRequired,
   user: PropTypes.object.isRequired,
   customers: PropTypes.array.isRequired,
   terminals: PropTypes.array.isRequired,
   handleClose: PropTypes.func.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
};

RateNew.defaultProps = {
   open: false,
   user: {},
   customers: [],
   terminals: [],
   handleClose: () => {
      return;
   },
   openAlertMessage: () => {
      return;
   },
};
// #endregion

export default connect(mapStateToProps)(RateNew);
