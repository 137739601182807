import * as applicationApi from "../../api/applicationApi";
import actionTypes from "./actionTypes";

export function getTerminals() {
   return function (dispatch) {
      applicationApi
         .getTerminals()
         .then((resp) => {
            if (resp.terminals !== "undefined") {
               if (resp.terminals.length > 0) {
                  resp.terminals = resp.terminals.sort((a, b) => -b.name.localeCompare(a.name));
                  return dispatch({ type: actionTypes.GET_TERMINALS, terminals: resp });
               } else {
                  return dispatch({ type: actionTypes.GET_TERMINALS, terminals: resp });
               }
            } else {
               return dispatch({ type: actionTypes.GET_TERMINALS, terminals: resp });
            }
         })
         .catch((err) => {
            console.error("Getting terminals error: " + err);
            return;
         });
   };
}
