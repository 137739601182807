import * as activityApi from "../api/activityApi";

export function setActivity(service, activity_code, activity) {
   try {
      //Activity is required.
      if (JSON.stringify(activity) === "" || activity === null) {
         var error = new Error("Missing Activity");
         throw error;
      }

      //Do validations.
      for (const act in activity) {
         //Module is required.
         if (activity[act] == null && act == "module") {
            var error = new Error("Missing Module");
            throw error;
         }

         //User ID is required.
         if (activity[act] == null && act == "user_id") {
            var error = new Error("Missing User ID");
            throw error;
         }

         //Record ID is required.
         if (activity[act] == null && act == "record_id") {
            var error = new Error("Missing Record ID");
            throw error;
         }
      }

      //Assign object.
      var activityObj = {
         _id: null, //Assign null to insert.
         service: service,
         activity_code: activity_code,
         activity: activity,
      };

      activityApi.saveActivity(activityObj);
   } catch (err) {
      console.error(err);
   }
}
