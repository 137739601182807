import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import * as React from 'react';

export default function AccessorialVerbiage(props) {

  const {
    localAccessorialGuides,
    accessorialGuides,
    setAccessorialComment,
    setOverrideAccessorialComment,
    overrideAccessorialComment,
    accessorialTariffs,
    customers,
    quoteSearch
  } = props;
  let customerAccessorial = customers?.accessorialTariffs ? customers?.accessorialTariffs.find(x => x?.terminal_id === quoteSearch?.selectedTerminal?._id)?.accessorialGuide : [];
  let terminalAccessorial = accessorialTariffs?.accessorialGuide || [];
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  
  const setData = () => {
    let overrideccessorialGuides = [];
  
    localAccessorialGuides.map((localAccessorialGuide, i) => {
     let defaultLevel = accessorialGuides ? accessorialGuides.find(x => x.name.trim() === localAccessorialGuide?.name.trim()) : [];
      localAccessorialGuides[i].pdfVerbiageDefaultLevel = defaultLevel?.pdfVerbiage || "";

      let customerLevel = customerAccessorial ? customerAccessorial.find(x => x.name.trim() === localAccessorialGuide?.name.trim()) : [];
      localAccessorialGuides[i].pdfVerbiageCustomerLevel = customerLevel?.pdfVerbiage || "";

      let terminalLevel = terminalAccessorial ? terminalAccessorial.find(x => x.name.trim() === localAccessorialGuide?.name.trim()) : [];
      localAccessorialGuides[i].pdfVerbiageTerminalLevel = terminalLevel?.pdfVerbiage || "";
      // check for override
      if (
        localAccessorialGuide?.pdfVerbiage !== localAccessorialGuide?.pdfVerbiageDefaultLevel &&
        localAccessorialGuide?.pdfVerbiage !== localAccessorialGuide?.pdfVerbiageCustomerLevel &&
        localAccessorialGuide?.pdfVerbiage !== localAccessorialGuide?.pdfVerbiageTerminalLevel
      )
      {
        overrideccessorialGuides.push(localAccessorialGuide);
        }
    });
    setOverrideAccessorialComment(overrideccessorialGuides);
   }

  React.useEffect(() => {
    setData();
    setValue(null);
  }, []);

  const handleChange = (e) => {
    localAccessorialGuides.find((o) => {
      if (o.name == e.target.name) {
        o.pdfVerbiage = e.target.value;
        o.updated_at = new Date();
        o.status = "UPDATED";
        setValue({ ...value, ...o });
      }
    });
  };


  const handleClickOpen = () => () => {
    setOpen(true);
    setValue(null);
  };



  const handleClose = () => {
    setOpen(false);
    setValue(null);
  };

  const onSave = () => {
    let updatedValue = localAccessorialGuides.filter(x => x.status === "UPDATED");
    setAccessorialComment(updatedValue);
    setOpen(false);
    
   let newOverrideccessorialGuides = overrideAccessorialComment;
    updatedValue.map((updatedValue, i) => {
      let findIn = overrideAccessorialComment.find(x => x.name.trim() === updatedValue?.name.trim());
      if (findIn) {
        _.remove(newOverrideccessorialGuides, {
          name: findIn?.name
        });
      }
     newOverrideccessorialGuides.push(updatedValue);
    });
    setOverrideAccessorialComment(newOverrideccessorialGuides);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  return (
    <React.Fragment>
      <Tooltip title="Accessorial Comments" arrow>
        <Button onClick={handleClickOpen()} style={{
          float: "right",
          marginTop: "22px"
        }}><SummarizeRoundedIcon style={{ fontSize: "2rem" }} /></Button></Tooltip>
      <Dialog
        open={open}
        // onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>Accessorial Comments</DialogTitle>
        <DialogContent dividers="true">
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {localAccessorialGuides.map(
              (guide) => (
                <TextField
                  id={`tf-${guide?.name}`}
                  key={`tf-${guide?.name}`}
                  style={{ margin: 15 }}
                  label={`${guide?.name}`}
                  helperText={`Comment for-${guide?.name}`}
                  variant="standard"
                  name={`${guide?.name}`}
                  onChange={handleChange}
                  type="text"
                  value={value?.name == guide?.name ? value?.pdfVerbiage : `${guide?.pdfVerbiage || guide?.pdfVerbiageDefaultLevel}`} />
              )
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Cancel & Close" arrow>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel <CloseIcon /></Button></Tooltip>
          <Tooltip title="Save & Close" arrow>
            <Button variant="outlined" onClick={onSave}>Save <Check /></Button></Tooltip>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
