/**
 * Definition: This function formats decimal values coming from the api to two decimal places.
 *
 * Example:
 * decimalFormatter(2.5)
 *
 * Should Return:
 * 2.50
 *
 */

export const decimalFormatter = (value = 0, decimalPlaces = 2) => {
   try {
      return value === null ? Number(0).toFixed(2) : isNaN(value) ? value : parseFloat(value).toFixed(decimalPlaces);
   } catch (error) {
      return Number(0).toFixed(2);
   }
};
