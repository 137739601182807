import actionTypes from "../actions/actionTypes";

const initialState = [];

export default function accessorialGuideReducer(state = initialState, action) {
   switch (action.type) {
      case actionTypes.GET_ACCESSORIAL_GUIDES:
         return action.guides;
      default:
         return state;
   }
}
