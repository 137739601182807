import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import * as accessorialApi from "../../api/accessorialApi";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const AccessorialGuideNew = (props) => {
   const handleError = useErrorHandler();

   let [saving, setSaving] = useState(false);
   const [saveErrors, setSaveErrors] = useState(null);
   const [errorSeverity, setErrorSeverity] = useState("");

   const [newGuide, setNewGuide] = useState({
      _id: null,
      name: "",
      pdfVerbiage: "",
      add_date: null,
      edit_date: null,
      delete_date: null,
   });

   useEffect(() => {
      setNewGuide({
         _id: null,
         name: "",
         pdfVerbiage: "",
         add_date: null,
         edit_date: null,
         delete_date: null,
      });

      setSaveErrors(null);
   }, [props]);

   function saveNewGuide() {
      if (!newGuide.name) {
         setSaveErrors("Name field is empty. Please enter a Accessorial Guide name");
         setErrorSeverity("warning");
         return;
      }

      setSaving(true);

      accessorialApi
         .setGuides(newGuide)
         .then((resp) => {
            props.openAlertMessage("Accessorial Guide Added.", "success");
            props.getGuides();
            props.handleClose();
            setSaving(false);
         })
         .catch((err) => {
            setSaving(false);
            setSaveErrors(`Could Not Save New Guide: ${err}`);
            setErrorSeverity("error");
         });
   }

   function onChange(event) {
      try {
         var selectedGuide = _.cloneDeep(newGuide);
         selectedGuide[event.target.name] = event.target.value;
         setNewGuide(selectedGuide);
      } catch (err) {
         handleError(err);
      }
   }

   const inputProps = {
      step: 0.01,
   };

   return (
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.handleClose}>
         <Collapse in={saveErrors ? true : false}>
            <Alert
               style={{ color: "#FFFFFF" }}
               variant="filled"
               severity={errorSeverity}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     sx={{ marginBottom: "0.25rem" }}
                     onClick={() => {
                        setSaveErrors(null);
                     }}
                  >
                     <CloseIcon fontSize="inherit" />
                  </IconButton>
               }
            >
               {saveErrors}
            </Alert>
         </Collapse>
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            New Accessorial Guide
         </DialogTitle>
         <DialogContent>
            <form noValidate autoComplete="off" style={{ textAlign: "center" }}>
               <Grid item style={{ marginTop: "1rem" }}>
                  <TextField
                     id="tf-guideName"
                     style={{ width: "100%", padding: "1%", marginBottom: "1rem" }}
                     label="Accessorial Name"
                     variant="outlined"
                     name="name"
                     onChange={onChange}
                     type="text"
                     value={newGuide.name}
                  />
                  <TextField
                     id="tf-pdfVerbiage"
                     style={{ width: "100%", padding: "1%" }}
                     label="PDF Verbiage"
                     variant="outlined"
                     name="pdfVerbiage"
                     onChange={onChange}
                     type="text"
                     inputProps={inputProps}
                     value={newGuide.pdfVerbiage}
                  />
               </Grid>
            </form>
         </DialogContent>
         <DialogActions>
            <Tooltip title="Exit" arrow>
               <IconButton onClick={props.handleClose} variant="contained" color="default">
                  <CloseIcon />
               </IconButton>
            </Tooltip>
            <Tooltip title="Save New Guide" arrow>
               <IconButton disabled={saving} onClick={saveNewGuide} variant="contained" color="primary">
                  <SaveIcon />
               </IconButton>
            </Tooltip>
         </DialogActions>
      </Dialog>
   );
};

AccessorialGuideNew.propTypes = {
   open: PropTypes.bool.isRequired,
   user: PropTypes.object.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
   getGuides: PropTypes.func.isRequired,
   handleClose: PropTypes.func.isRequired,
};

AccessorialGuideNew.defaultProps = {
   open: false,
   user: {},
   openAlertMessage: () => {
      return;
   },
   getGuides: () => {
      return;
   },
   handleClose: () => {
      return;
   },
};

export default AccessorialGuideNew;
