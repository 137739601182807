//format the csv export so it can be used to import
export const headerCSV = [
   { label: "Quote #", key: "orderNumber" },
   { label: "Date Quoted", key: "dateQuoted" },
   { label: "Quoted By", key: "quotedBy" },
   { label: "Valid From", key: "validFrom" },
   { label: "Expiry Date", key: "validTill" },
   { label: "Customer Email", key: "email" },
   { label: "Customer", key: "customer" },
   { label: "Terminal", key: "terminal" },
   { label: "Destination", key: "destination" },
   { label: "Rate", key: "rate_total" },
   { label: "FSC", key: "fsc" },
   { label: "Is Override", key: "isOverride" },
];
