import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import * as applicationApi from "../../api/applicationApi";
import { stateList } from "../../common/stateListAbbreviation";
import PlaceSearch from "../Google/PlaceSearch";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const AliasNew = (props) => {
   var handleError = useErrorHandler();

   let [saveErrors, setSaveErrors] = useState(null);
   let [saving, setSaving] = useState(false);
   let [newAlias, setNewAlias] = useState({
      _id: null,
      value: "",
      alias: "",
      place_id: "",
      add_date: "",
   });

   useEffect(() => {
      setNewAlias({
         _id: null,
         value: "",
         alias: "",
         place_id: "",
         add_date: "",
      });
   }, [props]);

   function saveNewAlias() {
      if (!newAlias.value) {
         setSaveErrors(`You must enter a value!`);
         return;
      }

      if (!newAlias.alias) {
         setSaveErrors(`You must enter a location!`);
         return;
      }

      var aliasFound = props.aliases.filter((x) => x.alias.toUpperCase() === newAlias.alias.toUpperCase());

      if (aliasFound.length > 0) {
         setSaveErrors(`Alias already exists!`);
         return;
      }

      setSaving(true);

      applicationApi
         .saveAlias(newAlias, true)
         .then((resp) => {
            if (resp.success === false) {
               setSaveErrors(`Could not save new alias: ${resp.message}`);
            } else {
               props.openAlertMessage("Alias Successfully Added.", "success");
               props.handleClose();
            }

            setSaving(false);
         })
         .catch((err) => {
            setSaving(false);
            setSaveErrors(`Could not save new alias: ${err}`);
         });
   }

   function onChange(event) {
      try {
         var selectedAlias = _.cloneDeep(newAlias);
         selectedAlias[event.target.name] =
            event.target.type === "checkbox" ? event.target.checked : event.target.value;
         setNewAlias(selectedAlias);
      } catch (err) {
         handleError(err);
      }
   }

   function onDestinationChange(event, values) {
      try {
         //need to check to see how many array items were returned in google api results
         var destination = {
            city: null,
            state: null,
            zip: null,
            place_id: null,
         };

         if (values) {
            //input defaults
            destination.city = values.terms[0].value;

            if (
               values.terms[1].value.length > 2 &&
               values.terms[1].value.toUpperCase() !== "USA" &&
               values.terms[1].value.toUpperCase() !== "US"
            ) {
               //See if terms[1] is state or space in city name.
               let state = _.filter(stateList, (x) => x.name.toUpperCase() === values.terms[1].value.toUpperCase());
               let abbreviation = _.filter(
                  stateList,
                  (x) => x.abbreviation.toUpperCase() === values.terms[1].value.toUpperCase(),
               );

               if (state.length > 0 || abbreviation.length > 0) {
                  if (state.length > 0) {
                     destination.state = state[0].abbreviation;

                     if (
                        values.terms[2].value.toUpperCase() !== "USA" &&
                        values.terms[2].value.toUpperCase() !== "US"
                     ) {
                        destination.zip = values.terms[2].value;
                     } else if (values.terms.length >= 4) {
                        if (
                           values.terms[3].value.toUpperCase() !== "USA" &&
                           values.terms[3].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[3].value;
                        }
                     }
                  } else {
                     destination.state = abbreviation[0].abbreviation;

                     if (
                        values.terms[2].value.toUpperCase() !== "USA" &&
                        values.terms[2].value.toUpperCase() !== "US"
                     ) {
                        destination.zip = values.terms[2].value;
                     } else if (values.terms.length >= 4) {
                        if (
                           values.terms[3].value.toUpperCase() !== "USA" &&
                           values.terms[3].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[3].value;
                        }
                     }
                  }
               } else {
                  destination.city += " " + values.terms[1].value;

                  if (values.terms[2].value.length > 2) {
                     destination.city += " " + values.terms[2].value;

                     destination.state = values.terms[3].value;

                     if (values.terms.length >= 5) {
                        if (
                           values.terms[4].value.toUpperCase() !== "USA" &&
                           values.terms[4].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[4].value;
                        }
                     }
                  } else {
                     destination.state = values.terms[2].value;

                     if (values.terms.length >= 5) {
                        destination.zip = values.terms[3].value;
                     }
                  }
               }
            } else {
               if (values.terms[1].value.toUpperCase() !== "USA" && values.terms[1].value.toUpperCase() !== "US") {
                  destination.state = values.terms[1].value;
               }

               //if searched by zip, then it will appear with 4 items in array, last one always being country
               if (values.terms.length >= 4) {
                  destination.zip = values.terms[2].value;
               }
            }

            destination.place_id = values.place_id;
         }

         var selectedAlias = _.cloneDeep(newAlias);
         selectedAlias.alias = destination.city;
         selectedAlias.place_id = destination.place_id;
         setNewAlias(selectedAlias);
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.handleClose}>
         <Collapse in={saveErrors ? true : false}>
            <Alert
               style={{ color: "#FFFFFF" }}
               variant="filled"
               severity="error"
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setSaveErrors(null);
                     }}
                  >
                     <CloseIcon fontSize="inherit" />
                  </IconButton>
               }
            >
               {saveErrors}
            </Alert>
         </Collapse>
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            New Alias
         </DialogTitle>
         <DialogContent style={{ paddingLeft: "5rem", paddingRight: "5rem", paddingBottom: "3rem" }}>
            <form>
               <TextField
                  style={{ width: "18rem", marginBottom: "2rem" }}
                  id="standard-basic"
                  label="Value"
                  variant="standard"
                  name="value"
                  value={newAlias.value}
                  onChange={onChange}
               />
               <PlaceSearch
                  onChange={(e, values) => onDestinationChange(e, values)}
                  destination={newAlias.alias ? newAlias.alias : null}
                  style={{ width: "18rem" }}
               />
            </form>
         </DialogContent>
         <DialogActions>
            <Tooltip title="Exit" arrow>
               <IconButton onClick={props.handleClose} variant="contained" color="default">
                  <CloseIcon />
               </IconButton>
            </Tooltip>
            <Tooltip title="Save New Alias" arrow>
               <IconButton disabled={saving} onClick={saveNewAlias} variant="contained" color="primary">
                  <SaveIcon />
               </IconButton>
            </Tooltip>
         </DialogActions>
      </Dialog>
   );
};

AliasNew.propTypes = {
   open: PropTypes.bool.isRequired,
   user: PropTypes.object.isRequired,
   aliases: PropTypes.array.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
   handleClose: PropTypes.func.isRequired,
};

AliasNew.defaultProps = {
   open: false,
   user: {},
   aliases: [],
   openAlertMessage: () => {
      return;
   },
   handleClose: () => {
      return;
   },
};

export default AliasNew;
