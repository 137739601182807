const getTimezoneOffset = (value) => value.getTimezoneOffset() * 60000;

export const localToUTC = (datetime) => {
   if (!datetime) {
      return null;
   }

   const myDate = new Date(datetime);
   const utcFromLocal = new Date(myDate.getTime() - getTimezoneOffset(myDate));
   return utcFromLocal;
};
