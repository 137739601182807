import { makeStyles } from "@mui/styles";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const DraftJsEditor = (props) => {
   const [editorState, setEditorState] = useState(null);
   const [convertedContent, setConvertedContent] = useState(null);

   const { onChangeEditor } = props;

   useEffect(() => {
      setEditorState(calculateEditorState(props.state));
   }, []);

   useEffect(() => {
      const field = props.name;
      // if convertedContent is null so don't dispatch onChangeEditor
      if (!convertedContent) return;
      onChangeEditor(field, convertedContent);
   }, [convertedContent]);

   const useStyles = makeStyles((theme) => ({
      ".wrapper-class": {
         padding: "0.5rem",
      },
      ".editor-class": {
         padding: "0.5rem 1rem",
         border: "1px solid #0000003B",
      },
      ".toolbar-class": {
         border: "1px solid #0000003B",
      },
      editor: {
         borderWidth: "1px",
         borderColor: "#0000003B",
         borderStyle: "solid",
         borderRadius: "4px",

         "& legend span": {
            color: "#0000008A",
         },
         "& .rdw-block-wrapper": {
            display: "none",
         },
         "& .rdw-fontsize-wrapper": {
            display: "none",
         },
         "& .rdw-fontfamily-wrapper": {
            display: "none",
         },
         "& .rdw-text-align-wrapper": {
            display: "none",
         },
         "& .rdw-colorpicker-wrapper": {
            display: "none",
         },
         "& .rdw-link-wrapper": {
            display: "none",
         },
         "& .rdw-embedded-wrapper": {
            display: "none",
         },
         "& .rdw-emoji-wrapper": {
            display: "none",
         },
         "& .rdw-image-wrapper": {
            display: "none",
         },
         "& .rdw-remove-wrapper": {
            display: "none",
         },
         "& .rdw-history-wrapper": {
            display: "none",
         },
         "& .rdw-option-wrapper[title='Strikethrough']": {
            display: "none",
         },
         "& .rdw-option-wrapper[title='Monospace']": {
            display: "none",
         },
         "& .rdw-option-wrapper[title='Subscript']": {
            display: "none",
         },
         "& .rdw-option-wrapper[title='Superscript']": {
            display: "none",
         },
      },
   }));

   const classes = useStyles();

   function calculateEditorState(value) {
      return value
         ? EditorState.createWithContent(convertFromHTML(JSON.parse(JSON.stringify(value))))
         : EditorState.createEmpty();
   }

   const handleEditorChange = (state) => {
      setEditorState(state);
      convertContentToHTML();
   };

   const convertContentToHTML = () => {
      let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(currentContentAsHTML);
   };

   return (
      <fieldset className={classes.editor}>
         <legend>
            <span>{props.label}</span>
         </legend>
         <Editor
            placeholder="Type Here"
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName={classes[".wrapper-class"]}
            editorClassName={classes[".editor-class"]}
            toolbarClassName={classes[".toolbar-class"]}
         />
      </fieldset>
   );
};

export default DraftJsEditor;
