import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Activity from "./Activity";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const ActivityDialog = (props) => {
   let [loadActivity, setLoadActivity] = useState(true);

   useEffect(() => {
      setLoadActivity(true);
   }, [props.open]);

   return (
      <Dialog
         open={props.open || false}
         TransitionComponent={Transition}
         keepMounted
         onClose={props.handleClose}
         style={{ width: "100%" }}
         fullWidth
         maxWidth="md"
      >
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            {"More Activity"}
         </DialogTitle>
         <DialogContent>
            <DialogContentText>
               <Grid item style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                     <Paper style={{ height: "fit-content", width: "100%" }}>
                        <Activity
                           activityOptions={props.activityOptions}
                           loadActivity={loadActivity}
                           setLoadActivity={() => setLoadActivity(false)}
                        ></Activity>
                     </Paper>
                  </div>
               </Grid>
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Tooltip title="Exit" arrow>
               <Fab
                  color="primary"
                  size="small"
                  component="span"
                  aria-label="add"
                  onClick={props.handleClose}
                  style={{ float: "right" }}
               >
                  <ExitToAppRoundedIcon />
               </Fab>
            </Tooltip>
         </DialogActions>
      </Dialog>
   );
};

ActivityDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   activityOptions: PropTypes.object.isRequired,
   handleClose: PropTypes.func.isRequired,
};

ActivityDialog.defaultProps = {
   open: false,
   activityOptions: {},
   handleClose: () => {
      return;
   },
};

export default ActivityDialog;
