import PropTypes from "prop-types";
import { useEffect } from "react";
// Material
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
// Components
import useTypeDelay from "../../../hooks/useTypeDelay";
import StyledDataGrid from "../../StyledDataGrid/StyledDataGrid";

export function SearchInput({ onChangeEvent }) {
   const [textSearching, setTextSearching] = useTypeDelay();

   const handleChangeFilter = (event) => {
      setTextSearching(event.target.value);
   };

   useEffect(() => {
      onChangeEvent(textSearching);
   }, [textSearching]);

   return (
      <TextField
         sx={{
            borderRadius: "3px",
            width: "430px",
            "& .MuiOutlinedInput-root": {
               height: "30px",
            },
         }}
         margin="none"
         placeholder="Search"
         size="small"
         InputProps={{
            startAdornment: (
               <InputAdornment position="start">
                  <SearchIcon style={{ color: "#000000DE" }} />
               </InputAdornment>
            ),
         }}
         onChange={handleChangeFilter}
      />
   );
}

function MessageReaders({ data }) {
   const parseDate = (date) => {
      if (!date) return "";
      const dateString = date.split("T")[0];
      const dateSplited = dateString.split("-");
      return `${dateSplited[1]}/${dateSplited[2]}/${dateSplited[0]}`;
   };

   console.log(data);

   return (
      <StyledDataGrid
         gridHeight={"310px"}
         loading={false}
         getRowId={(row) => (row != null ? row._id : "")}
         columns={[
            {
               field: "full_name",
               headerName: "User",
               description: "User Name",
               type: "string",
               flex: 0.45,
            },
            {
               field: "email",
               headerName: "Email",
               description: "Email User",
               type: "string",
               flex: 0.35,
            },
            {
               field: "readDate",
               headerName: "Date Last Read",
               description: "Date Last Read",
               type: "string",
               flex: 0.2,
               valueGetter: (data) => {
                  if (!data.row.readDate) return "";
                  return parseDate(data.row.readDate);
               },
            },
         ]}
         rows={data}
      ></StyledDataGrid>
   );
}

MessageReaders.propTypes = {
   data: PropTypes.array.isRequired,
};

SearchInput.propTypes = {
   onChangeEvent: PropTypes.func.isRequired,
};

export default MessageReaders;
