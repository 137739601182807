/**
 * Definition: This function formats customer code to the first master account #.
 *
 * Example:
 * customerCodeFormatter("123-456-789")
 *
 * Should Return:
 * "123"
 *
 */

export const customerCodeFormatter = (code = "") => {
   try {
      if (code.indexOf("-") <= -1) {
         return code;
      }

      return code.substring(0, code.indexOf("-"));
   } catch (error) {
      return code;
   }
};
