import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SaveIcon from "@mui/icons-material/Save";
import { AppBar, Box, Tab, Tabs } from "@mui/material";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as activityApi from "../../api/activityApi";
import * as userApi from "../../api/userApi";
import { dateFormatter } from "../../common/dateFormatter";
import { timeFormatter } from "../../common/timeFormatter";
import * as userActions from "../../redux/actions/userActions";
import "../../styles/truck.css";
import ActivityDialog from "../Activity/ActivityDialog";
import ActivityLogGrid from "../Activity/ActivityLogGrid";
import containerLogo from "../Logo/ContainerPort-Logo-white.png";
import TransferList from "../TransferList/TransferList";

const UserDetails = (props) => {
   var history = useHistory();
   var handleError = useErrorHandler();

   let [loading, setLoading] = useState(true);
   let [readOnly, setReadOnly] = useState(false);
   let [selectedUser, setSelectedUser] = useState(null);
   let [transferListLeft, setTransferListLeft] = useState([]);
   let [transferListRight, setTransferListRight] = useState([]);
   let [openDrawer, setOpenDrawer] = useState(false);
   let [showAlert, setShowAlert] = useState(false);
   let [alertMessage, setAlertMessage] = useState(null);
   let [alertType, setAlertType] = useState("");
   let [loadActivity, setLoadActivity] = useState(true);
   let [activityModal, setActivityModal] = useState(false);
   const [tabsValue, setTabsValue] = useState(0);
   const [activityTableData, setActivityTableData] = useState([]);

   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         borderBottom: "1px solid #2F3136",
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         fontSize: "2rem",
      },
      save: {
         float: "right",
         fontSize: "2rem",
         marginRight: "1rem",
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      appBar: {
         "& .MuiButtonBase-root": {
            padding: "1.25rem 2.25rem",
         },
         "& .MuiTabs-indicator": {
            height: "4px",
         },
      },
      inputRoot: {},
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
      large: {
         width: theme.spacing(7),
         height: theme.spacing(7),
      },
      listDrawer: {
         width: "300px",
         maxWidth: 360,
         backgroundColor: theme.palette.background.paper,
      },
   }));

   const classes = useStyles();

   useEffect(() => {
      //set loading to true
      setLoading(true);
      //retrieve record from API
      userApi.getUsers(props.match.params.id).then((resp) => {
         if (resp.users.length > 0) {
            var newSelectedUser = resp.users[0];

            //load left and right columns of transfer list
            var leftList = [];
            var rightList = [];
            props.terminals.map((term) => {
               var termFound = _.find(newSelectedUser.security.terminal_ids, (t) => t === term._id);

               if (termFound) {
                  rightList.push({ _id: term._id, name: term.name });
               } else {
                  leftList.push({ _id: term._id, name: term.name });
               }
            });

            setTransferListLeft(leftList);
            setTransferListRight(rightList);
            setSelectedUser(newSelectedUser);
            setLoading(false);
         }
      });
   }, []);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "intusermanagement");
      var userReadOnly = true;
      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   useEffect(() => {
      if (loadActivity) {
         activityApi
            .getActivity(null, null, null, [
               {
                  module: "USER",
                  record_id: props.match.params.id,
                  rows: null,
               },
            ])
            .then((resp) => {
               //Build the tableData
               const newTableData = [];

               resp.activities.forEach((activityObjectResp) => {
                  activityObjectResp.activity.forEach((activity, i) => {
                     const newActivityObj = {};

                     newActivityObj["_id"] = activityObjectResp._id;
                     newActivityObj["id"] = `${activityObjectResp._id}-${i}`;
                     newActivityObj["date"] = dateFormatter(activityObjectResp.add_date);
                     newActivityObj["hour"] = timeFormatter(activityObjectResp.add_date);
                     newActivityObj["event"] = activity.event_code || "N/A";
                     newActivityObj["description"] = activity.event || "N/A";

                     newTableData.push(newActivityObj);
                  });
               });

               setActivityTableData(newTableData);
               setLoadActivity(false);
            })
            .catch((err) => {
               console.error(err);
            });
      }
   }, [loadActivity]);

   const getLists = (leftList, rightList) => {
      try {
         //update selected tariff
         var newUser = _.cloneDeep(selectedUser);
         newUser.security.terminal_ids = [];

         rightList.map((item) => {
            newUser.security.terminal_ids.push(item._id);
         });

         setTransferListLeft(leftList);
         setTransferListRight(rightList);
         setSelectedUser(newUser);
      } catch (err) {
         handleError(err);
      }
   };

   function onChange(event) {
      try {
         var userObj = { ...selectedUser };
         userObj[event.target.name] = event.target.type === "checkbox" ? event.target.checked : event.target.value;
         setSelectedUser(userObj);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeSecurity(event) {
      try {
         var userObj = { ...selectedUser };
         userObj.security[event.target.name] =
            event.target.type === "checkbox" ? event.target.checked : event.target.value;
         setSelectedUser(userObj);
      } catch (err) {
         handleError(err);
      }
   }

   function onClickMod(event, route) {
      try {
         var userObj = { ...selectedUser };

         userObj.security.routes.forEach((rt) => {
            if (route.module === rt.module) {
               rt.permission = event.target.value;
            }
         });

         setSelectedUser(userObj);
      } catch (err) {
         handleError(err);
      }
   }

   function updateUser() {
      //When update user, check if the rate discount is null, if that is the case asign 0
      if (!selectedUser.security.discountRatePercentage || selectedUser.security.discountRatePercentage === "") {
         selectedUser.security.discountRatePercentage = 0;
      } else {
         selectedUser.security.discountRatePercentage = +selectedUser.security.discountRatePercentage;
      }

      userApi
         .saveUser(selectedUser)
         .then((resp) => {
            //success
            props.getInternalUsers();
            openAlertMessage("User Successfully Updated.", "success");

            //Refresh activity
            setLoadActivity(true);
         })
         .catch((err) => {
            openAlertMessage("Error saving user: " + err, "error");
         });
   }

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   const handleTabsChange = (event, newValue) => {
      setTabsValue(newValue);
   };

   function a11yProps(index) {
      return {
         id: `simple-tab-${index}`,
         "aria-controls": `simple-tabpanel-${index}`,
      };
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   const inputProps = {
      step: 0.01,
   };

   return (
      <>
         <div id={"userDetails"} className={classes.layoutMain}>
            <div id={"userDetailsHeader"} className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                  <Grid item xs={6}>
                     {!loading ? (
                        <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                           <Link
                              color="inherit"
                              style={{ cursor: "pointer" }}
                              onClick={() => breadcrumbOnClick("/user")}
                           >
                              <h3 style={{ fontWeight: "500" }}>{"Users"}</h3>
                           </Link>
                           <Link
                              color="inherit"
                              style={{ cursor: "pointer" }}
                              onClick={() => breadcrumbOnClick("/user/details/" + props.match.params.id)}
                           >
                              <h3 style={{ fontWeight: "500" }}>
                                 {selectedUser.firstname ? selectedUser.firstname + " " + selectedUser.lastname : null}
                              </h3>
                           </Link>
                        </Breadcrumbs>
                     ) : null}
                  </Grid>
                  <Grid item xs={6}>
                     <div>
                        <Tooltip title="Save" arrow>
                           <IconButton
                              variant="contained"
                              color="primary"
                              className={classes.save}
                              onClick={updateUser}
                              disabled={readOnly}
                           >
                              <SaveIcon style={{ fontSize: "2rem" }} />
                           </IconButton>
                        </Tooltip>
                     </div>
                  </Grid>
               </Grid>
               <Collapse in={showAlert}>
                  <Alert
                     style={{ color: "#FFFFFF" }}
                     variant="filled"
                     severity={alertType ? alertType : "success"}
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           sx={{ marginBottom: "0.25rem" }}
                           onClick={() => {
                              {
                                 closeAlertMessage();
                              }
                           }}
                        >
                           <CloseIcon fontSize="inherit" />
                        </IconButton>
                     }
                  >
                     {alertMessage}
                  </Alert>
               </Collapse>
            </div>
            <div id={"userDetailsBody"} className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}>
               {loading ? (
                  <div className="loader-wrapper">
                     <div className="truck-wrapper">
                        <div className="truck">
                           <div className="truck-container">
                              <img
                                 style={{ width: "65px", paddingLeft: "5px", paddingTop: "15px" }}
                                 src={containerLogo}
                              />
                           </div>
                           <div className="glases"></div>
                           <div className="bonet"></div>
                           <div className="base"></div>
                           <div className="base-aux"></div>
                           <div className="wheel-back"></div>
                           <div className="wheel-front"></div>
                           <div className="smoke"></div>
                        </div>
                     </div>
                  </div>
               ) : (
                  <>
                     <Grid container direction="row" justifyContent="space-between">
                        <Grid item xs={12}>
                           <Grid container direction="column" justifyContent="space-between" alignItems="center">
                              <Grid item sx={{ width: "100%" }}>
                                 <Box p="1%" width="100%">
                                    <Paper elevation={3}>
                                       <AppBar
                                          position="static"
                                          color="default"
                                          className={classes.appBar}
                                          sx={{
                                             boxShadow: "none",
                                             borderBottom: "1px solid black",
                                          }}
                                       >
                                          <Tabs
                                             indicatorColor="primary"
                                             textColor="primary"
                                             onChange={handleTabsChange}
                                             value={tabsValue}
                                             aria-label="tabs"
                                          >
                                             <Tab
                                                sx={{
                                                   fontSize: "1rem",
                                                   fontWeight: "700",
                                                   letterSpacing: "0px",
                                                   textTransform: "none",
                                                }}
                                                label="User Details"
                                                {...a11yProps(0)}
                                             />
                                             <Tab
                                                sx={{
                                                   fontSize: "1rem",
                                                   fontWeight: "700",
                                                   letterSpacing: "0px",
                                                   textTransform: "none",
                                                }}
                                                label="Activity"
                                                {...a11yProps(1)}
                                             />
                                          </Tabs>
                                       </AppBar>
                                       {tabsValue === 0 && (
                                          <Box sx={{ padding: "0 2rem 1rem 0" }}>
                                             <Grid container direction={"row"} justifyContent="space-between">
                                                <Grid item xs={6}>
                                                   {/*Left pane*/}
                                                   <Grid
                                                      container
                                                      direction={"column"}
                                                      justifyContent="space-between"
                                                      alignItems="center"
                                                   >
                                                      {/*Column 1*/}
                                                      <Grid item style={{ width: "100%" }}>
                                                         <div style={{ padding: "1%", width: "100%" }}>
                                                            <Paper elevation={3} style={{ padding: "3%" }}>
                                                               <form>
                                                                  <div>
                                                                     <Avatar
                                                                        style={{ marginBottom: "1rem" }}
                                                                        src={require("../Logo/defaultuser.png")}
                                                                        className={classes.large}
                                                                     />
                                                                     <TextField
                                                                        style={{ marginRight: "2rem" }}
                                                                        id="standard-basic"
                                                                        label="First Name"
                                                                        variant="standard"
                                                                        name="firstname"
                                                                        value={selectedUser.firstname}
                                                                        onChange={(e) => onChange(e)}
                                                                        disabled={readOnly}
                                                                     />
                                                                     <TextField
                                                                        id="standard-basic"
                                                                        label="Last Name"
                                                                        variant="standard"
                                                                        name="lastname"
                                                                        value={selectedUser.lastname}
                                                                        onChange={(e) => onChange(e)}
                                                                        disabled={readOnly}
                                                                     />
                                                                     <TextField
                                                                        id="standard-basic"
                                                                        label="Email"
                                                                        variant="standard"
                                                                        name="email"
                                                                        style={{ width: "25rem" }}
                                                                        value={selectedUser.email}
                                                                        onChange={(e) => onChange(e)}
                                                                        disabled={readOnly}
                                                                     />
                                                                     <div>
                                                                        <FormControlLabel
                                                                           style={{ marginTop: "1rem" }}
                                                                           control={
                                                                              <Switch
                                                                                 name="active"
                                                                                 checked={selectedUser.active}
                                                                                 onClick={onChange}
                                                                                 color="primary"
                                                                                 disabled={readOnly}
                                                                              />
                                                                           }
                                                                           label="Active"
                                                                        />
                                                                     </div>
                                                                  </div>
                                                               </form>
                                                            </Paper>
                                                         </div>
                                                      </Grid>
                                                      {/*Column 2*/}
                                                      <Grid item style={{ width: "100%" }}>
                                                         <div style={{ padding: "1%", width: "100%" }}>
                                                            <Paper elevation={3}>
                                                               <h5>Modules</h5>
                                                               <form>
                                                                  <TableContainer component={Paper}>
                                                                     <Table>
                                                                        <TableHead>
                                                                           <TableRow>
                                                                              <TableCell>Module</TableCell>
                                                                              <TableCell>Permission</TableCell>
                                                                           </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                           {selectedUser.security.routes.map(
                                                                              (rt, index) => {
                                                                                 return (
                                                                                    <TableRow key={`tr-${index}`}>
                                                                                       <TableCell
                                                                                          component="th"
                                                                                          scope="row"
                                                                                       >
                                                                                          {rt.label}
                                                                                       </TableCell>
                                                                                       <TableCell>
                                                                                          <FormControl variant="standard">
                                                                                             <Select
                                                                                                value={rt.permission}
                                                                                                onChange={(e) =>
                                                                                                   onClickMod(e, rt)
                                                                                                }
                                                                                                disabled={readOnly}
                                                                                             >
                                                                                                <MenuItem
                                                                                                   value="none"
                                                                                                   onClick={(e) =>
                                                                                                      onClickMod(e, rt)
                                                                                                   }
                                                                                                >
                                                                                                   No Access
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                   value="readOnly"
                                                                                                   onClick={(e) =>
                                                                                                      onClickMod(e, rt)
                                                                                                   }
                                                                                                >
                                                                                                   Read Only
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                   value="readWrite"
                                                                                                   onClick={(e) =>
                                                                                                      onClickMod(e, rt)
                                                                                                   }
                                                                                                >
                                                                                                   Read / Write
                                                                                                </MenuItem>
                                                                                             </Select>
                                                                                          </FormControl>
                                                                                       </TableCell>
                                                                                    </TableRow>
                                                                                 );
                                                                              },
                                                                           )}
                                                                        </TableBody>
                                                                     </Table>
                                                                  </TableContainer>
                                                                  <Grid item>
                                                                     <FormControlLabel
                                                                        control={
                                                                           <Checkbox
                                                                              name="admin"
                                                                              color="primary"
                                                                              onClick={onChangeSecurity}
                                                                              checked={selectedUser.security.admin}
                                                                              disabled={readOnly}
                                                                           />
                                                                        }
                                                                        label="Admin"
                                                                     />
                                                                  </Grid>
                                                               </form>
                                                            </Paper>
                                                         </div>
                                                      </Grid>
                                                   </Grid>
                                                </Grid>
                                                {/*Right Pane*/}
                                                <Grid item xs={6}>
                                                   <Grid
                                                      container
                                                      direction={"column"}
                                                      justifyContent="space-between"
                                                      alignItems="center"
                                                   >
                                                      {/*Column 1*/}
                                                      <Grid item style={{ width: "100%" }}>
                                                         <div
                                                            style={{
                                                               padding: "1%",
                                                               width: "100%",
                                                               marginTop: "-1.25rem",
                                                            }}
                                                         >
                                                            <Paper elevation={3}>
                                                               <h5>Terminals</h5>
                                                               <TransferList
                                                                  leftItems={transferListLeft}
                                                                  rightItems={transferListRight}
                                                                  width={260}
                                                                  height={800}
                                                                  getLists={getLists}
                                                                  leftLabel="Available Terminals"
                                                                  rightLabel="Assigned Terminals"
                                                               />
                                                            </Paper>
                                                         </div>
                                                      </Grid>
                                                      {/*Column 2*/}
                                                      <Grid item style={{ width: "100%" }}>
                                                         <div
                                                            style={{
                                                               padding: "1%",
                                                               width: "100%",
                                                               marginTop: "-1.25rem",
                                                            }}
                                                         >
                                                            <Paper elevation={3}>
                                                               <h5>Security</h5>
                                                               <List dense>
                                                                  <ListItem>
                                                                     <ListItemText primary={`Allow Edit Rate`} />
                                                                     <ListItemSecondaryAction>
                                                                        <Checkbox
                                                                           color="secondary"
                                                                           edge="end"
                                                                           onChange={onChangeSecurity}
                                                                           checked={
                                                                              !selectedUser.security.editRate
                                                                                 ? false
                                                                                 : true
                                                                           }
                                                                           name="editRate"
                                                                           disabled={readOnly}
                                                                        />
                                                                     </ListItemSecondaryAction>
                                                                  </ListItem>
                                                                  <ListItem>
                                                                     <ListItemText
                                                                        primary={`Allow Accessorials Override`}
                                                                     />
                                                                     <ListItemSecondaryAction>
                                                                        <Checkbox
                                                                           color="secondary"
                                                                           edge="end"
                                                                           onChange={onChangeSecurity}
                                                                           checked={
                                                                              !selectedUser.security.overRideAA
                                                                                 ? false
                                                                                 : true
                                                                           }
                                                                           name="overRideAA"
                                                                           disabled={readOnly}
                                                                        />
                                                                     </ListItemSecondaryAction>
                                                                  </ListItem>
                                                                  <ListItem>
                                                                     <ListItemText primary={`Allow Fuel Override`} />
                                                                     <ListItemSecondaryAction>
                                                                        <Checkbox
                                                                           color="secondary"
                                                                           edge="end"
                                                                           onChange={onChangeSecurity}
                                                                           checked={
                                                                              !selectedUser.security.overRideFuel
                                                                                 ? false
                                                                                 : true
                                                                           }
                                                                           name="overRideFuel"
                                                                           disabled={readOnly}
                                                                        />
                                                                     </ListItemSecondaryAction>
                                                                  </ListItem>
                                                                  <ListItem>
                                                                     <ListItemText
                                                                        primary={`Edit Rate Discount Limit`}
                                                                     />
                                                                     <ListItemSecondaryAction>
                                                                        <Input
                                                                           id="discount-rate"
                                                                           value={
                                                                              selectedUser.security
                                                                                 .discountRatePercentage
                                                                           }
                                                                           name="discountRatePercentage"
                                                                           type="number"
                                                                           inputProps={inputProps}
                                                                           onChange={onChangeSecurity}
                                                                           startAdornment={
                                                                              <InputAdornment position="start">
                                                                                 %
                                                                              </InputAdornment>
                                                                           }
                                                                           disabled={readOnly}
                                                                        />
                                                                     </ListItemSecondaryAction>
                                                                  </ListItem>
                                                               </List>
                                                            </Paper>
                                                         </div>
                                                      </Grid>
                                                   </Grid>
                                                </Grid>
                                             </Grid>
                                          </Box>
                                       )}
                                       {tabsValue === 1 && (
                                          <Box p={3}>
                                             <ActivityLogGrid
                                                columns={[
                                                   {
                                                      field: "date",
                                                      headerName: "Date",
                                                      description: "Date",
                                                      type: "date",
                                                      flex: 0.1,
                                                   },
                                                   {
                                                      field: "hour",
                                                      headerName: "Hour",
                                                      description: "Hour",
                                                      type: "time",
                                                      flex: 0.1,
                                                   },

                                                   {
                                                      field: "event",
                                                      headerName: "Event",
                                                      description: "Event",
                                                      type: "string",
                                                      flex: 0.2,
                                                   },
                                                   {
                                                      field: "description",
                                                      headerName: "Event Description",
                                                      description: "Event Description",
                                                      type: "string",
                                                      flex: 0.6,
                                                   },
                                                ]}
                                                tableData={activityTableData}
                                             />
                                          </Box>
                                       )}
                                    </Paper>
                                 </Box>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                  </>
               )}
               <ActivityDialog
                  open={activityModal}
                  handleClose={() => setActivityModal(!activityModal)}
                  activityOptions={{ module: "USER", record_id: props.match.params.id, rows: null }}
               />
            </div>
         </div>
      </>
   );
};

function mapStateToProps(state, ownProps) {
   return {
      terminals: state.terminal.terminals || [],
      users: state.user.internalUsers || [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getInternalUsers: () => dispatch(userActions.getInternalUsers()),
   };
}

UserDetails.propTypes = {
   match: PropTypes.shape({
      params: PropTypes.shape({
         id: PropTypes.string.isRequired,
      }),
   }),
   user: PropTypes.object.isRequired,
   terminals: PropTypes.array.isRequired,
   users: PropTypes.array.isRequired,
   getInternalUsers: PropTypes.func.isRequired,
};

UserDetails.defaultProps = {
   match: { params: { id: "" } },
   user: {},
   terminals: [],
   users: [],
   getInternalUsers: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
