import * as accessorialApi from "../../api/accessorialApi";
import actionTypes from "./actionTypes";

export function getGuides() {
   return function (dispatch) {
      accessorialApi
         .getGuides()
         .then((resp) => {
            dispatch({ type: actionTypes.GET_ACCESSORIAL_GUIDES, guides: resp || [] });
         })
         .catch((err) => {
            console.error("Getting accessorial guides error: " + err);
            return [];
         });
   };
}
