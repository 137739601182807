import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
// Components
import MessageManagementEdit from "./MessageDetail/messageManagementEdit";
import MessageManagement from "./MessageManagement";

const MessageManagementRouter = (props) => {
   var history = useHistory();

   useEffect(() => {
      if (props.user) {
         if (!props.user.security.admin) {
            history.push("/ratemanagement");
         }
      }
   }, [props.user]);

   return (
      <>
         <Switch>
            <Route path="/adminmessagemanagement" exact component={MessageManagement} />
            <Route path="/adminmessagemanagement/:id" exact component={MessageManagementEdit} />
         </Switch>
      </>
   );
};

function mapStateToProps(state, ownProps) {
   return {
      user: state.user.currentUser || null,
   };
}

export default connect(mapStateToProps)(MessageManagementRouter);
