import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import * as applicationApi from "../../api/applicationApi";
import { sortAlphabetically } from "../../common/sortAlphabetically";
import { stateList } from "../../common/stateList";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const TerminalNew = (props) => {
   var handleError = useErrorHandler();

   let [saving, setSaving] = useState(false);
   let [saveErrors, setSaveErrors] = useState(null);
   const [fieldsWereModified, setFieldsWereModified] = useState(false);
   let [newTerm, setNewTerm] = useState({
      _id: null,
      code: "",
      name: "",
      address: {
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         country: "",
      },
      security: {
         terminal_ids: [],
      },
      active: true,
      add_date: "",
      fuelTariff_id: "",
      accessorialTariff_id: "",
   });

   useEffect(() => {
      setNewTerm({
         _id: null,
         code: "",
         name: "",
         address: {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
         },
         security: {
            terminal_ids: [],
         },
         active: true,
         add_date: "",
         fuelTariff_id: "",
         accessorialTariff_id: "",
      });
   }, [props]);

   useEffect(() => {
      if (!props.open) {
         setFieldsWereModified(false);
      }
   }, [props.open]);

   function saveNewTerminal() {
      // Validate Name and Code non-empty
      if (
         newTerm.name.length === 0 ||
         newTerm.code.length === 0 ||
         newTerm.fuelTariff_id.length === 0 ||
         newTerm.accessorialTariff_id.length === 0
      ) {
         setFieldsWereModified(true);
         return;
      }

      setSaving(true);

      applicationApi
         .saveTerminal(newTerm, true)
         .then((resp) => {
            if (!resp.success) {
               setSaveErrors(resp.message);
            } else {
               //success
               props.openAlertMessage("Terminal Successfully Added.", "success");
               props.getTerminals();
               props.handleClose();
               setSaving(false);
            }
         })
         .catch((err) => {
            setSaving(false);
            setSaveErrors(`Could Not Save New Terminal: ${err}`);
         });
   }

   function onChange(event) {
      try {
         var selectedTerminal = _.cloneDeep(newTerm);
         selectedTerminal[event.target.name] =
            event.target.type === "checkbox" ? event.target.checked : event.target.value;
         setNewTerm(selectedTerminal);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeAddress(event) {
      try {
         var selectedTerminal = _.cloneDeep(newTerm);
         selectedTerminal.address[event.target.name] = event.target.value;
         setNewTerm(selectedTerminal);
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <Dialog
         open={props.open}
         TransitionComponent={Transition}
         keepMounted
         onClose={props.handleClose}
         aria-labelledby="alert-dialog-slide-title"
         aria-describedby="alert-dialog-slide-description"
      >
         <Collapse in={saveErrors ? true : false}>
            <Alert
               style={{ color: "#FFFFFF" }}
               variant="filled"
               severity="error"
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setSaveErrors(null);
                     }}
                  >
                     <CloseIcon fontSize="inherit" />
                  </IconButton>
               }
            >
               {saveErrors}
            </Alert>
         </Collapse>
         <DialogTitle id="alert-dialog-slide-title">New Terminal</DialogTitle>
         <DialogContent>
            <form>
               <TextField
                  error={newTerm.name.length > 0 || !fieldsWereModified ? false : true}
                  style={{ marginRight: "1rem" }}
                  id={newTerm.name.length > 0 || fieldsWereModified ? "standard-basic" : "outlined-error-helper-text"}
                  label="Name"
                  helperText={newTerm.name.length === 0 && fieldsWereModified && "Required Field"}
                  variant="standard"
                  name="name"
                  value={newTerm.name}
                  onChange={onChange}
                  required
               />
               <TextField
                  error={newTerm.code.length > 0 || !fieldsWereModified ? false : true}
                  style={{ marginRight: "1rem" }}
                  id={newTerm.code.length > 0 || fieldsWereModified ? "standard-basic" : "outlined-error-helper-text"}
                  label="Code"
                  helperText={newTerm.code.length === 0 && fieldsWereModified && "Required Field"}
                  variant="standard"
                  name="code"
                  value={newTerm.code}
                  onChange={onChange}
                  required
               />
               <TextField
                  style={{ marginRight: "1rem" }}
                  id="standard-basic"
                  label="Address"
                  variant="standard"
                  name="address1"
                  value={newTerm.address.address1}
                  onChange={(e) => onChangeAddress(e)}
               />
               <TextField
                  style={{ marginRight: "1rem" }}
                  id="standard-basic"
                  label="City"
                  variant="standard"
                  name="city"
                  value={newTerm.address.city}
                  onChange={onChangeAddress}
               />
               <TextField
                  style={{ marginRight: "1rem" }}
                  id="standard-basic"
                  label="Address 2"
                  variant="standard"
                  name="address2"
                  value={newTerm.address.address2}
                  onChange={onChangeAddress}
               />
               <TextField
                  style={{ marginRight: "1rem" }}
                  id="standard-basic"
                  label="Zip"
                  variant="standard"
                  name="zip"
                  value={newTerm.address.zip}
                  onChange={(e) => onChangeAddress(e)}
               />
               <FormControl style={{ width: "5rem", marginRight: "1rem", marginTop: ".5rem" }}>
                  <InputLabel id="state-simple-select-label" style={{ marginLeft: "-.8rem" }}>
                     State
                  </InputLabel>
                  <Select
                     labelId="state-simple-select-label"
                     id="state-simple-select"
                     value={newTerm.address.state}
                     name="state"
                     onChange={(e) => onChangeAddress(e)}
                     variant="standard"
                  >
                     <MenuItem value={null}>Choose...</MenuItem>
                     {stateList.map((state, index) => {
                        return (
                           <MenuItem key={`mi-${index}`} value={state}>
                              {state}
                           </MenuItem>
                        );
                     })}
                  </Select>
               </FormControl>
               <FormControl
                  style={{ width: "10rem", marginRight: "1rem", marginTop: ".5rem" }}
                  error={newTerm.fuelTariff_id.length > 0 || !fieldsWereModified ? false : true}
               >
                  <InputLabel id="il-fuel-tariff" style={{ marginLeft: "-.8rem" }}>
                     Fuel Tariff
                  </InputLabel>
                  <Select name="fuelTariff_id" value={newTerm.fuelTariff_id} onChange={onChange} variant="standard">
                     {sortAlphabetically(props.fuelTariffs).map((tar, index) => {
                        return (
                           <MenuItem key={`mi-fuelTariffs-${index}`} value={tar._id}>
                              {tar.name}
                           </MenuItem>
                        );
                     })}
                  </Select>
                  <FormHelperText id="fht-fuel-tariff" style={{ marginLeft: "-.10rem" }}>
                     {newTerm.fuelTariff_id.length === 0 && fieldsWereModified && "Required Field"}
                  </FormHelperText>
               </FormControl>
               <FormControl
                  style={{ width: "10rem", marginTop: ".5rem" }}
                  error={newTerm.accessorialTariff_id.length > 0 || !fieldsWereModified ? false : true}
               >
                  <InputLabel id="il-accessorial-tariff" style={{ marginLeft: "-.8rem" }}>
                     Accessorial Tariff
                  </InputLabel>
                  <Select
                     name="accessorialTariff_id"
                     value={newTerm.accessorialTariff_id}
                     onChange={onChange}
                     variant="standard"
                  >
                     {sortAlphabetically(props.accessorialTariffs).map((tar, index) => {
                        return (
                           <MenuItem key={`mi-accessorialTariffs-${index}`} value={tar._id}>
                              {tar.name}
                           </MenuItem>
                        );
                     })}
                  </Select>
                  <FormHelperText id="fht-fuel-tariff" style={{ marginLeft: "-.10rem" }}>
                     {newTerm.accessorialTariff_id.length === 0 && fieldsWereModified && "Required Field"}
                  </FormHelperText>
               </FormControl>
            </form>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.handleClose} variant="contained" color="secondary">
               Cancel
            </Button>
            <Button disabled={saving} onClick={saveNewTerminal} variant="contained" color="primary">
               Save
            </Button>
         </DialogActions>
      </Dialog>
   );
};

function mapStateToProps(state) {
   return {
      user: state.user.currentUser,
      fuelTariffs: state.fuel.tariff || [],
      accessorialTariffs: state.accessorial.tariff || [],
   };
}

TerminalNew.propTypes = {
   open: PropTypes.bool.isRequired,
   termGroupList: PropTypes.array.isRequired,
   terminals: PropTypes.array.isRequired,
   fuelTariffs: PropTypes.array.isRequired,
   accessorialTariffs: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   getTerminals: PropTypes.func.isRequired,
   handleClose: PropTypes.func.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
};

TerminalNew.defaultProps = {
   open: false,
   termGroupList: [],
   terminals: [],
   fuelTariffs: [],
   accessorialTariffs: [],
   user: {},
   getTerminals: () => {
      return;
   },
   handleClose: () => {
      return;
   },
   openAlertMessage: () => {
      return;
   },
};

export default connect(mapStateToProps)(TerminalNew);
