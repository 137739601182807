import actionTypes from "../actions/actionTypes";

const initialState = {
   customer: [],
   user: [],
};

export default function customerReducer(state = initialState, action) {
   switch (action.type) {
      case actionTypes.GET_CUSTOMERS:
         return { ...state, customer: action.customer, user: action.customerUser };
      default:
         return state;
   }
}
