import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, InputAdornment, Paper, TextField, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as accessorialApi from "../../api/accessorialApi";
import { _handleObjectProperty } from "../../common/convert";
import { escapeRegExp } from "../../common/escapeRegExp";
import * as accessorialActions from "../../redux/actions/accessorialActions";
import DeleteDialog from "../Delete/DeleteDialog";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";
import AccessorialTariffNew from "./AccessorialTariffNew";

const AccessorialTariffView = (props) => {
   //////////////////////////init //////////////////////////////////////////////////////////
   const history = useHistory();
   const handleError = useErrorHandler();

   const [tariffList, setTariffList] = useState([]);
   const [tableData, setTableData] = useState([]);
   const [gridLoading, setGridLoading] = useState(true);
   const [deleteModal, setDeleteModal] = useState(false);
   const [newTariffModal, setNewTariffModal] = useState(false);
   const [selectedTariff, setSelectedTariff] = useState(null);
   const [openDrawer, setOpenDrawer] = useState(false);
   const [showAlert, setShowAlert] = useState(false);
   const [alertMessage, setAlertMessage] = useState(null);
   const [alertType, setAlertType] = useState("");
   const [readOnly, setReadOnly] = useState(false);

   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         borderBottom: "1px solid #2F3136",
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      save: {
         backgroundColor: "#002D72",
         float: "right",
         color: "white",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "#010440",
         },
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
      listDrawer: {
         width: "300px",
         maxWidth: 360,
         backgroundColor: theme.palette.background.paper,
      },
   }));

   const classes = useStyles();

   function newTariffClick() {
      try {
         setNewTariffModal(true);
         setOpenDrawer(false);
      } catch (err) {
         handleError(err);
      }
   }

   //create header state
   var headerData = [
      { id: "name", allign: "left", label: "Name", edit: false, type: "string" },
      { id: "currentchassispo", allign: "center", label: "Chassis Split", edit: false, type: "decimal" },
      { id: "currentchassisstd", allign: "center", label: "Chassis Usage", edit: false, type: "decimal" },
      { id: "currentchassistri", allign: "center", label: "Triaxle", edit: false, type: "decimal" },
      { id: "currenthazardous", allign: "center", label: "Hazardous", edit: false, type: "decimal" },
      { id: "currentreefer", allign: "center", label: "Reefer", edit: false, type: "decimal" },
      { id: "currentstopoff", allign: "center", label: "Stop Off", edit: false, type: "decimal" },
      { id: "currentscale", allign: "center", label: "Scale", edit: false, type: "decimal" },
      { id: "currentstorage", allign: "center", label: "Storage", edit: false, type: "decimal" },
      { id: "currentprepull", allign: "center", label: "PrePull", edit: false, type: "decimal" },
      { id: "active", allign: "center", label: "Active", edit: false, type: "boolean" },
      { id: "add_date", allign: "center", label: "Date Added", edit: false, type: "date" },
   ];

   //on init, get the current tariff into and create a new attribute for each current item from accessorialGuide array
   useEffect(() => {
      async function createTableList() {
         var newTariffList = [];
         await _.forEach(props.tariffs, async (tar) => {
            var newTariff = _.cloneDeep(tar);

            newTariff.id = _handleObjectProperty(newTariff, "_id") ? newTariff._id : 0;

            let tariffFound = _.filter(newTariff.accessorialGuide, (x) => x.name.toLowerCase() === "detention");

            newTariff.currentdetention = tariffFound.length > 0 ? (tariffFound[0].value ? tariffFound[0].value : 0) : 0;

            tariffFound = _.filter(newTariff.accessorialGuide, (x) => x.name.toLowerCase() === "chassis split");

            newTariff.currentchassispo = tariffFound.length > 0 ? (tariffFound[0].value ? tariffFound[0].value : 0) : 0;

            tariffFound = _.filter(newTariff.accessorialGuide, (x) => x.name.toLowerCase() === "chassis rental");

            newTariff.currentchassisstd =
               tariffFound.length > 0 ? (tariffFound[0].value ? tariffFound[0].value : 0) : 0;

            tariffFound = _.filter(newTariff.accessorialGuide, (x) => x.name.toLowerCase() === "triaxle");

            newTariff.currentchassistri =
               tariffFound.length > 0 ? (tariffFound[0].value ? tariffFound[0].value : 0) : 0;

            tariffFound = _.filter(newTariff.accessorialGuide, (x) => x.name.toLowerCase() === "hazardous");

            newTariff.currenthazardous = tariffFound.length > 0 ? (tariffFound[0].value ? tariffFound[0].value : 0) : 0;

            tariffFound = _.filter(newTariff.accessorialGuide, (x) => x.name.toLowerCase() === "reefer");

            newTariff.currentreefer = tariffFound.length > 0 ? (tariffFound[0].value ? tariffFound[0].value : 0) : 0;

            tariffFound = _.filter(newTariff.accessorialGuide, (x) => x.name.toLowerCase() === "howland hook");

            newTariff.currenthowland = tariffFound.length > 0 ? (tariffFound[0].value ? tariffFound[0].value : 0) : 0;

            tariffFound = _.filter(newTariff.accessorialGuide, (x) => x.name.toLowerCase() === "liquor");

            newTariff.currentliquor = tariffFound.length > 0 ? (tariffFound[0].value ? tariffFound[0].value : 0) : 0;

            tariffFound = _.filter(newTariff.accessorialGuide, (x) => x.name.toLowerCase() === "stop-off");

            newTariff.currentstopoff = tariffFound.length > 0 ? (tariffFound[0].value ? tariffFound[0].value : 0) : 0;

            tariffFound = _.filter(newTariff.accessorialGuide, (x) => x.name.toLowerCase() === "scale");

            newTariff.currentscale = tariffFound.length > 0 ? (tariffFound[0].value ? tariffFound[0].value : 0) : 0;

            tariffFound = _.filter(newTariff.accessorialGuide, (x) => x.name.toLowerCase() === "storage");

            newTariff.currentstorage = tariffFound.length > 0 ? (tariffFound[0].value ? tariffFound[0].value : 0) : 0;

            tariffFound = _.filter(newTariff.accessorialGuide, (x) => x.name.toLowerCase() === "pre-pull");

            newTariff.currentprepull = tariffFound.length > 0 ? (tariffFound[0].value ? tariffFound[0].value : 0) : 0;

            tariffFound = _.filter(newTariff.accessorialGuide, (x) => x.name.toLowerCase() === "bond fee");

            newTariff.currentbond = tariffFound.length > 0 ? (tariffFound[0].value ? tariffFound[0].value : 0) : 0;

            await newTariffList.push(newTariff);
         });

         setTariffList(newTariffList);
         setTableData(newTariffList);
         setGridLoading(false);
      }

      //call the above function
      createTableList();
   }, [props.tariffs]);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "accessorial");
      var userReadOnly = true;

      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   function onChangeFilter(event, searchValue = null) {
      try {
         //create blank array for return
         var filteredObjects = [];
         //this turns values into lowercase strings
         var regValue = new RegExp(
            searchValue ? searchValue.toLowerCase() : escapeRegExp(event.target.value).toLowerCase(),
            "g",
         );
         //loop through header data since it contains the field names we need to check
         headerData.map((header) => {
            var filter = [];
            //we can only do this on strings, so make sure the type is filled in via the parent component
            if (header.type === "string") {
               //fill the filter on the original table data that was passed in from parent
               filter = tariffList.filter((filt) => {
                  //return a match where the filter index is the name of the header id
                  return filt[header.id] ? filt[header.id].toLowerCase().match(regValue) : null;
               });
            } else {
               //fill the filter on the original table data that was passed in from parent
               filter = tariffList.filter(
                  (filt) => filt[header.id === (searchValue ? searchValue : event.target.value)],
               );
            }
            //check to see if it returned objects
            if (filter.length > 0) {
               //concat merges arrays since we are adding data from one array to another.  Push only adds a single item/object to an existing array
               filteredObjects = filteredObjects.concat(filter);
            }
         });
         //remove duplicates
         filteredObjects = _.uniqBy(filteredObjects, "_id");

         if (filteredObjects) {
            if (filteredObjects.length) {
               Object.keys(filteredObjects).forEach(function (key) {
                  filteredObjects[key].id = filteredObjects[key]._id;
               });
            }
         }

         //set the hook with the newly filtered data and it will render
         setTableData(filteredObjects);
         setGridLoading(false);
      } catch (err) {
         setGridLoading(false);
         handleError(err);
      }
   }

   function handleEditClick(tariff) {
      try {
         var newSelectedTariff = _.cloneDeep(tariff);
         history.push({ pathname: `/accessorialtariff/details/${newSelectedTariff.row._id}` });
      } catch (err) {
         handleError(err);
      }
   }

   function handleDeleteClick(tariff) {
      try {
         setSelectedTariff(tariff.row);
         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   const deleteTariff = () => {
      setDeleteModal(!deleteModal);

      accessorialApi
         .deleteTariff(selectedTariff._id)
         .then((resp) => {
            openAlertMessage("Tariff Successfully Deleted.", "success");
            props.getAccessorialTariffs();
         })
         .catch((err) => {
            openAlertMessage(`Error deleting tariff: ${err}`, "error");
         });
   };

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   return (
      <>
         <div id={"accessorialTariffView"} className={classes.layoutMain}>
            <div id={"accessorialTariffViewHeader"} className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                  <Grid item xs={7}>
                     <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                        <Link
                           color="inherit"
                           style={{ cursor: "pointer" }}
                           onClick={() => breadcrumbOnClick("/accessorialtariff")}
                        >
                           <h3 style={{ fontWeight: "500" }}>{"Accessorial Tariffs"}</h3>
                        </Link>
                     </Breadcrumbs>
                  </Grid>
                  <Grid item xs={3} display="flex" justifyContent="flex-end">
                     <TextField
                        style={{
                           width: "100%",
                           borderRadius: "3px",
                        }}
                        id="filled-basic"
                        placeholder="Search"
                        size="small"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <SearchIcon style={{ color: "#000000DE" }} />
                              </InputAdornment>
                           ),
                        }}
                        onChange={onChangeFilter}
                     />
                  </Grid>
                  <Grid item xs={2}>
                     <Tooltip title="New Accessorial" arrow>
                        <IconButton
                           style={{ float: "right", marginRight: "1rem" }}
                           variant="contained"
                           color="primary"
                           onClick={newTariffClick}
                           disabled={readOnly}
                        >
                           <AddIcon style={{ fontSize: "2rem" }} />
                        </IconButton>
                     </Tooltip>
                  </Grid>
               </Grid>
               <Collapse in={showAlert}>
                  <Alert
                     style={{ color: "#FFFFFF" }}
                     variant="filled"
                     severity={alertType ? alertType : "success"}
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           sx={{ marginBottom: "0.25rem" }}
                           onClick={() => {
                              {
                                 closeAlertMessage();
                              }
                           }}
                        >
                           <CloseIcon fontSize="inherit" />
                        </IconButton>
                     }
                  >
                     {alertMessage}
                  </Alert>
               </Collapse>
            </div>
            <div
               id={"accessorialTariffViewBody"}
               className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}
            >
               <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
                  <Grid item xs={12}>
                     <Paper elevation={3}>
                        <StyledDataGrid
                           gridHeight={"785px"}
                           loading={gridLoading}
                           columns={[
                              {
                                 field: "name",
                                 headerName: "Accessorial Name",
                                 description: "Name of the accessorial.",
                                 type: "string",
                                 flex: 0.3,
                              },
                              {
                                 field: "currentchassispo",
                                 headerName: "Chassis Split",
                                 description: "Cost of a chassis split.",
                                 type: "number",
                                 headerAlign: "left",
                                 align: "left",
                                 flex: 0.2,
                              },
                              {
                                 field: "currentchassisstd",
                                 headerName: "Chassis Usage",
                                 description: "Cost of a chassis usage per day.",
                                 type: "number",
                                 headerAlign: "left",
                                 align: "left",
                                 flex: 0.2,
                              },
                              {
                                 field: "currentchassistri",
                                 headerName: "Triaxle",
                                 description: "Cost of a triaxle.",
                                 type: "number",
                                 headerAlign: "left",
                                 align: "left",
                                 flex: 0.2,
                              },
                              {
                                 field: "currenthazardous",
                                 headerName: "Hazardous",
                                 description: "Cost of a hazardous shipping.",
                                 type: "number",
                                 headerAlign: "left",
                                 align: "left",
                                 flex: 0.2,
                              },
                              {
                                 field: "currentreefer",
                                 headerName: "Reefer",
                                 description: "Cost of using a reefer.",
                                 type: "number",
                                 headerAlign: "left",
                                 align: "left",
                                 flex: 0.2,
                              },
                              {
                                 field: "currentstopoff",
                                 headerName: "Stop-Off",
                                 description: "Cost of a stop-off.",
                                 type: "number",
                                 headerAlign: "left",
                                 align: "left",
                                 flex: 0.2,
                              },
                              {
                                 field: "currentscale",
                                 headerName: "Scale",
                                 description: "Cost of a scale ticket.",
                                 type: "number",
                                 headerAlign: "left",
                                 align: "left",
                                 flex: 0.2,
                              },
                              {
                                 field: "currentstorage",
                                 headerName: "Storage",
                                 description: "Cost of storage per day.",
                                 type: "number",
                                 headerAlign: "left",
                                 align: "left",
                                 flex: 0.2,
                              },
                              {
                                 field: "currentprepull",
                                 headerName: "Pre-Pull",
                                 description: "Cost of a pre-pull.",
                                 type: "number",
                                 headerAlign: "left",
                                 align: "left",
                                 flex: 0.2,
                              },
                              {
                                 field: "_id",
                                 headerName: " ", //This is a space because material-UI default to field unless there is a value.
                                 type: "string",
                                 sortable: false,
                                 filterable: false,
                                 renderCell: (params) => (
                                    <div>
                                       <Tooltip title="Edit Accessorial" arrow>
                                          <IconButton
                                             variant="contained"
                                             color="primary"
                                             size="small"
                                             onClick={() => handleEditClick(params)}
                                          >
                                             <EditIcon />
                                          </IconButton>
                                       </Tooltip>
                                       |
                                       <Tooltip title="Delete Accessorial" arrow>
                                          <IconButton
                                             variant="contained"
                                             color="secondary"
                                             size="small"
                                             onClick={() => handleDeleteClick(params)}
                                             disabled={readOnly}
                                          >
                                             <DeleteForeverIcon />
                                          </IconButton>
                                       </Tooltip>
                                    </div>
                                 ),
                                 flex: 0.15,
                              },
                           ]}
                           rows={tableData ? tableData : null}
                        ></StyledDataGrid>
                     </Paper>
                  </Grid>
               </Grid>
               {/* Modals */}
               <AccessorialTariffNew
                  open={newTariffModal}
                  handleClose={() => setNewTariffModal(!newTariffModal)}
                  getTariffs={props.getAccessorialTariffs}
                  openAlertMessage={openAlertMessage}
                  user={props.user}
               />
               <DeleteDialog
                  open={deleteModal}
                  handleCancel={() => setDeleteModal(!deleteModal)}
                  deleteFunc={deleteTariff}
                  title={selectedTariff && `Delete ${selectedTariff.name}`}
                  text={
                     selectedTariff &&
                     `Are you sure you want to delete ${selectedTariff.name} from the accessorial tariffs?`
                  }
               />
            </div>
         </div>
      </>
   );
};

function mapStateToProps(state, ownProps) {
   return {
      tariffs: state.accessorial.tariff || [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getAccessorialTariffs: () => dispatch(accessorialActions.getTariffs()),
   };
}

AccessorialTariffView.propTypes = {
   tariffs: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   getAccessorialTariffs: PropTypes.func.isRequired,
};

AccessorialTariffView.defaultProps = {
   tariffs: [],
   user: {},
   getAccessorialTariffs: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessorialTariffView);
