import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import * as accessorialApi from "../../api/accessorialApi";
import { _handleObjectProperty } from "../../common/convert";
import { sortAlphabetically } from "../../common/sortAlphabetically";
import * as accessorialGuideActions from "../../redux/actions/accessorialGuideActions";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const AccessorialTariffNew = (props) => {
   var handleError = useErrorHandler();

   const [guideList, setGuideList] = useState([]);
   let [saving, setSaving] = useState(false);
   let [saveErrors, setSaveErrors] = useState(null);
   let [errorSeverity, setErrorSeverity] = useState("");

   let [newTariff, setNewTariff] = useState({
      _id: null,
      name: "",
      accessorialGuide: [{}],
      active: true,
   });

   useEffect(() => {
      props.getAccessorialGuides();
   }, []);

   useEffect(() => {
      let newAccessorialGuide = [...props.accessorialGuides];

      if (newAccessorialGuide) {
         if (newAccessorialGuide.length) {
            Object.keys(newAccessorialGuide).forEach(function (key) {
               newAccessorialGuide[key].id = newAccessorialGuide[key]._id;
            });

            //sort by name
            newAccessorialGuide = sortAlphabetically(newAccessorialGuide);

            setGuideList(newAccessorialGuide);
         }
      }

      const newGuideArr = [];
      guideList.map((guide) => {
         const newGuideObject = {};
         newGuideObject["_id"] = guide._id;
         newGuideObject["name"] = guide.name;
         newGuideObject["pdfVerbiage"] = guide.pdfVerbiage;
         newGuideObject["value"] = "";
         newGuideArr.push(newGuideObject);
      });

      setNewTariff({
         _id: null,
         name: "",
         accessorialGuide: [...newGuideArr],
         active: true,
      });
   }, [props.accessorialGuides]);

   function saveNewTariff() {
      //validate name
      if (!newTariff.name) {
         setSaveErrors("Name field is empty. Please enter a Accessorial Tariff name");
         setErrorSeverity("warning");
         return;
      }

      //validate tariffInfo
      if (_handleObjectProperty(newTariff, "accessorialGuide") === null) {
         setSaveErrors("Please enter tariff information.");
         setErrorSeverity("warning");
         return;
      }

      //validate 1 by 1 of the fields
      let validation = true;
      newTariff.accessorialGuide.map((guide) => {
         if (!guide.value || guide.value === "") {
            setSaveErrors(`Please enter a ${guide.name} amount`);
            setErrorSeverity("warning");
            validation = false;
            return;
         }
      });

      if (!validation) return;

      setSaving(true);

      accessorialApi
         .setTariffs(newTariff)
         .then((resp) => {
            props.openAlertMessage("Accessorial Tariff Added.", "success");
            props.getTariffs();
            props.handleClose();
            setSaving(false);
         })
         .catch((err) => {
            setSaving(false);
            setSaveErrors(`Could Not Save New Tariff: ${err}`);
            setErrorSeverity("error");
         });
   }
   function onChange(event) {
      try {
         var selectedTariff = _.cloneDeep(newTariff);
         selectedTariff[event.target.name] =
            event.target.type === "checkbox" ? event.target.checked : event.target.value;
         setNewTariff(selectedTariff);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeTariffInfo(event, i) {
      const { name, value } = event.target;
      const clonedNewTariff = { ...newTariff };
      clonedNewTariff.accessorialGuide[i][name] = value;
      setNewTariff({ ...clonedNewTariff });
   }

   const inputProps = {
      step: 0.01,
   };

   return (
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.handleClose}>
         <Collapse in={saveErrors ? true : false}>
            <Alert
               style={{ color: "#FFFFFF" }}
               variant="filled"
               severity={errorSeverity}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     sx={{ marginBottom: "0.25rem" }}
                     onClick={() => {
                        setSaveErrors(null);
                     }}
                  >
                     <CloseIcon fontSize="inherit" />
                  </IconButton>
               }
            >
               {saveErrors}
            </Alert>
         </Collapse>
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            New Accessorial Tariff
         </DialogTitle>
         <DialogContent>
            <form noValidate autoComplete="off" style={{ textAlign: "center" }}>
               <Grid item style={{ marginTop: "2rem" }}>
                  <TextField
                     id="tf-tariffName"
                     style={{ margin: "1rem" }}
                     label="Tariff Name"
                     variant="outlined"
                     name="name"
                     onChange={onChange}
                     type="text"
                     value={newTariff.name}
                  />
                  {guideList.map((guide, i) => (
                     <TextField
                        key={guide._id}
                        id={`tf-${guide.name}`}
                        style={{ margin: "1rem" }}
                        label={guide.name}
                        variant="outlined"
                        name={"value"}
                        onChange={(e) => onChangeTariffInfo(e, i)}
                        inputProps={inputProps}
                        value={newTariff.accessorialGuide[i]?.value || ""}
                        type="number"
                     />
                  ))}
               </Grid>
            </form>
         </DialogContent>
         <DialogActions>
            <Tooltip title="Exit" arrow>
               <IconButton onClick={props.handleClose} variant="contained" color="default">
                  <CloseIcon />
               </IconButton>
            </Tooltip>
            <Tooltip title="Save New Tariff" arrow>
               <IconButton disabled={saving} onClick={saveNewTariff} variant="contained" color="primary">
                  <SaveIcon />
               </IconButton>
            </Tooltip>
         </DialogActions>
      </Dialog>
   );
};

function mapStateToProps(state) {
   return {
      accessorialGuides: state.accessorialGuide.accessorialGuide || [],
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getAccessorialGuides: () => dispatch(accessorialGuideActions.getGuides()),
   };
}

AccessorialTariffNew.propTypes = {
   accessorialGuides: PropTypes.array.isRequired,
   open: PropTypes.bool.isRequired,
   user: PropTypes.object.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
   getTariffs: PropTypes.func.isRequired,
   handleClose: PropTypes.func.isRequired,
};

AccessorialTariffNew.defaultProps = {
   accessorialGuides: [],
   open: false,
   user: {},
   getAccessorialGuides: () => {
      return;
   },
   openAlertMessage: () => {
      return;
   },
   getTariffs: () => {
      return;
   },
   handleClose: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessorialTariffNew);
