import CloseIcon from "@mui/icons-material/Close";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import { green, red } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import * as customerApi from "../../api/customerApi";
import { _handleObjectProperty } from "../../common/convert";
import { sortAlphabetically } from "../../common/sortAlphabetically";
import * as customerActions from "../../redux/actions/customerActions";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";
import TransferList from "../TransferList/TransferList";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
   wrapper: {
      margin: theme.spacing(1),
      position: "relative",
   },
   buttonProgress: {
      color: green[500],
   },
   checkIcon: {
      color: red[500],
   },
   label: {
      flexDirection: "column",
      fontSize: "9px",
   },
   buttonProgress: {
      color: green[500],
   },
   root: {
      "& .super-app.found": {
         backgroundColor: "#f2f2f2",
         fontWeight: "bold",
      },
      "& .super-app.notFound": {
         backgroundColor: "#ffffff",
      },
   },
}));

const CustomerFuelTariff = (props) => {
   const classes = useStyles();
   var handleError = useErrorHandler();

   const [alertMessage, setAlertMessage] = useState(null);
   const [alertType, setAlertType] = useState("");
   const [currentCustomer, setCurrentCustomer] = useState({});
   const [filteredTableData, setFilteredTableData] = useState([]);
   const [loading, setLoading] = useState(true);
   const [saving, setSaving] = useState(false);
   const [search, setSearch] = useState("");
   const [selectedFuelTariff, setSelectedFuelTariff] = useState(null);
   const [selectedTerminals, setSelectedTerminals] = useState([]);
   const [tableData, setTableData] = useState([]);
   const [transferListLeft, setTransferListLeft] = useState([]);
   const [transferListRight, setTransferListRight] = useState([]);

   //React Hook each time the screen opens/closes.
   useEffect(() => {
      //Reset selected fuel tariff.
      setSelectedFuelTariff(null);
      setAlertMessage(null);
      setAlertType("");
      setCurrentCustomer(_.cloneDeep(props.selectedCustomer));
   }, [props.customerFuelTariffModal]);

   useEffect(() => {
      //Reset terminals that are selected.
      let selectedTerms = [];

      if (_handleObjectProperty(currentCustomer, "fuelTariffs")) {
         currentCustomer.fuelTariffs.forEach((fuelTariff) => {
            selectedTerms = selectedTerms.concat(fuelTariff.terminal_ids);
         });
      }

      setSelectedTerminals(selectedTerms);

      var newFuelObject = _.cloneDeep(props.tariffs);

      if (newFuelObject) {
         if (newFuelObject.length) {
            Object.keys(newFuelObject).forEach(function (key) {
               newFuelObject[key].id = newFuelObject[key]._id;

               if (_handleObjectProperty(currentCustomer, "fuelTariffs")) {
                  let fuelTariffIndex = _.findIndex(currentCustomer.fuelTariffs, function (x) {
                     return x.fuelTariff_id.toString() === newFuelObject[key]._id.toString();
                  });

                  if (fuelTariffIndex > -1) {
                     newFuelObject[key].found = true;
                  } else {
                     newFuelObject[key].found = false;
                  }
               }
            });

            sortAlphabetically(newFuelObject);

            newFuelObject = _.orderBy(newFuelObject, (x) => x.found, "desc");

            setFilteredTableData(newFuelObject);
            setTableData(newFuelObject);
            setLoading(false);
         }
      }
   }, [currentCustomer]);

   //React Hook each time the selected terminals change.
   useEffect(() => {
      if (!selectedFuelTariff) {
         setTransferListLeft([]);
         setTransferListRight([]);
         return;
      }

      //load left and right columns of transfer list
      var leftList = [];
      var rightList = [];

      if (_handleObjectProperty(currentCustomer, "fuelTariffs")) {
         let fuelTariffIndex = _.findIndex(currentCustomer.fuelTariffs, function (x) {
            return x.fuelTariff_id.toString() === selectedFuelTariff._id.toString();
         });

         if (fuelTariffIndex > -1) {
            props.terminals.map((term) => {
               var termFound = _.find(
                  currentCustomer.fuelTariffs[fuelTariffIndex].terminal_ids,
                  (x) => x === term._id.toString(),
               );

               if (termFound) {
                  rightList.push({ _id: term._id, name: term.name });
               }
            });
         }
      } else {
         rightList = [];
      }

      leftList = _.filter(props.terminals, (x) => !_.includes(selectedTerminals, x._id));

      leftList = _.orderBy(leftList, ["name"], ["asc"]);
      rightList = _.orderBy(rightList, ["name"], ["asc"]);

      setTransferListLeft(leftList);
      setTransferListRight(rightList);
   }, [selectedFuelTariff]);

   useEffect(() => {
      if (!search) onSearch();
   }, [search]);

   //Create left and right list.
   const getLists = (leftList, rightList) => {
      try {
         leftList = _.orderBy(leftList, ["name"], ["asc"]);
         rightList = _.orderBy(rightList, ["name"], ["asc"]);

         setTransferListLeft(leftList);
         setTransferListRight(rightList);

         //refresh the selected terminals.
         let selectedTerms = [...selectedTerminals];
         let newSelectedCustomer = { ...currentCustomer };
         let newTerminalList = [];

         //Add terminals to selected terminals and create list for customer object.
         rightList.map((item) => {
            selectedTerms.push(item._id);
            newTerminalList.push(item._id);
         });

         //remove from selected terminals to display on left.
         leftList.map((item) => {
            let index = selectedTerms.indexOf(item._id);

            if (index !== -1) {
               selectedTerms = [...selectedTerms.slice(0, index), ...selectedTerms.slice(index + 1)];
            }
         });

         //Make sure unique.
         selectedTerms = _.uniqBy(selectedTerms);
         setSelectedTerminals(selectedTerms);

         //update selected customer's fule tariffs.
         if (_handleObjectProperty(newSelectedCustomer, "fuelTariffs")) {
            let fuelTariffIndex = _.findIndex(newSelectedCustomer.fuelTariffs, function (x) {
               return x.fuelTariff_id.toString() === selectedFuelTariff._id.toString();
            });

            if (fuelTariffIndex > -1) {
               if (newTerminalList.length > 0) {
                  newSelectedCustomer.fuelTariffs[fuelTariffIndex].terminal_ids = newTerminalList;
               } else {
                  newSelectedCustomer.fuelTariffs.splice(fuelTariffIndex, 1);
               }
            } else if (newTerminalList.length > 0) {
               newSelectedCustomer.fuelTariffs.push({
                  fuelTariff_id: selectedFuelTariff._id,
                  terminal_ids: newTerminalList,
               });
            }
         } else {
            if (newTerminalList.length > 0) {
               newSelectedCustomer.fuelTariffs = [];
               newSelectedCustomer.fuelTariffs.push({
                  fuelTariff_id: selectedFuelTariff._id,
                  terminal_ids: newTerminalList,
               });
            }
         }

         setCurrentCustomer(newSelectedCustomer);
      } catch (err) {
         handleError(err);
      }
   };

   const onSearch = () => {
      if (!search) {
         setFilteredTableData(tableData);
         return;
      }
      const filteredData = [...tableData].filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));
      setFilteredTableData(filteredData);

      setSelectedFuelTariff(null);
   };

   //Save customer fuel tariff information.
   function updateCust() {
      setSaving(true);

      customerApi
         .saveCustomer(currentCustomer)
         .then((resp) => {
            //success
            setSaving(false);
            openAlertMessage("Customer Fuel Tariffs Successfully Updated.", "success");

            props.getCustomers();
            props.setSelectedCustomer(currentCustomer);

            //Refresh activity
            props.setLoadActivity(true);
         })
         .catch((err) => {
            setSaving(false);
            openAlertMessage(`Error saving customer fuel tariffs: ${err}`, "error");
         });
   }

   function openAlertMessage(alertMessage, alertType) {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {}

   return (
      <Dialog
         open={props.open}
         TransitionComponent={Transition}
         onClose={props.handleClose}
         disableBackdropClick
         disableEscapeKeyDown
         maxWidth={"lg"}
         fullWidth={true}
      >
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            Fuel Tariffs For Customer: {props.selectedCustomer ? props.selectedCustomer.name : ""}
         </DialogTitle>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               style={{ color: "#FFFFFF" }}
               variant="filled"
               severity={alertType ? alertType : "info"}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage(null);
                     }}
                  >
                     <CloseIcon style={{ fontSize: "2rem" }} />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <DialogContent style={{ marginTop: "1.5rem" }}>
            <Grid
               xs={12}
               item
               container
               direction="row"
               justifyContent="space-between"
               alignItems="center"
               className={classes.root}
            >
               <Grid container spacing={0} direction="column" alignItems="flex-start" justifyContent="center" xs={5}>
                  <Grid conteiner item display="flex" flexDirection="row" alignItems="center" width="100%">
                     <Grid item xs={9} style={{ padding: "1%" }}>
                        <TextField
                           style={{
                              width: "100%",
                              borderRadius: "3px",
                           }}
                           id="filled-basic"
                           placeholder="Search"
                           variant="outlined"
                           size="small"
                           InputProps={{
                              startAdornment: (
                                 <InputAdornment position="start">
                                    <SearchIcon style={{ color: "#000000DE" }} />
                                 </InputAdornment>
                              ),
                           }}
                           onChange={(e) => setSearch(e.target.value)}
                        />
                     </Grid>
                     <Grid item xs={3} style={{ padding: "1%" }}>
                        <Button variant="contained" color="primary" onClick={onSearch}>
                           Search
                        </Button>
                     </Grid>
                  </Grid>
                  <StyledDataGrid
                     className={classes.colCellTitle}
                     gridHeight={"620px"}
                     loading={loading}
                     columns={[
                        {
                           field: "name",
                           headerName: "Tariff Name",
                           description: "The name of the fuel tariff.",
                           type: "string",
                           filterable: false,
                           flex: 1.0,
                           cellClassName: (params) =>
                              clsx("super-app", {
                                 found: params.row.found === true,
                                 notFound: params.row.found === false,
                              }),
                        },
                     ]}
                     rows={filteredTableData ? filteredTableData : null}
                     onSelectionModelChange={(params) => {
                        if (params) {
                           if (params.length > 0) {
                              const selectedRowData = _.filter(
                                 filteredTableData,
                                 (x) => x._id.toString() === params[0],
                              );

                              if (selectedRowData) {
                                 if (selectedRowData.length > 0) {
                                    setSelectedFuelTariff(selectedRowData[0]);
                                 }
                              }
                           }
                        }
                     }}
                     hideFooterRowCount={true}
                     hideFooterSelectedRowCount={true}
                  ></StyledDataGrid>
               </Grid>
               <Grid container spacing={0} direction="column" alignItems="flex-start" justifyContent="center" xs={7}>
                  <TransferList
                     leftItems={transferListLeft}
                     rightItems={transferListRight}
                     height={"70vh"}
                     getLists={getLists}
                     leftLabel="Available Terminals"
                     rightLabel="Assigned Terminals"
                  />
               </Grid>
            </Grid>
            <Grid xs={12} item container direction="row" justifyContent="space-between" alignItems="center">
               <Grid container spacing={0} direction="column" alignItems="flex-end" justifyContent="flex-end" xs={12}>
                  <ButtonGroup style={{ marginTop: "1rem" }}>
                     <Button
                        classes={{ label: classes.label }}
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={() => updateCust()}
                        disabled={saving}
                     >
                        {saving ? <CircularProgress size={24} className={classes.buttonProgress} /> : <SaveIcon />}
                        Save
                     </Button>
                     <Button
                        classes={{ label: classes.label }}
                        variant="contained"
                        color="secondary"
                        size="medium"
                        onClick={props.handleClose}
                     >
                        <ExitToAppIcon />
                        Exit
                     </Button>
                  </ButtonGroup>
               </Grid>
            </Grid>
         </DialogContent>
      </Dialog>
   );
};

function mapStateToProps(state) {
   return {
      tariffs: state.fuel.tariff || [],
      terminals: state.terminal.terminals || [],
      customers: state.customer !== null ? state.customer.customer : [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getCustomers: () => dispatch(customerActions.getCustomers()),
   };
}

CustomerFuelTariff.propTypes = {
   customerFuelTariffModal: PropTypes.bool.isRequired,
   open: PropTypes.bool.isRequired,
   selectedCustomer: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired,
   customers: PropTypes.array.isRequired,
   tariffs: PropTypes.array.isRequired,
   terminals: PropTypes.array.isRequired,
   getCustomers: PropTypes.func.isRequired,
   handleClose: PropTypes.func.isRequired,
   setLoadActivity: PropTypes.func.isRequired,
   setSelectedCustomer: PropTypes.func.isRequired,
};

CustomerFuelTariff.defaultProps = {
   customerFuelTariffModal: false,
   open: false,
   selectedCustomer: {},
   user: {},
   customers: [],
   tariffs: [],
   terminals: [],
   getCustomers: () => {
      return;
   },
   handleClose: () => {
      return;
   },
   setLoadActivity: () => {
      return;
   },
   setSelectedCustomer: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFuelTariff);
