import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PropTypes from "prop-types";
import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { currentDate, DAYS_OF_THE_WEEK, FRECUENCY, MONTHLY, YEARLY } from "./constants";
// Material
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// our styles
import "./MessageManangement.css";

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
   backgroundColor: "#002D72",
   color: "white",
   textAlign: "center",
}));

const StyledDialogContent = styled(DialogTitle)(({ theme }) => ({
   padding: "20px 30px",
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
   "& .MuiDialog-paper": {
      minWidth: "500px",
   },
}));

const StyledDiv = styled("div")(({ theme }) => ({
   display: "flex",
   justifyContent: "space-between",
}));

const StyledDivMessage = styled("div")(({ theme }) => ({
   display: "flex",
}));

const montlySx = {
   "& .MuiCalendarPicker-root": {
      "& .PrivatePickersFadeTransitionGroup-root div": {
         color: "#FFFFFF",
      },
      "& .MuiCalendarPicker-viewTransitionContainer": {
         "& .MuiTypography-caption": {
            color: "white",
            height: 0,
            display: "none",
         },
      },
   },
};

const initialState = {
   message: "",
   internal: false,
   external: false,
   schedule: "",
   message_date: "",
   dateOfYear: null, // virtual option
   dayOfTheMonth: null, // virtual option
};

const initialStateDaysOfWeek = {
   sunday: false, // -> 0
   monday: false,
   tuesday: false,
   wednesday: false,
   thursday: false,
   friday: false,
   saturday: false,
};

function reducer(state, action) {
   switch (action.type) {
      case "message":
         return { ...state, message: action.payload };
      case "internal":
         return { ...state, internal: action.payload };
      case "external":
         return { ...state, external: action.payload };
      case "schedule":
         return { ...state, schedule: action.payload };
      case "messageDate":
         return { ...state, message_date: action.payload };
      case "dayOfTheMonth":
         return { ...state, dayOfTheMonth: action.payload };
      case "dateOfYear":
         return { ...state, dateOfYear: action.payload };
      case "reset":
         return { ...initialState };
      default:
         throw new Error();
   }
}

function reducerDayOfWeek(state, action) {
   switch (action.type) {
      case "monday":
         return { ...state, monday: action.payload };
      case "tuesday":
         return { ...state, tuesday: action.payload };
      case "wednesday":
         return { ...state, wednesday: action.payload };
      case "thursday":
         return { ...state, thursday: action.payload };
      case "friday":
         return { ...state, friday: action.payload };
      case "saturday":
         return { ...state, saturday: action.payload };
      case "sunday":
         return { ...state, sunday: action.payload };
      case "reset":
         return { ...initialStateDaysOfWeek };
      default:
         throw new Error();
   }
}

export function MessageManagementModal({ open, handleClose, saveMessage, isLoadingProccess }) {
   const handleError = useErrorHandler();
   const [state, dispatch] = React.useReducer(reducer, initialState);
   const [daysOfWeek, dispatchDaysOfWeek] = React.useReducer(reducerDayOfWeek, initialStateDaysOfWeek);
   const [submit, setSubmit] = React.useState(false);
   const [isDayWeekSelected, setIsDayWeekSelected] = React.useState(false);
   const [isMonthlySelected, setIsMonthlySelected] = React.useState(false);
   const [isYearlySelected, setIsYearlySelected] = React.useState(false);
   const [errorMonth, setErrorMonth] = React.useState(false);
   const [errorYear, setErrorYear] = React.useState(false);

   const handleSave = () => {
      if (isLoadingProccess) return;

      // Validate errors
      const { internal, external, message, schedule, dayOfTheMonth, dateOfYear } = state;
      const checkboxError = [internal, external].filter((v) => v).length < 1;
      const messageError = !message?.trim();
      const scheduleError = !schedule;
      let daysOfWeekError = false;
      let monthlyError = false;
      let yearlyError = false;
      if (schedule === DAYS_OF_THE_WEEK) {
         daysOfWeekError = Object.values(daysOfWeek).filter((v) => v).length < 1;
      }
      if (schedule === MONTHLY && !dayOfTheMonth) {
         monthlyError = true;
      }
      if (schedule === YEARLY && !dateOfYear) {
         yearlyError = true;
      }
      if (checkboxError || messageError || scheduleError || daysOfWeekError || monthlyError || yearlyError) {
         setSubmit(true);
         return;
      }

      // Fill days of the week
      const scheduleDays = [];
      if (schedule === DAYS_OF_THE_WEEK) {
         Object.keys(daysOfWeek).forEach((day, index) => {
            if (daysOfWeek[day]) {
               scheduleDays.push(index);
            }
         });
      }

      const newMessage = { ...state, scheduleDays };

      // Build message_date with month and day -> month/day
      if (schedule === MONTHLY) newMessage.message_date = dayOfTheMonth.getDate() + "";
      else if (schedule === YEARLY) newMessage.message_date = dateOfYear.getMonth() + "/" + dateOfYear.getDate();
      else newMessage.message_date = "";

      if (newMessage.message_date === "NaN") {
         if (schedule === MONTHLY) setErrorMonth(true);
         if (schedule === YEARLY) setErrorYear(true);
         setSubmit(true);
         return;
      }
      if ((schedule === MONTHLY || schedule === YEARLY) && newMessage.message_date !== "NaN") {
         setErrorMonth(false);
         setErrorYear(false);
      }

      delete newMessage.dayOfTheMonth;
      delete newMessage.dateOfYear;
      saveMessage(newMessage);
   };

   const handleOnClose = () => {
      handleClose();
      clearForm();
   };

   const clearForm = () => {
      setSubmit(false);
      dispatch({ type: "reset" });
      dispatchDaysOfWeek({ type: "reset" });
   };

   const handleChange = (event) => {
      let value = event.target.value;
      if (event.target.name === "internal" || event.target.name === "external") {
         value = event.target.checked;
      }
      if (event.target.name === "schedule") {
         switch (value) {
            case DAYS_OF_THE_WEEK:
               setIsDayWeekSelected(true);
               setIsYearlySelected(false);
               setIsMonthlySelected(false);
               break;
            case MONTHLY:
               setIsMonthlySelected(true);
               setIsYearlySelected(false);
               setIsDayWeekSelected(false);
               dispatch({ type: "dayOfTheMonth", payload: "" });
               break;
            case YEARLY:
               setIsYearlySelected(true);
               setIsMonthlySelected(false);
               setIsDayWeekSelected(false);
               dispatch({ type: "dayOfTheMonth", payload: "" });
               break;
            default:
               setIsDayWeekSelected(false);
               setIsMonthlySelected(false);
               setIsYearlySelected(false);
               break;
         }
      }
      dispatch({ type: event.target.name, payload: value });
   };

   const handleChangeDayOfWeek = (event) => {
      dispatchDaysOfWeek({ type: event.target.name, payload: event.target.checked });
   };

   const { internal, external, message, schedule, dayOfTheMonth, dateOfYear } = state;
   const checkboxError = [internal, external].filter((v) => v).length < 1;
   const messageError = !message?.trim();
   const scheduleError = !schedule;
   let daysOfWeekError = false;
   let monthlyError = false;
   let yearlyError = false;
   if (schedule === DAYS_OF_THE_WEEK) {
      daysOfWeekError = Object.values(daysOfWeek).filter((v) => v).length < 1;
   }

   if (schedule === MONTHLY && !dayOfTheMonth) {
      monthlyError = true;
   }

   if (schedule === YEARLY && !dateOfYear) {
      yearlyError = true;
   }

   return (
      <StyledDialog open={open} keepMounted onClose={handleOnClose} className="modal-create-message">
         <StyledDialogTitle>New Message</StyledDialogTitle>
         <StyledDialogContent>
            <StyledDiv>
               <FormControl error={checkboxError && submit}>
                  <FormGroup>
                     <FormControlLabel
                        control={<Checkbox name="internal" checked={state.internal} onChange={handleChange} />}
                        label="Internal"
                     />
                     <FormControlLabel
                        control={<Checkbox name="external" checked={state.external} onChange={handleChange} />}
                        label="External"
                     />
                  </FormGroup>
                  {checkboxError && submit && <FormHelperText className="error-message">Required Field</FormHelperText>}
               </FormControl>
               <FormControl variant="standard" error={scheduleError && submit} className="content__select">
                  <InputLabel id="demo-simple-select-standard-label">Schedule</InputLabel>
                  <Select
                     labelId="select-standard-label"
                     name="schedule"
                     error={scheduleError && submit}
                     value={state.schedule}
                     onChange={handleChange}
                     label="schedule"
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {FRECUENCY.map((frecuency) => (
                        <MenuItem key={frecuency} value={frecuency}>
                           {frecuency}
                        </MenuItem>
                     ))}
                  </Select>
                  {scheduleError && submit && <FormHelperText className="error-message">Required Field</FormHelperText>}
               </FormControl>
            </StyledDiv>
            {isDayWeekSelected && (
               <section>
                  <Divider textAlign="left" className="text--divider">
                     Choose the days of the week
                  </Divider>
                  <FormControl error={daysOfWeekError && submit}>
                     <FormGroup className="day-of-the-week">
                        <FormControlLabel
                           control={
                              <Checkbox checked={daysOfWeek.monday} onChange={handleChangeDayOfWeek} name="monday" />
                           }
                           label="Monday"
                        />
                        <FormControlLabel
                           control={
                              <Checkbox checked={daysOfWeek.tuesday} onChange={handleChangeDayOfWeek} name="tuesday" />
                           }
                           label="Tuesday"
                        />
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={daysOfWeek.wednesday}
                                 onChange={handleChangeDayOfWeek}
                                 name="wednesday"
                              />
                           }
                           label="Wednesday"
                        />
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={daysOfWeek.thursday}
                                 onChange={handleChangeDayOfWeek}
                                 name="thursday"
                              />
                           }
                           label="Thursday"
                        />
                        <FormControlLabel
                           control={
                              <Checkbox checked={daysOfWeek.friday} onChange={handleChangeDayOfWeek} name="friday" />
                           }
                           label="Friday"
                        />
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={daysOfWeek.saturday}
                                 onChange={handleChangeDayOfWeek}
                                 name="saturday"
                              />
                           }
                           label="Saturday"
                        />
                        <FormControlLabel
                           control={
                              <Checkbox checked={daysOfWeek.sunday} onChange={handleChangeDayOfWeek} name="sunday" />
                           }
                           label="Sunday"
                        />
                     </FormGroup>
                     {daysOfWeekError && submit && (
                        <FormHelperText className="error-message">Required Field</FormHelperText>
                     )}
                  </FormControl>
               </section>
            )}
            {isMonthlySelected && (
               <section>
                  <Divider textAlign="left" className="text--divider">
                     Choose the day of the Month
                  </Divider>
                  <div className="message-date-section">
                     <FormControl variant="standard" error={monthlyError && submit} className="content__select pepes">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <DatePicker
                              views={["day"]}
                              label="Day of the Month"
                              inputFormat="dd"
                              mask="__"
                              PopperProps={{
                                 sx: montlySx,
                              }}
                              value={state.dayOfTheMonth}
                              defaultCalendarMonth={currentDate}
                              onChange={(newValue) => {
                                 if (errorMonth && !isNaN(newValue)) setErrorMonth(false);
                                 if (!errorMonth && isNaN(newValue)) setErrorMonth(true);
                                 dispatch({ type: "dayOfTheMonth", payload: newValue });
                              }}
                              renderInput={(params) => <TextField {...params} helperText={null} />}
                           />
                        </LocalizationProvider>
                        {monthlyError && submit && (
                           <FormHelperText className="error-message">Required Field</FormHelperText>
                        )}
                        {errorMonth && submit && (
                           <FormHelperText className="error-message">Format is not valid</FormHelperText>
                        )}
                     </FormControl>
                  </div>
               </section>
            )}
            {isYearlySelected && (
               <section>
                  <Divider textAlign="left" className="text--divider">
                     Choose the date in the Year
                  </Divider>
                  <div className="message-date-section">
                     <FormControl variant="standard" error={yearlyError && submit} className="content__select">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <DatePicker
                              views={["month", "day"]}
                              label="Date of the year"
                              inputFormat="MM/dd"
                              mask="__/__"
                              value={state.dateOfYear}
                              onChange={(newValue) => {
                                 if (errorYear && !isNaN(newValue)) setErrorYear(false);
                                 if (!errorYear && isNaN(newValue)) setErrorYear(true);
                                 dispatch({ type: "dateOfYear", payload: newValue });
                              }}
                              renderInput={(params) => <TextField {...params} helperText={null} />}
                           />
                        </LocalizationProvider>
                        {yearlyError && submit && (
                           <FormHelperText className="error-message">Required Field</FormHelperText>
                        )}
                        {submit && errorYear && (
                           <FormHelperText className="error-message">Format is not valid</FormHelperText>
                        )}
                     </FormControl>
                  </div>
               </section>
            )}
            <StyledDivMessage>
               <FormControl error={messageError && submit} className="content__input-message">
                  <TextField
                     name="message"
                     multiline
                     label="Message"
                     error={messageError && submit}
                     rows={4}
                     value={state.message}
                     onChange={handleChange}
                  />
                  {messageError && submit && <FormHelperText className="error-message">Required Field</FormHelperText>}
               </FormControl>
            </StyledDivMessage>
         </StyledDialogContent>
         <DialogActions className="actions">
            <Tooltip title="Cancel" arrow>
               <IconButton onClick={handleOnClose} variant="contained" color="default">
                  <CloseIcon />
               </IconButton>
            </Tooltip>
            <Tooltip title={isLoadingProccess ? "Loading" : "Save"} arrow>
               <IconButton onClick={handleSave} variant="contained" color="primary">
                  {isLoadingProccess ? <CircularProgress size={20} disableShrink /> : <SaveIcon />}
               </IconButton>
            </Tooltip>
         </DialogActions>
      </StyledDialog>
   );
}

MessageManagementModal.propTypes = {
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   saveMessage: PropTypes.func.isRequired,
   isLoadingProccess: PropTypes.bool.isRequired,
};

export default MessageManagementModal;
