import { GetApp } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, Paper, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as applicationApi from "../../api/applicationApi";
import { escapeRegExp } from "../../common/escapeRegExp";
import DeleteDialog from "../Delete/DeleteDialog";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";
import AliasNew from "./AliasNew";

const useStyles = makeStyles((theme) => ({
   secondaryHeader: {
      borderBottom: "1px solid #2F3136",
      zIndex: "3",
      width: "100%",
   },
   layoutMain: {
      marginTop: "2rem",
      position: "fixed",
      width: "inherit",
      height: "100%",
   },
   layoutBody: {
      position: "absolute",
      height: "calc(100% - 9.5rem)",
      width: "100%",
      overflowY: "scroll",
   },
   layoutBody2: {
      position: "absolute",
      height: "calc(100% - 12.5rem)",
      width: "100%",
      overflowY: "scroll",
   },
   search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      border: "1px solid black",
      backgroundColor: "#ffff",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "30%",
      float: "right",
      [theme.breakpoints.up("sm")]: {
         marginLeft: theme.spacing(3),
         width: "auto",
      },
   },
   searchIcon: {
      color: "black",
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   save: {
      backgroundColor: "#002D72",
      float: "right",
      color: "white",
      marginRight: "1rem",
      "&:hover": {
         backgroundColor: "#010440",
      },
   },
   back: {
      color: "white",
      backgroundColor: "#2F3136",
      float: "right",
      marginRight: "1rem",
      "&:hover": {
         backgroundColor: "black",
      },
   },
   inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
         width: "20ch",
      },
   },
   listGroup: {
      width: "100%",
      maxHeight: "59%",
   },
   tabInfo: {
      maxHeight: "59%",
   },
   modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   modalInfo: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
   },
   tabForm: {
      paddingTop: "2%",
   },
   listDrawer: {
      width: "300px",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
   },
}));

const AliasView = (props) => {
   //////////////////////////init //////////////////////////////////////////////////////////
   var history = useHistory();
   var handleError = useErrorHandler();

   let [tableData, setTableData] = useState([]);
   let [gridLoading, setGridLoading] = useState(true);
   let [aliases, setAliases] = useState(null);
   let [newAliasModal, setNewAliasModal] = useState(false);
   let [deleteModal, setDeleteModal] = useState(false);
   let [selectedAlias, setSelectedAlias] = useState(null);
   let [openDrawer, setOpenDrawer] = useState(false);
   let [loading, setLoading] = useState(true);
   let [showAlert, setShowAlert] = useState(false);
   let [alertMessage, setAlertMessage] = useState(null);
   let [alertType, setAlertType] = useState("");
   let [readOnly, setReadOnly] = useState(false);

   const classes = useStyles();

   var headerData = [
      { id: "value", allign: "center", disablePadding: false, label: "Value", type: "string" },
      { id: "alias", allign: "center", disablePadding: false, label: "Alias", type: "string" },
      { id: "add_date", allign: "center", disablePadding: false, label: "Date Added", type: "date" },
   ];

   useEffect(() => {
      getAliases();
   }, []);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "alias");
      var userReadOnly = true;

      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   function getAliases() {
      setLoading(true);

      applicationApi
         .getAlias(null)
         .then((resp) => {
            setAliases(resp.aliases);

            var newAliasObject = _.cloneDeep(resp.aliases);

            if (newAliasObject) {
               if (newAliasObject.length) {
                  Object.keys(newAliasObject).forEach(function (key) {
                     newAliasObject[key].id = newAliasObject[key]._id;
                  });

                  setTableData(newAliasObject);
                  setGridLoading(false);
               }
            }

            setLoading(false);
         })
         .catch((err) => {
            console.error(err);
            setLoading(false);
         });
   }

   function newAliasClick() {
      try {
         setOpenDrawer(false);
         setNewAliasModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   function deleteAlias() {
      setDeleteModal(!deleteModal);

      applicationApi
         .deleteAlias(selectedAlias._id)
         .then((resp) => {
            openAlertMessage("Alias Successfully Deleted.", "success");

            getAliases();
         })
         .catch((err) => openAlertMessage(`Error deleting alias: ${err}`, "error"));
   }

   const handleDeleteClick = (p_alias) => {
      try {
         setSelectedAlias(p_alias.row);
         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   };

   const handleCloseNew = () => {
      try {
         setNewAliasModal(!newAliasModal);
         getAliases();
      } catch (err) {
         handleError(err);
      }
   };

   function onChangeFilter(event, searchValue = null) {
      try {
         setGridLoading(true);

         //create blank array for return
         var filteredObjects = [];
         //this turns values into lowercase strings
         var regValue = new RegExp(
            searchValue ? searchValue.toLowerCase() : escapeRegExp(event.target.value).toLowerCase(),
            "g",
         );
         //loop through header data since it contains the field names we need to check
         headerData.map((header) => {
            var filter = [];
            //we can only do this on strings, so make sure the type is filled in via the parent component
            if (header.type === "string") {
               //fill the filter on the original table data that was passed in from parent
               filter = aliases.filter((filt) => {
                  //return a match where the filter index is the name of the header id
                  return filt[header.id].toLowerCase().match(regValue);
               });
            } else {
               //fill the filter on the original table data that was passed in from parent
               filter = aliases.filter((filt) => filt[header.id === (searchValue ? searchValue : event.target.value)]);
            }
            //check to see if it returned objects
            if (filter.length > 0) {
               //concat merges arrays since we are adding data from one array to another.  Push only adds a single item/object to an existing array
               filteredObjects = filteredObjects.concat(filter);
            }
         });
         //remove duplicates
         filteredObjects = _.uniqBy(filteredObjects, "_id");

         if (filteredObjects) {
            if (filteredObjects.length) {
               Object.keys(filteredObjects).forEach(function (key) {
                  filteredObjects[key].id = filteredObjects[key]._id;
               });
            }
         }

         //set the hook with the newly filtered data and it will render
         setTableData(filteredObjects);
         setGridLoading(false);
      } catch (err) {
         setGridLoading(false);
         handleError(err);
      }
   }

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   //data scrubbing function to return string values for display in table
   const dateFormatter = (value) => {
      try {
         var dateString = value;
         var newDate = new Date(dateString);
         const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(newDate);
         const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(newDate);
         const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(newDate);
         return month + "/" + day + "/" + year;
      } catch {
         return "00/00/0000";
      }
   };

   return (
      <>
         <div id={"aliasView"} className={classes.layoutMain}>
            <div id={"aliasViewHeader"} className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                  <Grid item xs={7}>
                     <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                        <Link color="inherit" style={{ cursor: "pointer" }} onClick={() => breadcrumbOnClick("/alias")}>
                           <h3 style={{ fontWeight: "500" }}>{"Alias"}</h3>
                        </Link>
                     </Breadcrumbs>
                  </Grid>
                  <Grid item xs={3}>
                     <TextField
                        style={{
                           width: "100%",
                           borderRadius: "3px",
                        }}
                        id="filled-basic"
                        placeholder="Search"
                        variant="outlined"
                        size="small"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <SearchIcon style={{ color: "#000000DE" }} />
                              </InputAdornment>
                           ),
                        }}
                        onChange={onChangeFilter}
                     />
                  </Grid>
                  <Grid item xs={2}>
                     <CSVLink
                        style={{ WebkitTextFillColor: "white", width: "-webkit-fill-available" }}
                        data={tableData ? tableData : []}
                        filename={Date.now() + "_alias.csv"}
                     >
                        <Tooltip title="Export to CSV file" arrow>
                           <IconButton
                              style={{ marginRight: "1rem", float: "right" }}
                              variant="contained"
                              color="primary"
                           >
                              <GetApp style={{ fontSize: "2rem" }} />
                           </IconButton>
                        </Tooltip>
                     </CSVLink>
                     <Tooltip title="New Alias" arrow>
                        <IconButton
                           style={{ marginLeft: "2rem", float: "right" }}
                           variant="contained"
                           color="primary"
                           onClick={newAliasClick}
                           disabled={readOnly}
                        >
                           <AddIcon style={{ fontSize: "2rem" }} />
                        </IconButton>
                     </Tooltip>
                  </Grid>
               </Grid>
               <Collapse in={showAlert}>
                  <Alert
                     style={{ color: "#FFFFFF" }}
                     variant="filled"
                     severity={alertType ? alertType : "success"}
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                              {
                                 closeAlertMessage();
                              }
                           }}
                        >
                           <CloseIcon fontSize="inherit" />
                        </IconButton>
                     }
                  >
                     {alertMessage}
                  </Alert>
               </Collapse>
            </div>
            <div id={"aliasViewBody"} className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}>
               <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
                  <Grid item xs={12}>
                     <Paper elevation={3}>
                        <StyledDataGrid
                           gridHeight={"785px"}
                           className={classes.colCellTitle}
                           loading={gridLoading}
                           columns={[
                              {
                                 field: "value",
                                 headerName: "Value",
                                 description: "Original city name.",
                                 type: "string",
                                 flex: 0.6,
                              },
                              {
                                 field: "alias",
                                 headerName: "Alias",
                                 description: "Alias of city.",
                                 type: "string",
                                 flex: 0.6,
                              },
                              {
                                 field: "add_date",
                                 headerName: "Date Added",
                                 description: "Date when alias was first added.",
                                 type: "date",
                                 valueFormatter: ({ value }) => dateFormatter(value),
                                 flex: 0.3,
                              },
                              {
                                 field: "_id",
                                 headerName: " ", //This is a space because material-UI default to field unless there is a value.
                                 type: "string",
                                 sortable: false,
                                 filterable: false,
                                 renderCell: (params) => (
                                    <div>
                                       <Tooltip title="Delete Alias" arrow>
                                          <IconButton
                                             variant="contained"
                                             color="secondary"
                                             size="small"
                                             onClick={() => handleDeleteClick(params)}
                                             disabled={readOnly}
                                          >
                                             <DeleteForeverIcon />
                                          </IconButton>
                                       </Tooltip>
                                    </div>
                                 ),
                                 flex: 0.1,
                              },
                           ]}
                           rows={tableData ? tableData : null}
                        ></StyledDataGrid>
                     </Paper>
                  </Grid>
               </Grid>
            </div>
         </div>
         <DeleteDialog
            open={deleteModal}
            handleCancel={() => setDeleteModal(!deleteModal)}
            deleteFunc={deleteAlias}
            title={selectedAlias && `Delete ${selectedAlias.alias}`}
            text={selectedAlias && `Are you sure you want to delete ${selectedAlias.alias} from alias?`}
         />
         <AliasNew
            open={newAliasModal}
            handleClose={handleCloseNew}
            openAlertMessage={openAlertMessage}
            aliases={aliases}
         />
      </>
   );
};

function mapStateToProps(state) {
   return {
      user: state.user.currentUser,
   };
}

AliasView.propTypes = {
   user: PropTypes.object,
};

AliasView.defaultProps = {
   user: {},
};

export default connect(mapStateToProps)(AliasView);
