import CloseIcon from "@mui/icons-material/Close";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import React from "react";
import "./DeleteDialog.css";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteDialog = (props) => {
   var deleteTitle = props.title || "Delete Item?";
   var deleteText = props.text || "Are you sure you want to delete this item?";

   return (
      <Dialog
         className="delete-modal"
         open={props.open || false}
         TransitionComponent={Transition}
         keepMounted
         onClose={props.handleClose}
      >
         <DialogTitle className="dialog-title">{deleteTitle}</DialogTitle>
         <DialogContent className="dialog-content">
            <DialogContentText>{deleteText}</DialogContentText>
         </DialogContent>
         <DialogActions className="actions">
            <Tooltip title="Cancel" arrow>
               <IconButton onClick={props.handleCancel} variant="contained" color="default">
                  <CloseIcon />
               </IconButton>
            </Tooltip>
            <Tooltip title="Delete" arrow>
               <IconButton onClick={props.deleteFunc} variant="contained" color="secondary">
                  <DeleteForever />
               </IconButton>
            </Tooltip>
         </DialogActions>
      </Dialog>
   );
};

DeleteDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   text: PropTypes.string.isRequired,
   title: PropTypes.string.isRequired,
   deleteFunc: PropTypes.func.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleCancel: PropTypes.func.isRequired,
};

DeleteDialog.defaultProps = {
   open: false,
   text: "",
   title: "",
   deleteFunc: () => {
      return;
   },
   handleClose: () => {
      return;
   },
   handleCancel: () => {
      return;
   },
};

export default DeleteDialog;
