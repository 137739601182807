import AddIcon from "@mui/icons-material/Add";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Paper } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as notificationApi from "../../api/notificationApi";

const NotificationView = (props) => {
   var history = useHistory();
   let [notifications, setNotifications] = useState([]);
   let [page, setPage] = useState(0);

   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         borderBottom: "1px solid #2F3136",
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         fontSize: "2rem",
      },
      save: {
         float: "right",
         fontSize: "2rem",
         marginRight: "1rem",
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      inputRoot: {},
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
   }));

   const classes = useStyles();

   useEffect(() => {
      getNotification();
   }, [props]);

   function getNotification() {
      var newNotifications = _.cloneDeep(notifications);

      notificationApi
         .getNotifications(null, page)
         .then((resp) => {
            newNotifications = newNotifications.concat(resp.notifications);
            setNotifications(newNotifications);
            setPage(+page + 1);
            if (resp.notifications.length == 0) {
               alert(`Reached end of notifications`);
            }
         })
         .catch((err) => {
            console.error(`Error Loading Notifications: ${err}`);
         });
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   return (
      <>
         <div id={"notificationView"} className={classes.layoutMain}>
            <div id={"notificationViewHeader"} className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                  <Grid item xs={3}>
                     <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                        <Link
                           color="inherit"
                           style={{ cursor: "pointer" }}
                           onClick={() => breadcrumbOnClick("/Notification/NotificationView")}
                        >
                           <h3 style={{ fontWeight: "500" }}>{"Notifications"}</h3>
                        </Link>
                     </Breadcrumbs>
                  </Grid>
               </Grid>
            </div>
            <div id={"notificationViewBody"} className={classes.layoutBody}>
               <Timeline position="alternate">
                  {notifications.map((not) => (
                     <TimelineItem key={`tli-${not._id}`}>
                        <TimelineSeparator>
                           <TimelineDot />
                           <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent key={not._id}>
                           <Paper style={{ padding: "3%", wordWrap: "break-word", fontSize: "12px" }}>
                              {not.notification}
                           </Paper>
                        </TimelineContent>
                     </TimelineItem>
                  ))}
               </Timeline>
               <div
                  style={{
                     width: "100%",
                     position: "absolute",
                     marginTop: "-1rem",
                     marginBottom: "1rem",
                     textAlign: "center",
                  }}
               >
                  <Tooltip title="Load More" arrow>
                     <Fab color="primary" aria-label="add" onClick={() => getNotification(notifications)}>
                        <AddIcon />
                     </Fab>
                  </Tooltip>
               </div>
            </div>
         </div>
      </>
   );
};

export default NotificationView;
