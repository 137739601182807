import { HandleError, HandleResponse } from "./apiUtils";
import { HEADERS } from "./headers";
const baseUrl = "/api/order/";

export class orderQuery {
   constructor(
      destination,
      order_id,
      orderNumber,
      customer_id,
      user_id,
      terminal_id,
      rate_id,
      add_date,
      startDate,
      endDate,
      minRate,
      maxRate,
   ) {
      this.destination = destination;
      this.order_id = order_id;
      this.orderNumber = orderNumber;
      this.customer_id = customer_id;
      this.user_id = user_id;
      this.terminal_id = terminal_id;
      this.rate_id = rate_id;
      this.add_date = add_date;
      this.startDate = startDate;
      this.endDate = endDate;
      this.minRate = minRate;
      this.maxRate = maxRate;
   }
}

export function checkout(orderInfo, accessorialComment) {

   return fetch(baseUrl + "checkout", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ orderInfo, accessorialComment }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getOrders(orderQuery, page, isPortal, limit) {

   return fetch(baseUrl + "getOrders", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ orderQuery, page, isPortal, limit }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getOrdersCount(orderQuery) {

   return fetch(baseUrl + "getOrdersCount", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ orderQuery }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getRateSheet(order_id, previewOrder) {

   return fetch(baseUrl + "getRateSheet", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ order_id, previewOrder }),
   })
      .then((response) => response)
      .catch(HandleError);
}

export function sendRateSheetEmail(emailAddresses, order_id, line_id) {

   return fetch(baseUrl + "emailRateSheet", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ emailAddresses, order_id, line_id }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getRateSheetPortal(order_id, previewOrder) {

   return fetch(baseUrl + "getRateSheetPortal", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ order_id, previewOrder }),
   })
      .then((response) => response)
      .catch(HandleError);
}

export function sendRateSheetEmailPortal(emailAddresses, order_id, line_id) {

   return fetch(baseUrl + "emailRateSheetPortal", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ emailAddresses, order_id, line_id }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function searchOrders(searchValue) {

   return fetch(baseUrl + "search", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ searchValue }),
   })
      .then(HandleResponse)
      .catch((err) => console.error(err));
}

export function setArchived(orderInfo) {

   return fetch(baseUrl + "setArchived", {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({ orderInfo }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}
