import * as notificationApi from "../../api/notificationApi";
import actionTypes from "./actionTypes";

export function getNotifications(userId) {
   return function (dispatch) {
      notificationApi
         .getNotifications(userId)
         .then((resp) => {
            dispatch({ type: actionTypes.GET_NOTIFICATIONS, notifications: resp.notifications });
         })
         .catch((error) => {
            console.error("Getting notifications error: " + error);
            return;
         });
   };
}

export function getUnreadNotifications() {
   return function (dispatch) {
      notificationApi
         .getNotifications(false)
         .then((resp) => {
            dispatch({
               type: actionTypes.GET_UNREAD_NOTIFICATIONS,
               notifications: resp.notifications,
            });
         })
         .catch((error) => {
            console.error("Getting unread notifications error: " + error);
            return;
         });
   };
}

export function removeNotification(notification_id) {
   notificationApi
      .removeNotification(notification_id)
      .then((resp) => {})
      .catch((err) => console.error(err));

   return { type: actionTypes.REMOVE_NOTIFICATIONS, notification_id };
}
